import React from 'react'
import UpdatePwdForm from '../Component/UpdatePwd/UpdatePwdForm'


const LoginPage = () => {
  return (
    <div className="container d-flex justify-content-center align-items-center ">
      <UpdatePwdForm />
    </div>

  )
}

export default LoginPage