import React, { useState , useEffect, useCallback , useRef} from 'react'
import { useSelector , useDispatch , Provider} from 'react-redux';
import moment from "moment";
import Page from '../../Component/All/Page'
import Datep from '../../Component/All/Date'
import { FormattedMessage , useIntl } from "react-intl";
import Spinner from '../Spinner/Spinner';
import ReplyIcon from '@mui/icons-material/Reply';
import DateRangeIcon from '@mui/icons-material/DateRange';

import Swal from 'sweetalert2'

import DatePicker, { registerLocale } from "react-datepicker";
import { subDays , addDays } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import zhTW from "date-fns/locale/zh-TW"; 
registerLocale("zhTW", zhTW); 


var currentDate = new Date();
currentDate.setDate(currentDate.getDate());
currentDate.setHours(0, 0, 0, 0);

var endToDate = new Date();
endToDate.setHours(23, 59, 59, 999);
const AgentInfo = ({setBoxSwitch}) => {
  const intl = useIntl();

  const [loading , setLoading] = useState(true)
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(endToDate);
  const [firstLoading, setFirstLoading] = useState(true)
  const startRef = useRef()
  const endRef = useRef()

  //redux
  const dispatch = useDispatch();
  const AgentInfoData = useSelector(state => state.agent.agentInfo)

  const searchHandler = () => {
    // event.preventDefault();

    //第一次loading讀取當天列表
    if(firstLoading === true){
      const startStamp = 0;
      const endStamp = 0;
      dispatch({ type: 'SAGA_FETCH_AGENTINFO_API' ,startStamp , endStamp , setLoading});
      setFirstLoading(false)
      console.log('111');
    } else{
      const encodedStartTime = moment(startRef.current.props.selected).format('YYYY-MM-DD HH:mm:ss');
      const encodedEndTime = moment(endRef.current.props.selected).format('YYYY-MM-DD HH:mm:ss');
      const startStamp = parseInt(`${moment(encodedStartTime, 'YYYY-MM-DD HH:mm:ss').unix()}000`);
      const endStamp = parseInt(`${moment(encodedEndTime, 'YYYY-MM-DD HH:mm:ss').unix()}999`);
      dispatch({ type: 'SAGA_FETCH_AGENTINFO_API' ,startStamp , endStamp , setLoading});
      console.log('2222');
    }
  };

  useEffect(() => {
    searchHandler()
  }, []);



  return (
    <div className="bettingRecordWrap bottWrap">
        <div className='titleFlex'>
            <button className='backBtn' onClick={()=>{
                setBoxSwitch(false)
            }}
            ><ReplyIcon/></button>
            
            <div>
              <FormattedMessage
                    id={`profile.agentInfo`}
              ></FormattedMessage>
            </div>
        </div>
        <div className='dataFlex'>
          <div className='timeTitle'>
            <FormattedMessage
                  id={`game.time`}
            ></FormattedMessage>
          </div>
          <DateRangeIcon/>
        </div>
        {/* <div className='dataFlex'>
          
            <DatePicker
                selected={startDate}
                onChange={(date) => {
                    setStartDate(date)
                    setEndDate()
                    console.log(date)
                }}
                startDate={startDate}
                endDate={endDate}
                maxDate={new Date()}
                ref={startRef}
                showTimeInput
                dateFormat="MM/dd/yyyy h:mm aa"
            />
            <span className='space'>-</span>
            
            <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                maxDate={addDays(startDate , 30)}
                ref={endRef}
                showTimeInput
                dateFormat="MM/dd/yyyy h:mm aa"
            />
        </div> */}
         <Datep
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        startRef={startRef}
        endRef={endRef}
        />
        <button className='searchBtn' 
            onClick={() => {
                    if (startDate && endDate) {
                        searchHandler();
                    } else {
                        Swal.fire({
                            icon: "warning",
                            text: intl.formatMessage({
                              id: 'alert.notSelected',
                            }),
                            showConfirmButton: true,
                        });
                    }
                }}>
                <FormattedMessage
                  id={`profile.inquire`}
                ></FormattedMessage>
        </button>
        <div className='listBx'>
            {loading ? <Spinner/> :
                <div>
                   <div>
                      <FormattedMessage
                          id={`agent.teamBetTotal`}
                      ></FormattedMessage>
                     {AgentInfoData.data.bet_amount}
                    </div>
                    <div>
                      <FormattedMessage
                          id={`agent.teamDeposit`}
                      ></FormattedMessage>
                      {AgentInfoData.data.deposit}
                    </div>
                    <div>
                      <FormattedMessage
                          id={`agent.personalBonus`}
                      ></FormattedMessage>
                      {AgentInfoData.data.dividends}
                    </div>
                    <div>
                      <FormattedMessage
                          id={`agent.teamPayout`}
                      ></FormattedMessage>
                      {AgentInfoData.data.win}
                    </div>
                    <div>
                      <FormattedMessage
                          id={`agent.teamWinOrLose`}
                      ></FormattedMessage>
                      {AgentInfoData.data.win}
                    </div>
                    <div>
                      <FormattedMessage
                          id={`agent.teamPayment`}
                      ></FormattedMessage>
                      {AgentInfoData.data.withdraw}
                    </div>
                </div>
                
            }
        </div>
    </div>
  )
}

export default AgentInfo