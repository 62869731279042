import React, { useEffect, useCallback , useRef , useState , useContext} from 'react'
import { useSelector , useDispatch } from 'react-redux';
import { FormattedMessage , useIntl } from "react-intl";

import Spinner from '../Spinner/Spinner';
import { HiMailOpen } from 'react-icons/hi';

import api from '../../api/api';
import Swal from 'sweetalert2'
import ReplyIcon from '@mui/icons-material/Reply';
import { ErrorContext } from '../../Routes';


const countryList = [
  {
    name:'越南',
    cnum:84,
    value:'vn'
  },
  {
    name:'日本',
    cnum:81,
    value:'jp'
  },
  {
    name:'韓國',
    cnum:82,
    value:'hq'
  },
  {
    name:'中國',
    cnum:86,
    value:'cn'
  },
  {
    name:'泰國',
    cnum:66,
    value:'tl'
  },
  {
    name:'緬甸',
    cnum:95,
    value:'mya'
  },
  {
    name:'馬來西亞',
    cnum:60,
    value:'mala'
  },
  {
    name:'柬埔寨',
    cnum:855,
    value:'cam'
  },
  {
    name:'新加玻',
    cnum:65,
    value:'singa'
  },
  {
    name:'菲律賓',
    cnum:63,
    value:'philip'
  },
  {
    name:'寮國',
    cnum:856,
    value:'Laos'
  },
  {
    name:'西班牙',
    cnum:34,
    value:'tbn'
  },
  {
    name:'葡萄牙',
    cnum:351,
    value:'bdn'
  },
  {
    name:'英國',
    cnum:44,
    value:'aq'
  },
  {
    name:'德國',
    cnum:49,
    value:'duc'
  },
  {
    name:'美國',
    cnum:1,
    value:'my'
  },
  {
    name:'法國',
    cnum:33,
    value:'phap'
  },
  {
    name:'印度',
    cnum:91,
    value:'ando'
  },
  {
    name:'台灣',
    cnum:886,
    value:'tw'
  },
]
const PhoneVerificationMsg = ({setBoxSwitch , getData}) => {

    const phoneRef = useRef()
    const phoneVerifRef = useRef()
    const { ErrorMessage } = useContext(ErrorContext);

    const [nowCountry , setNowCountry] = useState('84') 
    const [countdown, setCountdown] = useState(0);

    const intl = useIntl();

   // 用ref 紀錄 input value
   const usernameRef = useRef()
   const oldPasswordRef = useRef()
   const passwordRef = useRef()
  //redux
  const dispatch = useDispatch();
  const agentGroup = useSelector(state => state.agent)


  useEffect(() => {
//   dispatch({ type: 'SAGA_FETCH_AGENTGROUP_API'});
  }, [dispatch]);

  const atRegisterHandler = async (event) => {
    event.preventDefault();
    try {
        const res = await api.patch('/member', {
          // name : usernameRef.current.value,
          phone: phoneRef.current.value,
          country_code: nowCountry,
          // name : usernameRef.current.value
        }, {
          headers: {
            "Content-Type": "application/json",
            "x-platform-code": localStorage.getItem("x-platform-code"),
            "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        if (res.data.status) {
          console.log(res.data.data)
          Swal.fire({
            icon: "success",
            text: intl.formatMessage({
              id: 'alert.success',
            }),
            showConfirmButton: false,
            timer: 1000,
            });
          setBoxSwitch(false)
          getData()
        }
      } catch (err) {
        const code = err.response.status
        const msg = err.response.data.data.error
        ErrorMessage(code, msg , 'setPhone')
        console.log(err);
      }
  };
  const atPasswordEditHandler = async (event) => {
    event.preventDefault();
    try {
        const res = await api.patch('/member/password', {
          old_password : oldPasswordRef.current.value,
          new_password: passwordRef.current.value,
        }, {
          headers: {
            "Content-Type": "application/json",
            "x-platform-code": localStorage.getItem("x-platform-code"),
            "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        if (res.data.status) {
          console.log(res.data.data)
          Swal.fire({
            icon: "success",
            text: intl.formatMessage({
              id: 'alert.success',
            }),
            showConfirmButton: false,
            timer: 1000,
            });
          setBoxSwitch(false)
          getData()
        }
      } catch (err) {
        const code = err.response.status
        const msg = err.response.data.data.error
        ErrorMessage(code, msg , 'password')
        console.log(err);
      }
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [countdown]);

  const verPhone = async (e) =>{
    e.preventDefault();
    try {
      const res = await api.post('/verification-msg', {
        type:2,
        phone: phoneRef.current.value,
        country_code: '62',
      }, {
        headers: {
          "Content-Type": "application/json",
          "x-platform-code": localStorage.getItem("x-platform-code"),
        },
      });
      if (res.data.status) {
        console.log(res.data.data)
        Swal.fire({
          icon: "success",
          text: intl.formatMessage({
            id: 'alert.success',
          }),
          showConfirmButton: false,
          timer: 1000,
        });
        if (countdown === 0) {
            setCountdown(5);
        }
      }
    } catch (err) {
        const code = err.response.status
        const msg = err.response.data.data.error
        ErrorMessage(code, msg , 'sendVerificationMsg')
        console.log(err);
    }

    
  }

  return (
    <div className="phoneVerificationMsgWrap bottWrap">
        <div className='titleFlex'>
            <button className='backBtn' onClick={()=>{
                setBoxSwitch(false)
            }}
            ><ReplyIcon/></button>
            
            <div>
              <FormattedMessage
                  id={`profile.infoEdit`}
              ></FormattedMessage>
            </div>
        </div>
        
         <form action="">
            {/* <div className='mb-3'>
                <input
                ref={usernameRef}
                type="text"
                required
                placeholder={intl.formatMessage({
                  id: 'login.name',
                })} 
                maxlength="20"/>
            </div> */}
            {/* <div className='remaker'>6~20字英數字組合</div> */}
            {/* <div>
                <input
                ref={passwordRef}
                type="password" 
                required
                placeholder='密碼' 
                maxlength="20"/>
            </div>
            <div className='remaker'>6~20字英數字組合</div> */}
            <div className="phoneInput mb-3">
                <select
                //  ref={platformCodeRef} 
                onChange={(e) => {
                    setNowCountry(e.target.value)
                }}
                >
                    {/* <option>請選擇</option> */}
                    {countryList.map((v)=>{
                        return <option key={v.name} value={v.cnum}>
                            <FormattedMessage
                                id={`profile.${v.value}`}
                            ></FormattedMessage>
                        </option>
                    })}
                
                </select>
                <input
                ref={phoneRef}
                type="text"
                required
                // placeholder='手機號碼' 
                placeholder={intl.formatMessage({
                  id: 'profile.phoneNumber',
                })}
               
                />
            </div>
            {/* <button className='mb-3 com-btn2'   onClick={verPhone} disabled={countdown > 0}>
                {countdown === 0 ? 
                <FormattedMessage
                    id={`profile.SendVerificationCode`}
                ></FormattedMessage>
                 : 
                  <>
                    {countdown}
                    <FormattedMessage
                        id={`profile.SecondsToResend`}
                    ></FormattedMessage>
                  </>
                  
                }
            </button>
            <div className="loginInput">
                <input
                    ref={phoneVerifRef}
                    type="text"
                    required
                    // placeholder='驗證碼' 
                    placeholder={intl.formatMessage({
                      id: 'profile.verificationCode',
                    })}
                    />
                <HiMailOpen />
            </div> */}
            <button className='registerBtn com-btn' type="submit" onClick={atRegisterHandler}>
              <FormattedMessage
                  id={`game.confirm`}
              ></FormattedMessage>
            </button>
         </form>
        
         <div className='titleFlex mt-3'>
            <button className='backBtn' onClick={()=>{
                setBoxSwitch(false)
            }} style={{ opacity : 0}}
            ><ReplyIcon/></button>
            
            <div>
              <FormattedMessage
                  id={`profile.password`}
              ></FormattedMessage>
            </div>
        </div>

        <form action="">
            <div className='mb-3'>
                <input
                ref={oldPasswordRef}
                type="password"
                required
                // placeholder='舊密碼' 
                placeholder={intl.formatMessage({
                  id: 'profile.oldpassword',
                })} 
                maxlength="20"/>
            </div>
            <div>
                <input
                ref={passwordRef}
                type="password" 
                required
                // placeholder='新密碼' 
                placeholder={intl.formatMessage({
                  id: 'profile.newpassword',
                })} 
                maxlength="20"/>
            </div>

            <button className='registerBtn com-btn' type="submit" onClick={atPasswordEditHandler}>
              <FormattedMessage
                  id={`game.confirm`}
              ></FormattedMessage>
            </button>
         </form>
        

            
            
    </div>
  )
}

export default PhoneVerificationMsg