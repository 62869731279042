import { takeEvery, all, put } from 'redux-saga/effects';
import API from '../../../api/api';
import Swal from 'sweetalert2'

function* fetchAgentInfoAPI({startStamp ,endStamp , setLoading}) {
  yield setLoading(true);
  const { data } = yield API.get(`/agents?start_at=${startStamp}&end_at=${endStamp}`,{
    headers: {
    "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
    "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
    }
  }); 
  yield put({
    type: 'FETCH_AGENTINFO_API',
    payload: data
  })
  yield setLoading(false);
}

// 團隊管理
function* fetchAgentGroupAPI({platformMemberID , setLoading , setTotalPage , perpage , nowPage , startStamp , endStamp}) {
  yield setLoading(true);
  const { data } = yield API.get(`/agents/group?agent_type=all&member_account=${platformMemberID}&page=${nowPage}&per_page=${perpage}&start_at=${startStamp}&end_at=${endStamp}`,{
    headers: {
    "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
    "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
    }
  }); 
  yield put({
    type: 'FETCH_AGENTGROUP_API',
    payload: data
  })
  yield setTotalPage(Math.ceil(data.data.pagination.total / perpage))
  yield setLoading(false);

}

function* createAgentAPI({ username, password , ErrorMessage}) {
  try{
    const { data } = yield API.post(`/agents`, {
      account : username,
      password : password
    },{
      headers: {
        "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
        "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
      }
    }); 
   
    if(data.status === true){
      yield Swal.fire({
        text: "新增成功",
        showConfirmButton: false,
        timer: 1000,
      });
      yield put({
        type: 'CREAT_AGENT__API',
      })
    }
  }catch (err){
      const code = err.response.status;
      const msg = err.response.data.data.error_msg;
      yield console.log(err);
      yield ErrorMessage(code, msg)
  }

 
}
// 團隊盈虧
function* fetchAgentPlAPI({platformMemberID , setLoading , setTotalPage , perpage , nowPage , startStamp , endStamp}) {
  yield setLoading(true);
  const { data } = yield API.get(`/agents/pl?agent_type=all&member_account=${platformMemberID}&page=${nowPage}&per_page=${perpage}&start_at=${startStamp}&end_at=${endStamp}`,{
    headers: {
    "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
    "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
    }
  }); 
  yield put({
    type: 'FETCH_AGENTPL_API',
    payload: data
  })
  yield setTotalPage(Math.ceil(data.data.pagination.total / perpage))
  yield setLoading(false);

}
// 取得團隊下注
function* fetchAgentBetsAPI({platformMemberID , setLoading , setTotalPage , perpage , nowPage, startStamp , endStamp}) {
  yield setLoading(true);
  const { data } = yield API.get(`/agents/bets?member_account=${platformMemberID}&page=${nowPage}&per_page=${perpage}&start_at=${startStamp}&end_at=${endStamp}&language=${localStorage.getItem("language")}`,{
    headers: {
    "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
    "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
    }
  }); 
  yield put({
    type: 'FETCH_AGENTBETS_API',
    payload: data
  })
  yield setTotalPage(Math.ceil(data.data.pagination.total / perpage))
  yield setLoading(false);

}


function* watchFetchAgentInfoAPI() {
  yield takeEvery('SAGA_FETCH_AGENTINFO_API', fetchAgentInfoAPI);
}

function* watchFetchAgentGroupAPI() {
  yield takeEvery('SAGA_FETCH_AGENTGROUP_API', fetchAgentGroupAPI);
}
function* watchFetchAgentPlAPI() {
  yield takeEvery('SAGA_FETCH_AGENTPL_API', fetchAgentPlAPI);
}
function* watchFetchUnsplashInfoAPI() {
  yield takeEvery('SAGA_CREAT_AGENT_API', createAgentAPI);
}
function* watchFetchAgentBetsAPI() {
  yield takeEvery('SAGA_FETCH_AGENTBETS_API', fetchAgentBetsAPI);
}
export function* agentsSaga() {
  yield all([
    watchFetchAgentInfoAPI(),
    watchFetchAgentGroupAPI(),
    watchFetchUnsplashInfoAPI(),
    watchFetchAgentPlAPI(),
    watchFetchAgentBetsAPI()
  ]);
}