import React, { useEffect} from 'react'
import './GamePlace.scss';
import { FormattedMessage } from "react-intl";
import api from '../../api/api';
import Swal from 'sweetalert2'



const TwoStar = ({
    setRankID,laneID,setLaneID,handlePlayButtonClick,sizeID,handlePlaceShow,handleTotalClick,nowChip
    }) => {
    const Lane = [
        '1-2','1-3','1-4','1-5','1-6','1-7','1-8','1-9','1-10','2-3','2-4','2-5','2-6','2-7','2-8','2-9','2-10','3-4','3-5','3-6','3-7','3-8','3-9','3-10','4-5','4-6','4-7','4-8','4-9','4-10','5-6','5-7','5-8','5-9','5-10','6-7','6-8','6-9','6-10','7-8','7-9','7-10','8-9','8-10','9-10'
    ]
    useEffect(() => {
        handleTotalClick()
        setRankID(['group'])
    }, [laneID , sizeID ,nowChip]);
    return (

        <div className="gameWrap d-flex">
          <div className="itemWrap sumWrap">
            <h5 className='mt-2'>
              <FormattedMessage
                  id={`game.model4`}
              />
            </h5>
            <div className="circleWrap d-flex">
              {Lane.map((v) => {
                return (
                  <div key={v} className='circleBx'>
                    <div className="name"
                    data-active={laneID.includes(v)}
                    onClick={() => {
                        handlePlayButtonClick(v,laneID,setLaneID)
                        handlePlaceShow()
                    }}
                    >
                    <i>{v}</i>
                    </div>
                  </div> 
                )
              })}
            </div>
          </div>
        </div>
      )
}


export default TwoStar
