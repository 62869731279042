import React, { useRef, useState , useEffect , useContext } from 'react'
import { FormattedMessage , useIntl } from "react-intl";
// import LoadCanvasTemplate from 'react-simple-captcha';
// import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import './RegisterForm.scss';
import { HiQrCode, HiOutlineTicket, HiUser, HiLockClosed , HiFlag , HiMailOpen , HiOutlinePencil , HiOutlineClipboardCheck , HiRefresh} from 'react-icons/hi';
import { Link } from "react-router-dom";
import api , { NowplatformCode } from '../../api/api';
import { ErrorContext } from '../../Routes';

let url = new URL(window.location.href);
let query = url.href.split('?')[1];

// console.log(url.href.substring(1))

const countryList = [
  {
    name:'越南',
    cnum:84,
    value:'vn'
  },
  {
    name:'日本',
    cnum:81,
    value:'jp'
  },
  {
    name:'韓國',
    cnum:82,
    value:'hq'
  },
  {
    name:'中國',
    cnum:86,
    value:'cn'
  },
  {
    name:'泰國',
    cnum:66,
    value:'tl'
  },
  {
    name:'緬甸',
    cnum:95,
    value:'mya'
  },
  {
    name:'馬來西亞',
    cnum:60,
    value:'mala'
  },
  {
    name:'柬埔寨',
    cnum:855,
    value:'cam'
  },
  {
    name:'新加玻',
    cnum:65,
    value:'singa'
  },
  {
    name:'菲律賓',
    cnum:63,
    value:'philip'
  },
  {
    name:'寮國',
    cnum:856,
    value:'Laos'
  },
  {
    name:'西班牙',
    cnum:34,
    value:'tbn'
  },
  {
    name:'葡萄牙',
    cnum:351,
    value:'bdn'
  },
  {
    name:'英國',
    cnum:44,
    value:'aq'
  },
  {
    name:'德國',
    cnum:49,
    value:'duc'
  },
  {
    name:'美國',
    cnum:1,
    value:'my'
  },
  {
    name:'法國',
    cnum:33,
    value:'phap'
  },
  {
    name:'印度',
    cnum:91,
    value:'ando'
  },
  {
    name:'台灣',
    cnum:886,
    value:'tw'
  },
]

const now = new Date();
const currentHour = now.getHours() < 10 ? `0${now.getHours()}` : `${now.getHours()}`;
const currentMinutes = now.getMinutes() < 10 ? `0${now.getMinutes()}` : `${now.getMinutes()}`;

const RegisterForm = () => {
  const { ErrorMessage } = useContext(ErrorContext);

  const navigate = useNavigate();
  const intl = useIntl();
  // 用ref 紀錄 input value
  const usernameRef = useRef()
  const passwordRef = useRef()
  const checkPasswordRef = useRef()
  const invitedRef = useRef()
  const nameRef = useRef()
  const platformCodeRef = useRef()
  const phoneRef = useRef()
  const phoneVerifRef = useRef()

  const [nowCountry , setNowCountry] = useState('84') 
  const [countdown, setCountdown] = useState(0);
  const [csPhone , setcsPhone] = useState('');
  const [csLoading , setCsLoading] = useState(true)
  
  const Register_URL = `/register`;
  const CS_URL = `/cs?language=${localStorage.getItem('language')}`;

  // 註冊
  const atRegister = async (e) => {
    e.preventDefault();
    try {
      const res = await api.post(Register_URL, JSON.stringify({
        account: usernameRef.current.value,
        password: passwordRef.current.value,
        check_password: checkPasswordRef.current.value,
        name: nameRef.current.value,
        invitation_code: invitedRef.current.value,
        country_code:nowCountry,
        phone: phoneRef.current.value,
        // verification_code: phoneVerifRef.current.value
      }), {
        headers: {
          "Content-Type": "application/json",
          "x-platform-code": NowplatformCode,
        },
      });
      if (res.data.status) {
        console.log(res.data.data)
        Swal.fire({
          icon: "success",
          // text: intl.formatMessage({
          //   id: 'alert.success',
          // }),
          showConfirmButton: false,
          timer: 1000,
        });
        setTimeout(() => {
          navigate('/');
        }, 1000);
      }
    } catch (err) {
      const code = err.response
      const msg = err.error
      ErrorMessage(code, msg)
    }
  };
  // 驗證碼api
  const verPhone = async (e) =>{
    e.preventDefault();
    try {
      const res = await api.post('/verification-msg', JSON.stringify({
        type:1,
        phone: phoneRef.current.value,
        country_code: '62',
      }), {
        headers: {
          "Content-Type": "application/json",
          "x-platform-code": NowplatformCode,
        },
      });
      if (res.data.status) {
        console.log(res.data.data)
        Swal.fire({
          icon: "success",
          text: intl.formatMessage({
            id: 'alert.success',
          }),
          showConfirmButton: false,
          timer: 1000,
        });
      }
    } catch (err) {
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg , 'sendVerificationMsg')
    }

    if (countdown === 0) {
      setCountdown(5);
    }
  }

  // 拿客服電話
  const getCSphone = async () => {
    setCsLoading(true)
    try {
      const res = await api.get(CS_URL, {
        headers: {
          "x-platform-code": NowplatformCode,
          "Content-Type": "application/json",
        },
      });
      if (res.data.status) {
        setcsPhone(res.data.data)
        setCsLoading(false)
      }
    } catch (err) {
      console.log(err);
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg , 'cs')
    }
  };
  const goCS = (index) =>{
    window.location = `https://t.me/${csPhone[index].phone}`
  }

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [countdown]);

  // useEffect(() => {
  //   console.log(query);
  // },[query])

  useEffect(()=>{
    localStorage.setItem("language", 'vn');
  },[])
 

  const handleButtonClick = (e) => {
    e.preventDefault();
    let user_captcha = document.getElementById('user_captcha_input').value;

    if (validateCaptcha(user_captcha)===true) {
      // 驗證成功
        loadCaptchaEnginge(4,'white','black','upper');
        document.getElementById('user_captcha_input').value = "";
        if(passwordRef.current.value === checkPasswordRef.current.value){
          atRegister(e)
        } else{
          //密碼確認不一致
          Swal.fire({
            icon: "warning",
            text: intl.formatMessage({
              id: 'alert.passwordCheck',
            }),
            showConfirmButton: false,
            timer: 1000,
          });
        }
    }
    else {
      // 驗證碼錯誤
        Swal.fire({
          icon: "warning",
          text: intl.formatMessage({
            id: 'alert.verifError',
          }),
          showConfirmButton: false,
          timer: 1000,
        });
        document.getElementById('user_captcha_input').value = "";
    }
  
  };

  useEffect(() => {
    loadCaptchaEnginge(4,'white','black','upper');
    getCSphone()
  }, []);

  return (
    <div className="registerWrap">
     
      <div className='logo'></div>
      <form action="" onSubmit={handleButtonClick}>
        <h3>
          <FormattedMessage
              id={`register.register`}
          ></FormattedMessage> 
        </h3>
        <div className="loginInput mb-3">
          <input
            ref={usernameRef}
            type="text"
            required
            placeholder={intl.formatMessage({
              id: 'login.username',
            })} />
          <HiUser />
        </div>
        <div className="loginInput mb-3">
          <input
            ref={passwordRef}
            type="password"
            required
            placeholder={intl.formatMessage({
              id: 'login.password',
            })} />
          <HiLockClosed />
        </div>
        <div className="loginInput mb-3">
          <input
            ref={checkPasswordRef}
            type="password"
            required
            placeholder={intl.formatMessage({
              id: 'login.checkPassword',
            })} 
            // placeholder="確認密碼"
            />
          <HiLockClosed />
        </div>
        <div className="loginInput mb-3">
          <input
            ref={invitedRef}
            type="text"
            // required
            defaultValue={query}
            placeholder={intl.formatMessage({
              id: 'profile.InvitationCode',
            })} />
          <HiOutlineTicket />
        </div>
        <div className="loginInput mb-3">
          <input
            ref={nameRef}
            type="text"
            required
            placeholder={intl.formatMessage({
              id: 'login.name',
            })} 
            // placeholder="姓名"
            />
          <HiOutlinePencil />
        </div>
        {/* <div className="loginInput mb-3">
          <input
            ref={platformCodeRef}
            type="text"
            required
            placeholder={intl.formatMessage({
              id: 'login.sitecode',
            })} />
            <HiFlag />
            

        </div> */}
        <div className="phoneInput mb-3">
          <select
           onChange={(e) => {
            setNowCountry(e.target.value)
          }}
          >
            {countryList.map((v)=>{
                return <option key={v.name} value={v.cnum}>
                  <FormattedMessage
                      id={`profile.${v.value}`}
                  ></FormattedMessage>
                </option>
            })}
          
          </select>
          <input
            ref={phoneRef}
            type="text"
            required
            placeholder={intl.formatMessage({
              id: 'profile.phoneNumber',
            })}  />
        </div>
        {/* <button className='mb-3 com-btn2'   onClick={verPhone} disabled={countdown > 0}>
          {countdown === 0 ?  
            <FormattedMessage
                id={`profile.SendVerificationCode`}
            ></FormattedMessage> 
            :
            <>
              {countdown}
              <FormattedMessage
                  id={`profile.SecondsToResend`}
              ></FormattedMessage>
            </>
            }
        </button> */}
        {/* <div className="loginInput">
          <input
            ref={phoneVerifRef}
            type="text"
            required
            placeholder={intl.formatMessage({
              id: 'profile.verificationCode',
            })} />
          <HiMailOpen />
        </div> */}
         <div className="loginInput mb-3">
          <input
            type="text"
            required
            // placeholder={intl.formatMessage({
            //   id: 'login.password',
            // })} 
            id="user_captcha_input" name="user_captcha_input"
            placeholder={intl.formatMessage({
              id: 'profile.verificationCode',
            })}
            />
          <HiOutlineClipboardCheck />
        </div>
        <div className='captchaBx'>
          <LoadCanvasTemplate reloadText={intl.formatMessage({
              id: 'trader.manualRefresh',
            })} reloadColor="#A0CFFF"/>
        </div>
        
        <input className='com-btn' type="submit"
        // onClick={handleButtonClick}
        value={intl.formatMessage({
          id: 'register.register',
        })}/>
        <div className="links">
          <Link to="/">
            <FormattedMessage
                id={`register.takeawalk`}
            ></FormattedMessage> 
          </Link>
          {
            csLoading || csPhone.map((v)=>{
              return(
                <Link onClick={()=>goCS(v.phone)}>
                  <div>{v.name}</div>
                </Link>
              )
            })
          }
        </div>
      </form>

    </div>
  )
}

export default RegisterForm