import React, { useState , useEffect, useCallback , useRef , useContext} from 'react'

// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import { createStyles, makeStyles } from '@mui/styles';

import Paper from '@mui/material/Paper';
import { TextInput } from "./TextInput";
import { MessageLeft, MessageRight } from "./Message";
import CloseIcon from '@mui/icons-material/Close';
import GroupIcon from '@mui/icons-material/Group';
import api from '../../../api/api';
import Swal from 'sweetalert2'
import moment from "moment";
import { ErrorContext } from '../../../Routes';

export default function Chat({setMessageOpen,setBanListOpen,nowGameID}) {
  const { ErrorMessage , TimeMoment } = useContext(ErrorContext);
  const blockRef = useRef(null);

  const inputRef = useRef(null);
  const [accNambe] = useState(localStorage.getItem("accName"))
  const [megData , setMegData] = useState([])
  const [megList , setMegList] = useState([])
  const [megLoaging , setMegLoading] = useState(true)
  const [lastMegID , setLastMegID] = useState()
  const [newMegID , setNewMegID] = useState()
  const [sendMsgScroll , setSendMsgScroll] = useState(true)
  const [memberAccountList , setMemberAccountListt] = useState([])
  const [intervalId, setIntervalId] = useState(null);
  const [removedItems, setRemovedItems] = useState([]);


  const getMsg = async() => {
    try {
      const res = await api.get(`/games/115/chat/messages?sort=asc`,{
          headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
          },
      });
      if (res.data.status) {
         setMegData(res.data.data)
         setMegList(res.data.data.response)
         setLastMegID(res.data.data.response[0].id)
         setMegLoading(false)
         setNewMegID(res.data.data.response[res.data.data.response.length - 1].id)
         setMemberAccountListt(res.data.data.member_account)
         if(res.data.data.deleted.length !== 0){
          setRemovedItems(res.data.data.deleted)
         }
      }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    }

  }
  //撈最新
  const getNewMsg = async() => {
    try {
      const res = await api.get(`/games/115/chat/messages?sort=asc&id=${newMegID}&order=after`,{
          headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
          },
      });
      if (res.data.status) {
         setMegList(megList.concat(res.data.data.response))
         setMemberAccountListt({...res.data.data.member_account , ...memberAccountList})
         if(res.data.data.response.length > 0){
          setNewMegID(res.data.data.response[res.data.data.response.length - 1].id)
         }
         if(res.data.data.deleted.length !== 0){
          setRemovedItems(res.data.data.deleted)
         }
      }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    }

  }
  //撈歷史
  const getHistoryMsg = async(id) => {
    try {
      const res = await api.get(`/games/115/chat/messages?sort=asc&id=${id}&order=before`,{
          headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
          },
      });
      if (res.data.status) {
         console.log(res.data)
         setLastMegID(res.data.data.response[0].id)
         setMegList(res.data.data.response.concat(megList))
         setMemberAccountListt({...res.data.data.member_account , ...memberAccountList})
      }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    }

  }
  // 送出信息
  const sendMsg = async(CurrentValue) => {
    try {
      const res = await api.post(`/games/115/chat/messages`,
      {
        'text' : CurrentValue
      },
      {
          headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
          },
      });
      if (res.data.status) {
         getNewMsg()
         setMegData(res.data.data)
         setSendMsgScroll(!sendMsgScroll)
        
      }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    }

  }
  // 封禁對象
  const handelBanMember = async(id) => {
    // setMegLoading(true)
    try {
      const res = await api.post(`/games/115/chat/ban/${id}`,{},{
          headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
          },
      });
      if (res.data.status) {
         console.log(res.data)
         Swal.fire({
          icon: "success"
        });
      }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    }

  }
  // 刪除信息
  const deleteMsg = async(id) => {
    try {
      const res = await api.delete(`/games/115/chat/messages/${id}`,
      {
          headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
          },
      });
      if (res.data.status) {
        Swal.fire({
          icon: "success"
        });
      }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    }

  }

  useEffect(()=>{
    getMsg()
  },[])
  useEffect(() => {
      const interval = setInterval(() => {
        getNewMsg();
      }, 3000);
  
      return () => {
        clearInterval(interval); // 在每次重新渲染前取消定時器
      };
  }, [newMegID,megList]); // 

  useEffect(() => {
    // if (blockRef.current) {
      blockRef.current.scrollTop = blockRef.current.scrollHeight;
      setTimeout(()=>{
        blockRef.current.scrollTop = blockRef.current.scrollHeight;
      },1000)
    // }
  }, [megData,sendMsgScroll]);

  useEffect(() => {
    // 監聽區塊的滾動事件
    const handleScroll = () => {
      // 如果捲動位置在最上方且滾動方向為向上，則觸發get()函數
      if (blockRef.current.scrollTop === 0 && blockRef.current.scrollTop - blockRef.current.oldScrollTop < 0) {
        console.log('拿歷史')
        getHistoryMsg(lastMegID)
      }
      // 更新上一次的捲動位置
      blockRef.current.oldScrollTop = blockRef.current.scrollTop;
    };

    // 為區塊添加滾動事件監聽器
    if (blockRef.current) {
      blockRef.current.addEventListener('scroll', handleScroll);
    }

    // 清除事件監聽器
    return () => {
      if (blockRef.current) {
        blockRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [lastMegID]); 

  return (
    <div className={'chatWrap'}>
      <Paper className={'chatBx'} zDepth={2}>
        <Paper id="style-1" className={'chatPaperBx'} ref={blockRef} >
          {
            megLoaging || megList.map((v)=>{
              return (
                removedItems.includes(v.id) ? null :
                accNambe === v.create_by_id ?
                <MessageRight
                  message={v.text}
                  timestamp={TimeMoment(v.create_at)}

                  photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
                  displayName={memberAccountList[v.create_by_id]}
                  avatarDisp={true}
                  key={v.id}
                  messageID={v.id}
                  deleteMsg={deleteMsg}
                />
                :
                <MessageLeft
                  message={v.text}
                  timestamp={TimeMoment(v.create_at)}
                  // photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
                  displayName={memberAccountList[v.create_by_id]}
                  avatarDisp={true}
                  createID={v.create_by_id}
                  handelBanMember={handelBanMember}
                  key={v.id}
                  messageID={v.id}
                  deleteMsg={deleteMsg}
                />
                
              )
            })
          }
        </Paper>
        <TextInput  sendMsg={sendMsg} inputRef={inputRef}/>
        <button className="CloseBtn"
            onClick={()=>{
                setMessageOpen(false)
            }}
        >
            <CloseIcon/>
        </button>
        {
          localStorage.getItem("isAdmin") === 'true' &&
          <button className="BanListBtn"
              onClick={()=>{
                  setBanListOpen(true)
              }}
          >
            <GroupIcon/>
          </button>
        }
      </Paper>
    </div>
  );
}
