import React from 'react'
import './GamePlace.scss';
import CloseIcon from '@mui/icons-material/Close';

const GameRule = ({setRulePop , ruleText}) => {
    
    return (

        <div className='ruleWrap'>
            <div className='ruleDes'>
                <div className='ruleClose'
                onClick={() => {
                    // handleBoxSwitch('rule');
                    setRulePop(false)
                  }}
                >
                <CloseIcon/>
                </div>
                <div className='ruleScrollBx' dangerouslySetInnerHTML={{ __html: ruleText }} >
                    {/* {ruleText} */}
                    
                </div>
                
            </div>
        </div>
      )
}


export default GameRule
