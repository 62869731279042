import React, { useState , useEffect, useCallback , useRef , useContext} from 'react'
import { NavLink , Link } from "react-router-dom";
import { useNavigate , useParams } from "react-router-dom";
import { FormattedMessage , useIntl } from "react-intl";
import { useSelector , useDispatch , Provider} from 'react-redux';
import moment from "moment";
import AvatarStatus from './AvatarStatus'
import './Traders.scss'
import Page from '../../Component/All/Page'
import Datep from '../../Component/All/Date'
import ReplyIcon from '@mui/icons-material/Reply';
import Swal from 'sweetalert2'
import Switch from '@mui/material/Switch';
import { FaUser } from 'react-icons/fa';
// traderList
import avatar from '../../images/traders/avatar.png';
import number from '../../images/traders/icon1.png';
// date
import DatePicker, { registerLocale } from "react-datepicker";
import { subDays , addDays } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import RefreshIcon from '@mui/icons-material/Refresh';
import zhTW from "date-fns/locale/zh-TW"; 
import { textAlign } from '@mui/system';
import { ErrorContext } from '../../Routes';



registerLocale("zhTW", zhTW); 

const recordArr = [
    {
        name:'極速賽車',
        ric:'1000000',
        profit:100,
        player_bet:"組,1-4,10",
        content:'123456789',
    },
    {
        name:'極速賽車',
        ric:'2000000',
        profit:100,
        player_bet:"組,1-4,10",
        content:'123456789',
    }
]
var currentDate = new Date();
currentDate.setDate(currentDate.getDate());
currentDate.setHours(0, 0, 0, 0);

var endToDate = new Date();
endToDate.setHours(23, 59, 59, 999);

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const TrderRecordPage = () => {
  const { ErrorMessage , TimeMoment } = useContext(ErrorContext);

  const { TrderID , MirrorID } = useParams();
  const [refresh , setRefresh] = useState(false)
  const [loading, setLoading] = useState(true)
  const [listMirrorloading, setListMirrorLoading] = useState(true)
  const [listWaitLoading, setListWaitLoading] = useState(false)
  const [perpage , setPerpage] = useState(10)
  const [nowPage, setNowPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [boxSwitch, setBoxSwitch] = useState(false)
  const [model , setModel] = useState('model2')
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(endToDate);
  const [firstLoading , setFirstLoading] = useState(true)
  const [selRefresh , setSelRefresh] = useState(0)
  const [timerId , setTimeId] = useState(null); 
  const startRef = useRef()
  const endRef = useRef()
  const navigate = useNavigate();
  //redux
  const dispatch = useDispatch();
  const getTrader = useSelector(state => state.trader.getTrader)
  const listMirrorTradingsBets = useSelector(state => state.trader.listMirrorTradingsBets)
  const listWait = useSelector(state => state.trader.listWait)

  const getDataApi = () =>{
    const encodedStartTime = moment(startRef.current.props.selected).format('YYYY-MM-DD HH:mm:ss');
    const encodedEndTime = moment(endRef.current.props.selected).format('YYYY-MM-DD HH:mm:ss');

    const startStamp = parseInt(`${moment(encodedStartTime, 'YYYY-MM-DD HH:mm:ss').unix()}000`) - 86400000;
    const endStamp = parseInt(`${moment(encodedEndTime, 'YYYY-MM-DD HH:mm:ss').unix()}999`);

    dispatch({ type: 'SAGA_FETCH_LISTWAIT_API' , setListWaitLoading, TrderID , ErrorMessage })
    dispatch({ type: 'SAGA_FETCH_LISTMIRRORTRADINGBETS_API', TrderID , setListMirrorLoading , startStamp ,  endStamp , nowPage , perpage , setTotalPage , ErrorMessage});
}

  useEffect(() => {
    dispatch({ type: 'SAGA_FETCH_TRADER_API', setLoading, TrderID , ErrorMessage});
    dispatch({ type: 'SAGA_FETCH_LISTWAIT_API' , setListWaitLoading, TrderID , ErrorMessage })
    // getDataApi()
  }, [refresh]);

  const searchHandler = useCallback(( nowPage = 1 , perpage = 10) => {
    // event.preventDefault();
    // if(firstLoading){
    //     const startStamp = 0;
    //     const endStamp = 0;
    //     setFirstLoading(false)
    //     dispatch({ type: 'SAGA_FETCH_LISTMIRRORTRADINGBETS_API', TrderID , setListMirrorLoading , startStamp ,  endStamp , nowPage , perpage , setTotalPage});
        
    //     console.log('1111' , firstLoading)
    // } else{
        const encodedStartTime = moment(startRef.current.props.selected).format('YYYY-MM-DD HH:mm:ss');
        const encodedEndTime = moment(endRef.current.props.selected).format('YYYY-MM-DD HH:mm:ss');
    
        const startStamp = parseInt(`${moment(encodedStartTime, 'YYYY-MM-DD HH:mm:ss').unix()}`) - 86400000;
        const endStamp = parseInt(`${moment(encodedEndTime, 'YYYY-MM-DD HH:mm:ss').unix()}`);
    
        dispatch({ type: 'SAGA_FETCH_LISTMIRRORTRADINGBETS_API', TrderID , setListMirrorLoading , startStamp ,  endStamp , nowPage , perpage , setTotalPage , ErrorMessage});
    // }
   

  },[]);

  useEffect(() => {
    searchHandler(nowPage,perpage)
  }, [searchHandler,nowPage,perpage]);

  const field1 = [
    {
        name: "冠",
        value : "champion",
    },
    {
        name: "亞",
        value : "second",
    },
    {
        name: "三",
        value : "third",
    },
    {
        name: "四",
        value : "fourth",
    },
    {
        name: "五",
        value : "fifth",
    },
    {
        name: "六",
        value : "sixth",
    },
    {
        name: "七",
        value : "seventh",
    },
    {
        name: "八",
        value : "eighth",
    },
    {
        name: "九",
        value : "ninth",
    },
    {
        name: "十",
        value : "tenth",
    },
    {
        name: "特",
        value : "sp",
    },
    {
        name: "二星",
        value : "group",
    },
    {
        name: "1pk10",
        value : "1pk10",
    },
    {
        name: "2pk9",
        value : "2pk9",
    },
    {
        name: "3pk8",
        value : "3pk8",
    },
    {
        name: "4pk7",
        value : "4pk7",
    },
    {
        name: "5pk6",
        value : "5pk6",
    },
  ]
  const field2 = [
    {
        name: "大",
        value : "big",
    },
    {
        name: "小",
        value : "small",
    },
    {
        name: "单",
        value : "odd",
    },
    {
        name: "双",
        value : "even",
    },
    {
        name: "大双",
        value : "big_even",
    },
    {
        name: "小双",
        value : "small_even",
    },
    {
        name: "大单",
        value : "big_odd",
    },
    {
        name: "小单",
        value : "small_odd",
    },
    {
        name: "庄",
        value : "banker",
    },
    {
        name: "闲",
        value : "player",
    },
    {
        name: "龙",
        value : "dragon",
    },
    {
        name: "虎",
        value : "tiger",
    },
  ] 
  const judgeField = (player_bet , num) =>{
    if(num === 1){
        var element = field1.find(item => item.value === player_bet.split(',').slice(0, 2)[0]);
        return element ? element.name : player_bet.split(',').slice(0, 2)[0];
    } else if (num ===2){
        var element = field2.find(item => item.value === player_bet.split(',').slice(0, 2)[1]);
        return element ? element.name : player_bet.split(',').slice(0, 2)[1];
    }
  }


  const autoRefresh = (event) => {
    setSelRefresh(event.target.value);
  }
  const startFun = () => {

    if(selRefresh === '0'){
        setTimeId(clearInterval(timerId));
        console.log('0')
    } else if (selRefresh === '5'){
        setTimeId(setInterval(getDataApi, 5000));
        console.log('555')
    }
  }
  useEffect(() => {
    startFun();
  }, [selRefresh]);
  return (
    <div className="traderRecordPage homePage page home-bg mt-0">
        <div className='traderHeader'>
            <button className='backBtn' onClick={()=>{
                  navigate(`/traders`);
              }}
            ><ReplyIcon/></button>
            <div className='title'>
            {/* Riwayat order */}
                <FormattedMessage
                    id={`trader.followUpRecords`}
                ></FormattedMessage> 
            </div>
            <FaUser onClick={() => {
                    navigate(`/traderMyHome`);
            }}/>
        </div>
       <div className='container'>
            {
                loading || 
                <AvatarStatus model={model} avaterData={getTrader}/>
            }
            <div className='btnflex'>
                <button className='refreshBtn' onClick={() => getDataApi()}>
                <FormattedMessage
                    id={`trader.manualRefresh`}
                ></FormattedMessage>  <RefreshIcon/>
                </button>
                <div>
                    <span>
                        <FormattedMessage
                            id={`trader.whetherRefresh`}
                        ></FormattedMessage> 
                    </span>
                    {/* <Switch/> */}
                    <select onChange={autoRefresh}>
                        <option value={0}>
                            <FormattedMessage
                                id={`trader.negate`}
                            ></FormattedMessage> 
                        </option>
                        <option value={5}>
                            <FormattedMessage
                                id={`trader.5seconds`}
                            ></FormattedMessage> 
                        </option>
                    </select>
                </div>
            </div>
           
            {
                listWaitLoading && listWait.data.response.length > 0 ?
                <div className='recordBx mt-4 mb-4 waitRecordBx'>
                        <div className='recordItem red'>
                            <div className='f16' style={{textAlign:'center' , marginBottom:'10px'}}>
                                <FormattedMessage
                                    id={`trader.notDrawn`}
                                ></FormattedMessage> 
                            </div>
                            <div className='title'>      
                                <div>{listWait.data.game_name[listWait.data.response[0].game_id]}</div>
                                <div>
                                    {listWait.data.response[0].game_round_id.replace("rc-", "").substr(-7)}
                                    <FormattedMessage
                                        id={`trader.issue`}
                                    ></FormattedMessage> 
                                </div>
                            </div>
                            {/* <div className='f16 content mt-1 mb-1'>{moment(v.create_at).format('YYYY/MM/DD h:mm:ss')}</div> */}
                            <div className='desTitle'>
                                <div>
                                    <FormattedMessage
                                        id={`trader.bettingContent`}
                                    ></FormattedMessage>
                                </div>
                                <div>
                                    <FormattedMessage
                                        id={`trader.betAmount`}
                                    ></FormattedMessage>
                                </div>
                            </div>
                            {
                                console.log(listWait.data.response.length < 1)
                            }
                            {
                                listWait.data.response.map((v)=>{
                                    return(
                                        <div key={v.player_bet} className='betC'>
                                            {/* <div className='f16'>{v.player_bet}</div> */}
                                            <div className='f16'>{
                                                //  v.game_id === 1 &&
                                                 `${judgeField(v.player_bet , 1)},${judgeField(v.player_bet , 2)}`
                                            }</div>
                                            <div className='f16'>{v.amount}</div>
                                        </div>
                                    )
                                })
                            }
                            <div className='betC'>
                                <div className='f16'>
                                    <FormattedMessage
                                        id={`trader.total`}
                                    ></FormattedMessage>
                                </div>
                                <div className={listWait.data.result > 0 ? 'f16 green' : 'f16 red'}>{listWait.data.result}</div>
                            </div>
                        </div>
                </div>
                :
                <div className='f16' style={{ textAlign : 'center' , marginBottom : '10px'}}>
                    <FormattedMessage
                        id={`trader.noopen`}
                    ></FormattedMessage>
                </div>
            }

           
            {/* <div className='dataFlex mt-4'>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                        setStartDate(date)
                        // setEndDate(date)
                        setEndDate()
                        console.log(date)
                    }}
                    // selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={new Date()}
                    // minDate={subDays(new Date(), 30)}
                    // locale="zhTW"
                    ref={startRef}
                    showTimeInput
                    dateFormat="MM/dd/yyyy h:mm aa"
                />
                <span className='space'>-</span>
                
                <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    // selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    maxDate={addDays(startDate , 8)}
                    // locale="zhTW"
                    ref={endRef}
                    showTimeInput
                    dateFormat="MM/dd/yyyy h:mm aa"
                />

               
            </div> */}
            <Datep
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                startRef={startRef}
                endRef={endRef}
            />
            <div className='dataFlex mt-4'>
                <button className='searchBtn' 
                    onClick={() => {
                        if (startDate && endDate) {
                            searchHandler(1,10);
                            setNowPage(1);
                        } else {
                            Swal.fire({
                                icon: "warning",
                                // text: "時間未選擇完全",
                                showConfirmButton: true,
                            });
                        }
                    }}>
                    {/* mencari */}
                    <FormattedMessage
                        id={`profile.inquire`}
                    ></FormattedMessage>
                </button>
            </div>

           <div className='recordBx mt-5'>
            {
                listMirrorloading || 
                listMirrorTradingsBets.data.response.map((v)=>{
                    return(
                        <div key={v.game_round_id} className='recordItem'>
                            <div className='title'>
                                <div>{listMirrorTradingsBets.data.game_name[v.game_id]}</div>
                                <div>{v.game_round_id.replace("rc-", "").substr(-7)}</div>
                            </div>
                            <div className='f16 content mt-1 mb-1'>
                                {/* {moment(v.lottery_draw_at).format('YYYY/MM/DD HH:mm:ss')} */}
                                {TimeMoment(v.lottery_draw_at)}
                            </div>
                            <div className='desTitle'>
                                {/* <div>Isi Taruhan</div>
                                <div>Hasil</div> */}
                                <div>
                                    <FormattedMessage
                                        id={`trader.bettingContent`}
                                    ></FormattedMessage>
                                </div>
                                <div>
                                    <FormattedMessage
                                        id={`profile.amount`}
                                    ></FormattedMessage>
                                </div>
                                <div>
                                    <FormattedMessage
                                        id={`profile.result`}
                                    ></FormattedMessage>
                                </div>
                            </div>
                            {
                                v.statistics.map((s)=>{
                                    return(
                                        <div key={s.player_bet} className='betC'>
                                            <div className='f16'>{ `${judgeField(s.player_bet , 1)},${judgeField(s.player_bet , 2)}`}</div>
                                            <div className='f16'>{s.amount}</div>
                                            <div className='f16'>{s.win}</div>
                                        </div>
                                    )
                                })
                            }
                            <div className='betC'>
                                <div className='f16'>
                                    <FormattedMessage
                                        id={`trader.total`}
                                    ></FormattedMessage>
                                </div>
                                <div className={v.result > 0 ? 'f16 green' : 'f16 red'}>{v.result}</div>
                            </div>
                        </div>
                       
                    )
                })
            }
           </div>

           {
                totalPage > 1 &&  
                <Page
                    totalPage={totalPage}
                    setNowPage={setNowPage}
                    nowPage={nowPage}
                />
            }
       </div>
       
       
    </div>
  )
}

export default TrderRecordPage
