import React, { useState, useEffect , useContext } from 'react'
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { TbLogin } from 'react-icons/tb';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { SlEarphonesAlt } from 'react-icons/sl';
import api from '../api/api';
import Swal from 'sweetalert2';
import RulePop from '../Component/Home/RulePop'
import { ErrorContext } from '../Routes';

const Nav = () => {
  const { ErrorMessage } = useContext(ErrorContext);

  const [time, setTime] = useState("")
  const [date, setDate] = useState("")
  const navigate = useNavigate();

  // 格式化日期時間，如果小於10就補零
  function formatTime(val) {
    if (val < 10) {
      return "0"
    } else {
      return ""
    }
  }
  // component 移除的時候解除計時器
  useEffect(() => {
    const timerID = setInterval(() =>
      tick(), 1000)

    return function cleanup() {
      clearInterval(timerID)
    }
  })

  // 計時器func
  function tick() {
    const time = new Date()
    const year = time.getFullYear()
    const mon = time.getMonth() + 1
    const day = time.getDate()
    const hour = time.getHours()
    const mins = time.getMinutes()

    setTime(formatTime(hour) + hour + ":" + formatTime(mins) + mins)

    setDate(year + " / " + formatTime(mon) + mon + " / " + formatTime(day) + day)
  }

   // 登出func
   const handleLogout = async () => {
    try {
      const res = await api.delete('/logout', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          'x-platform-code': `${localStorage.getItem("x-platform-code")}`,
        },
      });
      if (res.data.status) {
        console.log(res.data.status)
        localStorage.removeItem("access_token");
        localStorage.removeItem("username");
        localStorage.removeItem("x-platform-code");
        Swal.fire({
          icon: "success",
          // text: "已登出",
          showConfirmButton: false,
          timer: 1000,
        });
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      }
    } catch (err) {
      const errorCode = err.response.status;
      const errMsg = err.response.data.data.error;
      ErrorMessage(errorCode, errMsg);
    } finally {
      localStorage.removeItem("access_token");
      localStorage.removeItem("username");
      localStorage.removeItem("x-platform-code");
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    }
  };

  return (
    <>
      <nav className="firstNav navbar fixed-top">
        <div className="container-fluid d-flex justify-content-between">
          <div className="clock d-flex">
            <p className='mb-0 me-3'>{date}</p>
            <p className='mb-0'>{time}</p>
          </div>
          <div className="linkItems">
            <Link to="/login" ><TbLogin className='me-1 mb-1' />登入</Link>
            <Link to="/register">註冊</Link>
            <Link to="/profile">我的主頁</Link>
            <Link to="/password">修改密碼</Link>
          </div>
        </div>
      </nav>
      {/* <nav className="subNav navbar navbar-expand-lg navbar-light ">
        <div className="webNav container-fluid ">
          <Link className="navbar-brand" href="/">Logo</Link>
          <div className=" collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  熱門遊戲
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>賽車</li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>時時彩</li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>快3</li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>百家樂</li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  所有遊戲
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>123</li>
                  <li>123</li>
                  <li><hr className="dropdown-divider" /></li>
                  <li>123</li>
                </ul>
              </li>
              <RulePop />
              <li className="nav-item">
                <Link to="#" className="nav-link d-flex align-items-center">
                  <SlEarphonesAlt
                    className='me-1'
                  />
                  客服
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="mobileTopNav w-100 justify-content-between">
          <NavLink to='/'>
            Logo
          </NavLink>
          <div className="icons d-flex">
            <div className="icon">
              <AiFillQuestionCircle />
            </div>
            <div className="icon">
              <SlEarphonesAlt />
            </div>
            <button onClick={handleLogout}>登出</button>
          </div>
        </div>
      </nav> */}
    </>

  )
}

export default Nav