import React, { useState, useEffect , useRef , useContext } from "react";
import { NavLink , useNavigate} from "react-router-dom";
import { useSelector , useDispatch , Provider} from 'react-redux';
import Banner from '../../Component/Home/Banner'
// GiRaceCar
import MemberWrap from '../../Component/All/MemberWrap'
import FixBx from '../../Hook/fixBx'
// import HomeIcon from '../Component/Icon/Icons';
import Page from '../../Component/All/Page'
import '../../CSS/Home.scss'
import '../../CSS/common.scss'
import './Activity.scss'
import moment from "moment";
import SubNav from '../../Component/Home/SubNav';
import testImg from '../../images/activity/rancing.png';
import { ErrorContext } from '../../Routes';


const testArr = [
    {
        title:'標題',
        des:'描述',
        date:'2023/07/11',
        id:1,
        img:testImg
    },
    {
        title:'標題2',
        des:'描述2',
        date:'2023/07/11',
        id:2,
        img:testImg
    },
    {
        title:'標題3',
        des:'描述3',
        date:'2023/07/11',
        id:3,
        img:testImg
    }
]

const Activity = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listEvents = useSelector(state => state.activity.listEvents)

  const [leftBtn , setLeftBtn] = useState(''); 

  const [loading , setLoading] = useState(true);
  const [loadingMember , setLoadingMember] = useState(false);
  const [loadingEvents , setLoadingEvents] = useState(true);

  const [perpage , setPerpage] = useState(10)
  const [nowPage, setNowPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)

  const fixBxRef = useRef(null);
  const mainRef = useRef(null);
  const fixbarRef = useRef(null);
  // 引入上推function
  FixBx(fixBxRef,mainRef,loading,loadingMember,fixbarRef)

  const { TimeMoment } = useContext(ErrorContext);

  useEffect(() => {
    console.log(1)
    dispatch({ type: 'SAGA_FETCH_LISTEVENT_API' , setLoadingEvents , nowPage , perpage , setTotalPage});
  }, [dispatch , nowPage]);

  return (
    <div className="page">
      {/* <div ref={fixBxRef} className="fixBx"> */}
        {/* <Banner loading={loading} setLoading={setLoading}/> */}
        {/*手機版的 subNav */}
        {
        //   localStorage.getItem('access_token') ?
        //   <MemberWrap loadingMember={loadingMember} setLoadingMember={setLoadingMember}/>
        //   :
        //   <SubNav />
        }
      {/* </div> */}
      <div ref={mainRef}  className="main">
        <div className="ActivityPage container home-bg">
         {/* <div>活動列表</div> */}
         {
            loadingEvents ||
            <>
                <ul className="activityList">
                    {
                        listEvents.data.response.map((v)=>{
                            return(
                                <li key={v.id} onClick={() => {
                                    navigate(`/activity/${v.id}`);
                                }}>
                                    <div className="date">{TimeMoment(v.update_at)}</div>
                                    <div className="borderBx">
                                        <div>
                                            <img src={`${v.image_link}`}></img>
                                            {/* <img src={v.img}></img> */}
                                        </div>
                                        <div className="title">{v.title}</div>
                                        <div className="des">{v.description}</div>
                                    </div>
                                </li>
                            )
                        })
                    }
                    <li>
                        
                    </li>
                </ul>
                {
                    totalPage > 1 &&  
                    <Page
                        totalPage={totalPage}
                        setNowPage={setNowPage}
                        nowPage={nowPage}
                    />
                }
            </>
        }
        </div>
      </div>
    </div>
  )
}

export default Activity