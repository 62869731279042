import React from 'react'
import { Pagination } from '@mui/material'
import Stack from '@mui/material/Stack';

const Page = ({ totalPage, setNowPage, nowPage }) => {
  return (
    <Pagination
      count={totalPage}
      color="primary"
      defaultPage={1}
      // siblingCount={2}
      page={nowPage}
      onChange={(e, page) => {
        setNowPage(page);
      }}
    />
  )
}

export default Page