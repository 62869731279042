import axios from "axios";

export const NowplatformCode = "cx88"
export const NowpBaseURL = "https://apif.asiaplay888.online"
// export const NowpBaseURL = "https://apif.59416888.xyz"
export default axios.create({
  // 測試環境API
  // baseURL: "https://gcfore.japay.xyz",
  // 正式環境API
  // baseURL: "https://xtfront.88kayabet.com",
   // 越南彩環境API
  // baseURL: "https://apif.vn188.online",
   // 越南彩環境2API vn188 bi18 bi88(語系切換)
  //  baseURL: "https://apif.59416888.xyz",
  // 越南彩環境3API dm168
  // baseURL: "https://apif.daminbet.com",
  // 越南彩環境4API bi18 bi88
  // baseURL: "https://apif.superbingo18.com",
   // 越南彩環境5 cx88
  baseURL: "https://apif.asiaplay888.online",
});
