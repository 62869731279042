import { takeEvery, all, put , call } from 'redux-saga/effects';
import API from '../../../api/api';
import { ErrorContext } from '../../../Routes';



import Swal from 'sweetalert2'
// 活動列表
function* fetchEventListAPI({setLoadingEvents , nowPage , perpage , setTotalPage}) {
  yield setLoadingEvents(true);
  const { data } = yield API.get(`events?page=${nowPage}&per_page=${perpage}`,{
    headers: {
    "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
    "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
    }
  }); 
  yield setTotalPage(Math.ceil(data.data.pagination.total / perpage))
  yield put({
    type: 'FETCH_LISTEVENTS_API',
    payload: data
  })
  yield setLoadingEvents(false);
}
// 活動內頁
function* fetchEventAPI({setLoading , ActivityID}) {
    yield setLoading(true);
    const { data } = yield API.get(`events/${ActivityID}`,{
      headers: {
      "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
      "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
      }
    }); 
    yield put({
      type: 'FETCH_EVENTS_API',
      payload: data
    })
    yield setLoading(false);
}
// 活動報名
function* fetchJoinEventAPI({ActivityID , ErrorMessage}) {
    try{
        const { data } = yield API.post(`events/${ActivityID}`,{},{
        headers: {
        "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
        "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        }
        }); 
        if(data.status === true){
            yield Swal.fire({
              text: "報名成功",
              showConfirmButton: false,
              timer: 1000,
            });
            yield put({
                type: 'FETCH_JOINEVENTS_API',
                payload: data
            })
        }
        
    }catch (err){
        const code = err.response.status;
        const msg = err.response.data.data.error_msg;
       
         
        if(msg.includes("api.bad_request")){
            Swal.fire({
              icon: "warning",
              text: "未到報名時間",
              showConfirmButton: true,
            });
        } else {
            yield ErrorMessage(code, msg)
        }
    }
    // yield setLoading(false);
}




function* watchFetchEventListAPI() {
  yield takeEvery('SAGA_FETCH_LISTEVENT_API', fetchEventListAPI);
}
function* watchFetchEventAPI() {
    yield takeEvery('SAGA_FETCH_EVENT_API', fetchEventAPI);
}
function* watchFetchJoinEventAPI() {
    yield takeEvery('SAGA_FETCH_JOINEVENT_API', fetchJoinEventAPI);
}


export function* activitySaga() {
  yield all([
    watchFetchEventListAPI(),
    watchFetchEventAPI(),
    watchFetchJoinEventAPI()
  ]);
}