// import React from 'react'
import { useEffect , useState , useContext} from 'react';
import { FormattedMessage , useIntl } from "react-intl";
import moment from "moment";
import './../GamePlace.scss';
import Spinner from '../../Spinner/Spinner';
import api from '../../../api/api';
import { ErrorContext } from '../../../Routes';

const KenoTrend = ({gameID}) => {
    const intl = useIntl();
    const { ErrorMessage , TimeMoment } = useContext(ErrorContext);

    const [historyList , setHistoryList] = useState([]);
    const [listLoading , setListLoading] = useState(false);
    const mapping = {
        '1': 
        
        <i className='TRed'>
            {intl.formatMessage({
                id: 'game.dragon',
            })}
            {/* <FormattedMessage
                id={`game.dragon`}
            /> */}
        </i>, 
        '0': 
        <i className='TBlue'>
        {intl.formatMessage({
            id: 'game.tiger',
        })}
            {/* <FormattedMessage
                id={`game.tiger`}
            /> */}
        </i>
    };  

    //拿到開獎紀錄
    const getHistory = async() => {
        setListLoading(true)
        try {
        const res = await api.get(`/games/${gameID}/history`,{
            headers: {
            "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
            "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
        });
        if (res.data.status) {
            setHistoryList(res.data.data)
        }
        } catch (err) {
        console.log(err)
        const code = err.response.status
        const msg = err.response.data.data.error
        ErrorMessage(code, msg)
        }
        finally {
            setListLoading(false)
        }
    }
    useEffect(() => {
        getHistory()
        // setInterval(()=>{
        //     getHistory()
        // },60000)
        
      }, []);
    return (
        <div className="fixed_headers trendTable kenoTrend">
            {listLoading ? <Spinner/> : 
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FormattedMessage
                                    id={`game.issue`}
                                />
                            </td>
                            <td>
                                <FormattedMessage
                                    id={`game.lotteryDraw`}
                                />
                            </td>
                            <td>
                                <FormattedMessage
                                    id={`game.tie`}
                                />
                            </td>
                            <td>
                                <FormattedMessage
                                    id={`game.super`}
                                />
                            </td>
                            <td>
                                <FormattedMessage
                                    id={`game.time`}
                                />
                            </td>
                        </tr>
                        {historyList.map((v) => {
                            return (
                                <tr key={v.issue_no}>
                                    <td>{parseInt(v.issue_no)}</td>
                                    <td>
                                        {
                                         JSON.parse(v.result).winning_numbers.join(',')
                                        }
                                        
                                    </td>
                                    <td>
                                        {
                                         JSON.parse(v.result).sum
                                        }
                                        
                                    </td>
                                    <td>
                                        {
                                            intl.formatMessage({
                                                id: `game.${ JSON.parse(v.result).sum_big_small}`,
                                            }) 
                                        },
                                        {
                                            intl.formatMessage({
                                                id: `game.${ JSON.parse(v.result).sum_odd_even}`,
                                            }) 
                                        },
                                        {
                                            intl.formatMessage({
                                                id: `game.${ JSON.parse(v.result).upper_lower}`,
                                            }) 
                                        },
                                        {
                                            intl.formatMessage({
                                                id: `game.${ JSON.parse(v.result).count_odd_even}`,
                                            }) 
                                        },
                                        {
                                            intl.formatMessage({
                                                id: `game.${ JSON.parse(v.result).five_elements}`,
                                            }) 
                                        }
                                    </td>

                                    
                                    <td>{
                                        TimeMoment(v.lottery_draw_at)
                                    }</td>
                                    
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                
            }
            
        </div>
    );
}


export default KenoTrend
