import React from 'react';
// import './index.css';
import { Col, Row, Statistic } from 'antd';
const { Countdown } = Statistic;
// const deadline = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30;



const CountDown = ({nowTime ,  getListGame}) => {
    const onFinish = () => {
        getListGame();
    };
    const judgeNowTime = nowTime === -1 || nowTime === 0 ? 0 : nowTime
    const nextDrawAt = judgeNowTime - Date.now();
    const deadline = Date.now() + nextDrawAt;
    return (
        <Row gutter={16}>
            <Col span={12}>
            {
                deadline > 0 ?
                <Countdown value={deadline} onFinish={onFinish} />
                :
                <span style={{width:"72px" , height : "37px" , display:"flex" , alignItems : "center"}}>{"--:--:--"}</span>
               
            }
           
            </Col>
        </Row>
    )
};
export default CountDown;