import React, { useState, useEffect , useRef } from "react";
import { useSelector , useDispatch , Provider} from 'react-redux';
import { FormattedMessage , useIntl } from "react-intl";
import Page from '../Component/All/Page'
import Spinner from '../Component/Spinner/Spinner'
import { NavLink } from "react-router-dom";
import Banner from '../Component/Home/Banner'
import { useParams, useNavigate } from "react-router-dom";
import { ReactComponent as HomeIcon } from '../Component/Icon/Home.svg'
import { ReactComponent as Charge } from '../Component/Icon/Charge.svg'
import { ReactComponent as Host } from '../Component/Icon/Host.svg'
import { ReactComponent as Revenue } from '../Component/Icon/Revenue.svg'
import { ReactComponent as Car } from '../Component/Icon/RaceCar.svg'
import { ReactComponent as Clock } from '../Component/Icon/Clock.svg'
import { ReactComponent as Dice } from '../Component/Icon/Dice.svg'
import { ReactComponent as Poker } from '../Component/Icon/Poker.svg'
// GiRaceCar
import { GiRaceCar, GiAlarmClock, GiPokerHand, GiPerspectiveDiceSixFacesTwo } from 'react-icons/gi';
import RulePop from '../Component/Home/RulePop'
import ThirdWalletsPop from '../Component/All/ThirdWalletsPop'

import MemberWrap from '../Component/All/MemberWrap'
import FixBx from '../Hook/fixBx'
// import HomeIcon from '../Component/Icon/Icons';

import btn1 from '../images/front-page-lottery/lottery.svg';
import btn1a from '../images/front-page-lottery/lottery(click).svg';
import btn2 from '../images/front-page-lottery/Chess.png';
import btn2a from '../images/front-page-lottery/Chess(click).png';
import btn3 from '../images/front-page-lottery/Electronic games.svg';
import btn3a from '../images/front-page-lottery/Electronic-games(click).svg';
import btn4 from '../images/front-page-lottery/sport.svg';
import btn4a from '../images/front-page-lottery/sport(click).svg';
import btn5 from '../images/front-page-lottery/real-person.svg';
import btn5a from '../images/front-page-lottery/real-person(click).svg';

import game1 from '../images/front-page-lottery/racing-A.png';
import game2 from '../images/front-page-lottery/racing-B.png';
import game3 from '../images/front-page-lottery/racing-C.png';
import game4 from '../images/front-page-lottery/Always-color-A.png';
import game5 from '../images/front-page-lottery/Always-color-B.png';
import game6 from '../images/front-page-lottery/baccarat.png';

import '../CSS/Home.scss'
import '../CSS/common.scss'
import GameCard from '../Component/Home/GameCard'
import SubNav from '../Component/Home/SubNav';


// import useSound from 'use-sound';
// import boopSfx from '../sound/mouse_click.mp3';

const Cards = [
  {
    key: 1,
    imageSrc: game1,
  },
  // {
  //   key: 2,
  //   imageSrc: game2,
  // },
  // {
  //   key: 3,
  //   imageSrc: game3,
  // },
  // {
  //   key: 4,
  //   imageSrc: game4,
  // },
  // {
  //   key: 5,
  //   imageSrc: game5,
  // },
  // {
  //   key: 6,
  //   imageSrc: game6,
  // }
  // {
  //   key: 2,
  //   tag: "B",
  //   title: "賽車",
  //   icon: <GiRaceCar />,
  //   isHot: true,
  //   mobileTitle: "極速賽車B廳",
  // },
  // {
  //   key: 3,
  //   tag: "C",
  //   title: "賽車",
  //   icon: <GiRaceCar />,
  //   isHot: true,
  //   mobileTitle: "極速賽車C廳",
  // },
  // {
  //   key: 4,
  //   tag: "A",
  //   title: "時時彩",
  //   icon: <GiAlarmClock />,
  //   isHot: true,
  //   mobileTitle: "時時彩A廳",
  // },
  // {
  //   key: 5,
  //   tag: "B",
  //   title: "時時彩",
  //   icon: <GiAlarmClock />,
  //   isHot: true,
  //   mobileTitle: "時時彩B廳",
  // },
  // {
  //   key: 6,
  //   tag: "極速",
  //   title: "快3",
  //   icon: <GiPerspectiveDiceSixFacesTwo />,
  //   isHot: false,
  //   mobileTitle: "極速快3",
  // },
  // {
  //   key: 7,
  //   tag: "極速",
  //   title: "百家樂",
  //   icon: <GiPokerHand />,
  //   isHot: true,
  //   mobileTitle: "極速百家樂",
  // }
]
const leftButtons = [
  {
    title: "彩票",
    src: btn1,
    aSrc:btn1a,
    value:1,
    homeName:"Lottery"
  },
  // {
  //   title: "棋牌",
  //   src: btn2,
  //   aSrc:btn2a,
  //   value:4
  // },
  {
    title: "電子",
    src: btn3,
    aSrc:btn3a,
    value:2,
    homeName:"Electronic"
  },
  {
    title: "體育",
    src: btn4,
    aSrc:btn4a,
    value:5,
    homeName:"Sport"
  },
  {
    title: "真人",
    src: btn5,
    aSrc:btn5a,
    value:3,
    homeName:"RealPerson"
  },
]
// const Buttons = [
//   {
//     title: "首頁",
//     imageSrc: botbtn1,
//     link: "/",
//   },
//   {
//     title: "充值",
//     imageSrc: botbtn2,
//     link: "/",
//   },
//   {
//     title: "活動",
//     imageSrc: botbtn3,
//     link: "/",
//   },
//   {
//     title: "客服",
//     imageSrc: botbtn4,
//     link: "/",
//   },
// ]

const Home = () => {
  const intl = useIntl();
  const [open, setOpen] = useState(true);
  const [perpage , setPerpage] = useState(20)
  const [nowPage, setNowPage] = useState(1)
  const [gameType , setGameType] = useState(0)
  const [totalPage, setTotalPage] = useState(0)
  const [leftBtn , setLeftBtn] = useState('彩票'); 
  const [loading , setLoading] = useState(true);
  const [loadingMember , setLoadingMember] = useState(false);
  const [gameName , setGameName] = useState('');

  const fixBxRef = useRef(null);
  const mainRef = useRef(null);
  const fixbarRef = useRef(null);
  // 引入上推function
  FixBx(fixBxRef,mainRef,loading,loadingMember,fixbarRef)
  const dispatch = useDispatch();
  const getGameList = useSelector(state => state.game.listGames)


  useEffect(()=>{
    // dispatch({ type: 'SAGA_FETCH_LISTGAMES_API' , nowPage , perpage , setLoading , setTotalPage , gameType , gameName});
    getListGame()
    getGameChips()
  },[nowPage , gameType , gameName])
 
  const getGameForward = (gameID) => {
    dispatch({ type: 'SAGA_FETCH_GAMESFORWARD_API' , gameID , setLoading});
  }



  const getListGame = () =>{
    dispatch({ type: 'SAGA_FETCH_LISTGAMES_API' , nowPage , perpage , setLoading , setTotalPage , gameType , gameName});
  }

  const getGameChips = () =>{
    dispatch({ type: 'SAGA_FETCH_GAMECHIPS_API'});
  }


  return (
    <div className="page">
     
      
      <div ref={mainRef}  className="main">
        <div className="homePage container  home-bg">
          {/* <input
            value={gameName}
            type="text"
            className="gameSerachInput"
            required
            placeholder={intl.formatMessage({
              id: 'game.search',
            })}
            onChange={(e)=>{
              setGameName(e.target.value)
            }}
          /> */}
          <div className="homeBx flex">
            {/* <div className="buttons">
              {leftButtons.map((v) => {
                  return (
                    <button key={v.title} onClick={()=>{
                      setLeftBtn(v.title)
                      setGameType(v.value)
                    }}
                    data-active={leftBtn.includes(`${v.title}`)}
                    // data-active={true}
                    >
                      <div className="imgBx">
                        <img src={v.src}></img>
                        <img src={v.aSrc}></img>
                      </div>
                      
                      <div className="title">
                        <FormattedMessage
                              id={`home${v.homeName}`}
                        ></FormattedMessage>
                      </div>
                    </button>
                  )
              })} 
            </div> */}
            
            <div className={gameType === 1 || gameType === 3 || gameType === 0 ? "gamesWrap" : "gamesWrap fourList"}>
              {/* {Cards.map((item) => {
                return (
                  <GameCard
                    key={item.key}
                    imageSrc={item.imageSrc}
                  />
                )
              })} */}
              <div className="listBx">
                {
                  loading ? 
                  <Spinner/> :
                  <>
                    {
                      getGameList.data.response.map((v) =>{
                        return (
                          <GameCard
                            key={v.image_link}
                            imageSrc={v.image_link}
                            cardData={v}
                            drawAt={getGameList.data.next_draw_at}
                            getGameForward={getGameForward}
                            getListGame={getListGame}
                            gameType={gameType}
                          />
                        )
                      })
                    }
                  </>
                }
              </div>
              {
                  totalPage > 1 &&  
                  <Page
                      totalPage={totalPage}
                      setNowPage={setNowPage}
                      nowPage={nowPage}
                  />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home