import React, { useRef} from 'react'
// import TextField from '@material-ui/core/TextField';
import TextField from '@mui/material/TextField';
import { FormattedMessage , useIntl } from "react-intl";
// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import SendIcon from '@material-ui/icons/Send';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';






export const TextInput = ({sendMsg , inputRef}) => {
    const intl = useIntl();

    return (
        <>
            <form className={'MesgbottomInputWrap'}  noValidate autoComplete="off">
            <TextField
                id="standard-text"
                
                label={
                    intl.formatMessage({
                        id: 'vLottery.sendMeg',
                    })
                }
                className={'tttt'}
                inputRef={inputRef}
                //margin="normal"
            />
            <Button variant="contained" color="primary" className={'ttt'}
            onClick={()=>{
                if(inputRef.current.value !== ''){
                    sendMsg(inputRef.current.value)
                    inputRef.current.value = ''
                }
                // sendMsg(inputRef.current.value)
                // console.log(inputRef.current.value)
            }}
            >
                <SendIcon />
            </Button>
            </form>
        </>
    )
}



