import React, { useState , useEffect, useCallback , useRef} from 'react'
import { NavLink , Link } from "react-router-dom";
import { useNavigate , useParams } from "react-router-dom";
import { useSelector , useDispatch , Provider} from 'react-redux';
import { FormattedMessage , useIntl } from "react-intl";

import AvatarStatus from './AvatarStatus'
import './Traders.scss'
import ReplyIcon from '@mui/icons-material/Reply';
import TraderSetPop from '../../Component/Trader/traderSetPop'
import { FaUser } from 'react-icons/fa';
// traderList
import avatar from '../../images/traders/avatar.png';
import number from '../../images/traders/icon1.png';
import Swal from 'sweetalert2';

const TraderSetPage = () => {
  const { TrderID } = useParams();
  const intl = useIntl();

  const [loading, setLoading] = useState(true)
  const [refresh , setRefresh] = useState(false)
  const [boxSwitch, setBoxSwitch] = useState(false)
  const [model , setModel] = useState('model2')
  const [popOpen , setPopOpen] = useState(false)
  const [proportion , setProportion] = useState('')
  
  const navigate = useNavigate();


  const [createMirror , setCraeteMirror] = useState({
    persent : '',
    stop_win : '',
    stop_loss : '',
    max_bet : '',
  })
  //redux
  const dispatch = useDispatch();
  const getTrader = useSelector(state => state.trader.getTrader)
  const getMyInfo = useSelector(state => state.trader.getTraderPage)

  useEffect(() => {
    dispatch({ type: 'SAGA_FETCH_TRADER_API', setLoading, TrderID});
  }, [dispatch , refresh]);

  const loginInputs = [
    {
      name: "止盈金額",
      eName:"stop_win",
      require: true,
      type: "number",
    //   valueRef: takeProRef,
      remark:"當盈利達止盈金額時停止跟單",
      eRemark:"stopFollow"
    },
    {
      name: "止損金額",
      eName:"stop_loss",
      require: true,
      type: "number",
    //   valueRef: stopLossRef,
      remark:"當虧損金額與下次跟單總額相加大於止損金額時停止跟單",
      eRemark:"stopFollow2"
    },
    {
      name: "單筆跟單最大注額",
      eName:"max_bet",
      require: true,
      type: "number",
    //   valueRef: maxAmountRef,
      remark:"單筆跟單金額大於單筆跟單最大注額時，僅投注最大注額",
      eRemark:"stopFollow3"
    }
  ]

  const proBtn = ['20','40','60','80','100']

  const atLogin = async (e) => {
    e.preventDefault();
    setPopOpen(true)
  }

  const createMirrorTradingData = (e, value) =>{
    setCraeteMirror({
        ...createMirror,
        [e.target.name]: Number(value)
    })
  }
  const navHome = () =>{
    setTimeout(()=>{
        navigate(`/traderMyHome`);
    },2000)
  }
  const onSubmitCreateMirrorTrading = (e) =>{
    e.preventDefault()
    console.log(getMyInfo.data.is_trader)
    if(getMyInfo.data.is_trader){
        Swal.fire({
            icon: "warning",
            text: intl.formatMessage({
              id: 'alert.notPlaceBets',
            }),
            showConfirmButton: true,
          });
    } else{
        dispatch({ type: 'SAGA_FETCH_CREATEMIRROR_API', TrderID, createMirror , navHome});
    }
    setPopOpen(false)
  }
  return (
    <>
        {
            popOpen &&  
            <TraderSetPop
            setPopOpen={setPopOpen}
            onSubmitCreateMirrorTrading={onSubmitCreateMirrorTrading}
            />
        }

        <div className="traderSetPage homePage page home-bg mt-0">
            <div className='traderHeader'>
                <button className='backBtn' onClick={()=>{
                    navigate(`/traders`);
                }}
                ><ReplyIcon/></button>
                <div className='title'>
                  <FormattedMessage
                      id={`trader.followUpSettings`}
                  ></FormattedMessage> 
                </div>
                <FaUser onClick={() => {
                    navigate(`/traderMyHome`);
                }}/>
            </div>
        <div className='container'>
                {
                    loading || 
                    <AvatarStatus model={model} avaterData={getTrader}/>
                }
                <div className='proportionBx'>
                    <div className='f16 mb-2'>
                      <FormattedMessage
                        id={`trader.callRatio`}
                      ></FormattedMessage> 
                    </div>
                    <div className='flex'>
                        {proBtn.map((v) => {
                            return (
                                <div className='btn' 
                                key={v}
                                onClick={()=>{
                                    setProportion(v)
                                    setCraeteMirror({
                                        ...createMirror,
                                        persent: Number(v)
                                    })
                                }}
                                data-active={proportion.includes(`${v}`)}
                                >{v}%</div>
                            )
                        })}
                    </div>
                </div>
                <form className='mt-5' action="" onSubmit={atLogin}>
                    {loginInputs.map((v) => {
                    return (
                        <div key={v.name} className="loginInput mb-4">
                            <label className='mb-2'>
                              <FormattedMessage
                                  id={`trader.${v.eName}`}
                              ></FormattedMessage> 
                            </label>
                            <input
                            name={v.eName}
                            // ref={v.valueRef}
                            type={v.type}
                            required={v.require}
                            // placeholder={v.name} 
                            onChange={(e)=>{
                                createMirrorTradingData(e, e.target.value)
                            }}
                            />
                            <span className='mt-2'>
                              <FormattedMessage
                                  id={`trader.${v.eRemark}`}
                              ></FormattedMessage> 
                            </span>
                        </div>
                    )
                    })}
                    {/* <input type="submit"/> */}
                    <button className='com-btn' type="submit"
                    >
                       <FormattedMessage
                            id={`trader.startFollow`}
                        ></FormattedMessage> 
                    </button>
                </form>

        </div>
        
        
        </div>
    </>
  )
}

export default TraderSetPage
