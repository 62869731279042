import { combineReducers } from 'redux';
import { betHistoryReducer } from './profile/betHistory';
import { AgentReducer } from './profile/agents';
import { TraderReducer } from './trader/trader';
import { ActivityReducer } from './activity/activity';
import { GameReducer } from './game/game';


const rootReducer = combineReducers({
    betHistory: betHistoryReducer,
    agent: AgentReducer,
    trader: TraderReducer,
    activity: ActivityReducer,
    game: GameReducer
});

export default rootReducer;