import { takeEvery, all, put , call } from 'redux-saga/effects';
import API from '../../../api/api';
import Swal from 'sweetalert2'
// 交易員列表
function* fetchTraderListAPI({searchSort , sendStartDate , sendendDate , searchAcc , nowPage , perpage , setTotalPage}) {
//   yield setTraderListLoading(true);
  const { data } = yield API.get(`traders?account=${searchAcc}&sort=${searchSort}&start_at=${sendStartDate}&end_at=${sendendDate}&page=${nowPage}&per_page=${perpage}`,{
    headers: {
    "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
    "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
    }
  }); 
  yield setTotalPage(Math.ceil(data.data.pagination.total / perpage))
  yield put({
    type: 'FETCH_LISTTRADERS_API',
    payload: data
  })
//   yield setTraderListLoading(false)
}
// 我的跟單主頁
function* fetchTraderPageAPI() {
    const { data } = yield API.get(`t`,{
      headers: {
      "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
      "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
      }
    }); 
    yield put({
      type: 'FETCH_TRADERPAGE_API',
      payload: data
    })
}
// 跟單列表
function* fetchListMirrorTradingsAPI() {
    const { data } = yield API.get(`t/mirror-tradings`,{
      headers: {
      "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
      "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
      }
    }); 
    yield put({
      type: 'FETCH_LISTMIRRORTRADING_API',
      payload: data
    })
}
// 關注列表
function* fetchListSubscribesAPI() {
    const { data } = yield API.get(`t/subscribes`,{
      headers: {
      "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
      "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
      }
    }); 
    yield put({
      type: 'FETCH_LISTSUBSCRIBES_API',
      payload: data
    })
}
// 申請成為交易員
function* applyToTraderAPI() {
    try{
        const { data } = yield API.post(`/traders`,{},{
        headers: {
            "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
            "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        }
        }); 
        
        if(data.status === true){
        yield Swal.fire({
            text: "新增成功",
            showConfirmButton: false,
            timer: 1000,
        });
        yield put({
            type: 'CREAT_TRADER_API',
        })
        }
    }catch (err){
        const code = err.response.status;
        const msg = err.response.data.data.error_msg;
        yield console.log(err);
        yield 
        if(msg === "api.bad_request.bet_amount"){
            Swal.fire({
              icon: "warning",
              text: "流水金額未達標準",
              showConfirmButton: true,
            });
        } else if (msg === "api.bad_request.mirror_trading"){
            Swal.fire({
                icon: "warning",
                text: "當前有跟單",
                showConfirmButton: true,
              });
        } else if(msg === "api.bad_request"){
            Swal.fire({
                icon: "warning",
                text: "註冊時間未超過7日",
                showConfirmButton: true,
              });
        } else if(msg === "api.data_existent"){
            Swal.fire({
                icon: "warning",
                text: "已申請 / 已成為交易員",
                showConfirmButton: true,
              });
        }
    }
}
// 交易員主頁
function* fetchTraderHomePageAPI({TrderID}) {
    const { data } = yield API.get(`traders/${TrderID}?start_at=0&end_at=0`,{
      headers: {
      "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
      "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
      }
    }); 
    yield put({
      type: 'FETCH_TRADER_API',
      payload: data
    })
}
// 建立跟單
function* createMirrorTradingAPI({TrderID, createMirror , navHome}) {
    try{
        const { data } = yield API.post(`/traders/${TrderID}/mirror-trading`,createMirror,{
        headers: {
            "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
            "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        }
        }); 
        
        if(data.status === true){
            yield Swal.fire({
                text: "跟單成功",
                icon: 'success',
                showConfirmButton: false,
                timer: 1000,
            });
            yield put({
                type: 'CREAT_MIRRORTRADING_API',
            })
            yield navHome()
        }
    }catch (err){
        const code = err.response.status;
        const msg = err.response.data.data.error_msg;
        yield console.log(err);
        if(msg === "api.bad_request"){
            Swal.fire({
                icon: "warning",
                text: "流水金額未達標準",
                showConfirmButton: true,
            });
        }
    }
}
// 取得跟單下注紀錄
// function* fetchListMirrorTradingBetsAPI({MirrorID , setListMirrorLoading , startStamp , endStamp , nowPage , perpage , setTotalPage}) {
//     yield setListMirrorLoading(true);
//     const { data } = yield API.get(`t/mirror-tradings/${MirrorID}/bets?game_id=0&start_at=${startStamp}&end_at=${endStamp}&page=${nowPage}&per_page=${perpage}`,{
//       headers: {
//       "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
//       "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
//       }
//     }); 
//     yield setTotalPage(Math.ceil(data.data.pagination.total / perpage))
//     yield put({
//       type: 'FETCH_LISTMIRRORTRADINGBETS_API',
//       payload: data
//     })
//     yield setListMirrorLoading(false);
// }
function* fetchListMirrorTradingBetsAPI({TrderID , setListMirrorLoading , startStamp , endStamp , nowPage , perpage , setTotalPage , ErrorMessage}) {
  yield setListMirrorLoading(true);
  try{
    const { data } = yield API.get(`t/mirror-tradings/draw?trader_id=${TrderID}&start_at=${startStamp}&end_at=${endStamp}&page=${nowPage}&per_page=${perpage}&language=${localStorage.getItem("language")}`,{
      headers: {
      "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
      "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
      }
    }); 
    yield setTotalPage(Math.ceil(data.data.pagination.total / perpage))
    yield put({
      type: 'FETCH_LISTMIRRORTRADINGBETS_API',
      payload: data
    })
  } catch (err){
    const code = err.response.status;
    const msg = err.response.data.data.error_msg;
    yield ErrorMessage(code , msg);
  } finally{
    yield setListMirrorLoading(false);
  }
}
// 取得未開跟單紀錄 FETCH_LISTWAIT_API
function* fetchListWaitAPI({TrderID , setListWaitLoading , ErrorMessage}) {
  yield setListWaitLoading(false);
  try{
    const { data } = yield API.get(`t/mirror-tradings/wait?trader_id=${TrderID}&language=${localStorage.getItem("language")}`,{
      headers: {
      "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
      "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
      }
    }); 
    yield put({
      type: 'FETCH_LISTWAIT_API',
      payload: data
    })
  } catch (err){
    const code = err.response.status;
    const msg = err.response.data.data.error_msg;
    yield ErrorMessage(code , msg);
  } finally{
    yield setListWaitLoading(true);
  }
  
}
// 取消跟單
function* cancelMirrorTradingAPI({id , refresh , setRefresh}) {
    try{
        const { data } = yield API.post(`/t/mirror-tradings/${id}/cancel`,{},{
        headers: {
            "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
            "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        }
        }); 
        
        if(data.status === true){
            yield Swal.fire({
                text: "跟單取消成功",
                showConfirmButton: false,
                timer: 1000,
            });
            yield setRefresh(!refresh)
            yield put({
                type: 'CANCEL_MIRRORTRADING_API',
            })
        }
    }catch (err){
        const code = err.response.status;
        const msg = err.response.data.data.error_msg;
        yield console.log(err);
    }
}
// 關注
function* fetchSubscribeAPI({TrderID , setRefresh ,refresh}) {
    // yield setLoading(true);
    try{
        const { data } = yield API.post(`traders/${TrderID}/subscribe`,{},{
        headers: {
        "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
        "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        }
        }); 
        if(data.status === true){
            setRefresh(!refresh);
        }
        yield put({
            type: 'FETCH_SUBSCRIBE_API',
            payload: data
        })
    }catch (err){
        const code = err.response.status;
        const msg = err.response.data.data.error_msg;
        yield console.log(err);
    }
    // yield setLoading(false);
}
// 取消關注
function* fetchUNSubscribeAPI({TrderID , setRefresh ,refresh}) {
    // yield setLoading(true);
    try{
        const { data } = yield API.post(`traders/${TrderID}/unsubscribe`,{},{
        headers: {
        "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
        "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        }
        }); 
        if(data.status === true){
            setRefresh(!refresh);
        }
        yield put({
        type: 'FETCH_UNSUBSCRIBE_API',
        payload: data
        })
    }catch (err){
        const code = err.response.status;
        const msg = err.response.data.data.error_msg;
        yield console.log(err);
    }
    // yield setLoading(false);
}
// FETCH_TRADERWINNINGPERCENTAGE_API 交易員走勢
function* fetchTRADERWINNINGAPI({TrderID}) {
  // yield setLoading(true);
  try{
      const { data } = yield API.get(`traders/${TrderID}/winning-percentage`,{
      headers: {
      "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
      "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
      }
      }); 
      yield put({
      type: 'FETCH_TRADERWINNINGPERCENTAGE_API',
      payload: data
      })
  }catch (err){
      const code = err.response.status;
      const msg = err.response.data.data.error_msg;
      yield console.log(err);
  }
  // yield setLoading(false);
}
// 同時呼叫跟單主頁,跟單列表,關注列表 (我的主頁用)
function* fetchTraderAllAPI({setLoading}){
    yield setLoading(true);
    yield all([
        call(fetchTraderPageAPI),
        call(fetchListMirrorTradingsAPI),
        call(fetchListSubscribesAPI),
    ]);
    yield setLoading(false);
}
// 同時呼叫跟單列表,我的跟單列表 (跟單列表用)
function* fetchTraderListAllAPI({setLoading , searchSort , sendStartDate , sendendDate , searchAcc , nowPage , perpage , setTotalPage}){
    yield setLoading(true);
    yield all([
        call(fetchTraderListAPI, {searchSort , sendStartDate , sendendDate , searchAcc , nowPage , perpage , setTotalPage}),
        call(fetchListMirrorTradingsAPI),
         call(fetchTraderPageAPI),
    ]);
    yield setLoading(false);
}
// 同時呼叫交易員主頁,我的跟單主頁,交易員走勢 (交易員主頁用)
function* fetchTraderInfoAllAPI({setLoading , TrderID , ErrorMessage}){
    yield setLoading(true);
    yield all([
        call(fetchTraderHomePageAPI, {TrderID}),
        call(fetchTraderPageAPI),
        call(fetchTRADERWINNINGAPI , {TrderID}),
        
    ]);
    yield setLoading(false);
}
function* watchFetchTraderListAPI() {
  yield takeEvery('SAGA_FETCH_LISTTRADERS_API', fetchTraderListAllAPI);
}
function* watchFetchTraderPageAPI() {
  yield takeEvery('SAGA_FETCH_TRADERPAGE_API', fetchTraderAllAPI);
}
function* watchFetchCreatTraderAPI() {
  yield takeEvery('SAGA_FETCH_CREATTRADER_API', applyToTraderAPI);
}
function* watchFetchTraderHomePageAPI() {
  yield takeEvery('SAGA_FETCH_TRADER_API', fetchTraderInfoAllAPI);
}
function* watchFetchCreateMirrorTradingAPI() {
  yield takeEvery('SAGA_FETCH_CREATEMIRROR_API', createMirrorTradingAPI);
}
function* watchFetchCancelMirrorTradingAPI() {
  yield takeEvery('SAGA_FETCH_CANCELMIRROR_API', cancelMirrorTradingAPI);
}
function* watchFetchSubscribeAPI() {
  yield takeEvery('SAGA_FETCH_SUBSCRIBE_API', fetchSubscribeAPI);
}
function* watchFetchUnSubscribeAPI() {
  yield takeEvery('SAGA_FETCH_UNSUBSCRIBE_API', fetchUNSubscribeAPI);
}
function* watchFetchListMirrorTradingBetsAPI() {
    yield takeEvery('SAGA_FETCH_LISTMIRRORTRADINGBETS_API', fetchListMirrorTradingBetsAPI);
}
function* watchFetchListWaitAPI() {
  yield takeEvery('SAGA_FETCH_LISTWAIT_API', fetchListWaitAPI);
}




export function* traderSaga() {
  yield all([
    watchFetchTraderListAPI(),
    watchFetchTraderPageAPI(),
    watchFetchCreatTraderAPI(),
    watchFetchTraderHomePageAPI(),
    watchFetchCreateMirrorTradingAPI(),
    watchFetchCancelMirrorTradingAPI(),
    watchFetchSubscribeAPI(),
    watchFetchUnSubscribeAPI(),
    watchFetchListMirrorTradingBetsAPI(),
    watchFetchListWaitAPI()
  ]);
}