import React from 'react'
import { FiLock } from 'react-icons/fi';
import { Link } from "react-router-dom";
import './UpdatePwdForm.scss'

const UpdatePwdForm = () => {
  return (
    <div className=" updatePwdWrap">
      <form action=""
      >
        <h3>修改密碼</h3>
        <div className="loginInput mb-3">
          <input
            type="text"
            required
            placeholder='舊密碼' />
          <FiLock />
        </div>
        <div className="loginInput mb-3">
          <input
            type="password"
            required
            placeholder='新密碼' />
          <FiLock />
        </div>
        <input type="submit" value={"修改"} />
        <div className="links">
          <Link to="/">回首頁</Link>
        </div>

      </form>

    </div>
  )
}

export default UpdatePwdForm