import React, { useState , useEffect, useCallback , useRef} from 'react'
import { NavLink , Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FormattedMessage , useIntl } from "react-intl";
import { useSelector , useDispatch , Provider} from 'react-redux';
import {TablePagination} from '@mui/material';
import Page from '../../Component/All/Page'

import './Traders.scss'

// import ProfileInfo from '../../Component/Profile/ProfileInfo';
// import ProfileBtn from '../../Component/Profile/ProfileBtn';
// import BettingRecord from '../../Component/Profile/bettingRecord';
// import Agents from '../../Component/Profile/agents';
// import AgentRegister from '../../Component/Profile/agentRegister';
// import AgentInfo from '../../Component/Profile/agentInfo';
// banner 
import banner from '../../images/traders/banner.png';
import bannerButton from '../../images/traders/become.png';
// traderList
import avatar from '../../images/traders/avatar.png';
import number from '../../images/traders/icon1.png';
import moment from "moment";
import Swal from 'sweetalert2'
import { FaUser } from 'react-icons/fa';
import { BiSearch } from 'react-icons/bi';


const dateSearch = [
    {
        name: '今天',
        value : 'toDay'
    },
    {
        name: '3天',
        value : 'threeDay'
    },
    {
        name: '7天',
        value : 'sevenDay'
    },
    {
        name: '10天',
        value : 'tenDay'
    },
];
const typeSearch = [
    {
        name: '收益',
        value : 1,
        linkName:'income'
    },
    {
        name: '收益率',
        value : 2,
        linkName:'rateOfReturn'
    },
    {
        name: '跟單者',
        value : 3,
        linkName:'follower'
    },
    {
        name: '跟單者總收益',
        value : 4,
        linkName:'totalIncomeOfTheFollower'
    },
   
   
    
    
]
const TradersPage = () => {
  const intl = useIntl();

  const [perpage , setPerpage] = useState(10)
  const [nowPage, setNowPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [traderListloading, setTraderListLoading] = useState(true)
  const [loading, setLoading] = useState(true)

  const [boxSwitch, setBoxSwitch] = useState(false)
  const navigate = useNavigate();
  //redux
  const dispatch = useDispatch();
  // search
  const [searchTime , setSearchTime] = useState('今天')
  const [activeSort , setActiveSort] = useState('收益')
  const [searchSort , setSearchSort] = useState(1)
  const [searchAcc, setSearchAcc] = useState('');
  //時間
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sendStartDate, setSendStartDate] = useState(0);
  const [sendendDate, setSendEndDate] = useState(0);
  const getTraderPage = useSelector(state => state.trader.getTraderPage)
  const listTraders = useSelector(state => state.trader.listTraders)
  const listMirrorTradings = useSelector(state => state.trader.listMirrorTradings)

  // 交易員帳號

  useEffect(() => {
    dispatch({ type: 'SAGA_FETCH_LISTTRADERS_API' , setLoading , searchSort , sendStartDate , sendendDate , searchAcc , nowPage , perpage , setTotalPage});
  }, [dispatch , searchSort , sendStartDate , sendendDate , searchAcc , nowPage]);
//   useEffect(() => {
//     dispatch({ type: 'SAGA_FETCH_TRADERPAGE_API' , setLoading});
//   }, []);
  const now = new Date();
  const timeChooseHandler = async (time) =>{
    if (time === 'toDay'){
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate());
        yesterday.setHours(0, 0, 0, 0);
        const yesterdayEnd = new Date(now);
        yesterdayEnd.setDate(now.getDate());
        yesterdayEnd.setHours(23, 59, 59, 999);
        setSendStartDate(yesterday.getTime());
        setSendEndDate(yesterdayEnd.getTime());
    }else if (time === 'threeDay'){
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 3);
        yesterday.setHours(0, 0, 0, 0);
        const yesterdayEnd = new Date(now);
        yesterdayEnd.setDate(now.getDate());
        yesterdayEnd.setHours(23, 59, 59, 999);
        setSendStartDate(yesterday.getTime());
        setSendEndDate(yesterdayEnd.getTime());
    } else if (time === 'sevenDay'){
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 7);
        yesterday.setHours(0, 0, 0, 0);
        const yesterdayEnd = new Date(now);
        yesterdayEnd.setDate(now.getDate());
        yesterdayEnd.setHours(23, 59, 59, 999);
        setSendStartDate(yesterday.getTime());
        setSendEndDate(yesterdayEnd.getTime());
    } else if (time === 'tenDay'){
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 10);
        yesterday.setHours(0, 0, 0, 0);
        const yesterdayEnd = new Date(now);
        yesterdayEnd.setDate(now.getDate());
        yesterdayEnd.setHours(23, 59, 59, 999);
        setSendStartDate(yesterday.getTime());
        setSendEndDate(yesterdayEnd.getTime());
    }
  }

  const judge = (id) =>{
    const find = listMirrorTradings.data.response.find((ele)=>{
        return ele.trader_id === id
    })
    if(find === undefined){
        // console.log('跟單設置')
        navigate(`/traderSet/${id}`);
    } else {
        Swal.fire({
            icon: "warning",
            text: intl.formatMessage({
                id: 'alert.haveFollowed',
            }),
            showConfirmButton: true,
        });
    }
  }


  return (
    <div className="tradersPage homePage page home-bg mt-0">
        <div className='traderHeader'>
            <div className='title'>
              <FormattedMessage
                  id={`trader.traderList`}
              ></FormattedMessage>
            </div>
            <FaUser onClick={() => {
                    navigate(`/traderMyHome`);
            }}/>
        </div>
        <div className='banner'>
            <img src={banner}></img>
            <button  onClick={() => {
                navigate(`/joinTrader`);
            }}>
                <img src={bannerButton}></img>
            </button>
        </div>
        <div className='container'>
            {/* <div className='not-loggedBtn'>
                <button
                    onClick={() => {
                        navigate(`/`);
                    }}
                >
                    我要跟單
                </button>
                <button
                    onClick={() => {
                        navigate(`/`);
                    }}
                >
                    我要帶單
                </button>
            </div> */}
            <div className='serach-bx'>
                <div className='timeBx'>
                    {
                        dateSearch.map((v)=>{
                            return(
                                <div
                                key={v.name}
                                onClick={()=>{
                                        setSearchTime(v.name)
                                        timeChooseHandler(v.value)
                                    }
                                }
                                data-active={searchTime.includes(`${v.name}`)}
                                >
                                    <FormattedMessage
                                        id={`trader.${v.value}`}
                                    ></FormattedMessage>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="loginInput">
                    <input 
                        placeholder={intl.formatMessage({
                            id: 'trader.findTrader',
                            defaultMessage:'123'
                        })}
                        onChange={(e) => {
                            setSearchAcc(e.target.value)

                      }}
                    ></input>
                    <BiSearch/>
                </div>
                <ul>
                    {
                        typeSearch.map((v)=>{
                            return(
                                <li
                                key={v.value}
                                onClick={
                                    ()=>{
                                        setActiveSort(v.name)
                                        setSearchSort(v.value)
                                    }
                                }
                                data-active={activeSort === `${v.name}`}
                                >
                                    <FormattedMessage
                                        id={`trader.${v.linkName}`}
                                    ></FormattedMessage>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <ul className='tradersList'>
                {
                    loading ||
                    listTraders.data.response.map((v)=>{
                        return(
                            <li key={v.id} onClick={() => {
                                navigate(`/traderHome/${v.id}`);
                            }}>
                                <div className='avatar'>
                                    <img src={avatar}></img>
                                </div>
                                <div className='d2'>
                                    <div className='tName'>{v.account}</div>
                                    <div className='number'>
                                        <img src={number}></img>
                                        <span>{v.mirror_trading_count}/100</span>
                                    </div>
                                </div>
                                <div  className='d3'>
                                    {
                                        searchSort === 1 && 
                                        <>
                                            <div className='incomeTi'>
                                                <FormattedMessage
                                                    id={`trader.income`}
                                                ></FormattedMessage>
                                            </div>
                                            <div className='incomeDes'>
                                                <span>{v.profit}</span>
                                            </div>
                                        </>
                                    }
                                    {
                                        searchSort === 2 && 
                                        <>
                                            <div className='incomeTi'>
                                                <FormattedMessage
                                                    id={`trader.rateOfReturn`}
                                                ></FormattedMessage>
                                            </div>
                                            <div className='incomeDes'>
                                                <span>{v.rate_of_return}%</span>
                                            </div>
                                        </>
                                    }
                                    {
                                        searchSort === 3 && 
                                        <>
                                            <div className='incomeTi'>
                                                <FormattedMessage
                                                    id={`trader.follower`}
                                                ></FormattedMessage>
                                            </div>
                                            <div className='incomeDes'>
                                                <span>{v.mirror_trading_count}</span>
                                            </div>
                                        </>
                                    }
                                    {
                                        searchSort === 4 && 
                                        <>
                                            <div className='incomeTi'>
                                                <FormattedMessage
                                                    id={`trader.totalIncomeOfTheFollower`}
                                                ></FormattedMessage>
                                            </div>
                                            <div className='incomeDes'>
                                                <span>{v.mirror_trading_profit}</span>
                                            </div>
                                        </>
                                    }
                                    
                                </div>
                                {
                                    getTraderPage.data.is_trader || 
                                    <>
                                        {v.mirror_trading_count >= 100 ?
                                            <button className='full'>
                                                <FormattedMessage
                                                    id={`trader.full`}
                                                ></FormattedMessage>
                                            </button>
                                            : 
                                            <button onClick={(e) => {
                                                e.stopPropagation();
                                                judge(v.id);
                                            }}
                                            >
                                                <FormattedMessage
                                                    id={`trader.followOrders`}
                                                ></FormattedMessage>
                                            </button>
                                        }
                                    </>
                                }
                               
                            </li>
                        )
                    })
                }
            </ul>
            {
                totalPage > 1 &&  
                <Page
                    totalPage={totalPage}
                    setNowPage={setNowPage}
                    nowPage={nowPage}
                />
            }
        </div>
       
    </div>
  )
}

export default TradersPage
