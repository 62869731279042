import React, { useState, useEffect , useRef , useContext} from "react";
import { NavLink , useParams , useNavigate } from "react-router-dom";
import { useSelector , useDispatch , Provider} from 'react-redux';


import '../../CSS/Home.scss'
import '../../CSS/common.scss'
import './Activity.scss'
import moment from "moment";
import testImg from '../../images/activity/rancing.png';
import leaveImg from '../../images/activity/leave.png';
import { ErrorContext } from '../../Routes';


const ActivityDetail = () => {
  const { ActivityID } = useParams();
  const { ErrorMessage , TimeMoment } = useContext(ErrorContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getEvent = useSelector(state => state.activity.getEvent)
  const [loading , setLoading] = useState(true);


  useEffect(() => {
    dispatch({ type: 'SAGA_FETCH_EVENT_API' , setLoading , ActivityID});
  }, [dispatch , ActivityID]);
  const signUp = (e) =>{
    e.preventDefault();

  }

  return (
    <div className="page">
      <div className="main">
        <div className="ActivityDetailPage container home-bg">
            {
                loading || 
                <>
                    <div className="date center-ti">
                        <div className="line-l"></div>
                            <span>{
                              `${TimeMoment(getEvent.data.start_at)}-${TimeMoment(getEvent.data.end_at)}`
                              // localStorage.getItem("language") === 'vn' ?
                              // `${moment(getEvent.data.start_at).format('MM/DD/YYYY')}-${moment(getEvent.data.end_at).format('MM/DD/YYYY')}`
                              // :
                              // `${moment(getEvent.data.start_at).format('YYYY/MM/DD')}-${moment(getEvent.data.end_at).format('YYYY/MM/DD')}`
                            // `${moment(getEvent.data.start_at).format('YYYY/MM/DD')}-${moment(getEvent.data.end_at).format('YYYY/MM/DD')}`
                            }</span>
                        <div className="line-r"></div>
                    </div>
                    <div className="title">{getEvent.data.title}</div>
                    
                    <div className="desBx">
                      <div className="img-bx">
                          {/* 220*255 */}
                          <img src={getEvent.data.image_link}></img>
                          {/* <img src={testImg}></img> */}
                      </div>
                        <div className="des" dangerouslySetInnerHTML={{ __html: getEvent.data.content }}>
                        </div>
                        {
                            getEvent.data.type === 3 &&
                            <button className="SignUp" onClick={() => {
                                dispatch({ type: 'SAGA_FETCH_JOINEVENT_API' , ActivityID , ErrorMessage});
                            }}>
                            報名
                            </button>
                        }
                        
                    </div>
                    
                    <button className="backBtn" onClick={() => {
                            navigate(`/activity`);
                        }}>
                        <img src={leaveImg}></img>
                    </button>
                </>
            }
        </div>
      </div>
    </div>
  )
}

export default ActivityDetail