import React, { useState , useEffect, useCallback , useRef , useContext} from 'react'
// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import { createStyles, makeStyles } from '@mui/styles';
import { FormattedMessage , useIntl } from "react-intl";
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2'
import api from '../../../api/api';
import { ErrorContext } from '../../../Routes';

const testArr = ['ttt','ttt1','ttt12','ttt123','ttt1234','ttt1232','ttt12321','ttt1231111']
export default function BanList({setBanListOpen}) {
  const { ErrorMessage } = useContext(ErrorContext);
  const intl = useIntl();
  const [banList , setBanList] = useState([])
  const [banListLoading , setBanListLoading] = useState(true)
  const getBanList = async() => {
    // setMegLoading(true)
    try {
      const res = await api.get(`/games/115/chat/ban`,{
          headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
          },
      });
      if (res.data.status) {
         console.log(res.data)
         setBanList(res.data)
         setBanListLoading(false)
      }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    }

  }

// 解禁對象
const handelUnBanMember = async(id) => {
 

  // setMegLoading(true)
  try {
    const res = await api.delete(`/games/115/chat/ban/${id}`,{
        headers: {
        "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
        "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    if (res.data.status) {
       console.log(res.data)
       Swal.fire({
        // title: "解禁成功!",
        icon: "success"
      });
      getBanList()
    }
  } catch (err) {
    console.log(err)
    const code = err.response.status
    const msg = err.response.data.data.error
    ErrorMessage(code, msg)
  }

}
  useEffect(()=>{
    getBanList()
  },[])
  return (
    <div className={'BanListWrap'}>
        <div className="title">
          <FormattedMessage
              id={`vLottery.banList`}
            />
        </div>
        <div className="banList">
            {
                banListLoading || 
                // banList.response.length !== 0
                <>
                  {
                     banList.data.response.length == 0 ?
                     <div>
                        <FormattedMessage
                          id={`vLottery.noList`}
                        />
                     </div> 
                     :
                     banList.data.response.map((v)=>{
                      return <div
                      onClick={()=>{
                          Swal.fire({
                              text: `${intl.formatMessage({
                                id: 'vLottery.whetherToUnban',
                              })} ${banList.data.member_account[v.platform_member_id]} ?`,
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonColor: "#3085d6",
                              cancelButtonColor: "#d33",
                              confirmButtonText: intl.formatMessage({
                                id: 'alert.yes',
                              }),
                              cancelButtonText: intl.formatMessage({
                                id: 'alert.no',
                              }),
                            }).then((result) => {
                              if (result.isConfirmed) {
                                handelUnBanMember(v.platform_member_id)
                              }
                          });
                        }}
                      >{banList.data.member_account[v.platform_member_id]}</div>
                  })
                  }
                </>
                
            }
        </div>
        <button className="CloseBtn"
            onClick={()=>{
                setBanListOpen(false)
            }}
        >
            <CloseIcon/>
        </button>
    </div>
  );
}
