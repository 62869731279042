import React, { useEffect , useState} from 'react'
import '../GamePlace.scss';
import { FormattedMessage } from "react-intl";

const TraditionModel = ({
    rankID,setRankID,payOrder,Lane,laneID,setLaneID,handlePlayButtonClick,Lane2,sizeID,setSizeID,nClickSizeID,setAutoHide,handlePlaceShow,handleTotalClick,nowChip,empty,laneID2,setLaneID2,Lane3,Lane4,normalFastinputBet,setGamePlaceShow,betLock
    }) => {
    const [qucikClearValue , setQucikClearValue] = useState('match_2')
    const qucikClearModel = [
        "match_2", "match_3", "match_4"
    ]
    const handleSizeButtonClick = (value , state , set , e) => {
        if (e.target.getAttribute('data-active') === 'true'){
            e.target.setAttribute('data-active', 'false');

            if(rankID == 'normalFast'){
                const newArray = state.filter((element) => element !== value);
                set(newArray);
            }
           
        } else{
            set([...state, value]);
            e.target.setAttribute('data-active', 'true');
        }
    };

    useEffect(() => {
        handleTotalClick(qucikClearValue)
        
        if(qucikClearValue === "match_2" && rankID != 'normalFast'){
            if(laneID.length % 2 === 0){
                const namefalse = document.querySelectorAll('.name[data-active="true"]');
                namefalse.forEach(element => {
                    element.setAttribute('data-active', 'false');
                });
            }
        } else if (qucikClearValue === "match_3" && rankID != 'normalFast'){
            if(laneID.length % 3 === 0){
                const namefalse = document.querySelectorAll('.name[data-active="true"]');
                namefalse.forEach(element => {
                    element.setAttribute('data-active', 'false');
                });
            }
        } else if (qucikClearValue === "match_4" && rankID != 'normalFast'){
            if(laneID.length % 4 === 0){
                const namefalse = document.querySelectorAll('.name[data-active="true"]');
                namefalse.forEach(element => {
                    element.setAttribute('data-active', 'false');
                });
            }
        }
        
    }, [rankID, laneID , laneID2, sizeID ,nowChip]);
    return (

        <div className="markSixWrap gameWrap d-flex">
          <div className="itemWrap model2">
           
                <h5 className=''>
                {
                    rankID == 'normalFast' ?
                    <FormattedMessage
                        id={`vLottery.normalFast`}
                    />
                    :
                    <FormattedMessage
                        id={`vLottery.quickClearance`}
                    />
                }
                </h5>
            {
                rankID != 'normalFast' &&
                <div className='qucikClearBx'>
                    {
                        qucikClearModel.map((v)=>{
                            return (
                                <div key={v}
                                data-active={qucikClearValue.includes(v)}
                                onClick={()=>{
                                    empty()
                                    setQucikClearValue(v)
                                    setRankID([v])

                                }}
                                >
                                    <FormattedMessage
                                        id={`vLottery.${v}`}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            }
            <div className="circleWrap d-flex ">
              {
                <>
                  {
                    Lane4.map((v) => {
                        return (
                          <div className={betLock ? 'name close' : 'name'} key={v}
                            // data-active={laneID.includes(v)}
                            data-active={'false'}
                            onClick={(e) => {
                              handleSizeButtonClick(v , laneID , setLaneID , e)
                              handlePlaceShow()
                            //   if(rankID == 'normalFast'){
                            //     if(laneID.length > 0){
                            //         normalFastinputBet()
                            //         setGamePlaceShow(true)
                            //         console.log('觸發')
                            //     }
                            //   }
                            }}
                          >
                            <i style={{'pointerEvents' : 'none'}}>{v}</i>
                          </div>
                        )
                      })
                  }
                </>
              }
            </div>
            
          </div>
        </div>
      )
}


export default TraditionModel
