import React, { useState , useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import avatar from '../../images/traders/avatar.png';
import number from '../../images/traders/icon1.png';
import heart from '../../images/traders/icon2.png';


const AvatarStatus = ({model , avaterData , onSubscribe , onUNSubscribe , TrderID , getMyTrader}) => {
    const navigate = useNavigate();

    return (
        <div className='avatarStatusBx'>
            <div className='avatarStatus'>
                <img src={avatar}></img>
                <div className='desBx'>
                    <div className='name'>{avaterData.data.response.account}</div>
                    {/* {
                        model !== 'model2' &&
                        <div className='age'>已入駐<span>一年一個月</span></div>
                    } */}
                  
                    {
                        model !== 'model1' &&
                        <>
                            <div className='numBx'>
                                <div className='number'>
                                    <img src={number}></img>
                                    <span>{avaterData.data.response.mirror_trading_count}/100</span>
                                </div>
                                {
                                    model !== 'model2' &&
                                    <div className='follow'>
                                        <img src={heart}></img>
                                        <span>{avaterData.data.trade_info.subscribe_count}</span>
                                    </div>
                                }
                                {/* <div className='follow'>
                                    <img src={heart}></img>
                                    <span>100</span>
                                </div> */}
                            </div>
                            <div className={avaterData.data.online ? 'online' : 'online red'}>
                                {avaterData.data.online ?
                                    <FormattedMessage
                                        id={`trader.online`}
                                    ></FormattedMessage>
                                    :
                                    <FormattedMessage
                                        id={`trader.Offline`}
                                    ></FormattedMessage>
                                }
                            </div>
                        </>
                    }
                    
                </div>
                {
                    model !== 'model2' &&
                    <div className='botLine'></div>
                }
            </div>
            {
                model !== 'model1' &&  model !== 'model2' &&
                <div className='btnFlex'>
                    {
                        getMyTrader.data.is_trader || 
                        <>
                            {
                                avaterData.data.trade_info.mirror_trading_total_count >= 100 || avaterData.data.mirror_trading ?
                                <button className='full'>
                                    {
                                        avaterData.data.mirror_trading ? 
                                        <FormattedMessage
                                            id={`trader.alreadyFollowed`}
                                        ></FormattedMessage> 
                                        :
                                        <FormattedMessage
                                            id={`trader.full`}
                                        ></FormattedMessage> 
                                    }
                                    {/* 滿員 */}
                                </button>
                                :
                                <button className='mirBtn'
                                    onClick={() => {
                                        navigate(`/traderSet/${TrderID}`);
                                    }}
                                >
                                    <FormattedMessage
                                        id={`trader.followOrders`}
                                    ></FormattedMessage> 
                                </button>
                            }
                        </>
                    }
                    {
                        avaterData.data.is_subscribe ?
                        <button className='Cancel'
                            onClick={()=>{
                                onUNSubscribe()
                            }}
                        >
                            <FormattedMessage
                                id={`trader.unsubscribe`}
                            ></FormattedMessage> 
                        </button>
                        :
                        <button className='subBtn'
                            onClick={()=>{
                                onSubscribe()
                            }}
                        >
                            <FormattedMessage
                                id={`trader.subscribe`}
                            ></FormattedMessage> 
                        </button>
                    }
                   
                </div>
            }
            {/* {
                model === 'model2' &&
                <div className='tagBtnFlex'>
                    <div>
                        雙面
                    </div>
                    <div>
                        倍投
                    </div>
                    <div>
                        AI推薦
                    </div>
                </div>
            } */}
           {/* style={{ color: v.profit >= 0 ? 'green' : 'red' }} */}
            <div className={model === 'model2' ? 'flex incomeRate' : 'incomeRate'}  >
                <div className='title'>
                    <FormattedMessage
                        id={`trader.rateOfReturn`}
                    ></FormattedMessage> 
                    <span>{avaterData.data.response.rate_of_return}%</span>
                </div>
                {/* <div className='testBorder'></div> */}
            </div>
            {
                model === 'model2' &&
                <div className='botLine'></div>
            }
        </div>
    )
  }
  
  export default AvatarStatus