// import React, { useState , useEffect, useCallback , useRef} from 'react'



const initState = {
    listTraders:{
        data:{
            data: {
                labels: {},
                response: []
            },
            status: false
        }
    },
    getTraderPage:{
        data:{
            data: {
                is_trader: true,
                response: {
                  account: "string",
                  balance: 0,
                  dividend: 0,
                  mirror_trading_count: 0,
                  rate_of_return: 0,
                  trade_at: 0
                },
                trade_info: {
                  mirror_trading_profit: 0,
                  mirror_trading_total_count: 0,
                  subscribe_count: 0
                }
              },
            status: false
        }
    },
    getTrader:{
        data:{
            data: {
                is_subscribe: true,
                labels: [
                  "string"
                ],
                mirror_trading: true,
                online: true,
                response: {
                  account: "string",
                  balance: 0,
                  dividend: 0,
                  mirror_trading_count: 0,
                  rate_of_return: 0,
                  trade_at: 0
                },
                trade_info: {
                  mirror_trading_profit: 0,
                  mirror_trading_total_count: 0,
                  subscribe_count: 0
                }
              },
              status: true
        }
    },
    listMirrorTradings:{
        data:{
            data: {
                rate_of_return: {
                  additionalProp1: 0,
                  additionalProp2: 0,
                  additionalProp3: 0
                },
                response: [
                  {
                    create_at: 0,
                    id: "0",
                    max_bet: 0,
                    persent: 0,
                    platform_member_id: "",
                    stop_at: 0,
                    stop_loss: 0,
                    stop_win: 0,
                    trader_id: ""
                  }
                ],
                trader: {
                  additionalProp1: {
                    account: "string",
                    balance: 0,
                    dividend: 0,
                    mirror_trading_count: 0,
                    rate_of_return: 0,
                    trade_at: 0
                  },
                  additionalProp2: {
                    account: "string",
                    balance: 0,
                    dividend: 0,
                    mirror_trading_count: 0,
                    rate_of_return: 0,
                    trade_at: 0
                  },
                  additionalProp3: {
                    account: "string",
                    balance: 0,
                    dividend: 0,
                    mirror_trading_count: 0,
                    rate_of_return: 0,
                    trade_at: 0
                  }
                }
              },
              status: true
        }
    },
    listWait:{
        data:{
            data: {
                game_name: {
                  additionalProp1: "string",
                  additionalProp2: "string",
                  additionalProp3: "string"
                },
                response: [
                  {
                    amount: 0,
                    game_id: 0,
                    game_round_id: "string",
                    player_bet: "string",
                    profit: 0
                  }
                ],
                result: 0
              },
              status: true
        }
    },
    listSubscribes:{
        data:{
            data: {
                data: [
                  {
                    account: "",
                    create_at: 0,
                    id: "",
                    password: "",
                    phone: "",
                    platform_id: "",
                    update_at: 0,
                    update_by_id: ""
                  }
                ],
                online: {
                  additionalProp1: true,
                  additionalProp2: true,
                  additionalProp3: true
                }
              },
              status: true
        }
    },
    listMirrorTradingsBets:{
        data:{
            data: {
                game_name: {
                  additionalProp1: "string",
                  additionalProp2: "string",
                  additionalProp3: "string"
                },
                pagination: {
                  page: 0,
                  per_page: 0,
                  total: 0
                },
                response: [
                  {
                    amount: 0,
                    bet_at: 0,
                    create_at: 0,
                    game_id: 0,
                    game_round_id: "",
                    has_partition: false,
                    id: "",
                    mirror_trading_id: "",
                    payout_at: 0,
                    platform_id: "",
                    platform_member_id: "",
                    player_bet: "string",
                    profit: 0,
                    status: 0,
                    third_party_game_id: 0,
                    third_party_game_tid: "",
                    update_at: 0
                  }
                ]
              },
              status: true
        }
    },
    getTraderWinningPercentage:{
      data:{
        data: [
          0
        ],
        status: true
      }
    }
};


export const TraderReducer = (state = initState, action) => {
    switch (action.type) {
      case "FETCH_LISTTRADERS_API":
        return {
          ...state,
          listTraders : action.payload
        };
      case "FETCH_TRADERPAGE_API":
        return {
          ...state,
          getTraderPage : action.payload
        };
      case "FETCH_TRADER_API":
        return {
            ...state,
            getTrader : action.payload
        };
      case "FETCH_LISTMIRRORTRADING_API":
        return {
            ...state,
            listMirrorTradings : action.payload
        };
      case "FETCH_LISTWAIT_API":
        return {
            ...state,
            listWait : action.payload
        };
      case "FETCH_LISTSUBSCRIBES_API":
        return {
            ...state,
            listSubscribes : action.payload
        };
      case "FETCH_LISTMIRRORTRADINGBETS_API":
        return {
            ...state,
            listMirrorTradingsBets : action.payload
        };
      case "FETCH_TRADERWINNINGPERCENTAGE_API":
          return {
              ...state,
              getTraderWinningPercentage : action.payload
          };
      case "CREAT_TRADER_API":
        return state;
      case "CREAT_MIRRORTRADING_API":
        return state;
      case "FETCH_SUBSCRIBE_API":
        return state;
      case "FETCH_UNSUBSCRIBE_API":
        return state;
      case "CANCEL_MIRRORTRADING_API":
        return state;
      default:
        return state;
    }
};
  
// export { betHistoryReducer };