import React, { useState , useEffect, useCallback , useRef , useContext} from 'react'
import { NavLink , Link } from "react-router-dom";

import ProfileInfo from '../../Component/Profile/ProfileInfo';
import ProfileBtn from '../../Component/Profile/ProfileBtn';
import BettingRecord from '../../Component/Profile/bettingRecord';
import Agents from '../../Component/Profile/agents';
import AgentRegister from '../../Component/Profile/agentRegister';
import AgentInfo from '../../Component/Profile/agentInfo';
import PhoneVerificationMsg from '../../Component/Profile/phoneVerificationMsg';
import ListTransactions from '../../Component/Profile/listTransactions';
import AgentProfit from '../../Component/Profile/agentProfit';
import AgentBetting from '../../Component/Profile/agentBetting';
import MessageBinding from '../../Component/Profile/messageBinding';



import { ReactComponent as HomeIcon } from '../../Component/Icon/Home.svg'
import { ReactComponent as Charge } from '../../Component/Icon/Charge.svg'
import { ReactComponent as Host } from '../../Component/Icon/Host.svg'
import { ReactComponent as Revenue } from '../../Component/Icon/Revenue.svg'
import Swal from 'sweetalert2'
import api from '../../api/api';
import { ErrorContext } from '../../Routes';

const ProfilePage = () => {
  const { ErrorMessage } = useContext(ErrorContext);

  const [loading, setLoading] = useState(false)
  const [boxSwitch, setBoxSwitch] = useState(false)
  const [memberDetail , setMemberDetail] = useState()
  const [invitation , setInvitation] = useState()
  const [timeStop , setTimeStop] = useState(false)

  const [loadingBalance, setLoadingBalance] = useState(true)
  const [nowBalance, setNowBalance] = useState(true)
  const [credit , setCredit] = useState('')

  const CREDIT_URL = `/member/credit`;

  const UserInfoButtons = [
    {
      title: "首頁",
      icon: <HomeIcon className='icon me-1' />,
      link: "/",
    },
    {
      title: "充值",
      icon: <Charge className='icon me-1' />,
      link: "/",
    },
    {
      title: "莊家",
      icon: <Host className='icon me-1' />,
      link: "/",
    },
    {
      title: "收益",
      icon: <Revenue className='icon me-1' />,
      link: "/",
    },
  ]

  useEffect(()=>{
    getData()
  },[])

  const getData = async() =>{
    setLoading(false)
    try{
      await Promise.all([getMe(), getAnn(),getMeBalance()]);
    } finally{
        setLoading(true)
    }
  }
  // getme
  const getMe = async() => {
    try {
    const res = await api.get('/member', {
        headers: {
        "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
        "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    if (res.data.status) {
        setMemberDetail(res.data.data)
        if(res.data.data.tg_account !== "" && res.data.data.tg_status === true){
          setTimeStop(true)
          console.log('暫停')
        }
    }
    } catch (err) {
    console.log(err)
    const code = err.response.status
    const msg = err.response.data.data.error
    ErrorMessage(code, msg)
    }
  }
   // 取得邀請碼
  const getAnn = async() => {
  try {
    const res = await api.get('/agents/invitation', {
      headers: {
        "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
        "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    if (res.data.status) {
      setInvitation(res.data.data)
    }
  } catch (err) {
    console.log(err)
    const code = err.response.status
    const msg = err.response.data.data.error
    ErrorMessage(code, msg)
  }
  }
  const getMeBalance = async() => {
    try {
    const res = await api.get('/member/balance', {
        headers: {
        "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
        "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
    if (res.data.status) {
      // setMemberDetail(...memberDetail , `balanc : ${res.data.data}`)
      setNowBalance(res.data.data);
      setLoadingBalance(false)
        // setMemberDetail(res.data.data)
        // localStorage.setItem("accName", res.data.data.response.id);
    }
    } catch (err) {
    console.log(err)
    const code = err.response.status
    const msg = err.response.data.data.error
    ErrorMessage(code, msg)
    }
  }
  // 拿信用驗證狀態
  const getCredit = async () => {
    try {
      const res = await api.get(CREDIT_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
        },
      });
      if (res.data.status) {
        console.log(res.data)
        setCredit(res.data.data)
      }
    } catch (err) {
      console.log(err);
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    }
  };



  

  useEffect(() => {
    let intervalId;

   if (!timeStop) {
    intervalId = setInterval(() => {
      getMeBalance();
    }, 10000);
  }

    return () => {
      
      clearInterval(intervalId);
    };
      
}, [timeStop]); 


  return (
    <div className="profilePage homePage page home-bg mt-0">
      {/* 選單按鈕 */}
      <div className="menuWrap mt-3">
        <div className="items d-flex">
          {UserInfoButtons.map((v) => {
            return (
              <NavLink key={v.title} to={v.link}>
                {v.icon}
                <span>{v.title}</span>
              </NavLink>
            )
          })}
        </div>
      </div>
      {/* 個人資料 */}
      {
        loading &&
        <>
          <ProfileInfo 
          memberDetail={memberDetail} 
          invitation={invitation} 
          getMe={getMe} 
          loadingBalance={loadingBalance} 
          nowBalance={nowBalance}
          credit={credit} 
          />
        
          {boxSwitch === false && 
          <ProfileBtn 
          setBoxSwitch={setBoxSwitch} 
          memberDetail={memberDetail} 
          credit={credit} 
          getCredit={getCredit}
          />
          }
          {boxSwitch === "agentInfo" && <AgentInfo setBoxSwitch={setBoxSwitch}/>}
          {boxSwitch === "bettingRecord" && <BettingRecord setBoxSwitch={setBoxSwitch}/>}
          {boxSwitch === "agents" && <Agents setBoxSwitch={setBoxSwitch}/>}
          {boxSwitch === "agentRegister" && <AgentRegister setBoxSwitch={setBoxSwitch}/>}
          {boxSwitch === "verificationMsg" && <PhoneVerificationMsg setBoxSwitch={setBoxSwitch} getData={getData}/>}
          {boxSwitch === "listTransactions" && <ListTransactions setBoxSwitch={setBoxSwitch}/>}
          {boxSwitch === "agentProfit" && <AgentProfit setBoxSwitch={setBoxSwitch}/>}
          {boxSwitch === "agentBetting" && <AgentBetting setBoxSwitch={setBoxSwitch}/>}
          {boxSwitch === "messageBinding" && <MessageBinding setBoxSwitch={setBoxSwitch}/>}
          
        </>
      }
      

    </div>
  )
}

export default ProfilePage
