import React, { useState , useEffect } from 'react'
import { useSelector , useDispatch} from 'react-redux';
import '../GamePlace.scss';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { FormattedMessage , useIntl } from "react-intl";
import Swal from 'sweetalert2'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import chip1 from '../../../images/chips1.png';
import chip2 from '../../../images/chips2.png';
import chip3 from '../../../images/chips3.png';
import chip4 from '../../../images/chips4.png';
import chip5 from '../../../images/chips5.png';


const GamePlace = ({chip , setChip , handleTotalClick , total , totalOpen , totalLen ,totalPrice ,handleBet,empty,customChipRef,chipReduce,setGamePlaceShow,gamePlaceShow , gameID , rankID , orderMultiple ,  setRankID , setGameModelValue , normalFastinputBet,inputRefs,handleBetFast,setBetLock , gameModelValue , laneID , laneID2 , laneID3 , laneID4 , nowOdds , nowLow}) => {
    const intl = useIntl();

    const dispatch = useDispatch();
    const gameChips = useSelector(state => state.game.gameChips)
    const [chipLoading , setChipLoading] = useState(true)
    const getGameChips = () =>{
        dispatch({ type: 'SAGA_FETCH_GAMECHIPS_API'});
    }
    // const [orderMultiple, setOrderMultiple] = useState(1)

    // const direction = 'middle';
    // const judgeBetMultiple = () => {
    //     switch (direction) {
    //         case 'North':
                
    //             break;
        
    //         case 'middle':
    //         case 'South':
    //             if (rankID === '2d_head_tail') {
    //                 setOrderMultiple(2)
    //                 // return 2;
    //             } else if (rankID === '2d_group') {
    //                 setOrderMultiple(18)
    //                 // return 18;
    //             } else if (rankID === '2d_group_seven') {
    //                 setOrderMultiple(7)
    //                 // return 7;
    //             }
    //             break;
    //         default:
    //             // 处理其他情况
    //             // ...
    //     }
    // }
    const isNumericString = (value) => {

        // return !isNaN(parseFloat(value)) && isFinite(value);
         // 使用 parseFloat 轉換為數字
        const numericValue = parseFloat(value);
        // 檢查轉換結果是否為有效的數字
        return !isNaN(numericValue) && isFinite(numericValue);
    }
    // const ChipBtn = [
    //     '1' ,'2' ,'10' ,'50' ,'100'
    // ]
    const [ chipBtn , setChipBtn ] = useState([
        {
            value : "1",
            img : chip1,
            seq : 1
        },
        {
            value : "2",
            img : chip5,
            seq : 2
        },
        {
            value : "10",
            img : chip2,
            seq : 3
        },
        {
            value : "50",
            img : chip3,
            seq : 4
        },
        {
            value : "100",
            img : chip4,
            seq : 5
        },
    ])

    const handleChipButtonClick = (value) => {
        setChip([value]);
        
        // if (chip.indexOf(value) !== -1) {
        //     const newArray = chip.filter((element) => element !== value);
        //     setChip(newArray);

        // } else {
        //     setChip([...chip, value]);
        // }
    };

    const chipEdit = () =>{
        const updatedArray = chipBtn.map(item => {
            const match = gameChips.data.find(el => el.seq === item.seq);
            if (match) {
              return { ...item, value: match.amount };
            }
            return item;
          });
      
        setChipBtn(updatedArray);
        setChipLoading(false)
    }
    useEffect(()=>{
        if(gameChips.data[0].amount === 0) {
            getGameChips()
        } else{
            chipEdit()
        }
        
        console.log(gameChips)
       
    },[gameChips])
    
    return (
        <div className='GamePlaceWrap vietGamePlace'>
            
            <div className='GamePlaceBx'>
                    {
                        !rankID.includes('normalFast') && 
                        <button className='expandBtn'
                        onClick={() => {
                            if(gamePlaceShow){
                                setGamePlaceShow(false)
                            } else {
                                setGamePlaceShow(true)
                            }
                            
                        }}>
                            {
                                gamePlaceShow ?  <ExpandMoreIcon/> :  <ExpandLessIcon/>
                            }
                        
                        </button>
                    }
                    {
                        rankID.includes('normalFast') && 
                        <>
                            {
                                gamePlaceShow &&
                                    <button className='normalFastShowBtn'
                                        onClick={() => {
                                            Swal.fire({
                                                text: intl.formatMessage({
                                                    id: `alert.numberFinText`
                                                }),
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText: intl.formatMessage({
                                                    id: `alert.yes`
                                                }),
                                                cancelButtonText:intl.formatMessage({
                                                    id: `alert.no`
                                                }),
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    setGamePlaceShow(false)
                                                    setBetLock(true)
                                                }
                                            });
                                            
                                        }}>
                                        <FormattedMessage
                                            id={`alert.numberFin`}
                                        ></FormattedMessage>  
                                    </button>
                            }
                        </>
                        
                    }

                    <div className='PlaceBottom'>
                    {
                        gamePlaceShow || 
                        <>
                            {
                                totalOpen &&
                                <div className='totalBx'>
                                    {
                                        total.map((e) => {
                                            return (
                                                <div key={e}>
                                                    
                                                    {
                                                        <>
                                                            <>
                                                                {
                                                                    `
                                                                    ${
                                                                        intl.formatMessage({
                                                                            id: `game.${e.split(',')[0]}`
                                                                        })
                                                                    },
                                                                    ${
                                                                        e.split(',')[1].startsWith(';') ? e.split(',')[1] :
                                                                        isNumericString(e.split(',')[1]) ? e.split(',')[1] :
                                                                        intl.formatMessage({
                                                                            id: `game.${e.split(',')[1]}`
                                                                        })
                                                                    },
                                                                    `
                                                                }
                                                            </>
                                                            <>
                                                                {
                                                                    rankID == 'normalFast' ?
                                                                    <input 
                                                                    id={e.split(',')[0]} 
                                                                    className='normalFastinput' 
                                                                    defaultValue={e.split(',')[2]}
                                                                    onChange={()=>normalFastinputBet(0)}
                                                                    ref={inputRefs.current[e.split(',')[0]]}
                                                                    type="number"
                                                                    min={0}
                                                                    ></input>
                                                                    :
                                                                    `${e.split(',')[2]}`
                                                                }
                                                            </>
                                                        </>
                                                        
                                                    }
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            }
                        </>
                    }
                        <div className='flex-bx'>
                            {/* <button className="edit-btn"
                            onClick={() => {
                                handleTotalClick()
                                // setGamePlaceShow(true)
                            }}
                            >確認</button> */}
                            <div className='btns'>
                            {chipLoading || chipBtn.map((v) => {
                                return (
                                    <button key={v}
                                    // data-active={chip.includes(v)}
                                    className={
                                        (rankID.includes('normalFast') && gamePlaceShow) ?
                                        'close' : 'open'
                                    }
                                    onClick={() => {
                                        if(gameModelValue === "model1"){
                                            if(laneID.length === 0 || laneID2.length === 0){
                                                if(!rankID.includes("2d_fixed_head") && !rankID.includes("2d_two_sides_head")){
                                                    Swal.fire({
                                                        icon: "error",
                                                        text: intl.formatMessage({
                                                            id: `alert.notSelected`
                                                        }),
                                                    });
                                                } else{
                                                    handleChipButtonClick(v.value)
                                                    chipReduce(v.value)
                                                    normalFastinputBet(v.value)
                                                }
                                                
                                            } else{
                                                handleChipButtonClick(v.value)
                                                chipReduce(v.value)
                                                normalFastinputBet(v.value)
                                            }
                                        } else if (gameModelValue === "model2"){
                                            if(laneID.length === 0 || laneID2.length === 0 || laneID3.length === 0){
                                                if(!rankID.includes("3d_fixed_head") && !rankID.includes("3d_two_sides_head")){
                                                    Swal.fire({
                                                        icon: "error",
                                                        text: intl.formatMessage({
                                                            id: `alert.notSelected`
                                                        }),
                                                    });
                                                } else{
                                                    handleChipButtonClick(v.value)
                                                    chipReduce(v.value)
                                                    normalFastinputBet(v.value)
                                                }
                                            } else{
                                                handleChipButtonClick(v.value)
                                                chipReduce(v.value)
                                                normalFastinputBet(v.value)
                                            }
                                        } else if (gameModelValue === "model3"){
                                            if(laneID.length === 0 || laneID2.length === 0 || laneID3.length === 0 || laneID4.length === 0){
                                                if(!rankID.includes("4d_fixed_tail") && !rankID.includes("4d_two_sides_tail")){
                                                    Swal.fire({
                                                        icon: "error",
                                                        text: intl.formatMessage({
                                                            id: `alert.notSelected`
                                                        }),
                                                    });
                                                } else{
                                                    handleChipButtonClick(v.value)
                                                    chipReduce(v.value)
                                                    normalFastinputBet(v.value)
                                                }
                                            } else{
                                                handleChipButtonClick(v.value)
                                                chipReduce(v.value)
                                                normalFastinputBet(v.value)
                                            }
                                        } else{
                                            handleChipButtonClick(v.value)
                                            chipReduce(v.value)
                                            normalFastinputBet(v.value)
                                        }
                                        // handleChipButtonClick(v)
                                        // chipReduce(v)
                                        // normalFastinputBet(v)
                                    }}
                                    >
                                           <img src={v.img}></img>
                                            <span>{`${v.value}`}</span>
                                    </button>
                                )
                            })}
                            </div>
                        </div>
                        <div className='flex-bx'>
                            <div className='price'>
                                <div className='title'>
                                <FormattedMessage
                                    id={`game.selected`}
                                ></FormattedMessage>  
                                <i>
                                    {
                                        totalOpen ? totalLen
                                        : 0
                                    }
                                </i> <FormattedMessage
                                        id={`game.bet`}
                                    ></FormattedMessage></div>
                                
                                
                                <div className='inputWrap'>
                                    {/* <input
                                        ref={customChipRef}
                                        type="number"
                                    ></input> */}
                                    <i><FormattedMessage
                                        id={`game.betAmount`}
                                    ></FormattedMessage>：</i>
                                    <span className='total'>
                                        { totalOpen ? `${totalPrice} x ${orderMultiple} = ${totalPrice * orderMultiple}` : 0} 
                                        {/* {rankID === } */}
                                    </span>
                                </div>
                                <div className='title'>
                                <FormattedMessage
                                    id={`game.odds`}
                                ></FormattedMessage>：
                                <i>
                                    {
                                        nowOdds ? `1 : ${
                                            Number.isInteger(nowOdds*orderMultiple) ? (nowOdds*orderMultiple) : (nowOdds*orderMultiple).toFixed(3)
                                        }`
                                        : ''
                                    }
                                </i></div>
                                <div className='title'>
                                <FormattedMessage
                                    id={`game.betlower`}
                                ></FormattedMessage>：
                               
                                <i>
                                    {
                                        nowLow
                                    }
                                </i></div>
                                

                                
                            </div>
                            
                            <div className='btn-bx'>
                                <button className='clear-btn'
                                onClick={() => {
                                    empty();
                                    // setRankID(['2d_tail_contrary'])
                                    // setGameModelValue('model1')
                                }}
                                >
                                    <FormattedMessage
                                        id={`game.clear`}
                                    ></FormattedMessage>
                                </button>
                                <button className='send-btn'
                                onClick={(e) => {
                                    if(rankID == 'normalFast'){
                                        handleBetFast(e);
                                    }else{
                                        handleBet(e);
                                    }
                                   
                                }}
                                >
                                    <FormattedMessage
                                        id={`game.confirm`}
                                    ></FormattedMessage>
                                </button>
                            </div>
                        </div>
                    
                    </div>
               
                
            </div>
            
        </div>

        
      )
}


export default GamePlace
