import React, { useEffect, useCallback , useRef , useContext} from 'react'
import { useSelector , useDispatch } from 'react-redux';
import Spinner from '../Spinner/Spinner';
import { FormattedMessage , useIntl } from "react-intl";

import ReplyIcon from '@mui/icons-material/Reply';
import { ErrorContext } from '../../Routes';

const AgentRegister = ({setBoxSwitch}) => {
  const { ErrorMessage } = useContext(ErrorContext);

   // 用ref 紀錄 input value
   const usernameRef = useRef()
   const passwordRef = useRef()
  //redux
  const dispatch = useDispatch();
  const agentGroup = useSelector(state => state.agent)

  const intl = useIntl();

  useEffect(() => {
//   dispatch({ type: 'SAGA_FETCH_AGENTGROUP_API'});

  }, [dispatch]);

//   useEffect(() => {
//     setTotalPage(Math.ceil(betHistory.data.data.pagination.total / perpage))
//   }, [betHistory.data.status])
//   const username = usernameRef.current.value;
//   const password = passwordRef.current.value;

  const atRegisterHandler = useCallback((event) => {
    event.preventDefault();
    const username = usernameRef.current.value;
    const password = passwordRef.current.value;
    dispatch({ type: 'SAGA_CREAT_AGENT_API', username, password , ErrorMessage });
  }, [dispatch]);

  return (
    <div className="agentRegisterWrap bottWrap">
        <div className='titleFlex'>
            <button className='backBtn' onClick={()=>{
                setBoxSwitch(false)
            }}
            ><ReplyIcon/></button>
            
            <div>
            < FormattedMessage
                  id={`profile.agentRegister`}
              ></FormattedMessage>
            </div>
        </div>
        
         <form action="">
            <div>
                <input
                ref={usernameRef}
                type="text"
                required
                // placeholder='用戶名' 
                placeholder={intl.formatMessage({
                  id: 'agent.username',
                })}
                maxlength="20"/>
            </div>
            <div className='remaker'>
              <FormattedMessage
                  id={`agent.characterAlphanumericCombination`}
              ></FormattedMessage>
            </div>
            <div>
                <input
                ref={passwordRef}
                type="password" 
                required
                // placeholder='密碼' 
                placeholder={intl.formatMessage({
                  id: 'agent.password',
                })}
                maxlength="20"/>
            </div>
            <div className='remaker'>
              <FormattedMessage
                  id={`agent.characterAlphanumericCombination`}
              ></FormattedMessage>
            </div>
            <button className='registerBtn com-btn' type="submit" onClick={atRegisterHandler}>
              <FormattedMessage
                  id={`agent.register`}
              ></FormattedMessage>
            </button>
         </form>
        

            
            
    </div>
  )
}

export default AgentRegister