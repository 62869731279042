// import React from 'react'
import React, { useState , useEffect, useCallback} from 'react'

import './../GamePlace.scss';
import { FormattedMessage } from "react-intl";
import changBtn from '../../../images/racing/automatic-betting.png';


import btn1 from '../../../images/racing/first-white.png';
import btn1a from '../../../images/racing/first-blue.png';
import btn2 from '../../../images/racing/second-white.png';
import btn2a from '../../../images/racing/second-blue.png';
import btn3 from '../../../images/racing/star-white.png';
import btn3a from '../../../images/racing/star-blue.png';

const ManualBet = ({
    fixbarRef,rankID,payOrder,Lane,laneID,setLaneID,Lane2,sizeID,setSizeID,nClickSizeID,setAutoHide,manualValue,handleManuaTotalClick,nowChip,handlePlaceShow,empty,handlePlayButtonClick,odds,setGamePlaceShow,emptyC,setGamePlaceButtonShow
    }) => {
    const btns = [
        {
            name: "单式",
            id : 1,
            value : "single",
            play:['big','small','odd','even'],
            oddName : "single"
        },
        {
            name: "复式",
            id : 2,
            value : "multiple",
            play:['big_odd','small_odd','big_even','small_even'],
            oddName : "multiple"
        },
        {
            name: "单式(不通殺)",
            id : 1,
            value : "single_nk",
            play:['big','small','odd','even'],
            oddName : "single_nk"
        },
        {
            name: "复式(不通殺)",
            id : 2,
            value : "multiple_nk",
            play:['big_odd','small_odd','big_even','small_even'],
            oddName : "multiple_nk"
        },
        {
            name: "特殊玩法",
            id : 3,
            value : "b18_sp",
            play:['triple','straight','one_pair'],
            oddName : "sp"
        },
        {
            name: "点杀",
            id : 4,
            value : "b18_S",
            play:['4','5','6','7','8','9','10','11','12','13','14','15','16','17'],
            oddName : "s"
        },
        {
            name: "点杀(不通殺)",
            id : 4,
            value : "b18_S_nk",
            play:['3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
            oddName : "s_nk"
        },
        {
            name: "龙虎",
            id : 5,
            value : "b18_dt",
            play:['dragon','tiger','tie'],
            oddName : "dt"
        },
        {
            name: "三不同玩法",
            id : 6,
            value : "b18_TN",
            play:['_'],
            oddName : "tn"
        },
        {
            name: "超高倍区",
            id : 7,
            value : "b18_B",
            play:['1','2','3','4','5','6'],
            oddName : "b"
        },
    ]
    // const refs = btns.map(btn => React.createRef(null));
    // const handleClick = useCallback((id) => {
    //     console.log(refs.map(btn => btn.current))
    //     const ref = refs.find(btn => btn.current.id === id);
    //     // console.log(refs.map(btn => btn))
    //     if (ref) {
    //         ref.current.scrollIntoView({ behavior: 'smooth'});
            
    //     } else{
    //         console.log(refs.map(btn => btn.current))
    //     }
    // }, []);
    

    // const handleScroll = useCallback(() => {
    //     const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    //     for (let i = 0; i < refs.length; i++) {
    //     if (refs[i].current.offsetTop <= scrollTop + 80) {
    //         setActiveBtn(i + 1);
    //     }
    //     }
    // }, [refs]);
    
    useEffect(() => {
        handleManuaTotalClick()
    }, [manualValue,nowChip]);

    //錨點移動
    const handleClick = (id) => {
        const ref = document.getElementById(`btna-${id}`);
        if (ref) {
            ref.scrollIntoView({ block: "center", behavior: 'smooth' });
        }
    };

    const [activeBtn, setActiveBtn] = useState(null);

    //建立fixbar的Ref
    // const fixbarRef = React.createRef(null);
    const [fixbarOffsetTop, setFixbarOffsetTop] = useState(0);

    useEffect(() => {
        const fixbar = fixbarRef.current;
        if (fixbar) {
            setFixbarOffsetTop(fixbar.offsetTop);
        }
        
        console.log(
            btns.filter(v => Object.keys(odds).includes(v.oddName))
        )
    }, []);
    
    const handleScroll = useCallback(() => {
        //使用Ref.current引用DOM
        const fixbar = fixbarRef.current;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        //判斷fixbar固定
    
        if (scrollTop >= fixbarOffsetTop - 36) {
            fixbar.classList.add('active');
        } else {
            fixbar.classList.remove('active');
        }
        
        //判斷fixbar item active
        for (let i = 0; i < btns.length; i++) {
            const ref = document.getElementById(`btn-${btns[i].id}`);
            if (ref && ref.offsetTop <= scrollTop + 300) {
                setActiveBtn(btns[i].id);
                // ref.scrollIntoView({ block: "center", behavior: 'smooth' })
            }
        }
    }, [btns]);

    const judgePlay = (v) => {
        if(v === 'b18_S' || v === 'b18_S_nk'){
            return 'circleWrap d-flex fourCircle b18'
        } else if (v === 'b18_sp' || v === "b18_TN"){
            return 'circleWrap d-flex fourCircle b18sp'
        } else if (v === 'b18_B'){
            return 'circleWrap d-flex fourCircle b18B'
        } else{
            return 'circleWrap d-flex fourCircle'
        }
    }

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [handleScroll]);

    return (
        <div className="gameWrap d-flex">
          <div className="itemWrap model1">
            {btns.filter(v => Object.keys(odds).includes(v.oddName)).map((btn) => {
              return (
                <div className='circleCard' key={btn.value} 
                id={`btn-${btn.id}`}
                data-active={odds[btn.oddName].status}
                >
                    <div id={`btna-${btn.id}`}  className='cardanchor'></div>
                    <h5 className='long'>
                        <span>
                            <FormattedMessage
                                id={`game.${btn.value}`}
                            />
                        {/* {btn.name} */}
                        </span>
                        
                        {/* <FormattedMessage
                            id={`game.${btn.value}`}
                        />
                        <FormattedMessage
                            id={`game.carNumber`}
                        /> */}
                    </h5>
                    <div className={judgePlay(btn.value)}>
                        {btn.play.map((v) => {
                            return (
                            <div key={v} className="name"
                            data-active={manualValue.includes(`${btn.value},${v}`)}
                            onClick={() => {
                                if(btn.value != rankID){
                                    empty()
                                    setGamePlaceShow(true)
                                    setGamePlaceButtonShow(true)
                                }
                                console.log(rankID)
                                handlePlayButtonClick(v,laneID,setLaneID,btn.value)
                                handlePlaceShow()
                            }}
                            >
                                <i>
                                    {/* {v} */}
                                    {
                                        btn.value === 'b18_S' || btn.value === 'b18_S_nk' ? v : 
                                        
                                        <>
                                            {
                                                v === '_' ?   
                                                <FormattedMessage
                                                id="game.b18tn"
                                                />: 
                                                <FormattedMessage
                                                id={`game.${v}`}
                                                />
                                            }
                                        </>
                                       
                                    }
                                    
                                </i>
                                <span className='odd'>
                                    {

                                        odds[btn.oddName].unified ? 
                                        `x${odds[btn.oddName].unified_odds.odds}`
                                        :
                                        `x${odds[btn.oddName].odds[v].odds}`
                                    }
                                </span>
                            </div>
                            )
                        })}
                    </div>
                </div>
              )
            })}
            
          </div>
        </div>
    )
}


export default ManualBet
