// import React from 'react'
import { useEffect , useState , useContext} from 'react';
import { FormattedMessage , useIntl } from "react-intl";
import moment from "moment";
import './../GamePlace.scss';
import Spinner from '../../Spinner/Spinner';
import api from '../../../api/api';
import { ErrorContext } from '../../../Routes';

const FourBallTrend = ({gameID}) => {
    const intl = useIntl();
    const { ErrorMessage , TimeMoment } = useContext(ErrorContext);

    const [historyList , setHistoryList] = useState([]);
    const [listLoading , setListLoading] = useState(false);
    const mapping = {
        '1': 
        
        <i className='TRed'>
            {intl.formatMessage({
                id: 'game.dragon',
            })}
            {/* <FormattedMessage
                id={`game.dragon`}
            /> */}
        </i>, 
        '0': 
        <i className='TBlue'>
        {intl.formatMessage({
            id: 'game.tiger',
        })}
            {/* <FormattedMessage
                id={`game.tiger`}
            /> */}
        </i>
    };  

    //拿到開獎紀錄
    const getHistory = async() => {
        setListLoading(true)
        try {
        const res = await api.get(`/games/${gameID}/history`,{
            headers: {
            "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
            "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
        });
        if (res.data.status) {
            setHistoryList(res.data.data)
        }
        } catch (err) {
        console.log(err)
        const code = err.response.status
        const msg = err.response.data.data.error
        ErrorMessage(code, msg)
        }
        finally {
            setListLoading(false)
        }
    }
    useEffect(() => {
        getHistory()
        // setInterval(()=>{
        //     getHistory()
        // },60000)
        
      }, []);
    return (
        <div className="fixed_headers trendTable fourBallTrend">
            {listLoading ? <Spinner/> : 
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FormattedMessage
                                    id={`game.issue`}
                                />
                            </td>
                            <td>
                                <FormattedMessage
                                    id={`game.lotteryDraw`}
                                />
                            </td>
                            <td>
                                <FormattedMessage
                                    id={`game.time`}
                                />
                            </td>
                            {/* <td>
                                <FormattedMessage
                                    id={`game.model2`}
                                />
                            </td>
                            <td>
                                <FormattedMessage
                                    id={`game.model3`}
                                />
                            </td> */}
                        </tr>
                        {historyList.map((v) => {

                            // const parsedResult = JSON.parse(v.result).winning_numbers;
                            // const numbers = parsedResult.winning_numbers;

                            //   // 將數字轉換為包含 <div> 的 JSX 元素陣列
                            // const divElements = parsedResult.map((number, index) => (
                            //     <div key={index} className={`T${number}`}>{number}</div>
                            // ));
                            return (
                                <tr key={v.issue_no}>
                                    <td>{parseInt(v.issue_no)}</td>
                                    <td>
                                        {
                                         JSON.parse(v.result).winning_numbers
                                        }
                                        { JSON.parse(v.result).fish_shrimp_crab &&  ` + `}
                                        {
                                            JSON.parse(v.result).fish_shrimp_crab && 
                                            intl.formatMessage({
                                                id: `game.${JSON.parse(v.result).fish_shrimp_crab}`,
                                            }) 
                                        }
                                    </td>
                                    <td>{
                                         TimeMoment(v.lottery_draw_at)
                                    }</td>
                                    {/* <td>
                                        <i>{JSON.parse(v.result).sum}</i>,
                                        {JSON.parse(v.result).is_big ? 
                                            <i className='TRed'>
                                                {
                                                    intl.formatMessage({
                                                        id: 'game.big',
                                                    }) 
                                                }
                                            </i>
                                            : 
                                            <i className='TBlue'>
                                                {
                                                    intl.formatMessage({
                                                        id: 'game.small',
                                                    })
                                                }
                                            </i>
                                        },
                                        {JSON.parse(v.result).is_odd ?
                                            <i className='TRed'>
                                                {
                                                    intl.formatMessage({
                                                        id: 'game.odd',
                                                    }) 
                                                }
                                            </i>
                                            : 
                                            <i className='TBlue'>
                                                {
                                                    intl.formatMessage({
                                                        id: 'game.even',
                                                    })
                                                }
                                            </i>
                                        },
                                        {JSON.parse(v.result).is_banker ? 
                                          <i className='TRed'>
                                            {
                                                 intl.formatMessage({
                                                    id: 'game.banker',
                                                })
                                            }
                                          </i>
                                        : 
                                            <i className='TBlue'>
                                                {
                                                    intl.formatMessage({
                                                        id: 'game.player',
                                                    }) 
                                                }
                                            </i>
                                       
                                        },
                                        {JSON.parse(v.result).sum_range}
                                    </td>
                                    <td>
                                        {
                                            Object.values(JSON.parse(v.result).dragon_tiger.map(key => mapping[key])).map((element, index) => (
                                                element
                                            ))
                                        }    
                                    </td> */}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                
            }
            
        </div>
    );
}


export default FourBallTrend
