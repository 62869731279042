import React, { useState , useEffect, useCallback , useRef , useContext} from 'react'
import './../GamePlace.scss';
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage , useIntl } from "react-intl";
import api from '../../../api/api';
import moment from "moment";
import Swal from 'sweetalert2'
import { ErrorContext } from '../../../Routes';

const VietOpenListPop = ({setVietOpenListPop , GameID , setNowGameID}) => {
    const intl = useIntl();

    const { ErrorMessage , TimeMoment } = useContext(ErrorContext);
    const [viteOpenList , setViteOpenList] = useState([])
    const [viteOpenListLoading , setViteOpenListLoading] = useState(true)

    const getOpen = async(status) => {
        try {
          // &status=1
          const res = await api.get(`/games/${GameID}/opening?language=${localStorage.getItem("language")}&status=${status}`,{
              headers: {
              "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
              "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
              },
          });
          if (res.data.status) {
              setViteOpenList(res.data.data)
              setViteOpenListLoading(false)
            }
        } catch (err) {
          console.log(err)
          const code = err.response.status
          const msg = err.response.data.data.error
          ErrorMessage(code, msg)
        }
    
      }
    useEffect(()=>{
        getOpen(0)
    },[])

    return (

        <div className='ruleWrap'>
            <div className='ruleDes vietOpenDes'>
                <div className='ruleClose'
                onClick={() => {
                    // handleBoxSwitch('rule');
                    setVietOpenListPop(false)
                  }}
                >
                <CloseIcon/>
                </div>
                <div className='ruleScrollBx' >
                    <div className='openListTitle'>
                        <FormattedMessage
                            id={`vLottery.vietnamLotteryHall`}
                        />
                    </div>
                    <div className='vietOpenList'>
                        {
                            viteOpenListLoading ||
                            viteOpenList.map((v)=>{
                                const startIndex =  v.name.indexOf('(');
                                const endIndex =  v.name.indexOf(')');
                                const substring = v.name.substring(startIndex + 1, endIndex);
                                return (
                                
                                        <div className='flexBx' onClick={()=>{
                                            if(v.status){
                                                setNowGameID(v.game_id)
                                                setVietOpenListPop(false)
                                            }
                                        }}>
                                            <div>{v.name}</div>
                                            <div>{v.status ? 
                                            <span className='green'>
                                                 <FormattedMessage
                                                    id={`vLottery.opening`}
                                                />
                                            </span>:<span className='red'>
                                                <FormattedMessage
                                                    id={`vLottery.Closed`}
                                                />
                                            </span>}
                                            </div>
                                            <div>{
                                                TimeMoment(v.draw_at)
                                            }</div>
                                        </div>
                                
                                )
                            })
                        }
                    </div>
                </div>
                
            </div>
        </div>
      )
}


export default VietOpenListPop
