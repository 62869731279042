import React, { useState , useEffect, useCallback , useRef} from 'react'
import { NavLink , Link ,useNavigate} from "react-router-dom";
import { useSelector , useDispatch , Provider} from 'react-redux';
import { FormattedMessage } from "react-intl";


import './Traders.scss'

import ProfileInfo from '../../Component/Profile/ProfileInfo';
import ProfileBtn from '../../Component/Profile/ProfileBtn';
import BettingRecord from '../../Component/Profile/bettingRecord';
import Agents from '../../Component/Profile/agents';
import AgentRegister from '../../Component/Profile/agentRegister';
import AgentInfo from '../../Component/Profile/agentInfo';
// banner 
import banner from '../../images/traders/banner2.png';
// traderHeader
import { FaUser } from 'react-icons/fa';
import { AiOutlineCheck } from 'react-icons/ai';

import ReplyIcon from '@mui/icons-material/Reply';
// icon
import icon1 from '../../images/traders/many-people.png';
import icon2 from '../../images/traders/Classification.png';
import icon3 from '../../images/traders/people-ok.png';
import icon4 from '../../images/traders/customer-service.png';






const JoinTraderPage = () => {
  const [loading, setLoading] = useState(false)
  const [boxSwitch, setBoxSwitch] = useState(false)
  const navigate = useNavigate();
  //redux
  const dispatch = useDispatch();
  const getTraderPage = useSelector(state => state.trader.getTraderPage)

//   useEffect(() => {
//     dispatch({ type: 'SAGA_FETCH_CREATTRADER_API' , setLoading});
//   }, [dispatch]);

  return (
    <div className="joinTradersPage homePage page home-bg mt-0">
        <div className='traderHeader'>
            <button className='backBtn' onClick={()=>{
                  navigate(`/traders`);
              }}
            ><ReplyIcon/></button>
            <div className='title'>
                <FormattedMessage
                    id={`trader.becomeatrader`}
                ></FormattedMessage> 
            </div>
            <FaUser onClick={() => {
                navigate(`/traderMyHome`);
            }}/>
        </div>
        <div className='banner'>
            <img src={banner}></img>
            <button  onClick={()=>{
                    dispatch({ type: 'SAGA_FETCH_CREATTRADER_API' , setLoading});
                }}>
                <FormattedMessage
                    id={`trader.applyNow`}
                ></FormattedMessage> 
                {/* <img src={bannerButton}></img> */}
            </button>
        </div>
        <div className='container'>
            <ul className='iconList'>
                <li>
                    <img src={icon1}></img>
                    <span>
                        <FormattedMessage
                            id={`trader.massivefans`}
                        ></FormattedMessage> 
                    </span>
                </li>
                <li>
                    <img src={icon2}></img>
                    <span>
                        <FormattedMessage
                            id={`trader.accurateExposure`}
                        ></FormattedMessage> 
                    </span>
                </li>
                <li>
                    <img src={icon3}></img>
                    <span>
                        <FormattedMessage
                            id={`trader.supportPlans`}
                        ></FormattedMessage> 
                    </span>
                </li>
                <li>
                    <img src={icon4}></img>
                    <span>
                        <FormattedMessage
                            id={`trader.oneOnOne`}
                        ></FormattedMessage> 
                    </span>
                </li>
            </ul>
            <div className='conditionBx'>
                <div className='title'>
                    <FormattedMessage
                        id={`trader.applicationConditions`}
                    ></FormattedMessage> 
                </div>
                <ul>
                    <li>
                        <AiOutlineCheck/>
                        <span>
                            <FormattedMessage
                                id={`trader.noOrderFromOtherTraders`}
                            ></FormattedMessage> 
                        </span>
                    </li>
                    <li>
                        <AiOutlineCheck/>
                        <span>
                            <FormattedMessage
                                id={`trader.registrationMore`}
                            ></FormattedMessage> 
                        </span>
                    </li>
                    <li>
                        <AiOutlineCheck/>
                        <span>
                            <FormattedMessage
                                id={`trader.yourTotalAssets`}
                            ></FormattedMessage> 
                        </span>
                    </li>
                    <li>
                        <AiOutlineCheck/>
                        <span>
                            <FormattedMessage
                                id={`trader.yourTotalExceeds`}
                            ></FormattedMessage> 
                        </span>
                    </li>
                </ul>
                <button
                    onClick={()=>{
                        dispatch({ type: 'SAGA_FETCH_CREATTRADER_API' , setLoading});
                    }}
                >
                    <FormattedMessage
                        id={`trader.becomeTrader`}
                    ></FormattedMessage> 
                </button>
            </div>
        </div>
        
    </div>
  )
}

export default JoinTraderPage
