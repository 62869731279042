import React, { useState , useEffect, useCallback , useRef , useContext} from 'react'
import { useParams , useNavigate } from 'react-router-dom';

import './GamePage.scss'
import MobileNav from '../../Layout/ＭobileNav'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { FormattedMessage , useIntl } from "react-intl";
import ReplyIcon from '@mui/icons-material/Reply';
import GamePlace from '../../Component/Game/VietnamLottery/GamePlace'
import CarVideo from '../../Component/Game/CarVideo'
import GameRule from '../../Component/Game/GameRule'
import GameTrend from '../../Component/Game/GameTrend'
import MarksixTrend from '../../Component/Game/markSix/MarksixTrend'
import NativeSelect from '../../Component/All/NativeSelect'
import BasicModal from '../../Component/All/PopupModal'
import VLottery2D from '../../Component/Game/VietnamLottery/2d'
import VLottery3D from '../../Component/Game/VietnamLottery/3d'
import VLottery4D from '../../Component/Game/VietnamLottery/4d'
import TraditionModel from '../../Component/Game/VietnamLottery/traditionModel'
import GameDraw from '../../Component/Game/VietnamLottery/GameDraw'
import MessageIcon from '@mui/icons-material/Message';
import Chat from '../../Component/Game/VietnamLottery/Chat'
import BanList from '../../Component/Game/VietnamLottery/BanList'
import VietOpenListPop from '../../Component/Game/VietnamLottery/VietOpenListPop'

import ManualBet from '../../Component/Game/ManualBet'
import AutoBet from '../../Component/Game/AutoBet'
import FixedSp from '../../Component/Game/markSix/FixedSp'
import SizeTwosideColor from '../../Component/Game/markSix/SizeTwosideColor'
import ChineseZodiac from '../../Component/Game/markSix/chineseZodiac'
import SPChineseZodiac from '../../Component/Game/markSix/SPChineseZodiac'



import Sum from '../../Component/Game/Sum'
import TwoStar from '../../Component/Game/twoStar'
import DragonTiger from '../../Component/Game/dragonTiger'
import MemberWrap from '../../Component/All/MemberWrap'
import FixBx from '../../Hook/fixBx'

import model1 from '../../images/racing/position.png';
import model1a from '../../images/racing/position(click).png';
import model2 from '../../images/racing/Guan-Yahe.png';
import model2a from '../../images/racing/Guan-Yahe(click).png';
import model3 from '../../images/racing/drago-with-ntiger.png';
import model3a from '../../images/racing/drago-with-ntiger(click).png';
import model4 from '../../images/racing/two-star.png';
import model4a from '../../images/racing/two-star(click).png';

import api from '../../api/api';
import Swal from 'sweetalert2'
import moment from "moment";
import { ErrorContext } from '../../Routes';



const Lane = [
  "0","1", '2', '3', "4", "5", "6", "7", "8", "9"
]
const Lane2 = [
  "0","1", '2', '3', "4", "5", "6", "7", "8", "9"
]
const Lane3 = [
  {
    name:'大',
    value:'big'
  },
  {
    name:'小',
    value:'small'
  },
  {
    name:'單',
    value:'odd'
  },
  {
    name:'雙',
    value:'even'
  }
]
const Lane4 = Array.from({ length: 100 }, (_, index) => {
  const paddedNumber = index < 10 ? `0${index}` : `${index}`;
  return paddedNumber;
});

const GameModel = [
  {
    name: "快速投注2D",
    value : "model1",
    rankValue:'2d',
    status:true,
    img:model1,
    aImg:model1a,
    drawValue:2,
  },
  {
    name: "快速投注3D",
    value : "model2",
    rankValue:'3d',
    status:true,
    img:model2,
    aImg:model2a,
    drawValue:3,
  },
  {
    name: "快速投注4D",
    value : "model3",
    rankValue:'4d',
    status:true,
    img:model3,
    aImg:model3a,
    drawValue:4,
  },
  {
    name: "傳統投注",
    value : "model4",
    rankValue:'tradition',
    status:true,
    img:model4,
    aImg:model4a,
    drawValue:2,
  },
]
const Member = "/member"
const VietnamLottery = () => {
  const intl = useIntl();

  const { ErrorMessage , TimeMoment } = useContext(ErrorContext);
  const { GameID } = useParams();

  // const history = useParams();
  const navigate = useNavigate();

  //loading status
  const [loadingMember , setLoadingMember] = useState(false);
  const [loading, setLoading] = useState(false)
  const fixBxRef = useRef(null);
  const mainRef = useRef(null);
  const leftFixRef = useRef(null);
  const fixbarRef = useRef(null);
  const normalFastinputRef = useRef();
  const inputRefs = useRef({
    '2d_head': React.createRef(),
    '2d_tail_contrary': React.createRef(),
    '2d_tail': React.createRef(),
    '2d_head_tail': React.createRef(),
    '2d_first_prize': React.createRef(),
    '2d_group': React.createRef(),
    '2d_group_seven': React.createRef(),
  });





  const [hideAmount, setHideAmount] = useState(false)
  //自動投注,手動投注
  const [autoHide, setAutoHide] = useState(false)
  //名次
  const [rankID , setRankID] = useState(['2d_tail_contrary']);
  //十位
  const [laneID , setLaneID] = useState([]);
  //個位
  const [laneID2 , setLaneID2] = useState([]);
  //百位
  const [laneID3 , setLaneID3] = useState([]);
  //千位
  const [laneID4 , setLaneID4] = useState([]);
  //大小單雙
  const [sizeID , setSizeID] = useState([]);
  const [nClickSizeID , setNClickSizeID] = useState([]);
  //籌碼
  const [chip , setChip] = useState([0]);
  const [nowChip , setNowChip] = useState(0);
  //加總
  const [totalOpen , setTotalOpen] = useState(false);
  const [total , setTotal] = useState([1]);
  const [totalLen , setTotalLen] = useState();
  const [totalPrice , setTotalPrice] = useState();
  //下方bar控制
  const [gamePlaceShow , setGamePlaceShow] = useState(false);
  const [gamePlaceButtonShow , setGamePlaceButtonShow] = useState(false);
  //送出資料型
  const [totalSendType , setTotalSendType] = useState();
  //會員
  const [memberDetail , setMemberDetail] = useState()
  //開關
  const [boxSwitch, setBoxswitch] = useState('video');
  //規則popup
  const [rulePop, setRulePop] = useState(false);
  //走勢
  const [historyList , setHistoryList] = useState([]);
  //手動下注值
  const [manualValue , setManualValue] = useState([]);
  //遊戲模式切換
  const [gameModelValue , setGameModelValue] = useState('model1');
  //賠率
  const [sumOdds , setSumOdds] = useState({
    "2D": {
      "status": true,
      "unified": false,
      "unified_odds": {
        "odds": 0,
        "lower": 0,
        "upper": 0
      },
      "odds": {
        "2d_first_prize": {
          "odds": 99,
          "lower": 1,
          "upper": 250000
        },
        "2d_group": {
          "odds": 3.66,
          "lower": 27,
          "upper": 250000
        },
        "2d_head": {
          "odds": 24.75,
          "lower": 4,
          "upper": 250000
        },
        "2d_head_tail": {
          "odds": 19.8,
          "lower": 5,
          "upper": 250000
        },
        "2d_tail": {
          "odds": 99,
          "lower": 1,
          "upper": 250000
        },
        "2d_tail_contrary": {
          "odds": 1.01,
          "lower": 100,
          "upper": 250000
        }
      }
    },
    "2D_match": {
      "status": true,
      "unified": false,
      "unified_odds": {
        "odds": 0,
        "lower": 0,
        "upper": 0
      },
      "odds": {
        "match_2": {
          "odds": 12,
          "lower": 10,
          "upper": 250000
        },
        "match_3": {
          "odds": 50,
          "lower": 10,
          "upper": 250000
        },
        "match_4": {
          "odds": 120,
          "lower": 10,
          "upper": 250000
        }
      }
    },
    "2D_fixed_head": {
      "status": true,
      "unified": false,
      "unified_odds": {
        "odds": 0,
        "lower": 0,
        "upper": 0
      },
      "odds": {
        "match_2": {
          "odds": 12,
          "lower": 10,
          "upper": 250000
        },
        "match_3": {
          "odds": 50,
          "lower": 10,
          "upper": 250000
        },
        "match_4": {
          "odds": 120,
          "lower": 10,
          "upper": 250000
        }
      }
    },
    
    "3D": {
      "status": true,
      "unified": false,
      "unified_odds": {
        "odds": 0,
        "lower": 0,
        "upper": 0
      },
      "odds": {
        "3d_first_prize": {
          "odds": 960,
          "lower": 1,
          "upper": 250000
        },
        "3d_group": {
          "odds": 35.5,
          "lower": 27,
          "upper": 250000
        },
        "3d_tail": {
          "odds": 960,
          "lower": 1,
          "upper": 250000
        }
      }
    },
    "4D": {
      "status": true,
      "unified": false,
      "unified_odds": {
        "odds": 0,
        "lower": 0,
        "upper": 0
      },
      "odds": {
        "4d_first_prize": {
          "odds": 8880,
          "lower": 0,
          "upper": 250000
        },
        "4d_tail": {
          "odds": 8880,
          "lower": 0,
          "upper": 250000
        }
      }
    }
  })
  const [oddsLoading , setOddsLoading] = useState(true)
  const [nowOdds , setNowOdds] = useState('')
  const [nowLow , setNowLow] = useState('')

  const [twoStarOddsStatus , setTwoStarOddsStatus] = useState()
  //規則
  const [ruleText , setRuleText] = useState('')
  //自填下注金額
  const customChipRef = useRef(0)
  //控制下注後刷新餘額
  const [betRefresh , setBetRefresh] = useState(true)
  const [needRefresh , setNeedRefresh] = useState(true)
  //判斷北/中南 north  south/middle
  const [gameDirection , setGameDirection] = useState('south');
  //當前玩法倍數
  const [orderMultiple, setOrderMultiple] = useState(1)
  //聊天室開關
  const [messageOpen , setMessageOpen] = useState(false)
  //Ban人列表
  const [banListOpen , setBanListOpen] = useState(false)
  //開盤列表
  const [openList , setOpenList] = useState([])
  const [nowGameID , setNowGameID] = useState('115')
  const [drawList , setDrawList] = useState([])
  const [spDrawList , setSpDrawList] = useState([])
  const [spNowDrawIss , setSpNowDrawIss] = useState()
  const [spDrawLoading , setSpDrawLoading] = useState(true)
  const [drawLoading , setDrawLoading] = useState(true)
  FixBx(fixBxRef,mainRef,loading,loadingMember,fixbarRef,gameModelValue,autoHide,boxSwitch)
  // 引入上推function
  // 普通快速-鎖定bet
  const [betLock , setBetLock] = useState(false)

  const [vietOpenListPop , setVietOpenListPop] = useState(true)
  const [drawLenValue , setDrawLenValue] = useState(2)
  useEffect(()=>{
    getData()
    setRankID([`2d_tail_contrary`])
    judgeNowIDDirection()
  },[])

  const judgeNowIDDirection = () => {
    switch (nowGameID) {
      case 116:
        // north  south/middle
        setGameDirection('middle')
        break;
      case 117:
        setGameDirection('north')
        break;
      case 118:
        setGameDirection('north')
        break;
      case 119:
        setGameDirection('south')
          break;
      case 120:
        setGameDirection('south')
        break;
      case 121:
        setGameDirection('north')
        break;
      case 122:
        setGameDirection('north')
        break;
      case 123:
        setGameDirection('north')
        break;
      case 124:
        setGameDirection('north')
        break;
      case 125:
        setGameDirection('middle')
        break;
      case 126:
        setGameDirection('middle')
          break;
      case 127:
        setGameDirection('middle')
        break;
      case 128:
        setGameDirection('middle')
        break;
      case 129:
        setGameDirection('middle')
        break;
      case 130:
        setGameDirection('middle')
        break;
      case 131:
        setGameDirection('middle')
        break;
      case 132:
        setGameDirection('middle')
        break;
      case 133:
        setGameDirection('middle')
          break;
      case 134:
        setGameDirection('middle')
        break;
      case 135:
        setGameDirection('middle')
        break;
      case 136:
        setGameDirection('south')
        break;
      case 137:
        setGameDirection('south')
        break;
      case 138:
        setGameDirection('south')
        break;
      case 139:
        setGameDirection('south')
        break;
      case 140:
        setGameDirection('south')
        break;
      case 141:
        setGameDirection('south')
        break;
      case 142:
        setGameDirection('south')
        break;
      case 143:
        setGameDirection('south')
          break;
      case 144:
        setGameDirection('south')
        break;
      case 145:
        setGameDirection('south')
        break;
      case 146:
        setGameDirection('south')
        break;
      case 147:
        setGameDirection('south')
        break;
      case 148:
        setGameDirection('south')
        break;
      case 149:
        setGameDirection('south')
        break;
      case 150:
        setGameDirection('south')
          break;
      case 151:
        setGameDirection('south')
        break;
      case 152:
        setGameDirection('middle')
        break;
      case 153:
        setGameDirection('middle')
        break;
      case 154:
        setGameDirection('south')
        break;
      case 155:
        setGameDirection('south')
          break;
      case 156:
        setGameDirection('south')
        break;
      default:
    }
  }
  useEffect(()=>{
   
    if(nowGameID !== '115'){
      getDraw()
      getSpDraw('')
      judgeNowIDDirection()
     
    }
  },[nowGameID])
  useEffect(()=>{
    getRule()
    getOdds()
  },[gameDirection])
  useEffect(()=>{
    if( oddsLoading === true){
      setOddsLoading(false)
    }{
      setTimeout(()=>{
       
      } , 1000)
      
    }
   
  },[sumOdds])

  // useEffect(()=>{
  //   if(nowGameID !== '115'){
  //     const Drawtimer = setInterval(() => {
  //       getDraw();
  //       getSpDraw(spNowDrawIss)
  //     }, 60000);

  //     return () => clearTimeout(Drawtimer);
  //   }

   
  // },[spNowDrawIss])
  // useEffect(()=>{
  //   FixBx(fixBxRef,mainRef,loading,loadingMember,fixbarRef,gameModelValue,autoHide)
  // },[boxSwitch])

  //判斷當前玩法倍數 先判斷北中南 再判斷玩法
  const judgeBetMultiple = () => {
   
    switch (gameDirection) {
        case 'north':
            if (rankID == '2d_head_tail') {
                setOrderMultiple(5)
            } else if (rankID == '2d_tail_contrary') {
                setOrderMultiple(100)
            } else if (rankID == '2d_head') {
                setOrderMultiple(4)
            } else if (rankID == '2d_group') {
                setOrderMultiple(27)
            } else if (rankID == '3d_head') {
                setOrderMultiple(3)
            } else if (rankID == '3d_head_tail') {
                setOrderMultiple(4)
            } else if (rankID == '3d_group') {
                setOrderMultiple(23)
            } else if (rankID == '4d_group') {
                setOrderMultiple(20)
            } else{
              setOrderMultiple(1)
            }
            break;
        case 'south':
        case 'middle':
            if (rankID == '2d_head_tail') {
                setOrderMultiple(2)
            } else if (rankID == '2d_tail_contrary') {
                setOrderMultiple(100)
            }else if (rankID == '2d_group') {
                setOrderMultiple(18)
            } else if (rankID == '2d_group_seven') {
                setOrderMultiple(7)
            } else if (rankID == '3d_head_tail') {
                setOrderMultiple(2)
            } else if (rankID == '3d_group') {
                setOrderMultiple(17)
            } else if (rankID == '3d_group_seven') {
                setOrderMultiple(7)
            } else if (rankID == '4d_group') {
                setOrderMultiple(16)
            } else{
              setOrderMultiple(1)
            }
            break;
        default:
    }
  }
  const judgeBetMultipleForBet = (v) => { 
    switch (gameDirection) {
        case 'north':
            if (v == '2d_head_tail') {
                return 5
            } else if (v == '2d_tail_contrary') {
                return 100
            }else if (v == '2d_head') {
                return 4
            } else if (v == '2d_group') {
                return 27
            } else if (v == '3d_head') {
                return 3
            } else if (v == '3d_head_tail') {
                return 4
            } else if (v == '3d_group') {
                return 23
            } else if (v == '4d_group') {
                return 20
            } else{
              return 1
            }
            break;
        case 'south':
        case 'middle':
            if (v == '2d_head_tail') {
                return 2
            } else if (v == '2d_tail_contrary') {
                return 100
            } else if (v == '2d_group') {
                return 18
            } else if (v == '2d_group_seven') {
                return 7
            } else if (v == '3d_head_tail') {
                return 2
            } else if (v == '3d_group') {
                return 17
            } else if (v == '3d_group_seven') {
                return 7
            } else if (v == '4d_group') {
                return 16
            } else{
              return 1
            }
            break;
        default:
    }
  }
  const judgePlay = (v) => {
    switch (gameDirection) {
        case 'north':
            if (rankID == '2d_head_tail') {

            } else if (rankID == '2d_head') {

            } else if (rankID == '2d_group') {

            } else if (rankID == '3d_head') {

            } else if (rankID == '3d_head_tail') {

            } else if (rankID == '3d_group') {

            } else if (rankID == '4d_group') {

            } else{

            }
            break;
        case 'south':
        case 'middle':
            if (rankID == '2d_head_tail') {

            } else if (rankID == '2d_head') {
              return <span>{v}</span>;
            } else if (rankID == '2d_tail') {
              return <div>{v.slice(0,-2)}<span>{v.slice(-2)}</span></div>;
            } else if (rankID == '2d_group') {

            } else if (rankID == '2d_group_seven') {

            } else if (rankID == '3d_head_tail') {

            } else if (rankID == '3d_group') {

            } else if (rankID == '3d_group_seven') {

            } else if (rankID == '4d_group') {

            } else{
              return  <div>{v}</div>
            }
            break;
        default:
    }
  }
  const judgeOdds = () => {
    switch (rankID[0]) {
      case '2d_tail_contrary':
        
        setNowOdds(sumOdds['2D'].odds['2d_tail_contrary'].odds)
        setNowLow(sumOdds['2D'].odds['2d_tail_contrary'].lower)
        break;
      case '2d_group':
        setNowOdds(sumOdds['2D'].odds['2d_group'].odds)
        setNowLow(sumOdds['2D'].odds['2d_group'].lower)
        break;
      case '2d_group_seven':
        setNowOdds(sumOdds['2D'].odds['2d_group_seven'].odds)
        setNowLow(sumOdds['2D'].odds['2d_group_seven'].lower)
        break;
      case '2d_head':
        setNowOdds(sumOdds['2D'].odds['2d_head'].odds)
        setNowLow(sumOdds['2D'].odds['2d_head'].lower)
        break;
      case '2d_head_tail':
        setNowOdds(sumOdds['2D'].odds['2d_head_tail'].odds)
        setNowLow(sumOdds['2D'].odds['2d_head_tail'].lower)
        break;
      case '2d_tail':
        setNowOdds(sumOdds['2D'].odds['2d_tail'].odds)
        setNowLow(sumOdds['2D'].odds['2d_tail'].lower)
        break;
      case '2d_fixed_head':
        setNowOdds(sumOdds['2D_fixed_head'].unified_odds.odds)
        setNowLow(sumOdds['2D_fixed_head'].unified_odds.lower)
        break;
      case '2d_first_prize':
        setNowOdds(sumOdds['2D'].odds['2d_first_prize'].odds)
        setNowLow(sumOdds['2D'].odds['2d_first_prize'].lower)
        break;
      case '2d_two_sides_head':
        setNowOdds(sumOdds['2D_two_sides_head'].unified_odds.odds)
        setNowLow(sumOdds['2D_two_sides_head'].unified_odds.lower)
        break;
      case '3d_first_prize':
        setNowOdds(sumOdds['3D'].odds['3d_first_prize'].odds)
        setNowLow(sumOdds['3D'].odds['3d_first_prize'].lower)
        break;
      case '3d_group':
        setNowOdds(sumOdds['3D'].odds['3d_group'].odds)
        setNowLow(sumOdds['3D'].odds['3d_group'].lower)
        break;
      case '3d_group_seven':
        setNowOdds(sumOdds['3D'].odds['3d_group_seven'].odds)
        setNowLow(sumOdds['3D'].odds['3d_group_seven'].lower)
        break;
      case '3d_tail':
        setNowOdds(sumOdds['3D'].odds['3d_tail'].odds)
        setNowLow(sumOdds['3D'].odds['3d_tail'].lower)
        break;
      case '3d_fixed_head':
        setNowOdds(sumOdds['3D_fixed_head'].unified_odds.odds)
        setNowLow(sumOdds['3D_fixed_head'].unified_odds.lower)
        break;
      case '3d_two_sides_head':
        setNowOdds(sumOdds['3D_two_sides_head'].unified_odds.odds)
        setNowLow(sumOdds['3D_two_sides_head'].unified_odds.lower)
        break;
      case '4d_first_prize':
        setNowOdds(sumOdds['4D'].odds['4d_first_prize'].odds)
        setNowLow(sumOdds['4D'].odds['4d_first_prize'].lower)
        break;
      case '4d_tail':
        setNowOdds(sumOdds['4D'].odds['4d_tail'].odds)
        setNowLow(sumOdds['4D'].odds['4d_tail'].lower)
        break;
      case '4d_fixed_tail':
        setNowOdds(sumOdds['4D_fixed_tail'].unified_odds.odds)
        setNowLow(sumOdds['4D_fixed_tail'].unified_odds.lower)
        break;
      case '4d_two_sides_tail':
        setNowOdds(sumOdds['4D_two_sides_tail'].unified_odds.odds)
        setNowLow(sumOdds['4D_two_sides_tail'].unified_odds.lower)
        break;
      case 'match_2':
        setNowOdds(sumOdds['2D_match'].odds['match_2'].odds)
        setNowLow(sumOdds['2D_match'].odds['match_2'].lower)
        break;
      case 'match_3':
        setNowOdds(sumOdds['2D_match'].odds['match_3'].odds)
        setNowLow(sumOdds['2D_match'].odds['match_3'].lower)
        break;
      case 'match_4':
        setNowOdds(sumOdds['2D_match'].odds['match_4'].odds)
        setNowLow(sumOdds['2D_match'].odds['match_4'].lower)
        break;
      default:
        setNowOdds('')

        
  }
  }
  useEffect(()=>{
    judgeBetMultiple()
    judgeOdds()
    
  },[rankID , sumOdds])
  // useEffect(()=>{
  //   normalFastinputBet()
  // },[nowChip])
  
  
  //getme
  const getMe = async() => {
    try {
      const res = await api.get('/member', {
        headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (res.data.status) {
        setMemberDetail(res.data.data)
      }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    }
  }
  //拿到賠率
  const getOdds = async() => {
    try {
      // ${judgeRule()}
      // ${nowGameID}
      const res = await api.get(`/games/${judgeRule()}/odds`,{
          headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
          },
      });
      if (res.data.status) {
        setSumOdds(res.data.data)
        }
    } catch (err) {
    console.log(err)
    const code = err.response.status
    const msg = err.response.data.data.error
    ErrorMessage(code, msg)
    }

  }

  const judgeRule = () => {
      // north  south/middle
    if(gameDirection === 'north'){
      return 115;
    } else if(gameDirection === 'middle'){
      return 157;
    } else if(gameDirection === 'south'){
      return 158;
    }
  }
  const getRule = async() => {
  
    try {
      const res = await api.get(`/games/${judgeRule()}/rule?language=${localStorage.getItem("language")}`,{
          headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
          },
      });
      if (res.data.status) {
          setRuleText(res.data.data.rule)
        }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    }

  }
  // 目前有開盤
  const getOpen = async(status) => {
    try {
      // &status=1
      const res = await api.get(`/games/${GameID}/opening?language=${localStorage.getItem("language")}&status=${status}`,{
          headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
          },
      });
      if (res.data.status) {
          // setRuleText(res.data.data.rule)
          setOpenList(res.data.data)
          setNowGameID(res.data.data[0].game_id)
          // console.log(res.data.data)
        }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    }

  }
   // 開獎
  const getDraw = async() => {
    try {
      const res = await api.get(`/games/${nowGameID}/draw`,{
          headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
          },
      });
      if (res.data.status) {
          // setRuleText(res.data.data.rule)
          // setOpenList(res.data.data)
          // setNowGameID(res.data.data[0].game_id)
          // console.log(res.data.data)
          setDrawList(res.data)
          setDrawLoading(false)
        }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    }

  }
   // 指定開獎
  const getSpDraw = async(num) => {
    try {
      const res = await api.get(`/games/${nowGameID}/round?issue_no=${num}`,{
          headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
          },
      });
      if (res.data.status) {
          // setRuleText(res.data.data.rule)
          // setOpenList(res.data.data)
          // setNowGameID(res.data.data[0].game_id)
          // console.log(res.data.data)
          // setDtawList(res.data)
          // setDrawLoading(false)
          setSpDrawList(res.data)
          setSpDrawLoading(false)
          setSpNowDrawIss(encodeURIComponent(res.data.data.response.issue_no))
          // console.log(res.data.data.response.issue_no)
        }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    }

  }
  // TODO : getOdds + getRule  後續帶入遊戲id
  const getData = async() =>{
    setLoading(false)
    try{
      await Promise.all([getMe(), getOpen(1)]);
    } finally{
      setLoading(true)
    }
  }
  
  
  //下注後行為
  const chipReduce = (i) => {
    if(rankID.length !== 0 && (laneID.length !== 0 || sizeID.length !== 0 || laneID2.length !== 0 || laneID3.length !== 0 || laneID4.length !== 0 )){
      setNowChip(prevNum => parseInt(prevNum)+parseInt(i));
    }

    if(manualValue.length !== 0){
      setNowChip(prevNum => parseInt(prevNum)+parseInt(i));
    }
   
  }
  const handleTotalClick = (qucikClearValue) => {
    setTotalOpen(true)

    if(gameModelValue === 'model4'){
        var result;
        if(qucikClearValue === "match_2" && rankID != 'normalFast'){
          result = laneID.map((value, index, arr) => {
            if (index % 2 === 0 && index + 1 < arr.length) {
              return `${value}-${arr[index + 1]}`;
            } else {
              return ;
            }
          });

          const resultfiltered = result.filter(value => value !== undefined);
          transformData3(resultfiltered)
        } else if(qucikClearValue === "match_3" && rankID != 'normalFast'){
            result = laneID.map((value, index, arr) => {
              if (index % 3 === 0 && index + 2 < arr.length) {
                return `${value}-${arr[index + 1]}-${arr[index + 2]}`;
              } else {
                return ;
              }
            });

            const resultfiltered = result.filter(value => value !== undefined);
            transformData3(resultfiltered)
        } else if(qucikClearValue === "match_4" && rankID != 'normalFast'){
          result = laneID.map((value, index, arr) => {
            if (index % 4 === 0 && index + 3 < arr.length) {
              return `${value}-${arr[index + 1]}-${arr[index + 2]}-${arr[index + 3]}`;
            } else {
              return ;
            }
          });

          const resultfiltered = result.filter(value => value !== undefined);
          transformData3(resultfiltered)
        } else if(rankID == 'normalFast'){
          // result = laneID.map((value, index, arr) => {
          //   return `${value}-${arr[index + 1]}`;
          // });
          result = laneID.join('-')
          transformData4(result)
          
        }
    } else if (gameModelValue === 'model1'){
        if(rankID == '2d_fixed_head' || rankID == '2d_two_sides_head' ){
            // transformData2(2)
            transformData2TSH(2)
        } else {
            const result = laneID.flatMap(elem1 =>
                laneID2.map(elem2 => `${elem1}${elem2}`)
            );
            transformData(result)
        }
    } else if (gameModelValue === 'model2'){
        if(rankID == '3d_fixed_head' || rankID == '3d_two_sides_head'){
          transformData2(3)
        } else {
          const result = laneID3.flatMap(hundreds =>
            laneID.flatMap(tens =>
              laneID2.map(ones =>
                `${hundreds}${tens}${ones}`
              )
            )
          );
          transformData(result)
        }

    } else if (gameModelValue === 'model3'){
      if(rankID == '4d_fixed_tail' || rankID == '4d_two_sides_tail'){
        // transformData2(4)
        transformData2TSH4D(4)
      } else{
        const result = laneID4.flatMap(thousands =>
            laneID3.flatMap(hundreds =>
                laneID.flatMap(tens =>
                    laneID2.map(ones =>
                      `${thousands}${hundreds}${tens}${ones}`
                )
              )
            )
        );
        transformData(result)
      }
    }

  }
  // 下注資料結構
  const transformData = (result) => {
   
    //畫面上的格式
    const dValue = result.map((ele)=>{
        return `${rankID},${ele},${parseInt(nowChip)}`
    })
    //吐給api的格式 *上倍率
    const dValue2 = result.map((ele)=>{
        return `${rankID},${ele},${parseInt(nowChip)*orderMultiple}`
    })
    const resultTotal = dValue;
    setTotal(resultTotal);
    
    //計算總金額
    setTotalLen(resultTotal.length)
    setTotalPrice(parseInt(nowChip)*resultTotal.length)
    
    //轉換api格式
    setTotalSendType(dValue2.join('/'))
  }
  const transformData2 = (m) => {
    var resultTotal ;
    const laneValue = laneID.map((ele)=>{
      return `${rankID},;${ele};,${parseInt(nowChip)}`
    })
    const lane2Value = laneID2.map((ele)=>{
      return `${rankID},;;${ele},${parseInt(nowChip)}`
    })
    const lane3Value = laneID3.map((ele)=>{
      return `${rankID},${ele};;,${parseInt(nowChip)}`
    })
    const lane4Value = laneID4.map((ele)=>{
      return `${rankID},${ele};;;,${parseInt(nowChip)}`
    })

    if( m === 2){
      resultTotal = laneValue.concat(lane2Value);
    } else if (m === 3){
      resultTotal = laneValue.concat(lane2Value , lane3Value);
    } else if (m === 4){
      resultTotal = laneValue.concat(lane2Value , lane3Value , lane4Value);
    }
    // const resultTotal = laneValue.concat(lane2Value);
   
    setTotal(resultTotal);
    
    //計算總金額
    setTotalLen(resultTotal.length)
    setTotalPrice(parseInt(nowChip)*resultTotal.length)
    
    //轉換api格式
    setTotalSendType(resultTotal.join('/'))
  }
  const transformData2TSH = (m) => {
    var resultTotal ;
    const laneValue = laneID.map((ele)=>{
      return `${rankID},${ele};,${parseInt(nowChip)}`
    })
    const lane2Value = laneID2.map((ele)=>{
      return `${rankID},;${ele},${parseInt(nowChip)}`
    })
    const lane3Value = laneID3.map((ele)=>{
      return `${rankID},${ele};;,${parseInt(nowChip)}`
    })
    const lane4Value = laneID4.map((ele)=>{
      return `${rankID},${ele};;;,${parseInt(nowChip)}`
    })

    if( m === 2){
      resultTotal = laneValue.concat(lane2Value);
    } else if (m === 3){
      resultTotal = laneValue.concat(lane2Value , lane3Value);
    } else if (m === 4){
      resultTotal = laneValue.concat(lane2Value , lane3Value , lane4Value);
    }
    // const resultTotal = laneValue.concat(lane2Value);
   
    setTotal(resultTotal);
    
    //計算總金額
    setTotalLen(resultTotal.length)
    setTotalPrice(parseInt(nowChip)*resultTotal.length)
    
    //轉換api格式
    setTotalSendType(resultTotal.join('/'))
  }
  const transformData2TSH4D = (m) => {
    var resultTotal ;
    const laneValue = laneID.map((ele)=>{
      return `${rankID},;;${ele};,${parseInt(nowChip)}`
    })
    const lane2Value = laneID2.map((ele)=>{
      return `${rankID},;;;${ele},${parseInt(nowChip)}`
    })
    const lane3Value = laneID3.map((ele)=>{
      return `${rankID},;${ele};;,${parseInt(nowChip)}`
    })
    const lane4Value = laneID4.map((ele)=>{
      return `${rankID},${ele};;;,${parseInt(nowChip)}`
    })

    if( m === 2){
      resultTotal = laneValue.concat(lane2Value);
    } else if (m === 3){
      resultTotal = laneValue.concat(lane2Value , lane3Value);
    } else if (m === 4){
      resultTotal = laneValue.concat(lane2Value , lane3Value , lane4Value);
    }
    // const resultTotal = laneValue.concat(lane2Value);
   
    setTotal(resultTotal);
    
    //計算總金額
    setTotalLen(resultTotal.length)
    setTotalPrice(parseInt(nowChip)*resultTotal.length)
    
    //轉換api格式
    setTotalSendType(resultTotal.join('/'))
  }
  const transformData3 = (result) => {

    //畫面上的格式
    // const dValue = [`${rankID},${result},${parseInt(nowChip)}`]
     const dValue = result.map((ele)=>{
        return `${rankID},${ele},${parseInt(nowChip)}`
    })
    //吐給api的格式 *上倍率
    // const dValue2 = result.map((ele)=>{
    //     return `${rankID},${ele},${parseInt(nowChip)*orderMultiple}`
    // })
    const resultTotal = dValue;
    setTotal(resultTotal);
    
    //計算總金額
    setTotalLen(resultTotal.length)
    setTotalPrice(parseInt(nowChip)*resultTotal.length)
    
    //轉換api格式
    setTotalSendType(dValue.join('/'))

    
    // if(laneID.length == 3){
    //   setLaneID([])
    // }
  }
  const transformData4 = (result) => {

    return new Promise((resolve) => {
      const arrType = ['2d_head','2d_tail_contrary','2d_tail','2d_head_tail','2d_first_prize','2d_group','2d_group_seven']
      const arrType2 = ['2d_head','2d_tail_contrary','2d_tail','2d_head_tail','2d_first_prize','2d_group']
      var dValue;
      if(gameDirection === 'north'){
        dValue = arrType2.map((v)=>{
          return `${v},${result},${parseInt(nowChip)}`
        })
      } else{
        dValue = arrType.map((v)=>{
          return `${v},${result},${parseInt(nowChip)}`
        })
      }
     
      const resultTotal = dValue;
      setTotal(resultTotal);
      
      //計算總金額
      // setTotalLen(resultTotal.length)
      // setTotalPrice(parseInt(nowChip)*resultTotal.length)
      
      //轉換api格式
      // setTotalSendType(dValue.join('/'))
      resolve();
    })

  }
  const handleManuaTotalClick = () => {
    setTotalOpen(true)    
    //列出列表
    //車號
    // const result = laneID && rankID.flatMap((r) => laneID.map((l) => 
    //   {
    //     // return chipTotal === 0 ? `${r},${l},${parseInt(nowChip)}` : `${r},${l},${parseInt(chipTotal)+parseInt(nowChip)}`
    //     return `${r},${l},${parseInt(nowChip)}`
    //   }
    // ));
    // //雙面
    // const result2 = sizeID && rankID.flatMap((r) => sizeID.map((l) => 
    //   {
    //     // return chipTotal === 0 ? `${r},${l},${parseInt(nowChip)}` : `${r},${l},${parseInt(chipTotal)+parseInt(nowChip)}`
    //     return `${r},${l},${parseInt(nowChip)}`
    //   }
    // ));
    const result = manualValue.map((item) => {
        return `${item},${parseInt(nowChip)}`
    })
    // const resultTotal = result.concat(result2);
    setTotal(result);
    
    //計算總金額
    setTotalLen(result.length)
    setTotalPrice(parseInt(nowChip)*result.length)
    
    //轉換api格式
    setTotalSendType(result.join('/'))
  }
  //儲值(test)
  const payOrder = async(e) => {
    e.preventDefault();

    try {
      const res = await api.post(`/pay-orders`, JSON.stringify({
        type : 1,
        amount : 50
      }), {
        headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (res.data.status) {
        Swal.fire({
          text: intl.formatMessage({
                id: 'alert.success',
          }),
          showConfirmButton: false,
          timer: 1000,
        });
       
      }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    }
    finally {
      setTimeout(() => {
        getMe()
      }, 3000);
    }
  }
  //普通快速資料結構
  const normalFastinputBet = async(chip) => {
    var inputValues;
    var MultipleValue = 0;
    var totalPrice = 0;
    var valuesArrayRef ;
  
     // 組合成下注格式
    if(gameDirection === 'north'){
      valuesArrayRef = Object.values(inputRefs.current);
      inputValues = valuesArrayRef.map((ref, index) => {
        const key = Object.keys(inputRefs.current)[index];
        if(key !== '2d_group_seven'){
          return { key, value: chip > 0 ? (Number(ref.current.value) + Number(chip)) :  ref.current.value};
        } else {
          return
        }
      });
    } else {
      valuesArrayRef = Object.values(inputRefs.current);
      inputValues = valuesArrayRef.map((ref, index) => {
        const key = Object.keys(inputRefs.current)[index];
        return { key, value: chip > 0 ? (Number(ref.current.value) + Number(chip)) :  ref.current.value};
      });
    }
    const valuesArray = 
    laneID.flatMap((v)=>
      inputValues
      .filter(inputElement => inputElement !== undefined  && inputElement.value !== '0' && inputElement.value !== '')
      .map(inputElement => {
        MultipleValue += judgeBetMultipleForBet(inputElement.key)
        totalPrice += Number(inputElement.value)
        return  `${inputElement.key},${v},${inputElement.value}`;
      })  
    )

    const valuesArray2 = 
    inputValues
    .filter(inputElement => inputElement !== undefined && inputElement.value !== '0' && inputElement.value !== '')
    .map(inputElement => {
      return  `${inputElement.key},${laneID.join('-')},${inputElement.value}`;
    })  
   
   


    setTotalLen(valuesArray.length)
    // setTotalPrice(parseInt(nowChip)*valuesArray.length)
    setTotalPrice(totalPrice)
    setOrderMultiple(MultipleValue / laneID.length)
    if(chip > 0){
     
      setTimeout(()=>{
        setTotal(valuesArray2)
      },500)
    }
    setTotalSendType(valuesArray.join('/'));
    console.log(valuesArray)
    console.log(totalPrice)
  }
  //下注(test)
  const handleBet = async(e) => {
    e.preventDefault();

    if(nowChip === 0 || nowChip[0] === 0){
        Swal.fire({
          icon: "warning",
          text: intl.formatMessage({
            id: 'alert.amount',
          }),
          showConfirmButton: true,
        });
    }else{
        try {
        const res = await api.post(`/bets`, {
            bet_str : `${totalSendType}`,
            game_id:  Number(nowGameID),
            language : localStorage.getItem("language")
        }, {
            headers: {
            "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
            "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
        });
        if (res.data.status) {
            Swal.fire({
            text: intl.formatMessage({
                id: 'alert.success',
            }),
            showConfirmButton: false,
            timer: 1000,
            });
            getMe()
            empty();
            // setRankID(['2d_tail_contrary'])
            setBetRefresh(!betRefresh)
        }
        } catch (err) {
        // console.log(err)
        // const code = err.response.status
        // ErrorMessage(code, msg)
        const msg = err.response.data.data.error_msg
        if (msg.includes("api.bad_request")) {
            Swal.fire({
            icon: "warning",
            text: intl.formatMessage({
                id: 'alert.betError',
            }),
            showConfirmButton: true,
            });
        } else if (msg.includes("api.data_invalid.amount")) {
            Swal.fire({
            icon: "warning",
            text: intl.formatMessage({
                id: 'alert.betFailed',
            }),
            showConfirmButton: true,
            });
        } else if (msg.includes("api.data_invalid.bet_time")) {
            Swal.fire({
            icon: "warning",
            text: intl.formatMessage({
                id: 'alert.betTimeout',
            }),
            showConfirmButton: true,
            });
        } else{
            Swal.fire({
            icon: "warning",
            text: intl.formatMessage({
                id: 'alert.betError',
            }),
            showConfirmButton: true,
            });
        }
        }
        finally {
        }
    }
  }
  const handleBetFast = async(e) => {
    e.preventDefault();

      try {
      const res = await api.post(`/bets`, {
          bet_str : `${totalSendType}`,
          game_id:Number(nowGameID),
          language : localStorage.getItem("language")
      }, {
          headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
          },
      });
      if (res.data.status) {
          Swal.fire({
          text: intl.formatMessage({
              id: 'alert.success',
          }),
          showConfirmButton: false,
          timer: 1000,
          });
          getMe()
          empty();
          setBetRefresh(!betRefresh)
          // setRankID(['2d_tail_contrary'])
          setGameModelValue('model1')
      }
      } catch (err) {
      // console.log(err)
      // const code = err.response.status
      // ErrorMessage(code, msg)
      const msg = err.response.data.data.error_msg
      if (msg.includes("api.bad_request")) {
          Swal.fire({
          icon: "warning",
          text: intl.formatMessage({
              id: 'alert.betError',
          }),
          showConfirmButton: true,
          });
      } else if (msg.includes("api.data_invalid.amount")) {
          Swal.fire({
          icon: "warning",
          text: intl.formatMessage({
              id: 'alert.betFailed',
          }),
          showConfirmButton: true,
          });
      } else if (msg.includes("api.data_invalid.bet_time")) {
          Swal.fire({
          icon: "warning",
          text: intl.formatMessage({
              id: 'alert.betTimeout',
          }),
          showConfirmButton: true,
          });
      } else{
          Swal.fire({
          icon: "warning",
          text: intl.formatMessage({
              id: 'alert.betError',
          }),
          showConfirmButton: true,
          });
      }
      }
      finally {
      }
  }
  //盒子開關
  const handleBoxSwitch = (i) =>{
    if(boxSwitch === i){
      setBoxswitch('video');
    }else{
      setBoxswitch(i);
    }
  }
  //打開下方bar
  const handlePlaceShow = () =>{
    if(gamePlaceButtonShow === false){
      setGamePlaceShow(true)
      setGamePlaceButtonShow(true)
    }
  }
  //清空
  const clearInputValue = (ref) => {
    if (ref && ref.current) {
      ref.current.value = ''; // 清空ref的值
    }
  }
  const empty = async() => {
    setTotalOpen(false)
    setTotal([1])
    setChip([])
    setTotalLen(0)
    setTotalPrice(0)
    setNowChip([0])
    setTotalSendType('')
    setLaneID([])
    setLaneID2([])
    setLaneID3([])
    setLaneID4([])
    setSizeID([])
    // setRankID([])
    clearInputValue(customChipRef)
    setGamePlaceShow(false)
    setGamePlaceButtonShow(false)
    setNClickSizeID([])
    setManualValue([])
    setBetLock(false)
  }
  // useEffect(() => {
  //   getMe()
  //   // getHistory()
  // }, []);
  //雙面判斷
  const judgeSize = (value,rank) =>{
    if(autoHide === true){
      switch (value) {
        case "odd":
          setNClickSizeID([...nClickSizeID , `${rank},even`])
          break;
        case "even":
          setNClickSizeID([...nClickSizeID , `${rank},odd`])
          break;
        case "big":
          setNClickSizeID([...nClickSizeID , `${rank},small`])
          break;
        case "small":
          setNClickSizeID([...nClickSizeID , `${rank},big`])
          break;
        default:
          break;
      }
    } else {
      switch (value) {
        case "odd":
          setNClickSizeID([...nClickSizeID , "even"])
          break;
        case "even":
          setNClickSizeID([...nClickSizeID , "odd"])
          break;
        case "big":
          setNClickSizeID([...nClickSizeID , "small"])
          break;
        case "small":
          setNClickSizeID([...nClickSizeID , "big"])
          break;
        default:
          break;
       }
    }
    
  }
  //雙面判斷恢復
  const judgeSizeRecover = (value,rank) =>{
    if(autoHide === true){
      switch (value) {
        case "odd":
        setNClickSizeID(nClickSizeID.filter(item => item !== `${rank},even`))
        break;
        case "even":
        setNClickSizeID(nClickSizeID.filter(item => item !== `${rank},odd`))
        break;
        case "big":
        setNClickSizeID(nClickSizeID.filter(item => item !== `${rank},small`))
        break;
        case "small":
        setNClickSizeID(nClickSizeID.filter(item => item !== `${rank},big`))
        break;
        default:
        break;
      }
    } else {
      switch (value) {
        case "odd":
          setNClickSizeID(nClickSizeID.filter(item => item !== 'even'))
          break;
        case "even":
          setNClickSizeID(nClickSizeID.filter(item => item !== 'odd'))
          break;
        case "big":
          setNClickSizeID(nClickSizeID.filter(item => item !== 'small'))
          break;
        case "small":
          setNClickSizeID(nClickSizeID.filter(item => item !== 'big'))
          break;
        default:
          break;
       }
    }
    
  }
  const handlePlayButtonClick = (value , state , set , rank) => {
    if(gameModelValue === 'model1'){
    //   if(autoHide === true){
    //     if (manualValue.indexOf(`${rank},${value}`) == -1) {
    //       setManualValue(prevNum => [...prevNum ,`${rank},${value}`])
    //       judgeSize(value,rank);
    //     } else{
    //         const newManuaValue = manualValue.filter((ele) => ele !== `${rank},${value}` )
    //         setManualValue(newManuaValue)
    //         judgeSizeRecover(value,rank)
    //     }
    //   } else {
    //     // 判斷有沒有選過 
    //     if (state.indexOf(value) !== -1) {
    //       // 選過就刪掉
    //       const newArray = state.filter((element) => element !== value);
    //       judgeSizeRecover(value)
    //       set(newArray);
    //     } else {
    //       set([...state, value]);
    //       judgeSize(value);
    //     }
    //   }
      if (state.indexOf(value) !== -1) {
        // 選過就刪掉
        const newArray = state.filter((element) => element !== value);
        judgeSizeRecover(value)
        set(newArray);
      } else {
        set([...state, value]);
        judgeSize(value);
      }
    } else {
      if (state.indexOf(value) !== -1) {
        // 選過就刪掉
        const newArray = state.filter((element) => element !== value);
        judgeSizeRecover(value)
        set(newArray);
      } else {
        set([...state, value]);
        judgeSize(value);
      }
    }
  };


  

  return (
    <div>
      {gamePlaceButtonShow &&
        <GamePlace
        handlePlayButtonClick={handlePlayButtonClick}
        chip={chip} 
        setChip={setChip}
        handleTotalClick={handleTotalClick}
        total={total}
        totalOpen={totalOpen}
        totalLen={totalLen}
        totalPrice={totalPrice}
        handleBet={handleBet}
        empty={empty}
        customChipRef={customChipRef}
        clearInputValue={clearInputValue}
        chipReduce={chipReduce}
        setGamePlaceShow={setGamePlaceShow}
        gamePlaceShow={gamePlaceShow}
        gameID={'109'}
        rankID={rankID}
        laneID={laneID}
        laneID2={laneID2}
        laneID3={laneID3}
        laneID4={laneID4}
        orderMultiple={orderMultiple}
        setRankID={setRankID}
        setGameModelValue={setGameModelValue}
        gameModelValue={gameModelValue}
        normalFastinputBet={normalFastinputBet}
        inputRefs={inputRefs}
        handleBetFast={handleBetFast}
        setBetLock={setBetLock}
        nowOdds={nowOdds}
        nowLow={nowLow}
        ></GamePlace>
      }
      {messageOpen &&
        <Chat
        setMessageOpen={setMessageOpen}
        setBanListOpen={setBanListOpen}
        nowGameID={nowGameID}
        />
      }
      {
        banListOpen &&
        <BanList
        setBanListOpen={setBanListOpen}
        // setMessageOpen={setMessageOpen}
        />
      }
      {
        vietOpenListPop &&
        <VietOpenListPop 
        setVietOpenListPop={setVietOpenListPop}
        GameID={GameID}
        setNowGameID={setNowGameID}
        >
        </VietOpenListPop>
      }

      <div className="gamePage page mt-0 vietnamLotteryPage">
        <div ref={fixBxRef} className="fixBx">
          <header className='d-flex'>
            <div className="left">

              <button className='backBtn' onClick={()=>{
                  navigate(`/index`);
              }}
              ><ReplyIcon/></button>
              <p className="title mb-0">
                <div>
                  <FormattedMessage
                    id={`vLottery.vietnamLottery`}
                  />
                  {/* {gameDirection} */}
                </div>
              </p>
              {/* <span>1231231期</span> */}
            </div>
            <div className="center">
              {/* <p className='mb-0'>離封盤</p>
              <span>00:00</span> */}
            </div>
            <div className="right">
              {
                loading && 
                <NativeSelect
                openList={openList}
                setNowGameID={setNowGameID}
                nowGameID={nowGameID}
                />
              }
             
              <button 
              className='messageBtn'
              onClick={() => {
                setMessageOpen(true)
              }}
              >
                <MessageIcon/>
              </button>
              {/* <button 
              onClick={() => {
                handleBoxSwitch('');
              }}
              >
                {
                    boxSwitch === '' ?
                    <FormattedMessage
                      id={`game.open`}
                    />
                    :
                    <FormattedMessage
                      id={`game.close`}
                    />
                 }
              </button> */}
              <button
              onClick={() => {
                // handleBoxSwitch('rule');
                setRulePop(true)
              }}
              >
                <FormattedMessage
                    id={`game.rule`}
                />
              </button>
            </div>
          </header>
        

            <MemberWrap loadingMember={loadingMember} setLoadingMember={setLoadingMember} betRefresh={betRefresh} setBetRefresh={setBetRefresh} needRefresh={needRefresh}/>
          
            <div className='nextDrawBx'>
                <div>
                    <FormattedMessage
                        id={`vLottery.lotteryDrawNumber`}
                    /> : <br/>
                    {
                        drawLoading ||
                        drawList.data.next_issue_no
                    }
                    <FormattedMessage
                        id={`trader.issue`}
                    /> 
                </div>
                <div>  
                    <FormattedMessage
                        id={`vLottery.lotteryDrawingTime`}
                    /> : <br/>
                    {
                        drawLoading ||
                        <>
                        {/* {
                          localStorage.getItem("language") === 'vn' ?
                          moment(drawList.data.next_lottery_draw).format('DD/MM hh:mm') :
                          moment(drawList.data.next_lottery_draw).format('MM/DD hh:mm')
                        } */}
                        {TimeMoment(drawList.data.next_lottery_draw)}
                        </>
                       
                        // moment(drawList.data.next_lottery_draw).format('MM-DD HH:mm:ss')
                    } 
                </div>
            </div>

        </div>

        
        <div className="home-bg2">
          <div ref={mainRef} className="gameBotBx">
          {
                boxSwitch === 'video' && 
                <div className="vedioWrap">
                    {/* <iframe id="carVideo" src='../../../sixmark/sixMark.html'></iframe> */}
                  <GameDraw
                  rankID={rankID}
                  // judgePlay={judgePlay}
                  gameDirection={gameDirection}
                  drawList={drawList}
                  drawLoading={drawLoading}
                  spDrawList={spDrawList}
                  spDrawLoading={spDrawLoading}
                  getSpDraw={getSpDraw}
                  gameModelValue={gameModelValue}
                  drawLenValue={drawLenValue}
                  />
                </div>
              }
              {/* {
                boxSwitch === 'trend' && <MarksixTrend gameID={'109'}></MarksixTrend>
              } */}
              {
                rulePop && <GameRule setRulePop={setRulePop} ruleText={ruleText}></GameRule>
              }
            <ul className='gameModel'>
              {
                GameModel.map((v)=>{
                  return (
                    v.status &&
                    <li key={v.value}
                    data-active={gameModelValue.includes(`${v.value}`)}
                    onClick={() => {
                      setGameModelValue(v.value)
                      empty()
                      // setRankID([`${v.rankValue}_head`])
                      if(v.rankValue === 'tradition'){
                        setRankID([`match_2`])
                      } else if(v.rankValue == '2d'){
                        setRankID([`${v.rankValue}_tail_contrary`])
                        setDrawLenValue(2)
                      } else if(v.rankValue == '4d'){
                        setRankID([`${v.rankValue}_tail`])
                        setDrawLenValue(4)
                      } else{
                        setRankID([`${v.rankValue}_tail`])
                        setDrawLenValue(3)
                      }
                    }}
                    >
                      <div>
                        <FormattedMessage
                            id={`vLottery.${v.rankValue}`}
                        />
                      </div>
                    </li>
                  )
                })
              }
            </ul>
            {
                loading && 
                <>
                  {
                    gameModelValue === 'model1' &&
                    <>
                      {
                            <BasicModal 
                                rankID={rankID}
                                setRankID={setRankID}
                                model={'2d'}
                                empty={empty}
                                gameDirection={gameDirection}
                                setGameModelValue={setGameModelValue}
                            />
                      }
                      {
                            <VLottery2D
                            rankID={rankID}
                            setRankID={setRankID}
                            payOrder={payOrder}
                            Lane={Lane}
                            laneID={laneID}
                            laneID2={laneID2}
                            setLaneID={setLaneID}
                            setLaneID2={setLaneID2}
                            handlePlayButtonClick={handlePlayButtonClick}
                            Lane2={Lane2}
                            sizeID={sizeID}
                            setSizeID={setSizeID}
                            nClickSizeID={nClickSizeID}
                            setAutoHide={setAutoHide}
                            handlePlaceShow={handlePlaceShow}
                            nowChip={nowChip}
                            handleTotalClick={handleTotalClick}
                            empty={empty}
                            Lane3={Lane3}
                          />
                            
                          }
                    </>
                  }
                  {
                    gameModelValue === 'model2'&&
                    <>
                      {
                            <BasicModal 
                                rankID={rankID}
                                setRankID={setRankID}
                                model={'3d'}
                                empty={empty}
                                gameDirection={gameDirection}
                                setGameModelValue={setGameModelValue}
                            />
                      }
                      {
                            <VLottery3D
                            rankID={rankID}
                            setRankID={setRankID}
                            payOrder={payOrder}
                            Lane={Lane}
                            laneID={laneID}
                            laneID2={laneID2}
                            laneID3={laneID3}
                            setLaneID={setLaneID}
                            setLaneID2={setLaneID2}
                            setLaneID3={setLaneID3}
                            handlePlayButtonClick={handlePlayButtonClick}
                            Lane2={Lane2}
                            sizeID={sizeID}
                            setSizeID={setSizeID}
                            nClickSizeID={nClickSizeID}
                            setAutoHide={setAutoHide}
                            handlePlaceShow={handlePlaceShow}
                            nowChip={nowChip}
                            handleTotalClick={handleTotalClick}
                            empty={empty}
                            Lane3={Lane3}
                          />
                            
                          }
                    </>
                  }
                  {
                    gameModelValue === 'model3' &&
                    <>
                      {
                            <BasicModal 
                                rankID={rankID}
                                setRankID={setRankID}
                                model={'4d'}
                                empty={empty}
                                gameDirection={gameDirection}
                                setGameModelValue={setGameModelValue}
                            />
                      }
                      {
                            <VLottery4D
                            rankID={rankID}
                            setRankID={setRankID}
                            payOrder={payOrder}
                            Lane={Lane}
                            laneID={laneID}
                            laneID2={laneID2}
                            laneID3={laneID3}
                            laneID4={laneID4}
                            setLaneID={setLaneID}
                            setLaneID2={setLaneID2}
                            setLaneID3={setLaneID3}
                            setLaneID4={setLaneID4}
                            handlePlayButtonClick={handlePlayButtonClick}
                            Lane2={Lane2}
                            sizeID={sizeID}
                            setSizeID={setSizeID}
                            nClickSizeID={nClickSizeID}
                            setAutoHide={setAutoHide}
                            handlePlaceShow={handlePlaceShow}
                            nowChip={nowChip}
                            handleTotalClick={handleTotalClick}
                            empty={empty}
                            Lane3={Lane3}
                          />
                            
                          }
                    </>
                  }
                  {
                    gameModelValue === 'model4' &&
                    <>
                      {
                            <BasicModal 
                                rankID={rankID}
                                setRankID={setRankID}
                                model={'model4'}
                                empty={empty}
                                gameDirection={gameDirection}
                            />
                      }
                      {
                            <TraditionModel
                            rankID={rankID}
                            setRankID={setRankID}
                            payOrder={payOrder}
                            Lane={Lane}
                            laneID={laneID}
                            laneID2={laneID2}
                            laneID3={laneID3}
                            laneID4={laneID4}
                            setLaneID={setLaneID}
                            setLaneID2={setLaneID2}
                            setLaneID3={setLaneID3}
                            setLaneID4={setLaneID4}
                            handlePlayButtonClick={handlePlayButtonClick}
                            Lane2={Lane2}
                            sizeID={sizeID}
                            setSizeID={setSizeID}
                            nClickSizeID={nClickSizeID}
                            setAutoHide={setAutoHide}
                            handlePlaceShow={handlePlaceShow}
                            nowChip={nowChip}
                            handleTotalClick={handleTotalClick}
                            empty={empty}
                            Lane3={Lane3}
                            Lane4={Lane4}
                            normalFastinputBet={normalFastinputBet}
                            setGamePlaceShow={setGamePlaceShow}
                            betLock={betLock}
                          />
                            
                          }
                    </>
                  }
                </>
            
            }
          </div>
        </div>
        
        

      </div>

      
    </div>
  )
}

export default VietnamLottery