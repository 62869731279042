import { all } from 'redux-saga/effects';
import { betHistorySaga } from './profile/betHistorySaga';
import { agentsSaga } from './profile/agentsSaga';
import { traderSaga } from './trader/trader';
import { activitySaga } from './activity/activity'
import { gameSaga } from './game/game'


function* rootSaga() {
  yield all([
    betHistorySaga(),
    agentsSaga(),
    traderSaga(),
    activitySaga(),
    gameSaga()
  ]);
}
export default rootSaga;



