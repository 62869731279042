// import React from 'react'
import { useEffect , useState , useContext } from 'react';
import { FormattedMessage , useIntl } from "react-intl";
import moment from "moment";
import '../GamePlace.scss';
import Spinner from '../../Spinner/Spinner';
import api from '../../../api/api';
import { ErrorContext } from '../../../Routes';

const MarksixTrend = ({gameID}) => {
    const intl = useIntl();

    const { ErrorMessage , TimeMoment} = useContext(ErrorContext);

    const [historyList , setHistoryList] = useState([]);
    const [listLoading , setListLoading] = useState(false);
    const mappingColor = {
        'red': intl.formatMessage({
            id: 'game.red',
        }),
        'blue': intl.formatMessage({
            id: 'game.blue',
        }), 
        'green' : intl.formatMessage({
            id: 'game.green',
        }), 
    };  
    const mappingChineseZodiacs = {
        'rat': intl.formatMessage({
            id: 'game.rat',
        }), 
        'ox': intl.formatMessage({
            id: 'game.ox',
        }), 
        'tiger' : intl.formatMessage({
            id: 'game.tiger',
        }), 
        'rabbit' : intl.formatMessage({
            id: 'game.rabbit',
        }), 
        'dragon' : intl.formatMessage({
            id: 'game.dragon',
        }), 
        'snake' : intl.formatMessage({
            id: 'game.snake',
        }), 
        'horse' : intl.formatMessage({
            id: 'game.horse',
        }),  
        'goat' : intl.formatMessage({
            id: 'game.goat',
        }),  
        'monkey' : intl.formatMessage({
            id: 'game.monkey',
        }),  
        'rooster' : intl.formatMessage({
            id: 'game.rooster',
        }),  
        'dog' : intl.formatMessage({
            id: 'game.dog',
        }),  
        'pig' : intl.formatMessage({
            id: 'game.pig',
        }),  
    };  

    //拿到開獎紀錄
    const getHistory = async() => {
        setListLoading(true)
        try {
        const res = await api.get(`/games/${gameID}/history`,{
            headers: {
            "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
            "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
        });
        if (res.data.status) {
            setHistoryList(res.data.data)
        }
        } catch (err) {
        console.log(err)
        const code = err.response.status
        const msg = err.response.data.data.error
        ErrorMessage(code, msg)
        }
        finally {
            setListLoading(false)
        }
    }
    useEffect(() => {
        getHistory()
        // setInterval(()=>{
        //     getHistory()
        // },60000)
        
      }, []);
    return (
        <div className="fixed_headers trendTable markSixTrend">
            {listLoading ? <Spinner/> : 
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FormattedMessage
                                    id={`game.issue`}
                                />
                            </td>
                            <td>
                                <FormattedMessage
                                    id={`game.lotteryDraw`}
                                />
                            </td>
                            <td>
                                <FormattedMessage
                                    id={`game.time`}
                                />
                            </td>
                            {/* <td>
                                <FormattedMessage
                                    id={`game.sp`}
                                />
                            </td> */}
                            {/* <td>
                                <FormattedMessage
                                    id={`game.color`}
                                />
                            </td>
                            <td>
                                <FormattedMessage
                                    id={`game.SPColor`}
                                />
                            </td> */}
                            <td>
                                <FormattedMessage
                                    id={`game.chineseZodiacTitle`}
                                />
                            </td>
                            {/* <td>
                                <FormattedMessage
                                    id={`game.sp_chineseZodiacTitle`}
                                />
                            </td> */}
                        </tr>
                        {historyList.map((v) => {
                             const combinedElements = JSON.parse(v.result).winning_numbers.map((number, index) => (
                                <div key={index} className={JSON.parse(v.result).colors[index]}>
                                  {number}
                                </div>
                            ))

                            const combinedSPElements = <div className={`${JSON.parse(v.result).sp_color}`}>
                                {JSON.parse(v.result).sp_number}
                            </div>
                            return (
                                <tr key={v.issue_no}>
                                    <td>{parseInt(v.issue_no)}</td>
                                    <td>{
                                            // JSON.parse(v.result).winning_numbers.join(',')
                                            combinedElements
                                        }
                                        {` + `}
                                        {combinedSPElements}
                                    </td>
                                    <td>{
                                         TimeMoment(v.lottery_draw_at)
                                    }</td>
                                    {/* <td>{JSON.parse(v.result).sp_number}</td> */}
                                    {/* <td>{combinedSPElements}</td> */}
                                    {/* <td>
                                        {
                                            JSON.parse(v.result).colors.map(item => mappingColor[item] || item).join(',')
                                        }    
                                    </td>
                                    <td>{mappingColor[JSON.parse(v.result).sp_color]}</td> */}
                                    <td>
                                        {
                                            JSON.parse(v.result).chinese_zodiacs.map(item => mappingChineseZodiacs[item] || item).join(',')
                                        }    
                                        {
                                            ` + `
                                        }
                                        {
                                            mappingChineseZodiacs[JSON.parse(v.result).sp_chinese_zodiacs]
                                        }
                                    </td>
                                    {/* <td>{mappingChineseZodiacs[JSON.parse(v.result).sp_chinese_zodiacs]}</td> */}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                
            }
            
        </div>
    );
}


export default MarksixTrend
