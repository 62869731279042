import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';

export default function NativeSelectDemo({openList , setNowGameID , nowGameID}) {
  return (
    <Box sx={{ minWidth: 160 , height : 30 , maxWidth: 160}}>
      <FormControl fullWidth>
        {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Age
        </InputLabel> */}
        <NativeSelect
          sx={{height : 30}}
          value={nowGameID}
          inputProps={{
            name: 'age',
            id: 'uncontrolled-native',
          }}
          onChange={(e)=>{
            setNowGameID(e.target.value)
          }}
        >
          {
            openList.map((v)=>{
              const startIndex =  v.name.indexOf('(');
              const endIndex =  v.name.indexOf(')');
              const substring = v.name.substring(startIndex + 1, endIndex);
              return(
                <option key={v.game_id} value={v.game_id}>{v.name}</option>
              )
            })
          }
          {/* <option value={10}>河內市(北部)</option>
          <option value={20}>同塔省(南部)</option>
          <option value={30}>胡志明市(南部)</option> */}
        </NativeSelect>
      </FormControl>
    </Box>
  );
}