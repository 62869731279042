import React, { useState , useEffect, useCallback , useRef} from 'react'
import { NavLink , Link } from "react-router-dom";
import { useSelector , useDispatch , Provider} from 'react-redux';
import { FormattedMessage } from "react-intl";

import { useNavigate } from "react-router-dom";
import AvatarStatus from './AvatarStatus'
import './Traders.scss'
import ReplyIcon from '@mui/icons-material/Reply';

import { FaUser } from 'react-icons/fa';
// traderList
import avatar from '../../images/traders/avatar.png';
import number from '../../images/traders/icon1.png';


// const myArr = [
//     {
//         name:'交易員A',
//         num:30,
//         income:'10000.00%'
//     },
//     {
//         name:'交易員B',
//         num:50,
//         income:'10000.00%'
//     },
//     {
//         name:'交易員C',
//         num:80,
//         income:'10000.00%'
//     },
// ]

const followArr = [
    {
        name:'交易員Aa',
        online:false
    },
    {
        name:'交易員Bb',
        online:true
    },
    {
        name:'交易員Cc',
        online:false
    },
]
const TraderMyHomePage = () => {
  const [loading, setLoading] = useState(true)
  const [refresh , setRefresh] = useState(false)
  const [boxSwitch, setBoxSwitch] = useState(false)
  const [model , setModel] = useState('model1')
  const [followOnline , setFollowOnline] = useState(true)

  const navigate = useNavigate();
  //redux
  const dispatch = useDispatch();
  const getTraderPage = useSelector(state => state.trader.getTraderPage)
  const listMirrorTradings = useSelector(state => state.trader.listMirrorTradings)
  const listSubscribes = useSelector(state => state.trader.listSubscribes)

  useEffect(() => {
    dispatch({ type: 'SAGA_FETCH_TRADERPAGE_API' , setLoading});
  }, [dispatch , refresh]);

//   SAGA_FETCH_CANCELMIRROR_API
  const cancelMirrorTrading = (id) =>{
    dispatch({ type: 'SAGA_FETCH_CANCELMIRROR_API' , id , refresh , setRefresh});
  }

  return (
    <div className="traderMyHomePage homePage page home-bg mt-0">
        <div className='traderHeader'>
            <button className='backBtn' onClick={()=>{
                  navigate(`/traders`);
              }}
            ><ReplyIcon/></button>
            <div className='title'>
                <FormattedMessage
                    id={`trader.myHomePage`}
                ></FormattedMessage>
            </div>
            <FaUser onClick={() => {
                    navigate(`/traderMyHome`);
            }}/>
        </div>
        {
            loading || 
            <div className='container'>
                    <AvatarStatus model={model} avaterData={getTraderPage}/>
                    <ul className='traderDataList mt-5'>
                        <li>
                            <div>
                                <FormattedMessage
                                    id={`trader.totalAssets`}
                                ></FormattedMessage>
                            </div>
                            <div>{getTraderPage.data.response.balance}</div>
                        </li>
                        <li>
                            <div>
                                <FormattedMessage
                                    id={`trader.totalRevenue`}
                                ></FormattedMessage>    
                            </div>
                            <div>{getTraderPage.data.response.dividend}</div>
                        </li>
                        <li>
                            <div>
                                <FormattedMessage
                                    id={`trader.rateOfReturn`}
                                ></FormattedMessage>    
                            </div>
                            <div>{getTraderPage.data.response.rate_of_return}%</div>
                        </li>
                        {
                            getTraderPage.data.is_trader &&
                            <>
                                <li className='mt-5'>
                                    <div>
                                        <FormattedMessage
                                            id={`trader.totalNumberOfOrders`}
                                        ></FormattedMessage> 
                                    </div>
                                    <div>{getTraderPage.data.tradeInfo.mirror_trading_total_count}</div>
                                </li>
                                <li>
                                    <div>
                                        <FormattedMessage
                                            id={`trader.numberOfFollowers`}
                                        ></FormattedMessage> 
                                    </div>
                                    <div>{getTraderPage.data.tradeInfo.subscribe_count}</div>
                                </li>
                                <li>
                                    <div>
                                        <FormattedMessage
                                            id={`trader.totalProfitFromFollowingOrders`}
                                        ></FormattedMessage> 
                                    </div>
                                    <div>{getTraderPage.data.tradeInfo.mirror_trading_profit}</div>
                                </li>
                            </>
                        }
                        
                    </ul>
                    <div className='myList mt-5'>
                        <div className='title'>
                            <FormattedMessage
                                id={`trader.myFollowOrders`}
                            ></FormattedMessage>
                        </div>
                        {
                            listMirrorTradings.data.response.length === 0 ?
                            <div className='moAttention'>
                                <FormattedMessage
                                    id={`trader.noFollowingOrders`}
                                ></FormattedMessage>
                            </div>
                            :
                            <ul className='tradersList'>
                                {
                                    listMirrorTradings.data.response.map((v)=>{
                                        return(
                                            <li key={v.id} onClick={() => {
                                                navigate(`/trderRecord/${v.trader_id}/${v.id}`);
                                            }}>
                                                <div className='avatar'>
                                                    <img src={avatar}></img>
                                                </div>
                                                <div className='d2'>
                                                    <div className='tName'>
                                                        {listMirrorTradings.data.trader[v.trader_id].account}
                                                    </div>
                                                    <div className='number'>
                                                        <img src={number}></img>
                                                        <span>
                                                            {listMirrorTradings.data.trader[v.trader_id].mirror_trading_count}
                                                            /100
                                                        </span>
                                                    </div>
                                                </div>
                                                <div  className='d3'>
                                                    <div className='incomeTi'>
                                                        <FormattedMessage
                                                            id={`trader.rateOfReturn`}
                                                        ></FormattedMessage>
                                                    </div>
                                                    <div className='incomeDes'>
                                                        <span>{listMirrorTradings.data.trader[v.trader_id].rate_of_return}%</span>
                                                    </div>
                                                </div>
                                                <button className='full' onClick={(e) => {
                                                    e.stopPropagation();
                                                    cancelMirrorTrading(v.id)
                                                }}
                                                >
                                                    <FormattedMessage
                                                        id={`trader.abort`}
                                                    ></FormattedMessage>
                                                </button>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        }
                        
                    </div>
                    <div className='myFollow mt-4'>
                        <div className='title'>
                            <FormattedMessage
                                id={`trader.mySubscription`}
                            ></FormattedMessage>
                        </div>
                        {
                            listSubscribes.data.response.length === 0 ?
                            <div className='moAttention'>
                                <FormattedMessage
                                    id={`trader.nosubscribers`}
                                ></FormattedMessage>
                            </div>
                            :
                            <ul className='tradersList'>
                            {
                                listSubscribes.data.response.map((v) => {
                                    return (
                                        <li key={v.id} onClick={() => {
                                            navigate(`/traderHome/${v.id}`);
                                        }}>
                                            <div className='avatar'>
                                                <img src={avatar}></img>
                                            </div>
                                            <div className='d2'>
                                                <div className='sTitle'>
                                                    <FormattedMessage
                                                        id={`trader.traders`}
                                                    ></FormattedMessage>
                                                </div>
                                                <div className='tName'>{v.account}</div>
                                            </div>
                                            <div  className='d3'>
                                                <div className='incomeTi'>
                                                    <FormattedMessage
                                                        id={`trader.rateOfReturn`}
                                                    ></FormattedMessage>
                                                </div>
                                                <div className='incomeDes'>
                                                    <span>{v.rate_of_return}%</span>
                                                </div>
                                            </div>
                                            <button
                                                onClick={()=>{
                                                    navigate(`/traderHome/${v.id}`);
                                                }}
                                            >
                                                <FormattedMessage
                                                    id={`trader.view`}
                                                ></FormattedMessage>
                                            </button>
                                        </li>
                                    )
                                })
                            }
                            </ul>
                        }
                        
                    </div>
            </div>
        }
       
       
    </div>
  )
}

export default TraderMyHomePage
