// import React from 'react'
import { useEffect , useState , useContext} from 'react';
import { FormattedMessage , useIntl } from "react-intl";
import moment from "moment";
import './../GamePlace.scss';
import Spinner from '../../Spinner/Spinner';
import api from '../../../api/api';
import { ErrorContext } from '../../../Routes';

const GameTrend = ({gameID , nkStatus}) => {
    const intl = useIntl();
    const { ErrorMessage , TimeMoment } = useContext(ErrorContext);
    const [historyList , setHistoryList] = useState([]);
    const [listLoading , setListLoading] = useState(false);
    const mapping = {
        '1': 
        
        <i className='TRed'>
            {intl.formatMessage({
                id: 'game.dragon',
            })}
            {/* <FormattedMessage
                id={`game.dragon`}
            /> */}
        </i>, 
        '0': 
        <i className='TBlue'>
        {intl.formatMessage({
            id: 'game.tiger',
        })}
            {/* <FormattedMessage
                id={`game.tiger`}
            /> */}
        </i>
    };  
    //拿到開獎紀錄
    const getHistory = async() => {
        setListLoading(true)
        try {
        const res = await api.get(`/games/${gameID}/history`,{
            headers: {
            "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
            "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
        });
        if (res.data.status) {
            setHistoryList(res.data.data)
        }
        } catch (err) {
        console.log(err)
        const code = err.response.status
        const msg = err.response.data.data.error
        ErrorMessage(code, msg)
        }
        finally {
            setListLoading(false)
        }
    }
    useEffect(() => {
        getHistory()
    }, []);
    return (
        <div className="fixed_headers trendTable">
            {listLoading ? <Spinner/> : 
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FormattedMessage
                                    id={`game.issue`}
                                />
                            </td>
                            <td>
                                <FormattedMessage
                                    id={`game.lotteryDraw`}
                                />
                            </td>
                            
                            <td>
                                <FormattedMessage
                                    id={`game.tie`}
                                />
                            </td>
                            <td>
                                <FormattedMessage
                                    id={`game.big`}
                                />
                                <FormattedMessage
                                    id={`game.small`}
                                />
                            </td>
                            <td>
                                <FormattedMessage
                                    id={`game.odd`}
                                />
                                <FormattedMessage
                                    id={`game.even`}
                                />
                            </td>
                            <td>
                                <FormattedMessage
                                    id={`game.model3`}
                                />
                            </td>
                            <td>
                                <FormattedMessage
                                    id={`game.time`}
                                />
                            </td>
                        </tr>
                        {historyList.map((v) => {
                            return (
                                <tr key={v.issue_no}>
                                    <td>{v.issue_no}</td>
                                     <td>
                                        {
                                            Object.values(JSON.parse(v.result).result)
                                        }
                                        <i>
                                        {/* nkStatus */}
                                        {/* {
                                            nkStatus ?
                                            <>
                                                {
                                                    JSON.parse(v.result).sum >= 10 ?
                                                    intl.formatMessage({
                                                        id: 'game.big',
                                                    }) :
                                                    intl.formatMessage({
                                                        id: 'game.small',
                                                    }) 
                                                }
                                                {
                                                    JSON.parse(v.result).sum % 2 === 0 ?
                                                    intl.formatMessage({
                                                        id: 'game.even',
                                                    }) :
                                                    intl.formatMessage({
                                                        id: 'game.odd',
                                                    })
                                                }
                                            </>
                                            :
                                            <>
                                                {
                                                    JSON.parse(v.result).sum !== 3 && JSON.parse(v.result).sum !== 18 ?
                                                    <>
                                                        {
                                                            JSON.parse(v.result).sum >= 10 ?
                                                            intl.formatMessage({
                                                                id: 'game.big',
                                                            }) :
                                                            intl.formatMessage({
                                                                id: 'game.small',
                                                            }) 
                                                        }
                                                        {
                                                            JSON.parse(v.result).sum % 2 === 0 ?
                                                            intl.formatMessage({
                                                                id: 'game.even',
                                                            }) :
                                                            intl.formatMessage({
                                                                id: 'game.odd',
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            // intl.formatMessage({
                                                            //     id: 'game.nk',
                                                            // })
                                                        }
                                                    </>
                                                }
                                            </>
                                        }    */}
                                        
                                            
                                        </i>
                                        
                                    </td>
                                    <td>
                                        {
                                              JSON.parse(v.result).sum
                                        }
                                        
                                    </td>
                                    <td>
                                        {
                                            nkStatus ?
                                            <>
                                                {
                                                    JSON.parse(v.result).sum >= 11 ?
                                                    intl.formatMessage({
                                                        id: 'game.big',
                                                    }) :
                                                    intl.formatMessage({
                                                        id: 'game.small',
                                                    }) 
                                                }
                                            </>
                                            :
                                            <>
                                                {
                                                    JSON.parse(v.result).sum !== 3 && JSON.parse(v.result).sum !== 18 ?
                                                    <>
                                                        {
                                                            JSON.parse(v.result).sum >= 11 ?
                                                            intl.formatMessage({
                                                                id: 'game.big',
                                                            }) :
                                                            intl.formatMessage({
                                                                id: 'game.small',
                                                            }) 
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            intl.formatMessage({
                                                                id: 'game.nk',
                                                            })
                                                        }
                                                    </>
                                                }
                                            </>
                                        }   
                                        
                                    </td>
                                    <td>
                                    {
                                            nkStatus ?
                                            <>
                                                {
                                                    JSON.parse(v.result).sum % 2 === 0 ?
                                                    intl.formatMessage({
                                                        id: 'game.even',
                                                    }) :
                                                    intl.formatMessage({
                                                        id: 'game.odd',
                                                    })
                                                }
                                            </>
                                            :
                                            <>
                                                {
                                                    JSON.parse(v.result).sum !== 3 && JSON.parse(v.result).sum !== 18 ?
                                                    <>
                                                        {
                                                            JSON.parse(v.result).sum % 2 === 0 ?
                                                            intl.formatMessage({
                                                                id: 'game.even',
                                                            }) :
                                                            intl.formatMessage({
                                                                id: 'game.odd',
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            intl.formatMessage({
                                                                id: 'game.nk',
                                                            })
                                                        }
                                                    </>
                                                }
                                            </>
                                        }   
                                    </td>
                                    <td>
                                        {
                                            intl.formatMessage({
                                                id: `bingogame.${JSON.parse(v.result).dragon_tiger}`
                                            })
                                            // Object.values(JSON.parse(v.result).dragon_tiger)
                                        }    
                                    </td>
                                    {/* <td>
                                        {
                                              JSON.parse(v.result).sum >= 10 ?
                                              intl.formatMessage({
                                                id: 'game.small',
                                              }) :
                                              intl.formatMessage({
                                                id: 'game.big',
                                              })
                                        }
                                    </td>
                                    <td>
                                        {
                                              JSON.parse(v.result).sum % 2 === 0 ?
                                              intl.formatMessage({
                                                id: 'game.even',
                                              }) :
                                              intl.formatMessage({
                                                id: 'game.odd',
                                              })
                                        }
                                    </td> */}
                                     <td>{
                                        TimeMoment(v.lottery_draw_at)
                                     }</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                
            }
            
        </div>
    );
}


export default GameTrend
