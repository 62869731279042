// import React from 'react'
import React, { useState , useEffect, useCallback} from 'react'
import { FormattedMessage } from "react-intl";
import './../GamePlace.scss';


import btn1 from '../../../images/racing/first-white.png';
import btn1a from '../../../images/racing/first-blue.png';
import btn2 from '../../../images/racing/second-white.png';
import btn2a from '../../../images/racing/second-blue.png';
import btn3 from '../../../images/racing/star-white.png';
import btn3a from '../../../images/racing/star-blue.png';

const FixedBet = ({
    fixbarRef,rankID,payOrder,Lane,laneID,setLaneID,nowChip,handlePlaceShow,empty,handlePlayButtonClick,setRankID,handleTotalClick,Lane2,sizeID,setSizeID,nClickSizeID,handleManuaTotalClick,Lane3
    }) => {
    const btns = [
        {
            name: "千位",
            id : 1,
            value : "thousands",
            hash : "thousands",
            img:btn1,
            aImg:btn1a,
        },
        {
            name: "百位",
            id : 2,
            value : "hundreds",
            hash : "hundreds",
            img:btn2,
            aImg:btn2a,
        },
        {
            name: "十位",
            id : 2,
            value : "tens",
            hash : "tens",
            img:btn1,
            aImg:btn1a,
        },
        {
            name: "個位",
            id : 2,
            value : "units",
            hash : "units",
            img:btn2,
            aImg:btn2a,
        },
        {
            name: "前二不定位",
            id : 2,
            value : "un_f",
            hash : "un_f",
            img:btn1,
            aImg:btn1a,
        },
        {
            name: "后二不定位",
            id : 2,
            value : "un_b",
            hash : "un_b",
            img:btn2,
            aImg:btn2a,
        },
        {
            name: "鱼虾蟹",
            id : 2,
            value : "fsc",
            hash : "fsc",
            img:btn1,
            aImg:btn1a,
        },
    ]
    useEffect(() =>{
        setRankID(['thousands']);
    },[])
    useEffect(() => {
        handleTotalClick()
    }, [nowChip , rankID, laneID , Lane2 , sizeID]);
 

    const [activeBtn, setActiveBtn] = useState(null);

    //建立fixbar的Ref
    // const fixbarRef = React.createRef(null);
    const [fixbarOffsetTop, setFixbarOffsetTop] = useState(0);

    useEffect(() => {
        const fixbar = fixbarRef.current;
        if (fixbar) {
            setFixbarOffsetTop(fixbar.offsetTop);
        }
        
    }, []);
  
    const handleScroll = useCallback(() => {
        //使用Ref.current引用DOM
        const fixbar = fixbarRef.current;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        //判斷fixbar固定
    
        if (scrollTop >= fixbarOffsetTop - 36) {
            fixbar.classList.add('active');
        } else {
            fixbar.classList.remove('active');
        }
        
        //判斷fixbar item active
        for (let i = 0; i < btns.length; i++) {
            const ref = document.getElementById(`btn-${btns[i].id}`);
            if (ref && ref.offsetTop <= scrollTop + 300) {
                setActiveBtn(btns[i].id);
                // ref.scrollIntoView({ block: "center", behavior: 'smooth' })
            }
        }
    }, [btns]);


    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);


    // useEffect(()=>{
    //     const namefalse = document.querySelectorAll('.name[data-active="false"]');

    //     if(laneID.length === 2){
    //         namefalse.forEach(element => {
    //             element.setAttribute('data-nclick', 'true');
    //         });
    //     } else {
    //         namefalse.forEach(element => {
    //             element.setAttribute('data-nclick', 'false');
    //         });
    //     }
    // },[laneID,rankID])

    useEffect(() => {
        handleManuaTotalClick()
    }, [nowChip , rankID, laneID , Lane2]);

    useEffect(() => {
        empty()
    }, [rankID]);

    return (
        <div className="gameWrap d-flex">
          <div className="btnsWrap d-flex" 
            id="fixbar"
            ref={fixbarRef}>
            
            <div></div>
            {btns.map((v) => {
              return (
                <li
                  className={rankID.includes(v.value) ? 'active' : ''}
                //   data-active={rankID.includes(v.value)}
                  key={v.id} 
                  
                  onClick={() => {
                    setRankID([v.value])
                  }}
                >
                  <div className='imgBx'>
                    <img src={v.img}></img>
                    <img src={v.aImg}></img>
                  </div>
                 
                  <div>
                    {/* {v.name} */}
                    <FormattedMessage
                        id={`game.${v.value}`}
                    ></FormattedMessage>  
                  </div>
                </li>
              )
            })}
          </div>
         
          <div className="itemWrap model1">
            {
                rankID.includes('thousands') &&
                <div className='circleCard'>
                    <div className='cardanchor'></div>
                    <h5 className='long'>
                        <FormattedMessage
                            id={`game.thousands`}
                        ></FormattedMessage>
                    </h5>
                    <div className="circleWrap d-flex">
                        {Lane.map((v) => {
                            return (
                            <div key={v} className="name"
                            data-active={laneID.includes(v)}
                            onClick={() => {
                                handlePlayButtonClick(v,laneID,setLaneID,"thousands")
                                // handleManuaTotalClick()
                                handlePlaceShow()
                            }}
                            >
                                <i>{v}</i>
                            </div>
                            )
                        })}
                        
                    </div>
                    <h5 className='mt-2'>
                        <FormattedMessage
                            id={`game.thousands`}
                        ></FormattedMessage>
                        <span> </span>
                        <FormattedMessage
                            id={`game.doubleSided`}
                        ></FormattedMessage>
                    </h5>
                    <div className="circleWrap d-flex fourCircle">
                    {Lane2.map((v) => {
                        return (
                        <div key={v.value} className="name"
                        data-active={laneID.includes(v.value)}
                        data-nclick={nClickSizeID.includes(v.value)}
                        // onClick={() => {
                        //     handlePlayButtonClick(v.value,sizeID,setSizeID)
                        //     // handleManuaTotalClick()
                        //     handlePlaceShow()
                        // }}
                        onClick={() => {
                            handlePlayButtonClick(v.value,laneID,setLaneID,"thousands")
                            // handleManuaTotalClick()
                            handlePlaceShow()
                        }}
                        >
                            <i>
                                <FormattedMessage
                                    id={`game.${v.value}`}
                                ></FormattedMessage>
                            </i>
                        </div>
                        )
                    })}
                    </div>
                </div>
            }
            { 
                rankID.includes('hundreds') &&
                <div className='circleCard'>
                    <h5 className='long'>
                        <FormattedMessage
                            id={`game.hundreds`}
                        ></FormattedMessage>
                    </h5>
                    <div className="circleWrap d-flex">
                        {Lane.map((v) => {
                            return (
                            <div key={v} className="name"
                            data-active={laneID.includes(v)}
                            onClick={() => {
                                handlePlayButtonClick(v,laneID,setLaneID,"hundreds")
                                handlePlaceShow()
                            }}
                            >
                                <i>{v}</i>
                            </div>
                            )
                        })}
                    </div>
                    <h5 className='mt-2'>
                        <FormattedMessage
                            id={`game.hundreds`}
                        ></FormattedMessage>
                        <span> </span>
                        <FormattedMessage
                            id={`game.doubleSided`}
                        ></FormattedMessage>
                    </h5>
                    <div className="circleWrap d-flex fourCircle">
                    {Lane2.map((v) => {
                        return (
                        <div key={v.value} className="name"
                        data-active={laneID.includes(v.value)}
                        data-nclick={nClickSizeID.includes(v.value)}
                        onClick={() => {
                            handlePlayButtonClick(v.value,laneID,setLaneID,"hundreds")
                            // handleManuaTotalClick()
                            handlePlaceShow()
                        }}
                        >
                            <i>
                                <FormattedMessage
                                    id={`game.${v.value}`}
                                ></FormattedMessage>
                            </i>
                        </div>
                        )
                    })}
                    </div>
                </div>
            }
            { 
                rankID.includes('tens') &&
                <div className='circleCard'>
                    <h5 className='long'>
                        <FormattedMessage
                            id={`game.tens`}
                        ></FormattedMessage>
                    </h5>
                    <div className="circleWrap d-flex">
                        {Lane.map((v) => {
                            return (
                            <div key={v} className="name"
                            data-active={laneID.includes(v)}
                            onClick={() => {
                                handlePlayButtonClick(v,laneID,setLaneID,"tens")
                                handlePlaceShow()
                            }}
                            >
                                <i>{v}</i>
                            </div>
                            )
                        })}
                    </div>
                    <h5 className='mt-2'>
                        <FormattedMessage
                            id={`game.tens`}
                        ></FormattedMessage>
                        <span> </span>
                        <FormattedMessage
                            id={`game.doubleSided`}
                        ></FormattedMessage>
                    </h5>
                    <div className="circleWrap d-flex fourCircle">
                    {Lane2.map((v) => {
                        return (
                        <div key={v.value} className="name"
                        data-active={laneID.includes(v.value)}
                        data-nclick={nClickSizeID.includes(v.value)}
                        onClick={() => {
                            handlePlayButtonClick(v.value,laneID,setLaneID,"tens")
                            // handleManuaTotalClick()
                            handlePlaceShow()
                        }}
                        >
                            <i>
                                <FormattedMessage
                                    id={`game.${v.value}`}
                                ></FormattedMessage>
                            </i>
                        </div>
                        )
                    })}
                    </div>
                </div>
            }
            { 
                rankID.includes('units') &&
                <div className='circleCard'>
                    <h5 className='long'>
                        <FormattedMessage
                            id={`game.units`}
                        ></FormattedMessage>
                    </h5>
                    <div className="circleWrap d-flex">
                        {Lane.map((v) => {
                            return (
                            <div key={v} className="name"
                            data-active={laneID.includes(v)}
                            onClick={() => {
                                handlePlayButtonClick(v,laneID,setLaneID,"units")
                                handlePlaceShow()
                            }}
                            >
                                <i>{v}</i>
                            </div>
                            )
                        })}
                    </div>
                    <h5 className='mt-2'>
                        <FormattedMessage
                            id={`game.units`}
                        ></FormattedMessage>
                        <span> </span>
                        <FormattedMessage
                            id={`game.doubleSided`}
                        ></FormattedMessage>
                    </h5>
                    <div className="circleWrap d-flex fourCircle">
                    {Lane2.map((v) => {
                        return (
                        <div key={v.value} className="name"
                        data-active={sizeID.includes(v.value)}
                        data-nclick={nClickSizeID.includes(v.value)}
                        onClick={() => {
                            handlePlayButtonClick(v.value,laneID,setLaneID,"units")
                            // handleManuaTotalClick()
                            handlePlaceShow()
                        }}
                        >
                            <i>
                                <FormattedMessage
                                    id={`game.${v.value}`}
                                ></FormattedMessage>
                            </i>
                        </div>
                        )
                    })}
                    </div>
                </div>
            }
            {
                rankID.includes('un_f') &&
                <div className='circleCard'>
                    <div className='cardanchor'></div>
                    <h5 className='long'>
                        <FormattedMessage
                            id={`game.un_f`}
                        ></FormattedMessage>
                    </h5>
                    <div className="circleWrap d-flex">
                        {Lane.map((v) => {
                            return (
                            <div key={v} className="name"
                            data-active={laneID.includes(v)}
                            onClick={() => {
                                handlePlayButtonClick(v,laneID,setLaneID,"un_f")
                                // handleManuaTotalClick()
                                handlePlaceShow()
                            }}
                            >
                                <i>{v}</i>
                            </div>
                            )
                        })}
                        
                    </div>
                </div>
            }
            {
                rankID.includes('un_b') &&
                <div className='circleCard'>
                    <div className='cardanchor'></div>
                    <h5 className='long'>
                        <FormattedMessage
                            id={`game.un_b`}
                        ></FormattedMessage>
                    </h5>
                    <div className="circleWrap d-flex">
                        {Lane.map((v) => {
                            return (
                            <div key={v} className="name"
                            data-active={laneID.includes(v)}
                            onClick={() => {
                                handlePlayButtonClick(v,laneID,setLaneID,"un_b")
                                // handleManuaTotalClick()
                                handlePlaceShow()
                            }}
                            >
                                <i>{v}</i>
                            </div>
                            )
                        })}
                        
                    </div>
                </div>
            }
            {
                rankID.includes('fsc') &&
                <div className='circleCard' style={{"paddingBottom" : "25vh"}}>
                    <div className='cardanchor'></div>
                    <h5 className='long'>
                        <FormattedMessage
                            id={`game.fsc`}
                        ></FormattedMessage>
                    </h5>
                    <div className="circleWrap d-flex">
                        {Lane3.map((v) => {
                            return (
                            <div key={v.value} className="name"
                            data-active={laneID.includes(v.value)}
                            onClick={() => {
                                handlePlayButtonClick(v.value,laneID,setLaneID,"fsc")
                                // handleManuaTotalClick()
                                handlePlaceShow()
                            }}
                            >
                                 <i>
                                    <FormattedMessage
                                        id={`game.${v.value}`}
                                    ></FormattedMessage>
                                </i>
                            </div>
                            )
                        })}
                        
                    </div>
                </div>
            }
            
          </div>
        </div>
    )
}


export default FixedBet
