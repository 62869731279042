import React, { useState , useEffect, useCallback , useRef} from 'react'
import Box from '@mui/material/Box';

import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';

import { LinePlot } from '@mui/x-charts/LineChart';

const testArr = Array.from({ length: 168 }, (_, index) => index + 1);
const TraderTrendMap = ({nunberValue}) => {
    return (
        <Box sx={{ width: '100%' }}>
            <ResponsiveChartContainer
            margin={{ top: 20, left: 10, right: 10, bottom: 30 }}
            height={100}
            series={[
            {
                type: 'line',
                data : nunberValue
            },
            ]}
            xAxis={[{ data: testArr }]}
            >
                <LinePlot />
            {/* <DrawingAreaBox /> */}
            </ResponsiveChartContainer>
      </Box>
    )
}


export default TraderTrendMap
