import React, { useEffect} from 'react'
import './GamePlace.scss';
import api from '../../api/api';
import Swal from 'sweetalert2'
import { FormattedMessage } from "react-intl";

const Sum = ({
    rankID,setRankID,payOrder,laneID,setLaneID,handlePlayButtonClick,Lane2,sizeID,setSizeID,nClickSizeID,setAutoHide,handlePlaceShow,handleTotalClick,nowChip,empty,sumOdds
    }) => {
    // 顯示在畫面上的選項
    const Lane = [
      '3', "4", "5", "6", "7", "8", "9", "10",'11','12','13','14','15','16','17','18','19','A','B','C'
    ]
    const lane2 = [
      {
        name:'大',
        value:'big'
      },
      {
        name:'小',
        value:'small'
      },
      {
        name:'單',
        value:'odd'
      },
      {
        name:'雙',
        value:'even'
      },
      {
        name:'庄',
        value:'banker'
      },
      {
        name:'闲',
        value:'player'
      }
    ]
    // 實際送出的值
    const valueLane = [
      '3', "4", "5", "6", "7", "8", "9", "10",'11','12','13','14','15','16','17','18','19','A','B','C'
    ]
    const valueLane2 = [
      'big','small','odd','even','banker','player'
    ]

    const combinedResult = valueLane.map((valueLane, index) => {
      return {
        name:Lane[index],
        value:valueLane,
        // odds:sumOdds[0]
        odds: sumOdds.find((item) => item.key === valueLane)?.value.odds
      }
      // }${Lane}${sumOdds[index].value};
    });
    const combinedResult2 = valueLane2.map((valueLane, index) => {
      return {
        name:lane2[index].name,
        value:valueLane,
        // odds:sumOdds[0]
        odds: sumOdds.find((item) => item.key === valueLane)?.value.odds
      }
      // }${Lane}${sumOdds[index].value};
    });
    const handleSizeButtonClick = (value , state , set) => {
        if (state.indexOf(value) !== -1) {
            // 選過就刪掉
            const newArray = state.filter((element) => element !== value);
                set(newArray);
          } else {
            set([...state, value]);
          }
    };
    
    

    useEffect(() => {
        handleTotalClick()
        setRankID(['sp'])
        console.log(sumOdds)
    }, [laneID , sizeID ,nowChip]);
    return (

        <div className="gameWrap d-flex">
          <div className="itemWrap sumWrap">
            {/* <h5 className='mt-2'>名次</h5>
            <div className="circleWrap d-flex">
              {btns.map((v) => {
                return (
                  <div className="name" key={v.value}
                    data-active={rankID.includes(v.value)}
                    onClick={() => {
                      handleSizeButtonClick(v.value , rankID , setRankID)
                      handlePlaceShow()
                    }}
                  >{v.value}</div>
                )
              })}
            </div> */}
            <h5 className='mt-2'>
              <FormattedMessage
                  id={`game.model2`}
              />
            </h5>
            <div className="circleWrap d-flex">
              {combinedResult.map((v) => {
                return (
                  <div key={v.name} className='circleBx'>
                    <div className="name"

                    data-active={laneID.includes(v.value)}
                    onClick={() => {
                      handlePlayButtonClick(v.value,laneID,setLaneID)
                      handlePlaceShow()
                    }}
                    >
                        <i>{v.name}</i>
                    
                    <span>x{v.odds}</span>  
                    </div>
                  </div>
                  
                )
                console.log(v)
              })}
            </div>
            <div className="circleWrap d-flex">
              {combinedResult2.map((v) => {
                return (
                  <div key={v.name} className='circleBx'>
                    <div className="name"

                    data-active={laneID.includes(v.value)}
                    onClick={() => {
                      handlePlayButtonClick(v.value,laneID,setLaneID)
                      handlePlaceShow()
                    }}
                    >
                        <i>
                          <FormattedMessage
                              id={`game.${v.value}`}
                          />
                          {/* {v.name} */}
                        </i>
                    
                    <span>x{v.odds}</span>  
                    </div>
                  </div>
                  
                )
                console.log(v)
              })}
            </div>
            {/* <h5 className='mt-2'>雙面</h5>
            <div className="circleWrap d-flex">
              {Lane2.map((v) => {
                return (
                  <div key={v} className="name"
                  data-active={sizeID.includes(v)}
                  data-nclick={nClickSizeID.includes(v)}
                  onClick={() => {
                    handlePlayButtonClick(v,sizeID,setSizeID)
                    handlePlaceShow()
                  }}
                  >{v}</div>
                )
              })}
            </div> */}
           
          </div>
        </div>
      )
}


export default Sum
