import React, { useState , useEffect, useCallback , useRef} from 'react'
import { AiFillExclamationCircle } from 'react-icons/ai';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const TraderSetPop = ({setPopOpen , onSubmitCreateMirrorTrading}) => {
    return (
        <div className='setPop'>
            <AiFillExclamationCircle/>
            <div className='des mt-3'>
                當任一計畫觸發停止跟單，將停止所有進行中的跟單計畫！
            </div>
            {/* <FormGroup>
                <FormControlLabel control={<Checkbox />} label="關閉通知，不再提醒我" />
            </FormGroup> */}
            <div className='btnFlex mt-5'>
                <button
                  onClick={()=>setPopOpen(false)}
                >
                    返回查看
                </button>
                <button
                  onClick={(e)=>onSubmitCreateMirrorTrading(e)}
                >
                    我知道了
                </button>
            </div>
        </div>
    )
}


export default TraderSetPop
