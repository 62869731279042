// import React, { useState , useEffect, useCallback , useRef} from 'react'



const initState = {
    listGames:{
        data:{
            data: {
                next_draw_at:{},
                pagination: {
                    page: 0,
                    per_page: 0,
                    total: 0
                  },
                  response: [
                    {
                      create_at: 0,
                      game_id: 0,
                      image_id: "",
                      image_link: "",
                      name: "",
                      platform_id: "",
                      third_party_game_id: 0,
                      type_id: 0
                    }
                  ]
            },
            status: false
        }
    },
    thirdPartyGames:{
        data:{
            data: [
                {
                  id: 0,
                  name: "string"
                }
              ],
              status: true
        }
    },
    gameTypes:{
        data:{
            data: [
                {
                  id: 0,
                  name: "string"
                }
              ],
              status: true
        }
    },
    gameChips:{
      data: [
        {
            seq: 1,
            amount: 0
        },
        {
            seq: 2,
            amount: 0
        },
        {
            seq: 3,
            amount: 0
        },
        {
            seq: 4,
            amount: 0
        },
        {
            seq: 5,
            amount: 0
        }
    ],
    "status": true
    },
    gameWallet:{
      data: {
        balance: 0,
        third_party_game_wallets: [
          {
            amount: 0,
            third_party_game_code: "string",
            third_party_game_id: 0,
            third_party_game_name: "string"
          }
        ]
      },
      status: true
    }
};


export const GameReducer = (state = initState, action) => {
    switch (action.type) {
      case "FETCH_LISTGAMES_API":
        return {
          ...state,
          listGames : action.payload
        };
      case "FETCH_THIRDPARTYGAMES_API":
        return {
            ...state,
            thirdPartyGames : action.payload
        };
      case "FETCH_GAMESTYPES_API":
        return {
            ...state,
            gameTypes : action.payload
        };
      case "FETCH_GAMESCHIPS_API":
        return {
            ...state,
            gameChips : action.payload
        };
      case "FETCH_GAMESWALLET_API":
        return {
          ...state,
          gameWallet : action.payload
        };
      case "FETCH_GAMESFORWARD_API":
        return state;
      case "FETCH_GAMESWALLETREFRESH_API":
        return {
          ...state,
          gameWallet : action.payload
        };
      case "FETCH_WALLETTRANSFER_API":
          return state;
      case "FETCH_GAMESWALLETRETURN_API":
        return {
          ...state,
          gameWallet : action.payload
        };
      default:
        return state;
    }
};
  
// export { betHistoryReducer };