import React , {useContext , useState , useEffect} from 'react'
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import CloseIcon from '@mui/icons-material/Close';

import { AiOutlineLineChart } from 'react-icons/ai';
import { HiHome, HiUser } from 'react-icons/hi';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { MdOutlineAddCircleOutline, MdCasino } from 'react-icons/md';

import api from '../api/api';
import { ErrorContext } from '../Routes';
import { Link } from "react-router-dom";

import botbtn1 from '../images/front-page-lottery/front-page.svg';
import botbtn1a from '../images/front-page-lottery/front-page(click).svg';
import botbtn2 from '../images/front-page-lottery/Activity.svg';
import botbtn2a from '../images/front-page-lottery/Activity(click).svg';
import botbtn3 from '../images/front-page-lottery/recharge.svg';
import botbtn3a from '../images/front-page-lottery/recharge(click).svg';
// import botbtn4 from '../images/front-page-lottery/customer-service.png';
// import botbtn4a from '../images/front-page-lottery/customer-service(click).png';
import botbtn4 from '../images/my-personal-center/tg.svg';
import botbtn4a from '../images/my-personal-center/tg.svg';
import botbtn5 from '../images/front-page-lottery/my.svg';
import botbtn5a from '../images/front-page-lottery/my(click).svg';

const mobileNavItems = [
  {
    key: 1,
    icon: botbtn1,
    active:botbtn1a,
    name: "首頁",
    link: "/index",
    value:'index'
  },
  {
    key: 2,
    icon: botbtn2,
    active:botbtn2a,
    name: "活動",
    link: "/activity",
    value:'activity'
  },
  {
    key: 3,
    icon: botbtn3,
    active:botbtn3a,
    name: "充值",
    link: "/charge",
    value:'charge'
  },
  {
    key: 4,
    icon: botbtn4,
    active:botbtn4a,
    name: "客服",
    link: "/traders",
    value:'onlineCustomerService'
  },
  {
    key: 5,
    icon: botbtn5,
    active:botbtn5a,
    name: "我的",
    link: "/profile",
    value:'profile'
  },
]



const ＭobileNav = () => {
  const [csPop , setCsPop] = useState(false)
  const [csPhone , setcsPhone] = useState('');

  const { ErrorMessage } = useContext(ErrorContext);
  const CS_URL = `/cs?language=${localStorage.getItem('language')}`;

  const goCS = (index) =>{
    // window.location = `${index}`
    window.open(`${index}`, "_blank");
  }
  // 拿客服電話
  const getCSphone = async () => {
    try {
      const res = await api.get(CS_URL, {
        headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Content-Type": "application/json",
        },
      });
      if (res.data.status) {
        console.log(res.data)
        setcsPhone(res.data.data)
      }
    } catch (err) {
      console.log(err);
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    }
  };

  useEffect(()=>{
    getCSphone()
  },[])

  const payOrder = async(e) => {
    e.preventDefault();
    var urlencoded = new URLSearchParams();
    urlencoded.append("redirect_url", window.location.href);
    try {
      const res = await api.post(`/pay-orders/launcher/pms`, urlencoded, {
        headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (res.data.status) {
        // console.log(res.data)
        window.location.href = res.data.data
      }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg , 'getPayOrderLauncher')
    }

  }
  return (
    <>
      {
        csPop &&
        <div className='ruleWrap'>
            <div className='ruleDes' style={{ height : '25vh' }}>
                <div className='ruleClose'
                onClick={() => {
                    // handleBoxSwitch('rule');
                    setCsPop(false)
                  }}
                >
                <CloseIcon/>
                </div>
                <div className='ruleScrollBx' >
                  {
                    csPhone.map((v)=>{
                      return(
                        <Link onClick={()=>goCS(v.phone)} 
                        style={{
                          "font-size":'18px',
                          "paddingBottom" :'10px',
                          "display":'block'
                        }}>
                          <div>{v.name}</div>
                        </Link>
                      )
                    })
                  }
                </div>
            </div>
        </div>
      }
      <div className='mobileBtmNav'>
        <div className="navItems d-flex">
          {mobileNavItems.map((v) => {
            return (
              // <>
                v.name ==='充值' ?
                <div key={v.name} className="item">
                  <NavLink onClick={(e)=>{
                    payOrder(e)
                  }} className="icon">
                    <div className='imgBx'>
                      <img src={v.icon}></img>
                      <img src={v.icon}></img>
                    </div>
                    <div className='text'>
                      <FormattedMessage
                          id={`nav.${v.value}`}
                          defaultMessage={'123'}
                      ></FormattedMessage>
                    </div>
                  </NavLink>
                </div>
                :
                <>
                  {
                    v.value ==='onlineCustomerService' ?
                    <div key={v.name} className="item">
                      <NavLink onClick={(e)=>{
                          setCsPop(true)
                        }} className="icon">
                        <div className='imgBx'>
                          <img src={v.icon}></img>
                          <img src={v.active}></img>
                        </div>
                        <div className='text'>
                          <FormattedMessage
                              id={`nav.${v.value}`}
                              defaultMessage={'123'}
                          ></FormattedMessage>
                        </div>
                      </NavLink>
                    </div>:
                    <div key={v.name} className="item">
                    <NavLink to={v.link} className="icon">
                      <div className='imgBx'>
                        <img src={v.icon}></img>
                        <img src={v.active}></img>
                      </div>
                      <div className='text'>
                        <FormattedMessage
                            id={`nav.${v.value}`}
                            defaultMessage={'123'}
                        ></FormattedMessage>
                      </div>
                    </NavLink>
                    </div>
                  }
                </>
              
              // </>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default ＭobileNav