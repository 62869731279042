import React from 'react'

// import $ from "jquery"
// import jQuery from "jquery"
// import * as PIXI from "pixi.js";
// import gsap from "gsap";
// import { CustomEase } from "gsap/CustomEase";
// import { TimelineMax, Power0,Power3,Power1 } from "gsap/all";
// import ribbon from './images/ribbon.png'; 
import CelebrationIcon from '@mui/icons-material/Celebration';




const CarVideo = ({GameID}) => {
    return (
        <div className="vedioWrap">
            {/* <div id="tar"></div> */}
            {
                GameID === '1' && 
                <iframe id="carVideo" src='../../../car.html'></iframe>
            }
             {
                GameID === '113' && 
                <iframe id="carVideo" src='../../../car2.html'></iframe>
            }
             {
                GameID === '114' && 
                <iframe id="carVideo" src='../../../car5.html'></iframe>
            }
            
        </div>
       

    )
}


export default CarVideo
