import React, { useEffect , useState} from 'react'
import '../GamePlace.scss';
import moment from "moment";
import { FormattedMessage , useIntl } from "react-intl";




const GameDraw = ({rankID , gameDirection , drawList , drawLoading , spDrawList , spDrawLoading , getSpDraw , gameModelValue , drawLenValue}) => {
    const [nowDrawArray , setNowDrawArray] = useState([])
    const [select0 , setSelect0] = useState([])
    const [select1 , setSelect1] = useState([])
    const [select2 , setSelect2] = useState([])
    const [select3 , setSelect3] = useState([])
    const [select4 , setSelect4] = useState([])
    const [select5 , setSelect5] = useState([])
    const [select6 , setSelect6] = useState([])
    const [select7 , setSelect7] = useState([])
    const [select8 , setSelect8] = useState([])
    const [select9 , setSelect9] = useState([])
    const [nowClickNum , setNowClickNum] = useState([])
    //物件篩選
    const filterSpDrawList = () =>{
        if(spDrawList.data){
            if(spDrawList.data.response.result){
                Object.values(JSON.parse(spDrawList.data.response.result)).map((v)=>{
                    if(Array.isArray(v)){
                        v.map((ele)=>{
                            setNowDrawArray(prev => [...prev , ele])
                        })
                    } else {
                        setNowDrawArray(prev => [...prev , v])
                    }
                })
            }
        }
    }
    // len = 第幾位數 num = 等於幾
    const selectNum = (len , num , set , state) =>{ 
        nowDrawArray.map((v)=>{
            const numStr = v.toString();
            const endsWithOne = numStr[numStr.length - len] === `${num}`;
            if (endsWithOne) {
                let newValue;
                if(len === 2){
                    newValue = v.substr(-1);
                } else if (len === 3){
                    newValue = v.substr(-2);
                } else if (len === 4){
                    newValue = v.substr(-3);
                }

                if(!state.includes(newValue)){
                    set(prev => [...prev , newValue]);
                }
            }
            return null;
        })
    };
    const onNumClick = (num) => {
        setNowClickNum([])
        switch (num) {
            case 0:
                Array.from(new Set(select0)).map((v)=>{
                    return  setNowClickNum(prev => [...prev , `0${v}`]) 
                })
                break;
            case 1:
                Array.from(new Set(select1)).map((v)=>{
                    return  setNowClickNum(prev => [...prev , `1${v}`]) 
                })
              break;
            case 2:
                Array.from(new Set(select2)).map((v)=>{
                    return  setNowClickNum(prev => [...prev , `2${v}`]) 
                })
                break;
            case 3:
                Array.from(new Set(select3)).map((v)=>{
                    return  setNowClickNum(prev => [...prev , `3${v}`]) 
                })
              break;
            case 4:
                Array.from(new Set(select4)).map((v)=>{
                    return  setNowClickNum(prev => [...prev , `4${v}`]) 
                })
              break;
            case 5:
                Array.from(new Set(select5)).map((v)=>{
                    return  setNowClickNum(prev => [...prev , `5${v}`]) 
                })
              break;
            case 6:
                Array.from(new Set(select6)).map((v)=>{
                    return  setNowClickNum(prev => [...prev , `6${v}`]) 
                })
                break;
            case 7:
                Array.from(new Set(select7)).map((v)=>{
                    return  setNowClickNum(prev => [...prev , `7${v}`]) 
                })
              break;
            case 8:
                Array.from(new Set(select8)).map((v)=>{
                    return  setNowClickNum(prev => [...prev , `8${v}`]) 
                })
              break;
            case 9:
                Array.from(new Set(select9)).map((v)=>{
                    return  setNowClickNum(prev => [...prev , `9${v}`]) 
                })
              break;
            default:
        }
        
    }
    useEffect(()=>{
        if(nowDrawArray.length > 1){
            for (let i = 0; i < 10; i++) {
                switch (i) {
                    case 0:
                        // console.log(1)
                      selectNum(drawLenValue, i , setSelect0 , select0)
                      break;
                    case 1:
                        // console.log(1)
                      selectNum(drawLenValue, i , setSelect1 , select1)
                      break;
                    case 2:
                      selectNum(drawLenValue, i , setSelect2 , select2)
                      break;
                    case 3:
                      selectNum(drawLenValue, i , setSelect3 , select3)
                      break;
                    case 4:
                      selectNum(drawLenValue, i , setSelect4 , select4)
                      break;
                    case 5:
                      selectNum(drawLenValue, i , setSelect5 , select5)
                      break;
                    case 6:
                      selectNum(drawLenValue, i , setSelect6 , select6)
                      break;
                    case 7:
                      selectNum(drawLenValue, i , setSelect7 , select7)
                      break;
                    case 8:
                      selectNum(drawLenValue, i , setSelect8 , select8)
                      break;
                    case 9:
                      selectNum(drawLenValue, i , setSelect9 , select9)
                      break;
                    default:
                }
                
            }
        }
        
    },[nowDrawArray])
    // 特獎
    const judgeSP = (v) => {
        switch (gameDirection) {
            case 'north':
                if (rankID == '2d_head_tail') {
                    return <div>{v.slice(0,-2)}<span data-active={nowClickNum.includes(v.slice(-2))}>{v.slice(-2)}</span></div>;
                } else if (rankID == '2d_tail') {
                    return <div>{v.slice(0,-2)}<span data-active={nowClickNum.includes(v.slice(-2))}>{v.slice(-2)}</span></div>;
                } else if (rankID == '2d_tail_contrary') {
                    return <div>{v.slice(0,-2)}<span data-active={nowClickNum.includes(v.slice(-2))}>{v.slice(-2)}</span></div>;
                } else if (rankID == '2d_group') {
                    return <div>{v.slice(0,-2)}<span data-active={nowClickNum.includes(v.slice(-2))}>{v.slice(-2)}</span></div>;
                } else if (rankID == '3d_tail') {
                    return <div>{v.slice(0,-3)}<span data-active={nowClickNum.includes(v.slice(-3))}>{v.slice(-3)}</span></div>;
                } else if (rankID == '3d_head_tail') {
                    return <div>{v.slice(0,-3)}<span data-active={nowClickNum.includes(v.slice(-3))}>{v.slice(-3)}</span></div>;
                } else if (rankID == '3d_group') {
                    return <div>{v.slice(0,-3)}<span data-active={nowClickNum.includes(v.slice(-3))}>{v.slice(-3)}</span></div>;
                } else if (rankID == '4d_tail') {
                    return <div>{v.slice(0,-4)}<span data-active={nowClickNum.includes(v.slice(-4))}>{v.slice(-4)}</span></div>;
                } else if (rankID == '4d_fixed_tail') {
                    return <div>{v.slice(0,-4)}<span data-active={nowClickNum.includes(v.slice(-4))}>{v.slice(-4)}</span></div>;
                } else if (rankID == '4d_group') {
                    return <div>{v.slice(0,-4)}<span data-active={nowClickNum.includes(v.slice(-4))}>{v.slice(-4)}</span></div>;
                } else if(drawLenValue === 2){
                    return <div>{v.slice(0,-2)}<i data-active={nowClickNum.includes(v.slice(-2))}>{v.slice(-2)}</i></div>;
                } else if(drawLenValue === 3){
                    return <div>{v.slice(0,-3)}<i data-active={nowClickNum.includes(v.slice(-3))}>{v.slice(-3)}</i></div>;
                } else if(drawLenValue === 4){
                    return <div>{v.slice(0,-4)}<i data-active={nowClickNum.includes(v.slice(-4))}>{v.slice(-4)}</i></div>;
                } 
                else{
                    return  <div>{v}</div>
                }
                break;
            case 'south':
            case 'middle':
                if (rankID == '2d_head_tail') {
                    return <div>{v.slice(0,-2)}<span data-active={nowClickNum.includes(v.slice(-2))}>{v.slice(-2)}</span></div>;
                } else if (rankID == '2d_tail') {
                    return <div>{v.slice(0,-2)}<span data-active={nowClickNum.includes(v.slice(-2))}>{v.slice(-2)}</span></div>;
                } else if (rankID == '2d_tail_contrary') {
                    return <div>{v.slice(0,-2)}<span data-active={nowClickNum.includes(v.slice(-2))}>{v.slice(-2)}</span></div>;
                } else if (rankID == '2d_group') {
                    return <div>{v.slice(0,-2)}<span data-active={nowClickNum.includes(v.slice(-2))}>{v.slice(-2)}</span></div>;
                } else if (rankID == '3d_tail') {
                    return <div>{v.slice(0,-3)}<span data-active={nowClickNum.includes(v.slice(-3))}>{v.slice(-3)}</span></div>;
                } else if (rankID == '4d_tail') {
                    return <div>{v.slice(0,-4)}<span data-active={nowClickNum.includes(v.slice(-4))}>{v.slice(-4)}</span></div>;
                } else if (rankID == '3d_group') {
                    return <div>{v.slice(0,-3)}<span data-active={nowClickNum.includes(v.slice(-3))}>{v.slice(-3)}</span></div>;
                } else if (rankID == '4d_group') {
                    return <div>{v.slice(0,-4)}<span data-active={nowClickNum.includes(v.slice(-4))}>{v.slice(-4)}</span></div>;
                } else if (rankID == '3d_head_tail') {
                    return <div>{v.slice(0,-3)}<span data-active={nowClickNum.includes(v.slice(-3))}>{v.slice(-3)}</span></div>;
                } else if (rankID == '4d_head_tail') {
                    return <div>{v.slice(0,-4)}<span data-active={nowClickNum.includes(v.slice(-4))}>{v.slice(-4)}</span></div>;
                } else if(drawLenValue === 2){
                    return <div>{v.slice(0,-2)}<i data-active={nowClickNum.includes(v.slice(-2))}>{v.slice(-2)}</i></div>;
                } else if(drawLenValue === 3){
                    return <div>{v.slice(0,-3)}<i data-active={nowClickNum.includes(v.slice(-3))}>{v.slice(-3)}</i></div>;
                } else if(drawLenValue === 4){
                    return <div>{v.slice(0,-4)}<i data-active={nowClickNum.includes(v.slice(-4))}>{v.slice(-4)}</i></div>;
                } else{
                    return  <div>{v}</div>
                }
                break;
            default:
        }
    }
    // 一獎
    const judgeFP = (v) => {
        switch (gameDirection) {
            case 'north':
                if (rankID == '2d_first_prize') {
                    return <div>{v.slice(0,-2)}<span data-active={nowClickNum.includes(v.slice(-2))}>{v.slice(-2)}</span></div>;
                } else if (rankID == '2d_group') {
                    return <div>{v.slice(0,-2)}<span data-active={nowClickNum.includes(v.slice(-2))}>{v.slice(-2)}</span></div>;
                } else if (rankID == '3d_first_prize') {
                    return <div>{v.slice(0,-3)}<span data-active={nowClickNum.includes(v.slice(-3))}>{v.slice(-3)}</span></div>;
                } else if (rankID == '3d_group') {
                    return <div>{v.slice(0,-3)}<span data-active={nowClickNum.includes(v.slice(-3))}>{v.slice(-3)}</span></div>;
                } else if (rankID == '4d_first_prize') {
                    return <div>{v.slice(0,-4)}<span data-active={nowClickNum.includes(v.slice(-4))}>{v.slice(-4)}</span></div>;
                } else if (rankID == '4d_group') {
                    return <div>{v.slice(0,-4)}<span data-active={nowClickNum.includes(v.slice(-4))}>{v.slice(-4)}</span></div>;
                } else if(drawLenValue === 2){
                    return <div>{v.slice(0,-2)}<i data-active={nowClickNum.includes(v.slice(-2))}>{v.slice(-2)}</i></div>;
                } else if(drawLenValue === 3){
                    return <div>{v.slice(0,-3)}<i data-active={nowClickNum.includes(v.slice(-3))}>{v.slice(-3)}</i></div>;
                } else if(drawLenValue === 4){
                    return <div>{v.slice(0,-4)}<i data-active={nowClickNum.includes(v.slice(-4))}>{v.slice(-4)}</i></div>;
                } else{
                    return  <div>{v}</div>
                }
                break;
            case 'south':
            case 'middle':
                if (rankID == '2d_first_prize') {
                    return <div>{v.slice(0,-2)}<span data-active={nowClickNum.includes(v.slice(-2))}>{v.slice(-2)}</span></div>;
                } else if (rankID == '2d_group') {
                    return <div>{v.slice(0,-2)}<span data-active={nowClickNum.includes(v.slice(-2))}>{v.slice(-2)}</span></div>;
                } else if (rankID == '2d_group_seven') {
                    return <div>{v.slice(0,-2)}<span data-active={nowClickNum.includes(v.slice(-2))}>{v.slice(-2)}</span></div>;
                } else if (rankID == '3d_first_prize') {
                    return <div>{v.slice(0,-3)}<span data-active={nowClickNum.includes(v.slice(-3))}>{v.slice(-3)}</span></div>;
                } else if (rankID == '3d_group') {
                    return <div>{v.slice(0,-3)}<span data-active={nowClickNum.includes(v.slice(-3))}>{v.slice(-3)}</span></div>;
                } else if (rankID == '3d_group_seven') {
                    return <div>{v.slice(0,-3)}<span data-active={nowClickNum.includes(v.slice(-3))}>{v.slice(-3)}</span></div>;
                } else if (rankID == '4d_first_prize') {
                    return <div>{v.slice(0,-4)}<span data-active={nowClickNum.includes(v.slice(-4))}>{v.slice(-4)}</span></div>;
                } else if (rankID == '4d_group') {
                    return <div>{v.slice(0,-4)}<span data-active={nowClickNum.includes(v.slice(-4))}>{v.slice(-4)}</span></div>;
                } else if(drawLenValue === 2){
                    return <div>{v.slice(0,-2)}<i data-active={nowClickNum.includes(v.slice(-2))}>{v.slice(-2)}</i></div>;
                } else if(drawLenValue === 3){
                    return <div>{v.slice(0,-3)}<i data-active={nowClickNum.includes(v.slice(-3))}>{v.slice(-3)}</i></div>;
                } else if(drawLenValue === 4){
                    return <div>{v.slice(0,-4)}<i data-active={nowClickNum.includes(v.slice(-4))}>{v.slice(-4)}</i></div>;
                } else{
                    return  <div>{v}</div>
                }
                break;
            default:
        }
    }
    // 二獎
    const judgeSECP = (v) => {
        switch (gameDirection) {
            case 'north':
                if (rankID == '2d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '3d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<span data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '4d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -4)}<span data-active={nowClickNum.includes(part.slice(-4))}>{part.slice(-4)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 2){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<i data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 3){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<i data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 4){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -4)}<i data-active={nowClickNum.includes(part.slice(-4))}>{part.slice(-4)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else{
                    return  <div>{v}</div>
                }
                break;
            case 'south':
            case 'middle':
                if (rankID == '2d_group') {
                    return <div>{v.slice(0,-2)}<span data-active={nowClickNum.includes(v.slice(-2))}>{v.slice(-2)}</span></div>;
                } else if (rankID == '2d_group_seven') {
                    return <div>{v.slice(0,-2)}<span data-active={nowClickNum.includes(v.slice(-2))}>{v.slice(-2)}</span></div>;
                } else if (rankID == '3d_group') {
                    return <div>{v.slice(0,-3)}<span data-active={nowClickNum.includes(v.slice(-3))}>{v.slice(-3)}</span></div>;
                } else if (rankID == '3d_group_seven') {
                    return <div>{v.slice(0,-3)}<span data-active={nowClickNum.includes(v.slice(-3))}>{v.slice(-3)}</span></div>;
                } else if (rankID == '4d_group') {
                    return <div>{v.slice(0,-4)}<span data-active={nowClickNum.includes(v.slice(-4))}>{v.slice(-4)}</span></div>;
                } else if(drawLenValue === 2){
                    return <div>{v.slice(0,-2)}<i data-active={nowClickNum.includes(v.slice(-2))}>{v.slice(-2)}</i></div>;
                } else if(drawLenValue === 3){
                    return <div>{v.slice(0,-3)}<i data-active={nowClickNum.includes(v.slice(-3))}>{v.slice(-3)}</i></div>;
                } else if(drawLenValue === 4){
                    return <div>{v.slice(0,-4)}<i data-active={nowClickNum.includes(v.slice(-4))}>{v.slice(-4)}</i></div>;
                } else{
                    return  <div>{v}</div>
                }
                break;
            default:
        }
    }
    // 三獎
    const judgeTHIRDP = (v) => {
        switch (gameDirection) {
            case 'north':
                if (rankID == '2d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '3d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<span data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '4d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -4)}<span data-active={nowClickNum.includes(part.slice(-4))}>{part.slice(-4)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 2){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<i data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 3){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<i data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 4){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -4)}<i data-active={nowClickNum.includes(part.slice(-4))}>{part.slice(-4)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                }else{
                    return  <div>{v}</div>
                }
                break;
            case 'south':
            case 'middle':
                if (rankID == '2d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '2d_group_seven') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '3d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<span>{part.slice(-3)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '3d_group_seven') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<span data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '4d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -4)}<span data-active={nowClickNum.includes(part.slice(-4))}>{part.slice(-4)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 2){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<i data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 3){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<i data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 4){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -4)}<i data-active={nowClickNum.includes(part.slice(-4))}>{part.slice(-4)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                }else{
                    return  <div>{v}</div>
                }
                break;
            default:
        }
    }
    // 四獎
    const judgeFOURP = (v) => {
        switch (gameDirection) {
            case 'north':
                if (rankID == '2d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '3d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<span data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '4d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -4)}<span data-active={nowClickNum.includes(part.slice(-4))}>{part.slice(-4)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 2){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<i data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 3){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<i data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 4){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -4)}<i data-active={nowClickNum.includes(part.slice(-4))}>{part.slice(-4)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                }else{
                    return  <div>{v}</div>
                }
                break;
            case 'south':
            case 'middle':
                if (rankID == '2d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '2d_group_seven') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '3d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<span data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '3d_group_seven') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<span data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '4d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -4)}<span data-active={nowClickNum.includes(part.slice(-4))}>{part.slice(-4)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 2){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<i data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 3){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<i data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 4){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -4)}<i data-active={nowClickNum.includes(part.slice(-4))}>{part.slice(-4)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                }else{
                    return  <div>{v}</div>
                }
                break;
            default:
        }
    }
    // 五獎
    const judgeFIVEP = (v) => {
        switch (gameDirection) {
            case 'north':
                if (rankID == '2d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '3d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<span data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '4d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -4)}<span data-active={nowClickNum.includes(part.slice(-4))}>{part.slice(-4)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 2){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<i data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 3){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<i data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 4){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -4)}<i data-active={nowClickNum.includes(part.slice(-4))}>{part.slice(-4)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                }else{
                    return  <div>{v}</div>
                }
                break;
            case 'south':
            case 'middle':
                if (rankID == '2d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '2d_group_seven') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '3d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<span data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '3d_group_seven') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<span data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '4d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -4)}<span data-active={nowClickNum.includes(part.slice(-4))}>{part.slice(-4)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 2){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<i data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 3){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<i data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 4){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -4)}<i data-active={nowClickNum.includes(part.slice(-4))}>{part.slice(-4)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                }else{
                    return  <div>{v}</div>
                }
                break;
            default:
        }
    }
    // 六獎
    const judgeSIXP = (v) => {
        switch (gameDirection) {
            case 'north':
                if (rankID == '2d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '3d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<span data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '3d_head') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<span data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '3d_head_tail') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<span data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 2){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<i data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 3){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<i data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 4){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -4)}<i data-active={nowClickNum.includes(part.slice(-4))}>{part.slice(-4)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                }else{
                    return  <div>{v}</div>
                }
                break;
            case 'south':
            case 'middle':
                if (rankID == '2d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '2d_group_seven') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '3d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<span data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '3d_group_seven') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<span data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '4d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -4)}<span data-active={nowClickNum.includes(part.slice(-4))}>{part.slice(-4)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 2){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<i data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 3){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<i data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 4){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -4)}<i data-active={nowClickNum.includes(part.slice(-4))}>{part.slice(-4)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                }else{
                    return  <div>{v}</div>
                }
                break;
            default:
        }
    }
    // 七獎
    const judgeSEVENP = (v) => {
        switch (gameDirection) {
            case 'north':
                if (rankID == '2d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '2d_head'){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '2d_head_tail'){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 2){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<i data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 3){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<i data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 4){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -4)}<i data-active={nowClickNum.includes(part.slice(-4))}>{part.slice(-4)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                }else{
                    return  <div>{v}</div>
                }
                break;
            case 'south':
            case 'middle':
                if (rankID == '2d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '2d_group_seven') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '3d_head') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<span data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '3d_head_tail') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<span data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '3d_fixed_head') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<span data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '3d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<span data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '3d_group_seven') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<span data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 2){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<i data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 3){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<i data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 4){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -4)}<i data-active={nowClickNum.includes(part.slice(-4))}>{part.slice(-4)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                }else{
                    return  <div>{v}</div>
                }
                break;
            default:
        }
    }
    // 八獎
    const judgeEIGHTP = (v) => {
        switch (gameDirection) {
            case 'north':
                if (rankID == '2d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '2d_head'){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '2d_head_tail'){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else{
                    return  <div>{v}</div>
                }
                break;
            case 'south':
            case 'middle':
                if (rankID == '2d_group') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '2d_head') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '2d_head_tail') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if (rankID == '2d_fixed_head') {
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<span data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</span></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 2){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -2)}<i data-active={nowClickNum.includes(part.slice(-2))}>{part.slice(-2)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 3){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -3)}<i data-active={nowClickNum.includes(part.slice(-3))}>{part.slice(-3)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                } else if(drawLenValue === 4){
                    const modifiedText = v.split('-').map((part, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && '-'}
                          {<div>{part.slice(0, -4)}<i data-active={nowClickNum.includes(part.slice(-4))}>{part.slice(-4)}</i></div>}
                        </React.Fragment>
                    ));
                    return modifiedText;
                }else{
                    return  <div>{v}</div>
                }
                break;
            default:
        }
    }

    useEffect(()=>{
        setNowDrawArray([])
        setSelect0([])
        setSelect1([])
        setSelect2([])
        setSelect3([])
        setSelect4([])
        setSelect5([])
        setSelect6([])
        setSelect7([])
        setSelect8([])
        setSelect9([])
        filterSpDrawList()
        setNowClickNum([])
    },[spDrawList,drawLenValue])
    return (

        <div className="gameDrawWrap">
            <div className='flex-bx'>
                {
                    spDrawLoading ||
                    spDrawList.data.previous_issue_no !== "" &&
                    <button className='prevDraw'
                        onClick={()=>{
                            getSpDraw(encodeURIComponent(spDrawList.data.previous_issue_no))
                        }}
                    ></button>
                }
                
                <div className='text'>
                    {
                        spDrawLoading ||
                        spDrawList.data.response.issue_no
                    }
                    {/* <FormattedMessage
                        id={`trader.issue`}
                    />  */}
                </div>
                {
                    spDrawLoading ||
                    spDrawList.data.next_issue_no !== "" &&
                    <button className='nextDraw'
                    onClick={()=>{
                        getSpDraw(encodeURIComponent(spDrawList.data.next_issue_no))
                    }}
                    ></button>
                }
            </div>
            <div className='flex'>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FormattedMessage
                                    id={`vLottery.drawSpecialPrize`}
                                />
                            </td>
                            <td>
                                
                                {
                                    spDrawLoading ||
                                    <>
                                        {
                                        // JSON.parse(spDrawList.data.response.result).special_prize && 
                                        spDrawList.data.response.result != "" &&
                                        judgeSP(JSON.parse(spDrawList.data.response.result).special_prize)}
                                    </>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <FormattedMessage
                                    id={`vLottery.drawFirstPrize`}
                                />
                            </td>
                            <td>
                                {
                                    spDrawLoading ||
                                    <>
                                        {
                                        // console.log(spDrawList.data.response.result)
                                        // JSON.parse(spDrawList.data.response.result).first_prize && 
                                        spDrawList.data.response.result !== "" &&
                                        judgeFP(JSON.parse(spDrawList.data.response.result).first_prize)}
                                    </>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <FormattedMessage
                                    id={`vLottery.drawSecondPrize`}
                                />
                            </td>
                            <td 
                            // style={{display:'flex',justifyContent:'center','flexWrap':'wrap'}}
                            >
                                {/* {judgeSECP('12345-12345')} */}
                                {
                                    spDrawLoading ||
                                    <>
                                        {
                                            // JSON.parse(spDrawList.data.response.result).second_prize && 
                                            spDrawList.data.response.result !== "" &&
                                            <>
                                                {
                                                    Array.isArray(JSON.parse(spDrawList.data.response.result).second_prize) ?
                                                    judgeSECP(JSON.parse(spDrawList.data.response.result).second_prize.join('-'))
                                                    :
                                                    judgeSECP(JSON.parse(spDrawList.data.response.result).second_prize)
                                                }
                                            </>
                                    
                                        }
                                    </>
                                }
                                {/* numbers.map(String).join('') */}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <FormattedMessage
                                    id={`vLottery.drawThirdPrize`}
                                />
                            </td>
                            <td 
                            // style={{display:'flex',justifyContent:'center','flexWrap':'wrap'}}
                            >
                                {/* {judgeTHIRDP(testText4)} */}
                                {
                                    spDrawLoading ||
                                    <>
                                        {
                                            // JSON.parse(spDrawList.data.response.result).third_prize && 
                                            spDrawList.data.response.result !== "" &&
                                            judgeTHIRDP(JSON.parse(spDrawList.data.response.result).third_prize.join('-'))
                                        }
                                    </>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <FormattedMessage
                                    id={`vLottery.drawFourthPrize`}
                                />
                            </td>
                            <td 
                            // style={{display:'flex',justifyContent:'center','flexWrap':'wrap'}}
                            >
                                {
                                    spDrawLoading ||
                                    <>
                                        {
                                            // JSON.parse(spDrawList.data.response.result).fourth_prize &&
                                            spDrawList.data.response.result !== "" &&
                                            judgeFOURP(JSON.parse(spDrawList.data.response.result).fourth_prize.join('-'))
                                        }
                                    </>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <FormattedMessage
                                    id={`vLottery.drawFifthPrize`}
                                />
                            </td>
                            <td 
                            // style={{display:'flex',justifyContent:'center','flexWrap':'wrap'}}
                            >
                                {/* {judgeFIVEP('1234-1234-1234-1234')} */}
                                {
                                    spDrawLoading ||
                                    <>
                                        {
                                            // JSON.parse(spDrawList.data.response.result).fifth_prize &&
                                            spDrawList.data.response.result !== "" && 
                                            <>
                                                {
                                                    Array.isArray(JSON.parse(spDrawList.data.response.result).fifth_prize) ?
                                                    judgeFIVEP(JSON.parse(spDrawList.data.response.result).fifth_prize.join('-'))
                                                    :
                                                    judgeFIVEP(JSON.parse(spDrawList.data.response.result).fifth_prize)
                                                }
                                            </>
                                    
                                        }
                                    </>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <FormattedMessage
                                    id={`vLottery.drawSixthPrize`}
                                />
                            </td>
                            <td 
                            // style={{display:'flex',justifyContent:'center','flexWrap':'wrap'}}
                            >
                                {/* {judgeSIXP('1234-1234-1234')} */}
                                {
                                    spDrawLoading ||
                                    <>
                                        {
                                            // JSON.parse(spDrawList.data.response.result).sixth_prize && 
                                            spDrawList.data.response.result !== "" &&
                                            judgeSIXP(JSON.parse(spDrawList.data.response.result).sixth_prize.join('-'))
                                        }
                                    </>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <FormattedMessage
                                    id={`vLottery.drawSeventhPrize`}
                                />
                            </td>
                            <td 
                            // style={{display:'flex',justifyContent:'center','flexWrap':'wrap'}}
                            >
                                {/* {judgeSEVENP('12-12-12-12')} */}
                                {
                                    spDrawLoading ||
                                    <>
                                        {
                                            // JSON.parse(spDrawList.data.response.result).seventh_prize &&
                                            spDrawList.data.response.result !== "" &&
                                            <>
                                                {
                                                    Array.isArray(JSON.parse(spDrawList.data.response.result).seventh_prize) ?
                                                    judgeSEVENP(JSON.parse(spDrawList.data.response.result).seventh_prize.join('-'))
                                                    :
                                                    judgeSEVENP(JSON.parse(spDrawList.data.response.result).seventh_prize)
                                                }
                                            </>
                                    
                                        }
                                    </>
                                }
                            </td>
                        </tr>
                        {
                            gameDirection !== 'north' &&
                        
                            <tr>
                                <td>
                                    <FormattedMessage
                                        id={`vLottery.drawEighthPrize`}
                                    />
                                </td>
                                <td>
                                    {
                                        spDrawLoading ||
                                        <>
                                            {
                                                // JSON.parse(spDrawList.data.response.result).eighth_prize && 
                                                spDrawList.data.response.result !== "" &&
                                                judgeEIGHTP(JSON.parse(spDrawList.data.response.result).eighth_prize)
                                            }
                                        </>
                                    }
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                <div className='drawIntegrateBx'>
                    <div onClick={()=>onNumClick(0)}>
                        <div>0</div>
                        {/* <div>1,4,7</div> */}
                        <div>
                            {
                                select0.filter((value, index, self) => {
                                    return self.indexOf(value) === index;
                                }).join(',')
                            }
                        </div>
                    </div>
                    <div onClick={()=>onNumClick(1)}>
                        <div>1</div>
                        {/* <div>1,4,7</div> */}
                        <div>
                            {
                                select1.filter((value, index, self) => {
                                    return self.indexOf(value) === index;
                                }).join(',')
                            }
                        </div>
                    </div>
                    <div onClick={()=>onNumClick(2)}>
                        <div>2</div>
                        {/* <div>1,4,7</div>*/}
                        <div>
                            {
                                select2.filter((value, index, self) => {
                                    return self.indexOf(value) === index;
                                }).join(',')
                            }
                        </div>
                    </div>
                    <div onClick={()=>onNumClick(3)}>
                        <div>3</div>
                        {/* <div>1,4,7</div> */}
                        <div>
                            {
                                select3.filter((value, index, self) => {
                                    return self.indexOf(value) === index;
                                }).join(',')
                            }
                        </div>
                    </div>
                    <div onClick={()=>onNumClick(4)}>
                        <div>4</div>
                        {/* <div>1,4,7</div> */}
                        <div>
                            {
                                select4.filter((value, index, self) => {
                                    return self.indexOf(value) === index;
                                }).join(',')
                            }
                        </div>
                    </div>
                    <div onClick={()=>onNumClick(5)}>
                        <div>5</div>
                        {/* <div>1,4,7</div> */}
                        <div>
                            {
                                select5.filter((value, index, self) => {
                                    return self.indexOf(value) === index;
                                }).join(',')
                            }
                        </div>
                    </div>
                    <div onClick={()=>onNumClick(6)}>
                        <div>6</div>
                        {/* <div>1,4,7</div> */}
                        <div>
                            {
                                select6.filter((value, index, self) => {
                                    return self.indexOf(value) === index;
                                }).join(',')
                            }
                        </div>
                    </div>
                    <div onClick={()=>onNumClick(7)}>
                        <div>7</div>
                        {/* <div>1,4,7</div> */}
                        <div>
                            {
                                select7.filter((value, index, self) => {
                                    return self.indexOf(value) === index;
                                }).join(',')
                            }
                        </div>
                    </div>
                    <div onClick={()=>onNumClick(8)}>
                        <div>8</div>
                        {/* <div>1,4,7</div> */}
                        <div>
                            {
                                select8.filter((value, index, self) => {
                                    return self.indexOf(value) === index;
                                }).join(',')
                            }
                        </div>
                    </div>
                    <div onClick={()=>onNumClick(9)}>
                        <div>9</div>
                        {/* <div>1,4,7</div> */}
                        <div>
                            {
                                select9.filter((value, index, self) => {
                                    return self.indexOf(value) === index;
                                }).join(',')
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
      )
}


export default GameDraw
