import React from 'react'
import RegisterForm from '../Component/Register/RegisterForm'


const RegisterPage = () => {
  return (
    <div className="register-bx container d-flex justify-content-center align-items-center ">
      <RegisterForm />
    </div>
  )
}

export default RegisterPage