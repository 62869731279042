import React, { useState , useEffect , useContext} from 'react'
import { NavLink , useNavigate} from "react-router-dom";

import { Carousel } from 'antd';
import 'antd/dist/antd.css';
import './Banner.scss';
import api from '../../api/api';
import { ErrorContext } from '../../Routes';

const contentStyle = {
  height: '100%',
  color: '#fff',
  lineHeight: '54vw', //手機版高度
  textAlign: 'center',
  background: '#364d79',
};

const Banner = () => {
  const { ErrorMessage } = useContext(ErrorContext);
  const navigate = useNavigate();
  const [bannerList , setBannerList] = useState([]);
  const [loading , setLoading] = useState(true);


  useEffect(()=>{
    getMe()
  },[])

  const getMe = async() => {
    setLoading(true)
    try {
      const res = await api.get('/events?banner=1', {
        headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (res.data.status) {
        setBannerList(res.data.data.response)
      }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    } finally {
      setLoading(false)
    }

  }
 

  return (
    <div className='BannerWrap'>
     
      {/* autoplay */}
      {/* <Carousel > */}
        {
          loading === false &&
          <Carousel autoplay>
            {
              bannerList.map((v)=>{
                return(
                  <div key={v.id} onClick={() => {
                    navigate(`/activity/${v.id}`);
                  }}>
                    <a href={v.link}  target="_blank" >
                      <h3 style={contentStyle}>
                        <img className='bannerImg' style={{'objectFit' : 'cover'  }}  src={`${v.image_link}`}></img>
                      </h3>
                    </a>
                  </div>
                )
              })
            }
          </Carousel>
          
        }
      {/* </Carousel> */}
     
    </div>
  )
}

export default Banner


