// import React, { useState , useEffect, useCallback , useRef} from 'react'



const initState = {
    agentInfo:{

    },
    agentGroup : {
      data:{
        data: {
          response: [
            
          ]
        },
        status: false
      }
    },
    agentPl:{
      data:{
        data: {
          agent_dividend: {
            additionalProp1: 0,
          },
          balance: {
            additionalProp1: 0,
          },
          direct_group: [
            {
              account: "string",
              create_at: 0,
              id: "string",
              login_at: 0,
              phone: "string"
            }
          ],
          response: [
            {
              account: "string",
              create_at: 0,
              id: "string",
              login_at: 0,
              phone: "string"
            }
          ]
        },
        status: true
      }
    },
    agentBets:{
      data:{
        data: {
          pagination: {
            page: 0,
            per_page: 0,
            total: 0
          },
          response: [
            {
              account: "string",
              bet_amount: 0,
              game_name: "string",
              game_round_num: 0,
              game_type_name: 0,
              id: "string",
              third_party_game_id: 0,
              win: 0
            }
          ]
        },
        status: true
      }
    }
};


export const AgentReducer = (state = initState, action) => {
    switch (action.type) {
      case "FETCH_AGENTINFO_API":
        return {
          ...state,
          agentInfo : action.payload
        };
      case "FETCH_AGENTGROUP_API":
        return {
          ...state,
          agentGroup : action.payload
        };
      case "CREAT_AGENT__API":
        return state;
      case "FETCH_AGENTPL_API":
        return {
          ...state,
          agentPl : action.payload
        };
      case "FETCH_AGENTBETS_API":
        return {
          ...state,
          agentBets : action.payload
        };

        
      default:
        return state;
    }
};
  
// export { betHistoryReducer };