import React, { useEffect} from 'react'
import './GamePlace.scss';
import { FormattedMessage } from "react-intl";
import api from '../../api/api';
import Swal from 'sweetalert2'



const DragonTiger = ({
    rankID,setRankID,payOrder,laneID,setLaneID,handlePlayButtonClick,Lane2,sizeID,setSizeID,nClickSizeID,setAutoHide,handlePlaceShow,handleTotalClick,nowChip,empty,sumOdds
    }) => {
    const btns = [
        '1pk10','2pk9','3pk8','4pk7','5pk6'
    ]
    const Lane = [
      {
        name:'龍',
        value:'dragon'
      },
      {
        name:'虎',
        value:'tiger'
      },
    ]
    const handleSizeButtonClick = (value , state , set) => {
        if (state.indexOf(value) !== -1) {
            // 選過就刪掉
            const newArray = state.filter((element) => element !== value);
                set(newArray);
          } else {
            set([...state, value]);
          }
    };
    
    

    useEffect(() => {
        handleTotalClick()
    }, [laneID , sizeID ,nowChip]);
    return (

        <div className="gameWrap d-flex">
          <div className="itemWrap sumWrap">
            <h5 className='mt-2'>
              <FormattedMessage
                  id={`game.location`}
              />
            </h5>
            <div className="circleWrap d-flex">
              {btns.map((v) => {
                return (
                  <div key={v} className='circleBx'>
                    <div className="name smallText" key={v}
                        data-active={rankID.includes(v)}
                        onClick={() => {
                        handleSizeButtonClick(v , rankID , setRankID)
                        handlePlaceShow()
                        }}
                    >
                        <i>{v}</i>
                    </div>
                  </div>
                )
              })}
            </div>
            <h5 className='mt-2'>
              <FormattedMessage
                  id={`game.model3`}
              />
            </h5>
            <div className="circleWrap d-flex">
              {Lane.map((v) => {
                return (
                  <div key={v.name} className='circleBx'>
                    <div className="name"
                    data-active={laneID.includes(v.value)}
                    onClick={() => {
                    handlePlayButtonClick(v.value,laneID,setLaneID)
                    handlePlaceShow()
                    }}
                    >
                        <i>
                          <FormattedMessage
                              id={`game.${v.value}`}
                          />
                        </i>
                    </div>
                  </div>
                  
                )
                console.log(v)
              })}
            </div>
            {/* <h5 className='mt-2'>雙面</h5>
            <div className="circleWrap d-flex">
              {Lane2.map((v) => {
                return (
                  <div key={v} className="name"
                  data-active={sizeID.includes(v)}
                  data-nclick={nClickSizeID.includes(v)}
                  onClick={() => {
                    handlePlayButtonClick(v,sizeID,setSizeID)
                    handlePlaceShow()
                  }}
                  >{v}</div>
                )
              })}
            </div> */}
           
          </div>
        </div>
      )
}


export default DragonTiger
