import React from 'react'
import { Outlet } from "react-router-dom";
import Nav from './Nav';
import MobileNav from './ＭobileNav'

const GameLayout = () => {
  return (
    <>
      <Nav />
      <Outlet />
      <MobileNav />
    </>
  )
}

export default GameLayout