import React from 'react'

import DatePicker, { registerLocale } from "react-datepicker";
import { subDays , addDays } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';

const Datep = ({ startDate , setStartDate , endDate , setEndDate , startRef , endRef}) => {
    const CustomTimeInput = ({ date, onChangeCustom }) => {
        const value =
            date instanceof Date && !isNaN(date)
            ? 
                date.toLocaleTimeString("it-IT")
            : "";
        
        return (
            <input
            type="time"
            step="1"
            value={value}
            onChange={(event) => onChangeCustom(date, event.target.value)}
            />
        );
    };

    const handleChangeTime = (date, time) => {
        const [hh, mm, ss] = time.split(":");
        const targetDate = date instanceof Date && !isNaN(date) ? date : new Date();
        targetDate.setHours(Number(hh) || 0, Number(mm) || 0, Number(ss) || 0);
        setStartDate(targetDate);
    };
    const handleChangeTimeEnd = (date, time) => {
        const [hh, mm, ss] = time.split(":");
        const targetDate = date instanceof Date && !isNaN(date) ? date : new Date();
        targetDate.setHours(Number(hh) || 0, Number(mm) || 0, Number(ss) || 0);
        setEndDate(targetDate);
    };

  return (
    <div className='dataFlex'>
        <DatePicker
            selected={startDate}
            onChange={(date) => {
                setStartDate(date)
                setEndDate()
            }}
            startDate={startDate}
            endDate={endDate}
            maxDate={new Date()}
            ref={startRef}
            showTimeInput
            dateFormat={`${localStorage.getItem("language") === 'vn' ? 'dd/MM/yyyy h:mm:ss aa' : 'MM/dd/yyyy h:mm:ss aa'}`}
            customTimeInput={<CustomTimeInput onChangeCustom={handleChangeTime} />}
        />
        <span className='space'>-</span>
        
        <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={addDays(startDate , 30)}
            ref={endRef}
            showTimeInput
            dateFormat={`${localStorage.getItem("language") === 'vn' ? 'dd/MM/yyyy h:mm:ss aa' : 'MM/dd/yyyy h:mm:ss aa'}`}
            customTimeInput={<CustomTimeInput onChangeCustom={handleChangeTimeEnd} />}
        />
    </div>
  )
}

export default Datep