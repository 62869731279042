import { takeEvery, all, put } from 'redux-saga/effects';
import API from '../../../api/api';
import Swal from 'sweetalert2'
import { tr } from 'date-fns/locale';

function* fetchGameListAPI({ nowPage , perpage , setLoading , setTotalPage , gameType , gameName}) {
  yield setLoading(true);
  const { data } = yield API.get(`/games?type_id=${gameType}&page=${nowPage}&per_page=${perpage}&name=${gameName}&language=${localStorage.getItem("language")}`,{
    headers: {
    "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
    "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
    }
  }); 
  yield put({
    type: 'FETCH_LISTGAMES_API',
    payload: data
  })
  yield setTotalPage(Math.ceil(data.data.pagination.total / perpage))
  yield setLoading(false);
}
function* fetchGameForwardAPI({ gameID , setLoading }) {
    yield setLoading(true);
    const { data } = yield API.post(`/games/${gameID}/forward`,{
      redirect_url: window.location.href
    },{
      headers: {
      "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
      "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
      }
    }); 
    yield put({
      type: 'FETCH_GAMESFORWARD_API',
      payload: data
    })
    yield window.location.href = data.data
    
  }
// 第三方列表

function* fetchThirdPartyGamesAPI({ setThirdPartyLoading }) {
    yield setThirdPartyLoading(true);
    const { data } = yield API.get(`/third_party_games`,{
      headers: {
      "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
      "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
      }
    }); 
    yield put({
      type: 'FETCH_THIRDPARTYGAMES_API',
      payload: data
    })
    yield setThirdPartyLoading(false);
  }
// 遊戲類型列表
function* fetchGameTypesAPI({ setGameTypesLoading }) {
    yield setGameTypesLoading(true);
    const { data } = yield API.get(`/game_types`,{
      headers: {
      "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
      "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
      }
    }); 
    yield put({
      type: 'FETCH_GAMESTYPES_API',
      payload: data
    })
    yield setGameTypesLoading(false);
}
// 遊戲類型列表
function* fetchGameChipsAPI({  }) {
  // yield setGameTypesLoading(true);
  const { data } = yield API.get(`/chips`,{
    headers: {
    "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
    "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
    }
  }); 
  yield put({
    type: 'FETCH_GAMESCHIPS_API',
    payload: data
  })
  // yield setGameTypesLoading(false);
}
function* fetchGameWalletAPI({  }) {
  // yield setLoading(true);
  const { data } = yield API.get(`/games/wallet`,{
    headers: {
    "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
    "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
    }
  }); 
  yield put({
    type: 'FETCH_GAMESWALLET_API',
    payload: data
  })
  // yield setLoading(true);
  
}
function* fetchGameWalletRefreshAPI({}) {
  const { data } = yield API.post(`/games/wallet/refresh`,{},{
    headers: {
    "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
    "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
    }
  }); 
  yield put({
    type: 'FETCH_GAMESWALLETREFRESH_API',
    payload: data
  })
  yield Swal.fire({
    icon: "success",
    showConfirmButton: false,
    timer: 1000,
  });
}
function* fetchGameWalletReturnAPI({ErrorMessage}) {
  try{
    const { data } = yield API.post(`/games/wallet/return`,{},{
      headers: {
      "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
      "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
      }
    }); 
    if(data.status === true){
      yield put({
        type: 'FETCH_GAMESWALLETRETURN_API',
        payload: data
      })
      yield Swal.fire({
        icon: "success",
        showConfirmButton: false,
        timer: 1000,
      });
    }
  }catch (err){
    const code = err.response.status;
    const msg = err.response.data.data.error_msg;
    if(msg.includes("api.too_many_requests")){
        Swal.fire({
          icon: "warning",
          text: "請求過於頻繁",
          showConfirmButton: true,
        });
    } else {
        yield ErrorMessage(code, msg)
    }
}
 
  
}

function* fetchWalletTransferAPI({ selGameID , transferType , amount , ErrorMessage }) {
  try{
    const { data } = yield API.post(`/games/wallet`,{
      "third_party_game_id" : Number(selGameID) , 
      "type" : Number(transferType) , 
      "amount" : Number(amount)
    },{
      headers: {
      "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
      "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
      }
    }); 
    yield put({
      type: 'FETCH_WALLETTRANSFER_API',
      payload: data
    })
    yield Swal.fire({
      text: "轉帳成功",
      showConfirmButton: false,
      timer: 1000,
    });
  }catch (err){
    const code = err.response.status;
    const msg = err.response.data.data.error_msg;
    yield ErrorMessage(code, msg)
  }
}



function* watchFetchGameListAPI() {
  yield takeEvery('SAGA_FETCH_LISTGAMES_API', fetchGameListAPI);
}
function* watchFetchGameForwardAPI() {
  yield takeEvery('SAGA_FETCH_GAMESFORWARD_API', fetchGameForwardAPI);
}
function* watchFetchThirdPartyGamesAPI() {
    yield takeEvery('SAGA_FETCH_THIRDPARTYGAMES_API', fetchThirdPartyGamesAPI);
}
function* watchFetchGameTypesAPI() {
    yield takeEvery('SAGA_FETCH_GAMESTYPES_API', fetchGameTypesAPI);
}
function* watchFetchGameChipsAPI() {
  yield takeEvery('SAGA_FETCH_GAMECHIPS_API', fetchGameChipsAPI);
}
function* watchFetchGameWalletAPI() {
  yield takeEvery('SAGA_FETCH_GAMEWALLET_API', fetchGameWalletAPI);
}
function* watchFetchGameWalletRefreshAPI() {
  yield takeEvery('SAGA_FETCH_GAMEWALLETREFRESH_API', fetchGameWalletRefreshAPI);
}
function* watchFetchGameWalletReturnAPI() {
  yield takeEvery('SAGA_FETCH_GAMEWALLETRETURN_API', fetchGameWalletReturnAPI);
}

function* watchFetchWalletTransferAPI() {
  yield takeEvery('SAGA_FETCH_WALLETTRANSFER_API', fetchWalletTransferAPI);
}


export function* gameSaga() {   
    yield all([
        watchFetchGameListAPI(),
        watchFetchGameForwardAPI(),
        watchFetchThirdPartyGamesAPI(),
        watchFetchGameTypesAPI(),
        watchFetchGameChipsAPI(),
        watchFetchGameWalletAPI(),
        watchFetchGameWalletRefreshAPI(),
        watchFetchWalletTransferAPI(),
        watchFetchGameWalletReturnAPI()
    ]);
}