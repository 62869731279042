// import * as React from 'react';
import React, { useState , useEffect} from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FormattedMessage , useIntl } from "react-intl";

const style = {
  position: 'absolute',
 
 
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'rgba(0,0,0,0.7)',
  border: '2px solid #fff',
  borderRadius:'10px',
//   boxShadow: 'inset 0px 0px 0px 0px #ABABAB, 0px 0px 7px -2px #FFFFFF',
  boxShadow: 24,
  p: '10px 20px 15px 20px',
  boxShadow: '0px 0px 15px 0px rgba(255,255,255,1)',
};
const flexBx = {
    display:'flex',
    flexWrap:'wrap',
    justifyContent: 'space-between',
    width: '100%',
}
const buttonStyle = {
    width:'calc(100% - 1.6rem)',
    margin:'0 auto',
    display:'block',
    color:'#fff',
    boxShadow: 'inset 0px 0px 0px 0px #ABABAB, 0px 0px 7px -2px #FFFFFF',
    border: '1px solid #fff',
};
const smallButtonStyle = {
    width:'46%',
    margin:'0',
    marginBottom:"10px",
    display:'block',
    color:'#fff',
    // boxShadow: 'inset 0px 0px 0px 0px #ABABAB, 0px 0px 7px -2px #FFFFFF',
    boxShadow: '0px 0px 6px 0px rgba(255,255,255,1)',
    border: '1px solid #fff',
};
const titleStyle = {
    color:'#fff',
    textAlign:'center',
    fontSize:'18px',
    marginBottom:"20px",
    marginTop:"10px",
    paddingBottom:'5px',
    borderBottom:'1px solid #fff'
}

export default function BasicModal({rankID , setRankID , model , empty , gameDirection , setGameModelValue}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const smallButtonOnClick = (value) =>{
    empty();
    setRankID([`${model}_${value}`])
    handleClose()
    console.log(model)
  }
//   useEffect(()=>{
//     setRankID([`${model}_head`])
//   })
  return (
    <div className='basicBx'>
      <Button sx={buttonStyle} onClick={handleOpen}>
        {/* {`${rankID}`} */}
        <FormattedMessage
            id={`game.${rankID}`}
        />   
     </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        {
            model !== 'model4' &&
            <>
                <Typography style={titleStyle} id="modal-modal-title" variant="h6" component="h2">
                    <FormattedMessage
                        id={`vLottery.duplex`}
                    />
                </Typography>
                <div style={flexBx}>
                    {
                        model === '2d' &&
                        <Button sx={smallButtonStyle} onClick={()=> smallButtonOnClick('tail_contrary')}>
                            {<FormattedMessage
                                    id={`vLottery.headOpposite`}
                            />}
                        </Button>
                    }
                    {model !== '4d' &&
                    <>
                        {model !== '3d' &&
                            <Button sx={smallButtonStyle} onClick={()=> smallButtonOnClick('head')}>
                                {<FormattedMessage
                                        id={`vLottery.head`}
                                />}
                            </Button>
                        }
                    </>
                    
                    }
                    <Button sx={smallButtonStyle} onClick={()=> smallButtonOnClick('tail')}>
                        {<FormattedMessage
                                id={`vLottery.tail`}
                        />}
                    </Button>
                    
                    {model !== '4d' &&
                        <>
                            {model !== '3d' &&
                                <Button sx={smallButtonStyle} onClick={()=> smallButtonOnClick('head_tail')}>
                                    {<FormattedMessage
                                            id={`vLottery.head_tail`}
                                    />}
                                </Button>
                            }
                        </>
                   
                    }
                    <Button sx={smallButtonStyle} onClick={()=> smallButtonOnClick('first_prize')}>
                        {<FormattedMessage
                                id={`vLottery.firstPrize`}
                        />}
                    </Button>
                    {model !== '4d' &&
                        <Button sx={smallButtonStyle} onClick={()=> smallButtonOnClick('group')}>
                            {<FormattedMessage
                                    id={`vLottery.group`}
                            />}
                        </Button>
                    }
                    {/* || model !== '4d' */}
                    {gameDirection !== 'north' &&
                        <>
                            {model !== '4d' &&
                                <Button sx={smallButtonStyle} onClick={()=> smallButtonOnClick('group_seven')}>
                                    {<FormattedMessage
                                            id={`vLottery.groupSeven`}
                                    />}
                                </Button>
                            }
                        </>
                    }
                    {gameDirection !== 'north' &&
                        <>
                            {model !== '4d' &&
                                <Button sx={smallButtonStyle} onClick={()=> smallButtonOnClick('fixed_head')}>
                                    {<FormattedMessage
                                            id={`vLottery.positionHead`}
                                    />}
                                </Button>
                            }
                        </>
                    }
                    {gameDirection !== 'north' &&
                        <>
                            {model == '4d' &&
                                <Button sx={smallButtonStyle} onClick={()=> smallButtonOnClick('fixed_tail')}>
                                    {<FormattedMessage
                                            id={`vLottery.positionTail`}
                                    />}
                                </Button>
                            }
                        </>
                    }
                    {gameDirection !== 'north' &&
                        <>
                            {model !== '4d'&&
                            <Button sx={smallButtonStyle} onClick={()=> smallButtonOnClick('two_sides_head')}>
                                {<FormattedMessage
                                        id={`vLottery.sizeDouble`}
                                />}
                            </Button>
                            }
                        </>
                    }
                    {gameDirection !== 'north' &&
                        <>
                            {model == '4d' &&
                                <Button sx={smallButtonStyle} onClick={()=> smallButtonOnClick('two_sides_tail')}>
                                    {<FormattedMessage
                                            id={`vLottery.sizeDoubleTail`}
                                    />}
                                </Button>
                            }
                        </>
                    }
                    {/* {gameDirection === 'north' &&
                        <>
                            {model === '4d' &&
                                <Button sx={smallButtonStyle} onClick={()=> smallButtonOnClick('two_sides_tail')}>
                                    {<FormattedMessage
                                            id={`vLottery.sizeDoubleTail`}
                                    />}
                                </Button>
                            }
                        </>
                    } */}
                </div>
            </>
            }
            <Typography style={titleStyle} id="modal-modal-title" variant="h6" component="h2">
                <FormattedMessage
                    id={`vLottery.tradition`}
                />
            </Typography>
            <div style={flexBx}>
                <Button sx={smallButtonStyle} 
                    onClick={()=> {
                        empty()
                        setRankID([`normalFast`]) 
                        if(model !== 'model4'){
                            setGameModelValue('model4')
                        } else{
                            handleClose()
                        }
                        handleClose()
                }}>
                    {<FormattedMessage
                            id={`vLottery.normalFast`}
                    />}
                </Button>
                <Button sx={smallButtonStyle} onClick={()=> {
                     empty()
                    setRankID([`match_2`]) 
                    if(model !== 'model4'){
                        setGameModelValue('model4')
                    } else{
                        handleClose()
                    }
                    handleClose()
                    }}>
                    {<FormattedMessage
                        id={`vLottery.quickClearance`}
                    />}
                </Button>
            </div>
            {/* <Button sx={smallButtonStyle} onClick={handleOpen}>{'定位(頭)'}</Button> */}
        </Box>
      </Modal>
    </div>
  );
}