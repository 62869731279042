import React, { useState , useEffect, useCallback , useRef} from 'react'
import { NavLink , Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useNavigate , useParams} from "react-router-dom";
import { useSelector , useDispatch , Provider} from 'react-redux';
import TraderTrendMap from '../../Component/Trader/traderTrendMap'
import AvatarStatus from './AvatarStatus'
import './Traders.scss'
import ReplyIcon from '@mui/icons-material/Reply';

import { FaUser } from 'react-icons/fa';





const TraderHomePage = () => {
  const { TrderID } = useParams();
  const [loading, setLoading] = useState(true)
  const [boxSwitch, setBoxSwitch] = useState(false)
  const [refresh , setRefresh] = useState(false)
  const navigate = useNavigate();
  //redux
  const dispatch = useDispatch();
  const getMyTrader = useSelector(state => state.trader.getTraderPage)
  const getTrader = useSelector(state => state.trader.getTrader)
  const getTraderWinningPercentage = useSelector(state => state.trader.getTraderWinningPercentage)


  useEffect(() => {
    dispatch({ type: 'SAGA_FETCH_TRADER_API' , setLoading , TrderID});
  }, [dispatch , refresh]);

  // 關注
  const onSubscribe = () =>{
    dispatch({ type: 'SAGA_FETCH_SUBSCRIBE_API' , TrderID , setRefresh , refresh});
  }
  // 取消關注
  const onUNSubscribe = () =>{
    dispatch({ type: 'SAGA_FETCH_UNSUBSCRIBE_API' , TrderID , setRefresh , refresh});
  }
  return (
    <div className="traderHomePage homePage page home-bg mt-0">
        <div className='traderHeader'>
            <button className='backBtn' onClick={()=>{
                  navigate(`/traders`);
              }}
            ><ReplyIcon/></button>
            <div className='title'> 
                <FormattedMessage
                    id={`trader.myHomePage`}
                ></FormattedMessage>
            </div>
            <FaUser onClick={() => {
                navigate(`/traderMyHome`);
            }}/>
        </div>
       <div className='container'>
            { loading ||
                <>
                    <AvatarStatus avaterData={getTrader} onSubscribe={onSubscribe} onUNSubscribe={onUNSubscribe} TrderID={TrderID} getMyTrader={getMyTrader}/>
                    <TraderTrendMap nunberValue={getTraderWinningPercentage.data}/>
                    <ul className='traderDataList mt-5'>
                        <li>
                            <div>
                                <FormattedMessage
                                    id={`trader.totalAssets`}
                                ></FormattedMessage>
                            </div>
                            <div>{getTrader.data.response.balance}</div>
                        </li>
                        <li>
                            <div>
                                <FormattedMessage
                                    id={`trader.totalRevenue`}
                                ></FormattedMessage>
                            </div>
                            <div>{getTrader.data.response.dividend}</div>
                        </li>
                        <li>
                            <div>
                                <FormattedMessage
                                    id={`trader.totalNumberOfOrders`}
                                ></FormattedMessage>
                            </div>
                            <div>{getTrader.data.trade_info.mirror_trading_total_count}</div>
                        </li>
                        <li>
                            <div>
                                <FormattedMessage
                                    id={`trader.numberOfFollowers`}
                                ></FormattedMessage>
                            </div>
                            <div>{getTrader.data.trade_info.subscribe_count}</div>
                        </li>
                        <li>
                            <div>
                                <FormattedMessage
                                    id={`trader.totalProfitFromFollowingOrders`}
                                ></FormattedMessage>
                            </div>
                            <div>{getTrader.data.response.rate_of_return}</div>
                        </li>
                    </ul>
                </>
            }
       </div>
       
       
    </div>
  )
}

export default TraderHomePage
