import React, { useEffect, useCallback , useRef , useState , useContext} from 'react'
import { useSelector , useDispatch } from 'react-redux';
import { FormattedMessage , useIntl } from "react-intl";

import Spinner from '../Spinner/Spinner';
import { HiMailOpen } from 'react-icons/hi';

import api from '../../api/api';
import Swal from 'sweetalert2'
import ReplyIcon from '@mui/icons-material/Reply';
import { ErrorContext } from '../../Routes';



const MessageBinding = ({setBoxSwitch , getData}) => {

    const phoneRef = useRef()
    const phoneVerifRef = useRef()
    const { ErrorMessage } = useContext(ErrorContext);

    const [nowCountry , setNowCountry] = useState() 
    const [countdown, setCountdown] = useState(0);

    const intl = useIntl();

   // 用ref 紀錄 input value
   const usernameRef = useRef()
   const passwordRef = useRef()
  //redux
  const dispatch = useDispatch();
  const agentGroup = useSelector(state => state.agent)


  useEffect(() => {
//   dispatch({ type: 'SAGA_FETCH_AGENTGROUP_API'});
  }, [dispatch]);

  const atBindHandler = (url) =>{
    Swal.fire({
        text: intl.formatMessage({
            id: 'alert.tgnote',
        }),
        icon: "warning",
        confirmButtonColor: "#3085d6",
        confirmButtonText: intl.formatMessage({
            id: 'game.confirm',
        }),
      }).then((result) => {
        if (result.isConfirmed) {
            window.location.href = url
        }
      });
  }
  const atRegisterHandler = async (event) => {
    event.preventDefault();
    try {
        const res = await api.post('/member/tg', {
            account : `@${phoneRef.current.value}`,
        }, {
          headers: {
            "Content-Type": "application/json",
            "x-platform-code": localStorage.getItem("x-platform-code"),
            "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        if (res.data.status) {
            atBindHandler(res.data.data)
        }
      } catch (err) {
        const code = err.response.status
        const msg = err.response.data.data.error
        ErrorMessage(code, msg , 'setPhone')
        console.log(err);
      }
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [countdown]);

  const verPhone = async (e) =>{
    e.preventDefault();
    try {
      const res = await api.post('/verification-msg', {
        type:2,
        phone: phoneRef.current.value,
        country_code: '62',
      }, {
        headers: {
          "Content-Type": "application/json",
          "x-platform-code": localStorage.getItem("x-platform-code"),
        },
      });
      if (res.data.status) {
        console.log(res.data.data)
        Swal.fire({
          icon: "success",
          text: intl.formatMessage({
            id: 'alert.success',
          }),
          showConfirmButton: false,
          timer: 1000,
        });
        if (countdown === 0) {
            setCountdown(5);
        }
      }
    } catch (err) {
        const code = err.response.status
        const msg = err.response.data.data.error
        ErrorMessage(code, msg , 'sendVerificationMsg')
        console.log(err);
    }

    
  }

  return (
    <div className="phoneVerificationMsgWrap bottWrap">
        <div className='titleFlex'>
            <button className='backBtn' onClick={()=>{
                setBoxSwitch(false)
            }}
            ><ReplyIcon/></button>
            
            <div>
              <FormattedMessage
                  id={`profile.messageBinding`}
              ></FormattedMessage>
            </div>
        </div>
        
         <form action="">
            <div className="mb-3">
                <input
                ref={phoneRef}
                type="text"
                required
                placeholder={intl.formatMessage({
                  id: 'profile.enterAccNum',
                })}
               
                />
            </div>
            <button className='registerBtn com-btn' type="submit" onClick={atRegisterHandler}>
              <FormattedMessage
                  id={`profile.binding`}
              ></FormattedMessage>
            </button>
         </form>
        

            
            
    </div>
  )
}

export default MessageBinding