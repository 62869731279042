import React, { useState, useEffect } from "react";
import { FormattedMessage, IntlProvider , useIntl } from "react-intl";
import RoutesBox from "./Routes";


import Swal from 'sweetalert2';
import useSound from 'use-sound';
import boopSfx from './sound/mouse_click.mp3';


export const changLan = ({setLang , lang}) => {
  return <select className="changLanSel" value={lang} onChange={(evt) => {
      setLang(evt.target.value);
      localStorage.setItem("language", evt.target.value);
    }}>
      <option value="vn">Vietnam</option>
      <option value="cn">中文</option>
      <option value="en">English</option>
      <option value="Indonesia">Indonesia</option>
     
    </select>;
};
// export const ErrorContext = createContext();

const App = () => {

  // 語系
  const [lang, setLang] = useState("cn");
  const [locale, setLocale] = useState("");
  const [localLoading , setLocalLoading] = useState(true);

  // const numberFormatter = new Intl.NumberFormat('en');

  const [theme, setTheme] = useState('theme2'); // 目前樣式
  const [themeLoading , setThemeLoading] = useState(true);
  // 導入樣式
  useEffect(() => {
    if (theme === 'theme1') {
      import('./CSS/theme/theme1.scss').then((module) => {
        removeExistingStyle();
        addStyle(module.default);
        setThemeLoading(false)
      });
    } else if (theme === 'theme2') {
      import('./CSS/theme/theme2.scss').then((module) => {
        removeExistingStyle();
        addStyle(module.default);
        setThemeLoading(false)
      });
    } else if (theme === 'theme3') {
      import('./CSS/theme/theme3.scss').then((module) => {
        removeExistingStyle();
        addStyle(module.default);
        setThemeLoading(false)
      });
    }
  }, [theme]);

  const removeExistingStyle = () => {
    const existingStyle = document.getElementById('dynamic-styles');
    if (existingStyle) {
      existingStyle.parentNode.removeChild(existingStyle);
    }
  };

  const addStyle = (css) => {
    const style = document.createElement('style');
    style.id = 'dynamic-styles';
    style.innerHTML = css;
    document.head.appendChild(style);
  };

  useEffect(() => {

    async function fetchData() {
      const resp = await fetch(`./lang/${lang}.json`);
      const data = await resp.json();
      setLocale(data);
      setLocalLoading(false);
    }
    fetchData()

  }, [lang]);


  
  return (
    <>
      {
        localLoading ||
        <IntlProvider locale={'vn'} messages={locale} defaultLocale="vn">
          {
            themeLoading ||
            <RoutesBox lang={lang} setLang={setLang} setLocale={setLocale}></RoutesBox>
          }
        </IntlProvider>
      }
    </>
  );
}

export default App;
