import React, { useEffect, useCallback , useRef , useState} from 'react'
import { useSelector , useDispatch } from 'react-redux';
import Spinner from '../Spinner/Spinner';
import { HiMailOpen } from 'react-icons/hi';
import { useIntl } from "react-intl";

import api from '../../api/api';
import Swal from 'sweetalert2'
import ReplyIcon from '@mui/icons-material/Reply';

const SearchBar = ({setPlatformMemberID}) => {
  const intl = useIntl();


  return (
    <div className="searchWrap">
         <form action="">
            <div className="phoneInput mb-3">
                <input
                type="text"
                required
                // placeholder='會員名稱'
                placeholder={intl.formatMessage({
                  id: 'agent.memberName',
                })}
                onChange={(e)=>{
                    setPlatformMemberID(e.target.value)
                }}
                />
            </div>
         </form>
    </div>
  )
}

export default SearchBar