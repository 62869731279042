import React, { useState , useEffect, useCallback , useRef , useContext} from 'react'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { FormattedMessage } from "react-intl";
import Marquee from "react-fast-marquee";
import './All.scss';
import api from '../../api/api';
import { ErrorContext } from '../../Routes';

import icon1 from '../../images/coin.png';
import icon2 from '../../images/man.png';
import icon3 from '../../images/bell.png';

const gameSel = [
  {
    name : 'Kecepatan pembalap',
    id : 1
  }
]

const MemberWrap = ({betRefresh , setBetRefresh , needRefresh}) => {
  const { ErrorMessage , currencyJudge } = useContext(ErrorContext);

  const [loadingMember, setLoadingMember] = useState(false)
  const [loadingAnn, setLoadingAnn] = useState(false)
  const [loadingRefresh, setLoadingRefresh] = useState(true)
  const [loadingBalance, setLoadingBalance] = useState(true)
  const [nowBalance, setNowBalance] = useState(true)

  const [hideAmount, setHideAmount] = useState(false)
  const [memberDetail , setMemberDetail] = useState()
  const [betAnnouncements , setBetAnnouncements] = useState([])
  const [systemAnnouncements , setSystemAnnouncements] = useState([])
  const [announcement , setAnnouncement] = useState([])


    useEffect(()=>{
      getData()
    },[])
    useEffect(() => {
      if(needRefresh){
        const intervalId = setInterval(() => {
          setBetRefresh(prevBetRefresh => !prevBetRefresh);
        }, 10000);
    
        return () => {
          clearInterval(intervalId); // 在每次重新渲染前取消定時器
        };
      }
    }, []); // 
    useEffect(()=>{
      try{
        setLoadingRefresh(false)
        getMeBalance()
      } finally{
        setLoadingRefresh(true)
      }
    },[betRefresh])
    useEffect(() => {
        // const interval = setInterval(() => {
        //     getAnnAgain();
        // }, 5000);
        // return () => {
        //   clearInterval(interval);
        // };
    }, []);

    const getData = async() =>{
      getMe();
      getAnn();
        // setLoadingMember(false)
        // try{
        //   await Promise.all([getMe(), getAnn()]);
        // } finally{
        //     setLoadingMember(true)
        // }
    }

    // getme
    const getMe = async() => {
      setLoadingMember(false)
        try {
        const res = await api.get('/member', {
            headers: {
            "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
            "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
        });
        if (res.data.status) {
            setMemberDetail(res.data.data)
            localStorage.setItem("accName", res.data.data.response.id);
            localStorage.setItem("isAdmin", res.data.data.response.is_admin);          
        }
        } catch (err) {
        console.log(err)
        const code = err.response.status
        const msg = err.response.data.data.error
        ErrorMessage(code, msg)
        } finally{
          setLoadingMember(true)
        }
    }
    const getMeBalance = async() => {
      try {
      const res = await api.get('/member/balance', {
          headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
          },
      });
      if (res.data.status) {
        // setMemberDetail(...memberDetail , `balanc : ${res.data.data}`)
        setNowBalance(res.data.data);
        setLoadingBalance(false)
          // setMemberDetail(res.data.data)
          // localStorage.setItem("accName", res.data.data.response.id);
      }
      } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
      }
  }

   // 取得公告
   const getAnn = async() => {
    setLoadingAnn(false)
    try {
      const res = await api.get(`/announcements?language=${localStorage.getItem("language")}`, {
        headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (res.data.status) {
        // .. memenangkan hadiah .... pada ...
        res.data.data.bet_announcements.map((v)=>{
          setBetAnnouncements(prevState => [...prevState , `${v.account} memenangkan hadiah ${gameSel.filter((g)=> g.id === v.game_id)[0].name} pada ${v.amount}`])
        })
        setSystemAnnouncements(res.data.data.system_announcements)
        
      }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    } finally {
      setLoadingAnn(true)
    }
   }

   const getAnnAgain = async() => {
    try {
      const res = await api.get('/announcements', {
        headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (res.data.status) {
        setBetAnnouncements([])
        res.data.data.bet_announcements.map((v)=>{
          setBetAnnouncements(prevState => [...prevState , `${v.account} memenangkan hadiah ${gameSel.filter((g)=> g.id === v.game_id)[0].name} pada ${v.amount}`])
        })
        setSystemAnnouncements(res.data.data.system_announcements)
      }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    }
   }

   useEffect(() => {
    setAnnouncement(betAnnouncements.concat(systemAnnouncements))
    }, [betAnnouncements , systemAnnouncements]);

    const payOrder = async(e) => {
    e.preventDefault();
    var urlencoded = new URLSearchParams();
    urlencoded.append("redirect_url", window.location.href);
    try {
      const res = await api.post(`/pay-orders/launcher/pms`, urlencoded, {
        headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (res.data.status) {
        // console.log(res.data)
        window.location.href = res.data.data
      }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg , 'getPayOrderLauncher')
    }

  }


  return (
    <>
      {
        // loadingMember &&
        <>
          <div className='MarqueeBx'>
            <img src={icon3}></img>
            <Marquee autoFill={false} onCycleComplete={()=> getAnnAgain()}>
              
                {
                  loadingAnn && announcement.map((v)=>{
                    return(
                      <div key={v} style={{minWidth:'450px',marginRight:'30px'}}>
                        {
                        v
                        }
                      </div>
                    )
                  })
                }
            </Marquee>
          </div>
          <div className="memberWrap d-flex">
              <div className="left d-flex">
              <span>
                <img src={icon2}></img>
                {/* 會員: */}
                <FormattedMessage
                      id="MemberWrap.member"
                      defaultMessage="Member"
                />{` : `}
                  {
                    loadingMember && memberDetail.response.account
                  }
              </span>
            
              </div>
              <div className="right d-flex">
                <span>
                    <img src={icon1}></img>
                    <FormattedMessage
                          id="MemberWrap.balance"
                          defaultMessage="Member"
                    />:
                    <span className='amount'>
                    {
                        loadingBalance || `${nowBalance} ${currencyJudge()}`
                    }
                    </span>
                </span>
              {/* <span>收益/
                  <span>
                  {hideAmount ?  loadingMember && memberDetail.dividend : "*****"}
                  </span>
              </span> */}
              {/* {hideAmount ?
                  <VisibilityOffIcon
                  onClick={() => {
                      setHideAmount(false)
                  }}
                  /> : <RemoveRedEyeIcon
                  onClick={() => {
                      setHideAmount(true)
                  }}
                  />} */}
              {/* <button
              onClick={(e)=>{
                payOrder(e)
              }}
              >
                <FormattedMessage
                          id="MemberWrap.withdraw"
                          defaultMessage="Member"
                />
              </button> */}
              </div>
          </div>
        </>
        }
    </>
  )
}

export default MemberWrap