import React, { useEffect} from 'react'
import '../GamePlace.scss';
import { FormattedMessage } from "react-intl";

const VLottery2D = ({
    rankID,setRankID,payOrder,Lane,laneID,setLaneID,handlePlayButtonClick,Lane2,sizeID,setSizeID,nClickSizeID,setAutoHide,handlePlaceShow,handleTotalClick,nowChip,empty,laneID2,setLaneID2,Lane3
    }) => {
    const handleSizeButtonClick = (value , state , set) => {
        if (state.indexOf(value) !== -1) {
            // 選過就刪掉
            const newArray = state.filter((element) => element !== value);
            set(newArray);
          } else {
            set([...state, value]);
          }
    };

    useEffect(() => {
        handleTotalClick()
        
    }, [rankID, laneID , laneID2, sizeID ,nowChip]);
    return (

        <div className="markSixWrap gameWrap d-flex">
          <div className="itemWrap model2">
            <h5 className=''>
              <FormattedMessage
                  id={`game.tens`}
              />
            </h5>
            <div className="circleWrap d-flex ">
              {
                <>
                  {
                    rankID == '2d_two_sides_head' ? 
                    Lane3.map((v) => {
                      return (
                        <div className="name" key={v.value}
                          data-active={laneID.includes(v.value)}
                          onClick={() => {
                            handleSizeButtonClick(v.value , laneID , setLaneID)
                            handlePlaceShow()
                          }}
                        >
                          <i>
                            <FormattedMessage
                                  id={`game.${v.value}`}
                            />
                          </i>
                        </div>
                      )
                    })
                    :
                    Lane.map((v) => {
                      return (
                        <div className="name" key={v}
                          data-active={laneID.includes(v)}
                          onClick={() => {
                            handleSizeButtonClick(v , laneID , setLaneID)
                            handlePlaceShow()
                          }}
                        >
                           <i>{v}</i>
                        </div>
                      )
                    })
                  }
                </>
              }
              {/* {Lane.map((v) => {
                return (
                  <div className="name" key={v}
                    data-active={laneID.includes(v)}
                    onClick={() => {
                      handleSizeButtonClick(v , laneID , setLaneID)
                      handlePlaceShow()
                    }}
                  >
                     <i>{v}</i>
                  </div>
                )
              })} */}
              
            </div>
            <h5 className='mt-2'>
              <FormattedMessage
                  id={`game.units`}
              />
            </h5>
            <div className="circleWrap d-flex">
              {
                <>
                  {
                    rankID == '2d_two_sides_head' ? 
                    Lane3.map((v) => {
                      return (
                        <div className="name" key={v.value}
                          data-active={laneID2.includes(v.value)}
                          onClick={() => {
                            handleSizeButtonClick(v.value , laneID2 , setLaneID2)
                            handlePlaceShow()
                          }}
                        >
                          <i>
                            <FormattedMessage
                                  id={`game.${v.value}`}
                            />
                          </i>
                        </div>
                      )
                    })
                    :
                    Lane2.map((v) => {
                      return (
                        <div key={v} className="name"
      
                        data-active={laneID2.includes(v)}
                        onClick={() => {
      
                          handlePlayButtonClick(v, laneID2 ,setLaneID2)
                          handlePlaceShow()
                          
                        }}
                        >
                           <i>{v}</i>
                        </div>
                      )
                    })
                  }
                </>
              }
              {/* {Lane2.map((v) => {
                return (
                  <div key={v} className="name"

                  data-active={laneID2.includes(v)}
                  onClick={() => {

                    handlePlayButtonClick(v, laneID2 ,setLaneID2)
                    handlePlaceShow()
                    
                  }}
                  >
                     <i>{v}</i>
                  </div>
                )
              })} */}
            </div>
           
          </div>
        </div>
      )
}


export default VLottery2D
