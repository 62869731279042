import { useEffect } from 'react';

const FixBx = (fixBxRef , mainRef , loading , loadingMember , fixbarRef , gameModelValue , autoHide , boxSwitch) => {
    useEffect(() => {
      const fixBxElement = fixBxRef.current;
      const mainElement = mainRef.current;
      const leftFixElement = fixbarRef.current;
  
      const handleResize = () => {
        if (fixBxElement && mainElement) {
          setTimeout(()=>{
            const fixBxHeight = fixBxElement.getBoundingClientRect().height;
            mainElement.style.marginTop = `${fixBxHeight}px`;
          },500)
          // const fixBxHeight = fixBxElement.getBoundingClientRect().height;
          // mainElement.style.marginTop = `${fixBxHeight}px`;
        }

        if (leftFixElement && mainElement) {
            const fixBxHeight = fixBxElement.getBoundingClientRect().height;
            leftFixElement.style.top = `${fixBxHeight + 22}px`;
        }
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [loading,loadingMember,fixBxRef, mainRef , gameModelValue , autoHide , boxSwitch]);
};



export default FixBx;