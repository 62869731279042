import { takeEvery, all, put } from 'redux-saga/effects';
import API from '../../../api/api';

function* fetchBetHistoryAPI({ nowPage , perpage , startStamp ,endStamp , setLoading , setTotalPage}) {
  yield setLoading(true);
  const { data } = yield API.get(`/bets?start_at=${startStamp}&end_at=${endStamp}&page=${nowPage}&per_page=${perpage}&language=${localStorage.getItem("language")}`,{
    headers: {
    "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
    "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
    }
  }); 
  yield put({
    type: 'FETCH_BETHISTORY_API',
    payload: data
  })
  // yield console.log(data)
  yield setTotalPage(Math.ceil(data.data.pagination.total / perpage))
  yield setLoading(false);
}

function* fetchListTransactionsAPI({ nowPage , perpage , startStamp ,endStamp , setLoading , setTotalPage}) {
  yield setLoading(true);
  const { data } = yield API.get(`/member/transactions?start_at=${startStamp}&end_at=${endStamp}&page=${nowPage}&per_page=${perpage}`,{
    headers: {
    "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
    "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
    }
  }); 
  yield put({
    type: 'FETCH_LISTTRANSACTIONS_API',
    payload: data
  })
  // yield console.log(data)
  yield setTotalPage(Math.ceil(data.data.pagination.total / perpage))
  yield setLoading(false);
}



function* watchFetchBetHistoryAPI() {
  yield takeEvery('SAGA_FETCH_BETHISTORY_API', fetchBetHistoryAPI);
}
function* watchFetchListTransactionsAPI() {
  yield takeEvery('SAGA_FETCH_ISTTRANSACTIONS_API', fetchListTransactionsAPI);
}


export function* betHistorySaga() {
  yield all([
    watchFetchBetHistoryAPI(),
    watchFetchListTransactionsAPI()
  ]);
}