import React from "react";
// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import Avatar from "@material-ui/core/Avatar";
import Avatar from '@mui/material/Avatar';
import Swal from 'sweetalert2'
import ClearIcon from '@mui/icons-material/Clear';
// import { deepOrange } from "@material-ui/core/colors";
import { FormattedMessage , useIntl } from "react-intl";


export const MessageLeft = (props) => {
  const intl = useIntl();


  const message = props.message ? props.message : "無訊息";
  const timestamp = props.timestamp ? props.timestamp : "";
  const photoURL = props.photoURL ? props.photoURL : "dummy.js";
  const displayName = props.displayName ;
  const createID = props.createID
  const handelBanMember = props.handelBanMember
  const messageID = props.messageID
  const deleteMsg = props.deleteMsg
  return (
    <>
      <div className={'messageRow'}>
        <Avatar
          alt={displayName}
          className={'orange'}
          src={photoURL}
          onClick={()=>{
            if(localStorage.getItem("isAdmin") == 'true'){
              Swal.fire({
                  text: `${intl.formatMessage({
                    id: 'vLottery.whetherToBan',
                  })} ${displayName} ?`,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: intl.formatMessage({
                    id: 'alert.yes',
                  }),
                  cancelButtonText: intl.formatMessage({
                    id: 'alert.no',
                  }),
                }).then((result) => {
                  if (result.isConfirmed) {
                    handelBanMember(createID)
                  
                  }
              });
            }
          }}
        ></Avatar>
        <div className="messageRbx">
          <div className={'displayName'}>{displayName}</div>
          <div className={'messageBlue'}>
            <div>
              <p className={'messageContent'}>{message}</p>
            </div>
            <div className={'messageTimeStampRight'}>{timestamp}</div>
            {
            localStorage.getItem("isAdmin") === 'true' &&
              <button 
              className="deleteButton"
              onClick={()=>{
                deleteMsg(messageID)
              }}
              ><ClearIcon/></button>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export const MessageRight = (props) => {
  const message = props.message ? props.message : "no message";
  const timestamp = props.timestamp ? props.timestamp : "";
  const messageID = props.messageID
  const deleteMsg = props.deleteMsg
  return (
    <div className={'messageRowRight'}>
      <div className={'messageOrange'}>
        <p className={'messageContent'}>{message}</p>
        <div className={'messageTimeStampRight'}>{timestamp}</div>
        {
          localStorage.getItem("isAdmin") === 'true' &&
          <button 
          className="deleteButton"
          onClick={()=>{
            deleteMsg(messageID)
          }}
          ><ClearIcon/></button>
        }
      </div>
    </div>
  );
};
