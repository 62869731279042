import React, { useEffect, useRef , useState} from 'react'
import './GamePlace.scss';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { FormattedMessage , useIntl } from "react-intl";
import { useSelector , useDispatch} from 'react-redux';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import chip1 from '../../images/chips1.png';
import chip2 from '../../images/chips2.png';
import chip3 from '../../images/chips3.png';
import chip4 from '../../images/chips4.png';
import chip5 from '../../images/chips5.png';

const GamePlace = ({chip , setChip , handleTotalClick , total , totalOpen , totalLen ,totalPrice ,handleBet,empty,customChipRef,chipReduce,setGamePlaceShow,gamePlaceShow , gameID}) => {
    const intl = useIntl();
    const amountInputRef = useRef()
    const dispatch = useDispatch();
    const gameChips = useSelector(state => state.game.gameChips)
    const [chipLoading , setChipLoading] = useState(true)
    const getGameChips = () =>{
        dispatch({ type: 'SAGA_FETCH_GAMECHIPS_API'});
      }
    const isNumericString = (value) => {

        // return !isNaN(parseFloat(value)) && isFinite(value);
         // 使用 parseFloat 轉換為數字
        const numericValue = parseFloat(value);
        // 檢查轉換結果是否為有效的數字
        return !isNaN(numericValue) && isFinite(numericValue);
    }
    // const ChipBtn = [
    //     '1' ,'10' ,'50' ,'100'
    // ]
    const [ chipBtn , setChipBtn ] = useState([
        {
            value : "1",
            img : chip1,
            seq : 1
        },

        {
            value : "10",
            img : chip2 ,
            seq : 2
        },
        {
            value : "50",
            img : chip3 , 
            seq : 3
        },
        {
            value : "100",
            img : chip4 , 
            seq : 4
        },
    ])

    const handleChipButtonClick = (value) => {
        setChip([value]);
        // if (chip.indexOf(value) !== -1) {
        //     const newArray = chip.filter((element) => element !== value);
        //     setChip(newArray);

        // } else {
        //     setChip([...chip, value]);
        // }
    };
    const chipEdit = () =>{
        const updatedArray = chipBtn.map(item => {
            const match = gameChips.data.find(el => el.seq === item.seq);
            if (match) {
              return { ...item, value: match.amount };
            }
            return item;
          });
      
        setChipBtn(updatedArray);
        setChipLoading(false)
    }
    useEffect(()=>{
        if(gameChips.data[0].amount === 0) {
            getGameChips()
        } else{
            chipEdit()
        }
        console.log(gameChips)
       
    },[gameChips])

    
    return (
        <div className={`GamePlaceWrap c${gameID}`}>
            
            <div className='GamePlaceBx'>
                <button className='expandBtn'
                onClick={() => {
                    if(gamePlaceShow){
                        setGamePlaceShow(false)
                    } else {
                        setGamePlaceShow(true)
                    }
                    
                }}>
                    {
                        gamePlaceShow ?  <ExpandMoreIcon/> :  <ExpandLessIcon/>
                    }
                   
                </button>

                    <div className='PlaceBottom'>
                    {
                        gamePlaceShow || 
                        <>
                            {
                                totalOpen &&
                                <div className='totalBx'>
                                    {
                                        total.map((e) => {
                                            return (
                                                <div key={e}>
                                                    {
                                                        gameID === '1' && 
                                                        `
                                                        ${
                                                            intl.formatMessage({
                                                                id: `gamePlace.${e.split(',')[0]}`
                                                            })
                                                        },
                                                        ${

                                                            isNumericString(e.split(',')[1]) ? e.split(',')[1] :
                                                            intl.formatMessage({
                                                                id: `game.${e.split(',')[1]}`
                                                            })
                                                        },
                                                        ${e.split(',')[2]}
                                                        `
                                                    }
                                                    {
                                                        (gameID === '107' || gameID === '108' || gameID === '159') && 
                                                        `
                                                        ${
                                                            intl.formatMessage({
                                                                id: `game.${e.split(',')[0]}`
                                                            })
                                                        },
                                                        ${

                                                            isNumericString(e.split(',')[1]) ? e.split(',')[1] :
                                                            intl.formatMessage({
                                                                id: `game.${e.split(',')[1]}`
                                                            })
                                                        },
                                                        ${e.split(',')[2]}
                                                        `
                                                    }
                                                    {
                                                        gameID === '109' && 
                                                        `
                                                        ${
                                                            intl.formatMessage({
                                                                id: `game.${e.split(',')[0]}`
                                                            })
                                                        },
                                                        ${

                                                            isNumericString(e.split(',')[1]) ? e.split(',')[1] :
                                                            intl.formatMessage({
                                                                id: `game.${e.split(',')[1]}`
                                                            })
                                                        },
                                                        ${e.split(',')[2]}
                                                        `
                                                    }
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            }
                            </>
                    }
                        <div className='flex-bx'>
                            {/* <button className="edit-btn"
                            onClick={() => {
                                handleTotalClick()
                                // setGamePlaceShow(true)
                            }}
                            >確認</button> */}
                            <div className='btns'>
                                {chipLoading || chipBtn.map((v) => {
                                    return (
                                        <button key={v.value}
                                        // data-active={chip.includes(v)}
                                        onClick={() => {
                                            handleChipButtonClick(v.value)
                                            chipReduce(v.value)
                                        }}
                                        >
                                            <img src={v.img}></img>
                                            <span>{`${v.value}`}</span>
                                            
                                        </button>
                                    )
                                })}
                                <div className='inputbx'>
                                    <input
                                        defaultValue={0}
                                        min="1"
                                        ref={amountInputRef}
                                    ></input>
                                    <button
                                        onClick={() => {
                                            if(amountInputRef.current.value == ""){
                                                handleChipButtonClick(0)
                                                chipReduce(0)
                                            } else{
                                                handleChipButtonClick(amountInputRef.current.value)
                                                chipReduce(amountInputRef.current.value)
                                            }
                                        
                                        }}
                                    >
                                        <FormattedMessage
                                            id={`game.confirm`}
                                        ></FormattedMessage>
                                    </button>
                                </div>
                               
                            </div>
                        </div>
                        <div className='flex-bx'>
                            <div className='price'>
                                <div className='title'>
                                <FormattedMessage
                                    id={`game.selected`}
                                ></FormattedMessage>  
                                <i>
                                    {
                                        totalOpen ? totalLen
                                        : 0
                                    }
                                </i> <FormattedMessage
                                        id={`game.bet`}
                                    ></FormattedMessage></div>
                                
                                
                                <div className='inputWrap'>
                                    {/* <input
                                        ref={customChipRef}
                                        type="number"
                                    ></input> */}
                                    <i><FormattedMessage
                                        id={`game.betAmount`}
                                    ></FormattedMessage>：</i>
                                    <span className='total'>{ totalOpen ? totalPrice : 0} </span>
                                </div>
                            </div>
                            
                            <div className='btn-bx'>
                                <button className='clear-btn'
                                onClick={() => {
                                    empty();
                                }}
                                >
                                    <FormattedMessage
                                        id={`game.clear`}
                                    ></FormattedMessage>
                                </button>
                                <button className='send-btn'
                                onClick={(e) => {
                                    handleBet(e);
                                }}
                                >
                                    <FormattedMessage
                                        id={`game.confirm`}
                                    ></FormattedMessage>
                                </button>
                            </div>
                        </div>
                    
                    </div>
               
                
            </div>
            
        </div>

        
      )
}


export default GamePlace
