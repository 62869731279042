// import React from 'react'
import React, { useState , useEffect, useCallback} from 'react'
import { FormattedMessage } from "react-intl";

import './../GamePlace.scss';

import btn1 from '../../../images/racing/first-white.png';
import btn1a from '../../../images/racing/first-blue.png';
import btn2 from '../../../images/racing/second-white.png';
import btn2a from '../../../images/racing/second-blue.png';
import btn3 from '../../../images/racing/star-white.png';
import btn3a from '../../../images/racing/star-blue.png';

const FourDBet = ({
    fixbarRef,rankID,payOrder,Lane,laneID,setLaneID,nowChip,handlePlaceShow,empty,handlePlayButtonClick,setRankID,handleTotalClick
    }) => {
    const btns = [
        {
            name: "上正",
            id : 1,
            value : "4Dp",
            hash : "4Dp",
            img:btn1,
            aImg:btn1a,
        },
        {
            name: "上反",
            id : 2,
            value : "4Dn",
            hash : "4Dn",
            img:btn2,
            aImg:btn2a,
        },
    ]
    useEffect(() =>{
        setRankID(['4Dp']);
    },[])
    useEffect(() => {
        handleTotalClick()
    }, [nowChip,laneID]);
 

    // const [activeBtn, setActiveBtn] = useState(null);

    //建立fixbar的Ref
    // const fixbarRef = React.createRef(null);
    // const [fixbarOffsetTop, setFixbarOffsetTop] = useState(0);

    // useEffect(() => {
    //     const fixbar = fixbarRef.current;
    //     if (fixbar) {
    //         setFixbarOffsetTop(fixbar.offsetTop);
    //     }
        
    // }, []);
  

    useEffect(()=>{
        const namefalse = document.querySelectorAll('.name[data-active="false"]');

        if(laneID.length === 4){
            namefalse.forEach(element => {
                element.setAttribute('data-nclick', 'true');
            });
        } else {
            namefalse.forEach(element => {
                element.setAttribute('data-nclick', 'false');
            });
        }
    },[laneID,rankID])

    return (
        <div className="gameWrap d-flex fourBallWrap">
          <div className="btnsWrap d-flex" 
            id="fixbar"
            ref={fixbarRef}>
            
            <div></div>
            {btns.map((v) => {
              return (
                <li
                  className={rankID.includes(v.value) ? 'active' : ''}
                //   data-active={rankID.includes(v.value)}
                  key={v.id} 
                  
                  onClick={() => {
                    setRankID([v.value])
                    empty()
                  }}
                >
                  <div className='imgBx'>
                    <img src={v.img}></img>
                    <img src={v.aImg}></img>
                  </div>
                 
                  {/* <div>{v.name}</div> */}
                  <div>
                    <FormattedMessage
                        id={`game.${v.value}`}
                    ></FormattedMessage>
                  </div>
                </li>
              )
            })}
          </div>
         
          <div className="itemWrap model1">
            {
                <div className='selNum'>
                    <FormattedMessage
                        id={`game.selectedNumber`}
                    ></FormattedMessage>
                    <span>{laneID}</span>
                </div>
            }
            {
                rankID.includes('4Dp') &&
                <div className='circleCard'>
                    <div className='cardanchor'></div>
                    <h5 className='long'>
                        <FormattedMessage
                            id={`game.4Dp`}
                        ></FormattedMessage>
                    </h5>
                    <div className="circleWrap d-flex">
                        {Lane.map((v) => {
                            return (
                            <div key={v} className="name"
                            data-active={false}
                            onClick={() => {
                                handlePlayButtonClick(v,laneID,setLaneID,"4Dp")
                                handlePlaceShow()
                            }}
                            >
                                <i>{v}</i>
                            </div>
                            )
                        })}
                        <div className="name"
                            onClick={() => {
                                setLaneID([])
                            }}
                            >
                                <i>
                                    <FormattedMessage
                                        id={`game.clear`}
                                    ></FormattedMessage>
                                </i>
                        </div>
                        <div className="name"
                            onClick={() => {
                                const newArray = laneID.slice(0, laneID.length - 1);
                                setLaneID(newArray); 
                            }}
                            >
                                <i>
                                    <FormattedMessage
                                        id={`game.back`}
                                    ></FormattedMessage>
                                </i>
                        </div>
                    </div>
                    
                </div>
            }
            { 
                rankID.includes('4Dn') &&
                <div className='circleCard'>
                    <div className='cardanchor'></div>
                    <h5 className='long'>
                        <FormattedMessage
                            id={`game.4Dn`}
                        ></FormattedMessage>
                    </h5>
                    <div className="circleWrap d-flex">
                        {Lane.map((v) => {
                            return (
                            <div key={v} className="name"
                            data-active={false}
                            onClick={() => {
                                handlePlayButtonClick(v,laneID,setLaneID,"4Dn")
                                handlePlaceShow()
                            }}
                            >
                                <i>{v}</i>
                            </div>
                            )
                        })}
                        <div className="name"
                            onClick={() => {
                                setLaneID([])
                            }}
                            >
                                <i>
                                    <FormattedMessage
                                        id={`game.clear`}
                                    ></FormattedMessage>
                                </i>
                        </div>
                        <div className="name"
                            onClick={() => {
                                const newArray = laneID.slice(0, laneID.length - 1);
                                setLaneID(newArray); 
                            }}
                            >
                                <i>
                                    <FormattedMessage
                                        id={`game.back`}
                                    ></FormattedMessage>
                                </i>
                        </div>
                    </div>
                </div>
            }
          </div>
        </div>
    )
}


export default FourDBet
