import React from 'react'
import LoginForm from '../Component/Login/LoginForm'


const LoginPage = ({lang , setLang}) => {
  return (
    <div className="login-bx container d-flex justify-content-center align-items-center ">
      <LoginForm lang={lang} setLang={setLang} />
    </div>

  )
}

export default LoginPage