import React, { useEffect} from 'react'
import './GamePlace.scss';
import { FormattedMessage } from "react-intl";
import changBtn from '../../images/racing/from-hand.png';

const AutoBet = ({
    rankID,setRankID,payOrder,Lane,laneID,setLaneID,handlePlayButtonClick,Lane2,sizeID,setSizeID,nClickSizeID,setAutoHide,handlePlaceShow,handleTotalClick,nowChip,empty
    }) => {
    const btns = [
        {
            name: "冠",
            index : 2,
            value : "champion",
        },
        {
            name: "亞",
            index : 3,
            value : "second",
        },
        {
            name: "三",
            index : 4,
            value : "third",
        },
        {
            name: "四",
            index : 5,
            value : "fourth",
        },
        {
            name: "五",
            index : 6,
            value : "fifth",
        },
        {
            name: "六",
            index : 7,
            value : "sixth",
        },
        {
            name: "七",
            index : 8,
            value : "seventh",
        },
        {
            name: "八",
            index : 9,
            value : "eighth",
        },
        {
            name: "九",
            index : 10,
            value : "ninth",
        },
        {
            name: "十",
            index : 11,
            value : "tenth",
        },
    ]
    const handleSizeButtonClick = (value , state , set) => {
        if (state.indexOf(value) !== -1) {
            // 選過就刪掉
            const newArray = state.filter((element) => element !== value);
            set(newArray);
          } else {
            set([...state, value]);
          }
    };

    useEffect(() => {
        handleTotalClick()
        
    }, [rankID, laneID , sizeID ,nowChip]);
    return (

        <div className="gameWrap d-flex">
          <div className="btnsWrap d-flex">
            <li className='changModelBtn yellow'
                onClick={() => {
                    setAutoHide(true)
                    empty()
                }}
            >
              <div>
                <FormattedMessage
                        id={`game.GeneralBet`}
                    />
              </div>
            </li>
            
          </div>
         
          <div className="itemWrap model1">
            <h5 className=''>
              <FormattedMessage
                  id={`game.ranking`}
              />
            </h5>
            <div className="circleWrap d-flex ">
              {btns.map((v) => {
                return (
                  <div className="name" key={v.value}
                    data-active={rankID.includes(v.value)}
                    onClick={() => {
                      handleSizeButtonClick(v.value , rankID , setRankID)
                      handlePlaceShow()
                    }}
                  >
                    <i>
                    <FormattedMessage
                        id={`gameBtn.${v.value}`}
                    />
                      {/* {v.name} */}
                    </i>
                  </div>
                )
              })}
            </div>
            <h5 className='mt-2'>
              <FormattedMessage
                  id={`game.carNumber`}
              />
            </h5>
            <div className="circleWrap d-flex">
              {Lane.map((v) => {
                return (
                  <div key={v} className="name"

                  data-active={laneID.includes(v)}
                  onClick={() => {

                    handlePlayButtonClick(v,laneID,setLaneID)
                    handlePlaceShow()

                    
                  }}
                  >
                     <i>{v}</i>
                  </div>
                )
              })}
            </div>
            <h5 className='mt-2'>
              <FormattedMessage
                  id={`game.doubleSided`}
              />
            </h5>
            <div className="circleWrap d-flex fourCircle">
              {Lane2.map((v) => {
                return (
                  <div key={v.value} className="name"
                  data-active={sizeID.includes(v.value)}
                  data-nclick={nClickSizeID.includes(v.value)}
                  onClick={() => {
                    handlePlayButtonClick(v.value,sizeID,setSizeID)
                    handlePlaceShow()
                  }}
                  >
                     <i>
                     <FormattedMessage
                          id={`game.${v.value}`}
                      />
                     </i>
                  </div>
                )
              })}
            </div>
           
          </div>
        </div>
      )
}


export default AutoBet
