// import React from 'react'
import React, { useState , useEffect, useCallback} from 'react'

import './GamePlace.scss';
import { FormattedMessage } from "react-intl";
import changBtn from '../../images/racing/automatic-betting.png';

import btns1 from '../../images/racing/champion.png';
import btns1a from '../../images/racing/champion(click).png';
import btns2 from '../../images/racing/runner-up.png';
import btns2a from '../../images/racing/runner-up(click).png';
import btns3 from '../../images/racing/no.3.png';
import btns3a from '../../images/racing/no.3(click).png';
import btns4 from '../../images/racing/no.4.png';
import btns4a from '../../images/racing/no.4(click).png';
import btns5 from '../../images/racing/no.5.png';
import btns5a from '../../images/racing/no.5(click).png';
import btns6 from '../../images/racing/no.6.png';
import btns6a from '../../images/racing/no.6(click).png';
import btns7 from '../../images/racing/no.7.png';
import btns7a from '../../images/racing/no.7(click).png';
import btns8 from '../../images/racing/no.8.png';
import btns8a from '../../images/racing/no.8(click).png';
import btns9 from '../../images/racing/no.9.png';
import btns9a from '../../images/racing/no.9(click).png';
import btns10 from '../../images/racing/no.10.png';
import btns10a from '../../images/racing/no.10(click).png';

import btn1 from '../../images/racing/first-white.png';
import btn1a from '../../images/racing/first-blue.png';
import btn2 from '../../images/racing/second-white.png';
import btn2a from '../../images/racing/second-blue.png';
import btn3 from '../../images/racing/star-white.png';
import btn3a from '../../images/racing/star-blue.png';

const ManualBet = ({
    fixbarRef,rankID,payOrder,Lane,laneID,setLaneID,Lane2,sizeID,setSizeID,nClickSizeID,setAutoHide,manualValue,handleManuaTotalClick,nowChip,handlePlaceShow,empty,handlePlayButtonClick
    }) => {
    const btns = [
        {
            name: "冠軍",
            id : 1,
            value : "champion",
            hash : "champion",
            img:btn1,
            aImg:btn1a,
        },
        {
            name: "亞軍",
            id : 2,
            value : "second",
            hash : "runnerup",
            img:btn2,
            aImg:btn2a,
        },
        {
            name: "第三名",
            id : 3,
            value : "third",
            hash : "thirdplace",
            img:btn3,
            aImg:btn3a,
        },
        {
            name: "第四名",
            id : 4,
            value : "fourth",
            hash : "fourthplace",
            img:btn3,
            aImg:btn3a,
        },
        {
            name: "第五名",
            id : 5,
            value : "fifth",
            hash : "fifthplace",
            img:btn3,
            aImg:btn3a,
        },
        {
            name: "第六名",
            id : 6,
            value : "sixth",
            hash : "sixthplace",
            img:btn3,
            aImg:btn3a,
        },
        {
            name: "第七名",
            id : 7,
            value : "seventh",
            hash : "seventhplace",
            img:btn3,
            aImg:btn3a,
        },
        {
            name: "第八名",
            id : 8,
            value : "eighth",
            hash : "eightplace",
            img:btn3,
            aImg:btn3a,
        },
        {
            name: "第九名",
            id : 9,
            value : "ninth",
            hash : "ninthplace",
            img:btn3,
            aImg:btn3a,
        },
        {
            name: "第十名",
            id : 10,
            value : "tenth",
            hash : "tenthplace",
            img:btn3,
            aImg:btn3a,
        },
    ]
    // const refs = btns.map(btn => React.createRef(null));
    // const handleClick = useCallback((id) => {
    //     console.log(refs.map(btn => btn.current))
    //     const ref = refs.find(btn => btn.current.id === id);
    //     // console.log(refs.map(btn => btn))
    //     if (ref) {
    //         ref.current.scrollIntoView({ behavior: 'smooth'});
            
    //     } else{
    //         console.log(refs.map(btn => btn.current))
    //     }
    // }, []);
    

    // const handleScroll = useCallback(() => {
    //     const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    //     for (let i = 0; i < refs.length; i++) {
    //     if (refs[i].current.offsetTop <= scrollTop + 80) {
    //         setActiveBtn(i + 1);
    //     }
    //     }
    // }, [refs]);
    
    useEffect(() => {
        handleManuaTotalClick()
    }, [manualValue,nowChip]);

    //錨點移動
    const handleClick = (id) => {
        const ref = document.getElementById(`btna-${id}`);
        if (ref) {
            ref.scrollIntoView({ block: "center", behavior: 'smooth' });
        }
    };

    const [activeBtn, setActiveBtn] = useState(null);

    //建立fixbar的Ref
    // const fixbarRef = React.createRef(null);
    const [fixbarOffsetTop, setFixbarOffsetTop] = useState(0);

    useEffect(() => {
        const fixbar = fixbarRef.current;
        if (fixbar) {
            setFixbarOffsetTop(fixbar.offsetTop);
        }
        
    }, []);
    
    const handleScroll = useCallback(() => {
        //使用Ref.current引用DOM
        const fixbar = fixbarRef.current;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        //判斷fixbar固定
    
        if (scrollTop >= fixbarOffsetTop - 36) {
            fixbar.classList.add('active');
        } else {
            fixbar.classList.remove('active');
        }
        
        //判斷fixbar item active
        for (let i = 0; i < btns.length; i++) {
            const ref = document.getElementById(`btn-${btns[i].id}`);
            if (ref && ref.offsetTop <= scrollTop + 300) {
                setActiveBtn(btns[i].id);
                // ref.scrollIntoView({ block: "center", behavior: 'smooth' })
            }
        }
    }, [btns]);


    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return (
        <div className="gameWrap d-flex">
          <div className="btnsWrap d-flex" 
            id="fixbar"
            ref={fixbarRef}>
            
            <li className='changModelBtn'
                onClick={() => {
                    setAutoHide(false)
                    empty()
                }}
            >
                {/* <img src={changBtn}></img> */}
                <div>
                    <FormattedMessage
                        id={`game.QuickBet`}
                    />
                </div>
                {/* 自動 */}
            </li>
            {btns.map((v) => {
              return (
                <li
                  className={activeBtn === v.id ? 'active' : ''}
                  data-active={rankID.includes(v.value)}
                  key={v.id} 
                  
                  onClick={() => {
                    handleClick(v.id)
                  }}
                >
                  <div className='imgBx'>
                    <img src={v.img}></img>
                    <img src={v.aImg}></img>
                  </div>
                 
                  <div>
                    {/* <FormattedMessage
                      id={`game.${v.value}`}
                    //   defaultMessage="Member"
                    /> */}
                    <FormattedMessage
                        id={`game.${v.value}`}
                    />
                    {/* {v.name} */}
                  </div>
                </li>
              )
            })}
          </div>
         
          <div className="itemWrap model1">
            {btns.map((btn) => {
              return (
                <div className='circleCard' key={btn.id} 
                id={`btn-${btn.id}`}>
                    <div id={`btna-${btn.id}`}  className='cardanchor'></div>
                    <h5 className='long'>
                        {/* {btn.name}   */}
                        <FormattedMessage
                            id={`game.${btn.value}`}
                        />
                        <FormattedMessage
                            id={`game.carNumber`}
                        />
                    </h5>
                    <div className="circleWrap d-flex">
                    {Lane.map((v) => {
                        return (
                        <div key={v} className="name"
                        data-active={manualValue.includes(`${btn.value},${v}`)}
                        onClick={() => {
                            handlePlayButtonClick(v,laneID,setLaneID,btn.value)
                            handlePlaceShow()
                        }}
                        >
                            <i>{v}</i>
                        </div>
                        )
                    })}
                    </div>
                    <h5>
                        <FormattedMessage
                            id={`game.doubleSided`}
                        />
                    </h5>
                    <div className="circleWrap d-flex fourCircle">
                    {Lane2.map((v) => {
                        return (
                        <div key={v.value} className="name"
                        // data-active={sizeID.includes(v)}
                        data-active={manualValue.includes(`${btn.value},${v.value}`)}

                        data-nclick={nClickSizeID.includes(`${btn.value},${v.value}`)}
                        onClick={() => {
                            handlePlayButtonClick(v.value,sizeID,setSizeID,btn.value)
                            handlePlaceShow()
                            
                        }}
                        >
                            <i>
                                <FormattedMessage
                                    id={`game.${v.value}`}
                                    //   defaultMessage="Member"
                                />
                                {/* {v.name} */}
                            </i>
                        </div>
                        )
                    })}
                    </div>
           
                </div>
              )
            })}
            
          </div>
        </div>
    )
}


export default ManualBet
