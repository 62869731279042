import React, { useState , useEffect , useContext } from 'react'
import { FaRegHandshake } from 'react-icons/fa';
import { AiOutlineAccountBook, AiFillQuestionCircle } from 'react-icons/ai';
import { GiReceiveMoney } from 'react-icons/gi';
import { GrMoney } from 'react-icons/gr';
import { RiHomeGearLine } from 'react-icons/ri';
import { MdOutlineSecurity, MdMusicNote } from 'react-icons/md';
import { FormattedMessage } from "react-intl";
import CloseIcon from '@mui/icons-material/Close';

import topbtn1 from '../../images/my-personal-center/betting-history.svg';
import topbtn2 from '../../images/my-personal-center/Phone-verification.svg';
import topbtn3 from '../../images/my-personal-center/Account-Change-Record.svg';
import topbtn4 from '../../images/my-personal-center/online-service.svg';
import topbtn5 from '../../images/my-personal-center/tg.svg';

import botbtn1 from '../../images/my-agency-center/Agency-overview.png';
import botbtn2 from '../../images/my-agency-center/team-management.png';
import botbtn3 from '../../images/my-agency-center/Team-account-opening.png';
import botbtn4 from '../../images/my-agency-center/Kerugian-tim.png';
import botbtn5 from '../../images/my-agency-center/Taruhan-tim.png';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import { ErrorContext } from '../../Routes';
import api from '../../api/api';

const now = new Date();
const currentHour = now.getHours() < 10 ? `0${now.getHours()}` : `${now.getHours()}`;
const currentMinutes = now.getMinutes() < 10 ? `0${now.getMinutes()}` : `${now.getMinutes()}`;

const ProfileBtn = ({setBoxSwitch , memberDetail , credit, getCredit }) => {
  const { ErrorMessage } = useContext(ErrorContext);
  const [csPhone , setcsPhone] = useState('');
  const [csPop , setCsPop] = useState(false)
  const [creditPop , setCreditPop] = useState(false)
  const [selectedFile , setSelectedFile] = useState(null)
  const [selectedPass , setSelectedPass] = useState(null)

  const profileBtns = [
    {
      name: '下注紀錄',
      link: "bettingRecord",
      icon: topbtn1,
    },
    {
      name: '手機驗證',
      link: "verificationMsg",
      icon: topbtn2,
    },
    {
      name: '訊息綁定',
      link: "messageBinding",
      icon: topbtn4,
    },
    {
      name: '帳變紀錄',
      link: "listTransactions",
      icon: topbtn3,
    },
  ]
  const AgentprofileBtns = [
    {
      name: '代理總覽',
      link: "agentInfo",
      icon: botbtn1,
    },
    {
      name: '團隊管理',
      link: "agents",
      icon: botbtn2,
    },
    {
      name: '團隊開戶',
      link: "agentRegister",
      icon: botbtn3,
    },
    {
      name: '團隊盈虧',
      link: "agentProfit",
      icon: botbtn4,
    },
    {
      name: '團隊投注',
      link: "agentBetting",
      icon: botbtn5,
    },
  ]
  const CS_URL = `/cs?language=${localStorage.getItem('language')}`;
  const CREDIT_URL = `/member/credit`;

  useEffect(()=>{
    getCSphone()
      if(memberDetail.response.credit){
        getCredit()
      }
  },[])

  // 拿客服電話
  const getCSphone = async () => {
    try {
      const res = await api.get(CS_URL, {
        headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Content-Type": "application/json",
        },
      });
      if (res.data.status) {
        console.log(res.data)
        setcsPhone(res.data.data)
      }
    } catch (err) {
      console.log(err);
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    }
  };
  const goCS = (index) =>{
    // window.location = `${index}`
    window.open(`${index}`, "_blank");
  }
  
  const handleFileChange = async (event , value) => {
    const file = event.target.files[0];
    if(value === 1){
      setSelectedFile(file);
    } else {
      setSelectedPass(file)
    }
  };
  
  const sendCreditFile = async () =>{
    const formdata = new FormData();
    formdata.append('linked' , selectedFile);
    formdata.append('pass' , selectedPass);
    console.log(formdata)
    try {
        const res = await api.post(CREDIT_URL, formdata ,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
            },
        });
        
        if (res.data.status) {
          Swal.fire({
            icon: "success",
            showConfirmButton: false,
            timer: 1000,
          });
          getCredit()
        }
    } catch (err) {
        console.log(err)
        const errorCode = err.response.status;
        const errMsg = err.response.data.data.error_code;
        ErrorMessage(errorCode, errMsg);
    }
  }
  return (
    <>
      {
        csPop &&
        <div className='ruleWrap'>
            <div className='ruleDes' style={{ height : '25vh' }}>
                <div className='ruleClose'
                onClick={() => {
                    // handleBoxSwitch('rule');
                    setCsPop(false)
                  }}
                >
                <CloseIcon/>
                </div>
                <div className='ruleScrollBx' >
                  {
                    csPhone.map((v)=>{
                      return(
                        <Link onClick={()=>goCS(v.phone)} 
                        style={{
                          "font-size":'18px',
                          "paddingBottom" :'10px',
                          "display":'block'
                        }}>
                          <div>{v.name}</div>
                        </Link>
                      )
                    })
                  }
                </div>
            </div>
        </div>
      }
      {
        creditPop &&
        <div className='ruleWrap creditWrap'>
            <div className='ruleDes' style={{ height : '25vh' }}>
                <div className='ruleClose'
                onClick={() => {
                    // handleBoxSwitch('rule');
                    setCreditPop(false)
                  }}
                >
                <CloseIcon/>
                </div>
                <div className='ruleScrollBx' >
                    { credit.status || 
                      <>
                        {
                          credit.linked_link !== '' &&
                          <div className='title'>圖片已上傳 , 客服審核中</div>
                        }
                      </>
                      
                    }
                     <div className='imgBx'>
                      {
                        credit.linked_link !== '' &&
                        <div>
                          <p>目前連動帳號</p>
                          <img src={credit.linked_link}></img>
                        </div>
                      }
                      {
                        credit.pass_link !== '' &&
                        <div>
                          <p>目前澳門通行證</p>
                          <img src={credit.pass_link}></img>
                        </div>
                      }
                    </div>

                    {
                      credit.linked_link == '' &&
                      <>
                        <label>上傳連動帳號 : </label>
                        <input
                        required
                        name='file'
                        type='file'
                        onChange={(e)=> handleFileChange(e , 1)}
                        />
                        <label>上傳澳門通行證 : </label>
                        <input
                        required
                        name='file'
                        type='file'
                        onChange={(e)=> handleFileChange(e , 2)}
                        />
                        <button  onClick={sendCreditFile}>上傳</button>
                      </>
                    }
                    
                </div>
            </div>
        </div>
      }
      <div className="profileBtns my-4">
        <div className='center-ti'>
          <div className='line-l'></div>
          <span>
            <FormattedMessage
                id={`profile.PersonalCenter`}
            ></FormattedMessage>
          </span>
          <div className='line-r'></div>
        </div>
        <div className='itemBtnBx topBtnBx'>
          {profileBtns.map((v) => {
            return (
              <a className="itemBtn" key={v.name}
              onClick={()=>{
                setBoxSwitch(v.link)
              }}>
                <div className='imgBx'>
                  <img src={v.icon}></img>
                </div>
                <div className='ti'>
                  <FormattedMessage
                      id={`profile.${v.link}`}
                  ></FormattedMessage>
                </div>
            </a>
            )
          })}
          {/* <a className="itemBtn"
              onClick={()=>setCsPop(true)}>
                <div className='imgBx'>
                <img src={topbtn5}></img>
                </div>
                <div className='ti'>
                  <FormattedMessage
                      id={`profile.onlineCustomerService`}
                  ></FormattedMessage>
                </div>
          </a> */}
          {
            memberDetail.response.credit &&
            <>
              {
                credit.status ||
                <a className="itemBtn"
                  onClick={()=>setCreditPop(true)}
                  >
                    <div className='imgBx'>
                    <img src={topbtn5}></img>
                    </div>
                    <div className='ti'>
                      信用驗證
                    </div>
                </a>
              }
            </>
          }
          {/* <a className="itemBtn"
              onClick={()=>setCreditPop(true)}
              >
                <div className='imgBx'>
                <img src={topbtn5}></img>
                </div>
                <div className='ti'>
                  信用驗證
                </div>
          </a> */}
        </div>
        
        <div className='center-ti agent-ti'>
          <div className='line-l'></div>
          <span>
            <FormattedMessage
                id={`profile.AgencyCenter`}
            ></FormattedMessage>
          </span>
          <div className='line-r'></div>
        </div>
        <div className='itemBtnBx agentItemBx'>
          {AgentprofileBtns.map((v) => {
            return (
              <a className="itemBtn" key={v.name}
              onClick={()=>{
                setBoxSwitch(v.link)
              }}>
              <div className='imgBx'>
                <img src={v.icon}></img>
              </div>
              <div className='ti'>
                <FormattedMessage
                      id={`profile.${v.link}`}
                  ></FormattedMessage>
              </div>
            </a>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default ProfileBtn