import React from 'react'
import { AiOutlineLogin } from 'react-icons/ai';
import { useNavigate } from "react-router-dom";

const SubNav = () => {
  const navigate = useNavigate();

  return (
    <div className='mobileSubNav'>
      <div className="loginBtn d-flex"
        onClick={() => {
          navigate(`/login`);
        }}>
        <AiOutlineLogin
          className='me-1' />
        登入
      </div>
    </div>
  )
}

export default SubNav