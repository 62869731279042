import React, { useState , useEffect, useCallback , useRef} from 'react'
import { useSelector , useDispatch , Provider} from 'react-redux';
import { FormattedMessage , useIntl } from "react-intl";
import moment from "moment";
import Page from '../../Component/All/Page'
import Datep from '../../Component/All/Date'
import Spinner from '../Spinner/Spinner';
import SearchBar from './searchBar'
import GroupsIcon from '@mui/icons-material/Groups';
import ReplyIcon from '@mui/icons-material/Reply';
import Swal from 'sweetalert2'
var currentDate = new Date();
currentDate.setDate(currentDate.getDate());
currentDate.setHours(0, 0, 0, 0);

var endToDate = new Date();
endToDate.setHours(23, 59, 59, 999);

const AgentBetting = ({setBoxSwitch}) => {
  const intl = useIntl();

  const [perpage] = useState(15)
  const [nowPage, setNowPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [platformMemberID , setPlatformMemberID] = useState('')
  const [loading , setLoading] = useState(true)
  const [gameTypesLoading , setGameTypesLoading] = useState(true)
  const [thirdPartyLoading , setThirdPartyLoading] = useState(true)
  const [gameSel , setGameSel] = useState('0')
  //redux
  const dispatch = useDispatch();
  const agentBets = useSelector(state => state.agent.agentBets)
  const thirdPartyGames = useSelector(state => state.game.thirdPartyGames)
  const gameTypes = useSelector(state => state.game.gameTypes)

  const [firstLoading , setFirstLoading] = useState(true)
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(endToDate);
  const startRef = useRef()
  const endRef = useRef()
  const searchHandler = ( nowPage = 1 , perPage = 15) => {
    // event.preventDefault();
    //第一次loading讀取當天列表
    if(firstLoading){
        const startStamp = 0;
        const endStamp = 0;
        dispatch({ type: 'SAGA_FETCH_AGENTBETS_API' , platformMemberID , setLoading , setTotalPage , perpage , nowPage , startStamp , endStamp});
        setFirstLoading(false)
    } else{
        const encodedStartTime = moment(startRef.current.props.selected).format('YYYY-MM-DD HH:mm:ss');
        const encodedEndTime = moment(endRef.current.props.selected).format('YYYY-MM-DD HH:mm:ss');
        const startStamp = parseInt(`${moment(encodedStartTime, 'YYYY-MM-DD HH:mm:ss').unix()}000`);
        const endStamp = parseInt(`${moment(encodedEndTime, 'YYYY-MM-DD HH:mm:ss').unix()}999`);
        dispatch({ type: 'SAGA_FETCH_AGENTBETS_API' , platformMemberID , setLoading , setTotalPage , perpage , nowPage , startStamp , endStamp});
        
    }
  };

  useEffect(() => {
    searchHandler(nowPage,perpage)
  }, [nowPage,perpage,platformMemberID]);

  useEffect(()=>{
    dispatch({ type: 'SAGA_FETCH_GAMESTYPES_API' , setGameTypesLoading});
    dispatch({ type: 'SAGA_FETCH_THIRDPARTYGAMES_API' , setThirdPartyLoading});
  },[])
//   useEffect(() => {
//   dispatch({ type: 'SAGA_FETCH_AGENTBETS_API' , platformMemberID , setLoading , setTotalPage , perpage , nowPage});
//   }, [dispatch , platformMemberID , nowPage]);

//   useEffect(() => {
//     setTotalPage(Math.ceil(betHistory.data.data.pagination.total / perpage))
//   }, [betHistory.data.status])
  
  return (
    <div className="agentsWrap agentsProfitWrap bottWrap">
            <div className='listBx'>
                <div className='titleFlex'>
                    <button className='backBtn' onClick={()=>{
                        setBoxSwitch(false)
                    }}
                    ><ReplyIcon/></button>
                    <div>
                        <FormattedMessage
                            id={`profile.agentBetting`}
                        ></FormattedMessage>
                    </div>
                </div>
                {/* <div className='gameselBx'>
                    <ul>
                        {
                            gameTpye.map((v)=>{
                                return(
                                    <li key={v.value} onClick={()=>setGameSel(`${v.value}`)}
                                    data-active={gameSel.includes(`${v.value}`)}
                                    >
                                        {v.name}
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div> */}
                <SearchBar setPlatformMemberID={setPlatformMemberID}/>
                <Datep
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                startRef={startRef}
                endRef={endRef}
                />
                <button className='searchBtn' 
                    onClick={() => {
                            if (startDate && endDate) {
                                searchHandler(1,15);
                                setNowPage(1);
                            } else {
                                Swal.fire({
                                    icon: "warning",
                                    text:intl.formatMessage({
                                        id: 'alert.notSelected',
                                    }),
                                    showConfirmButton: true,
                                });
                            }
                        }}>
                        <FormattedMessage
                            id={`profile.inquire`}
                        ></FormattedMessage>
                </button>
                {agentBets.status ? 
                    agentBets.data.response.length === 0  ?
                    <div className='notData'>
                        <FormattedMessage
                            id={`profile.noDataFound`}
                        ></FormattedMessage>
                    </div> :
                    <>
                        {
                            loading && gameTypesLoading && thirdPartyLoading||
                            <>
                                                                
                                <div className='selBx'>
                                    {
                                        agentBets.data.direct_group.length === 0 ?
                                        <div>{agentBets.data.response[0].account}</div>
                                        :
                                        agentBets.data.direct_group.map((v)=>{
                                            return(
                                                <div key={v.id} onClick={()=>{
                                                    setPlatformMemberID(v.id)
                                                }}>{v.account}</div>
                                            )
                                        })
                                    }
                                </div>
                                <div className='scrollBx'>
                                    {  
                                        <ul>
                                            {
                                                agentBets.data.response.map((v)=>{ 
                                                    return (
                                                        <li key={v.id}>
                                                            <div>
                                                                <span><GroupsIcon/></span>
                                                                <i onClick={()=>{
                                                                    setPlatformMemberID(v.id)
                                                                }}>{v.account}</i>
                                                            </div>
                                                            {/* <div className='flexBx'> */}
                                                                <div>
                                                                    <span>
                                                                    <FormattedMessage
                                                                        id={`profile.gameName`}
                                                                    ></FormattedMessage> :
                                                                    </span>
                                                                    {v.game_name} | {gameTypes.data.find(item => item.id === v.game_type_id).name} | {thirdPartyGames.data.find(item => item.id === v.third_party_game_id).name}
                                                                </div>
                                                                <div>
                                                                    <span>
                                                                        <FormattedMessage
                                                                            id={`agent.numberOfBoard`}
                                                                        ></FormattedMessage> :
                                                                    </span>
                                                                    {v.game_round_num}
                                                                </div>
                                                            {/* </div> */}
                                                            <div className='flexBx'>
                                                                <div>
                                                                    <span>
                                                                        <FormattedMessage
                                                                            id={`agent.effectiveBetAmount`}
                                                                        ></FormattedMessage> :
                                                                    </span>
                                                                    {v.bet_amount}
                                                                </div>
                                                                <div>
                                                                    <span>
                                                                        <FormattedMessage
                                                                            id={`agent.winOrLose`}
                                                                        ></FormattedMessage> :
                                                                    </span>
                                                                    {v.win}
                                                                </div>
                                                            </div>                                                            
                                                            
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    }
                                </div>
                            </>
                        }
                    </>
                    : <Spinner/> 
                }
                
                
            </div>
    </div>
  )
}

export default AgentBetting