// import React, { useState , useEffect, useCallback , useRef} from 'react'



const initState = {
    betHistory : {
      data:{
        data: {
          game_name: {
            additionalProp1: "",
            additionalProp2: "",
            additionalProp3: ""
          },
          pagination: {
            page: 0,
            per_page: 0,
            total: 0
          },
          response: [
            
          ]
        },
        status: false
      }
    },
    listTransactions : {
      data:{
        data: {
          pagination: {
            page: 0,
            per_page: 0,
            total: 0
          },
          response: [
            {
              amount: 0,
              balance: 0,
              create_at: 0,
              id: "string",
              platform_id: "string",
              platform_member_id: "string",
              reference: "string",
              target_id: "string",
              third_party_game_deal_at: 0,
              type: 0
            }
          ]
        },
        status: true
      }
    }
};


export const betHistoryReducer = (state = initState, action) => {
    switch (action.type) {
      case "FETCH_BETHISTORY_API":
        return {
          ...state,
          betHistory : action.payload
        };
      case "FETCH_LISTTRANSACTIONS_API":
        return {
          ...state,
          listTransactions : action.payload
        };
      default:
        return state;
    }
  };
  
// export { betHistoryReducer };