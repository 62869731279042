// import React, { useState , useEffect, useCallback , useRef} from 'react'



const initState = {
    listEvents:{
        data:{ 
            data: {
                pagination: {
                page: 0,
                per_page: 0,
                total: 0
                },
                response: [
                {
                    content: "",
                    create_at: 0,
                    description: "",
                    end_at: 0,
                    id: 0,
                    image_id: "",
                    image_link: "",
                    is_banner: true,
                    platform_id: "",
                    start_at: 0,
                    title: "",
                    type: 0,
                    update_at: 0
                }
                ]
            },
            status: false
        }
    },
    getEvent:{
        data:{
            data: {
                content: "",
                create_at: 0,
                description: "",
                end_at: 0,
                id: 0,
                image_id: "",
                image_link: "",
                is_banner: true,
                platform_id: "",
                start_at: 0,
                title: "",
                type: 0,
                update_at: 0
              },
              status: false
        }
    }
    
};


export const ActivityReducer = (state = initState, action) => {
    switch (action.type) {
      case "FETCH_LISTEVENTS_API":
        return {
            ...state,
            listEvents : action.payload
        };
      case "FETCH_EVENTS_API":
        return {
            ...state,
            getEvent : action.payload
        };
      case "FETCH_JOINEVENTS_API":
        return state;
      default:
        return state;
    }
};
  
// export { betHistoryReducer };