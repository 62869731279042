// import React from 'react'
import React, { useState , useEffect, useCallback} from 'react'
import { FormattedMessage } from "react-intl";

import './../GamePlace.scss';

import btn1 from '../../../images/racing/first-white.png';
import btn1a from '../../../images/racing/first-blue.png';
import btn2 from '../../../images/racing/second-white.png';
import btn2a from '../../../images/racing/second-blue.png';
import btn3 from '../../../images/racing/star-white.png';
import btn3a from '../../../images/racing/star-blue.png';

const SpotBet = ({
    fixbarRef,rankID,payOrder,Lane,laneID,setLaneID,nowChip,handlePlaceShow,empty,handlePlayButtonClick,setRankID,handleTotalClick,onHandleTotalClick
    }) => {
    const btns = [
        {
            name: "spot_1",
            id : 1,
            value : "spot_1",
            img:btn1,
            aImg:btn1a,
        },
        {
            name: "spot_2",
            id : 2,
            value : "spot_2",
            img:btn2,
            aImg:btn2a,
        },
        {
            name: "spot_3",
            id : 3,
            value : "spot_3",
            img:btn2,
            aImg:btn2a,
        },
        {
            name: "spot_4",
            id : 4,
            value : "spot_4",
            img:btn2,
            aImg:btn2a,
        },
        {
            name: "spot_5",
            id : 5,
            value : "spot_5",
            img:btn2,
            aImg:btn2a,
        },
    ]
    useEffect(() => {
        if(!rankID.includes('spot_1')){
            onHandleTotalClick()
        } else {
            handleTotalClick()
        }
       
    }, [nowChip , rankID, laneID]);
    useEffect(() =>{
        setRankID(['spot_1']);
    },[])
    
    const [activeBtn, setActiveBtn] = useState(null);

    //建立fixbar的Ref
    // const fixbarRef = React.createRef(null);
    const [fixbarOffsetTop, setFixbarOffsetTop] = useState(0);

    useEffect(() => {
        const fixbar = fixbarRef.current;
        if (fixbar) {
            setFixbarOffsetTop(fixbar.offsetTop);
        }
        
    }, []);
    
    const handleScroll = useCallback(() => {
        //使用Ref.current引用DOM
        const fixbar = fixbarRef.current;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        //判斷fixbar固定
    
        if (scrollTop >= fixbarOffsetTop - 36) {
            fixbar.classList.add('active');
        } else {
            fixbar.classList.remove('active');
        }
        
        //判斷fixbar item active
        for (let i = 0; i < btns.length; i++) {
            const ref = document.getElementById(`btn-${btns[i].id}`);
            if (ref && ref.offsetTop <= scrollTop + 300) {
                setActiveBtn(btns[i].id);
                // ref.scrollIntoView({ block: "center", behavior: 'smooth' })
            }
        }
    }, [btns]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);
  

    useEffect(()=>{
        const namefalse = document.querySelectorAll('.name[data-active="false"]');
      
        if(rankID.includes('spot_2')){
            if(laneID.length === 2){
                namefalse.forEach(element => {
                    element.setAttribute('data-nclick', 'true');
                });
            } else {
                namefalse.forEach(element => {
                    element.setAttribute('data-nclick', 'false');
                });
            }
        } else if (rankID.includes('spot_3')){
            if(laneID.length === 3){
                namefalse.forEach(element => {
                    element.setAttribute('data-nclick', 'true');
                });
            } else {
                namefalse.forEach(element => {
                    element.setAttribute('data-nclick', 'false');
                });
            }
        } else if (rankID.includes('spot_4')){
            if(laneID.length === 4){
                namefalse.forEach(element => {
                    element.setAttribute('data-nclick', 'true');
                });
            } else {
                namefalse.forEach(element => {
                    element.setAttribute('data-nclick', 'false');
                });
            }
        } else if (rankID.includes('spot_5')){
            if(laneID.length === 5){
                namefalse.forEach(element => {
                    element.setAttribute('data-nclick', 'true');
                });
            } else {
                namefalse.forEach(element => {
                    element.setAttribute('data-nclick', 'false');
                });
            }
        } else if (rankID.includes('spot_1')){
            namefalse.forEach(element => {
                element.setAttribute('data-nclick', 'false');
            });
        }
        
    },[laneID,rankID])

    return (
        <div className="gameWrap d-flex">
          <div className="btnsWrap d-flex" 
            id="fixbar"
            ref={fixbarRef}>
            
            <div></div>
            {btns.map((v) => {
              return (
                <li
                  className={rankID.includes(v.value) ? 'active' : ''}
                //   data-active={rankID.includes(v.value)}
                  key={v.id} 
                  
                  onClick={() => {
                    empty()
                    setRankID([v.value])
                  }}
                >
                  <div className='imgBx'>
                    <img src={v.img}></img>
                    <img src={v.aImg}></img>
                  </div>
                 
                  {/* <div>{v.name}</div> */}
                  <div>
                    <FormattedMessage
                        id={`keno.${v.value}`}
                    ></FormattedMessage>
                  </div>
                </li>
              )
            })}
          </div>
         
          <div className="itemWrap model1">
            <h5 className='mt-2'>
              <FormattedMessage
                  id={`game.number`}
              />
            </h5>
            <div className="circleWrap d-flex">
              {Lane.map((v) => {
                return (
                  <div key={v} className="name"

                  data-active={laneID.includes(v)}
                  onClick={() => {

                    handlePlayButtonClick(v,laneID,setLaneID)
                    handlePlaceShow()

                    
                  }}
                  >
                     <i>{v}</i>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
    )
}


export default SpotBet
