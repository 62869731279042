import React from "react";
import BarLoader from "react-spinners/BarLoader";

const override = {
  position: "absolute",
  left: "50%",
  top: "50%",
  transform:"translate(-50%,-50%)",
};
const Spinner = ({ loading }) => {
  return (
    <div className="sweet-loading" css={override}>
      <BarLoader color="#E2EFFF" loading={loading}  size={30} />
    </div>
  );
};

export default Spinner;
