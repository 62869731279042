import React, { useState , useEffect, useCallback , useRef , useContext} from 'react'
import { useParams , useNavigate } from 'react-router-dom';

import './GamePage.scss'
import MobileNav from '../../Layout/ＭobileNav'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { FormattedMessage , useIntl } from "react-intl";
import ReplyIcon from '@mui/icons-material/Reply';
import RefreshIcon from '@mui/icons-material/Refresh';
import GamePlace from '../../Component/Game/GamePlace'
import CarVideo from '../../Component/Game/CarVideo'
import GameRule from '../../Component/Game/GameRule'
import GameTrend from '../../Component/Game/bingo/GameTrend'
import ManualBet from '../../Component/Game/bingo/ManualBet'
import AutoBet from '../../Component/Game/AutoBet'
import Sum from '../../Component/Game/Sum'
import TwoStar from '../../Component/Game/twoStar'
import DragonTiger from '../../Component/Game/dragonTiger'
import MemberWrap from '../../Component/All/MemberWrap'
import FixBx from '../../Hook/fixBx'
import moment from "moment";
import model1 from '../../images/racing/position.png';
import model1a from '../../images/racing/position(click).png';
import model2 from '../../images/racing/Guan-Yahe.png';
import model2a from '../../images/racing/Guan-Yahe(click).png';
import model3 from '../../images/racing/drago-with-ntiger.png';
import model3a from '../../images/racing/drago-with-ntiger(click).png';
import model4 from '../../images/racing/two-star.png';
import model4a from '../../images/racing/two-star(click).png';

import api from '../../api/api';
import Swal from 'sweetalert2'
import { ErrorContext } from '../../Routes';



const Lane = [
  "1", '2', '3', "4", "5", "6", "7", "8", "9", "10"
]
// const Lane2 = [
//   "大", '小', '單', "雙",'大雙','小雙','大單','小單'
// ]
const Lane2 = [
  {
    name:'大',
    value:'big'
  },
  {
    name:'小',
    value:'small'
  },
  {
    name:'單',
    value:'odd'
  },
  {
    name:'雙',
    value:'even'
  },
  {
    name:'大雙',
    value:'big_even'
  },
  {
    name:'小雙',
    value:'small_even'
  },
  {
    name:'大單',
    value:'big_odd'
  },
  {
    name:'小單',
    value:'small_odd'
  },
]

const GameModel = [
  {
    name: "定位/雙面",
    value : "model1",
    status:false,
    img:model1,
    aImg:model1a,
  },
  {
    name: "冠亞和",
    value : "model2",
    status:false,
    img:model2,
    aImg:model2a,
  },
  {
    name: "龍虎",
    value : "model3",
    status:false,
    img:model3,
    aImg:model3a,
  },
  {
    name: "二星",
    value : "model4",
    status:false,
    img:model4,
    aImg:model4a,
  },
]
const Member = "/member"
const BingoPage = () => {
  const { ErrorMessage , TimeMoment } = useContext(ErrorContext);

  // const history = useParams();
  const { GameID } = useParams();
  const navigate = useNavigate();
  const intl = useIntl();

  //loading status
  const [loadingMember , setLoadingMember] = useState(false);
  const [loading, setLoading] = useState(false)
  const fixBxRef = useRef(null);
  const mainRef = useRef(null);
  const leftFixRef = useRef(null);
  const fixbarRef = useRef(null);




  const [hideAmount, setHideAmount] = useState(false)
  //自動投注,手動投注
  const [autoHide, setAutoHide] = useState(true)
  //名次
  const [rankID , setRankID] = useState([]);
  //車道
  const [laneID , setLaneID] = useState([]);
  //大小單雙
  const [sizeID , setSizeID] = useState([]);
  const [nClickSizeID , setNClickSizeID] = useState([]);
  //籌碼
  const [chip , setChip] = useState([0]);
  const [nowChip , setNowChip] = useState(0);
  //加總
  const [totalOpen , setTotalOpen] = useState(false);
  const [total , setTotal] = useState(1);
  const [totalLen , setTotalLen] = useState();
  const [totalPrice , setTotalPrice] = useState();
  //下方bar控制
  const [gamePlaceShow , setGamePlaceShow] = useState(false);
  const [gamePlaceButtonShow , setGamePlaceButtonShow] = useState(false);
  //送出資料型
  const [totalSendType , setTotalSendType] = useState();
  //會員
  const [memberDetail , setMemberDetail] = useState()
  //開關
  const [boxSwitch, setBoxswitch] = useState('video');
  //規則popup
  const [rulePop, setRulePop] = useState(false);
  //走勢
  const [historyList , setHistoryList] = useState([]);
  //手動下注值
  const [manualValue , setManualValue] = useState([]);
  //遊戲模式切換
  const [gameModelValue , setGameModelValue] = useState('model1');
  //賠率
  const [odds , setOdds] = useState([])
  const [sumOdds , setSumOdds] = useState([])
  const [twoStarOddsStatus , setTwoStarOddsStatus] = useState()
  //規則
  const [ruleText , setRuleText] = useState('')
  //自填下注金額
  const customChipRef = useRef(0)
  //控制下注後刷新餘額
  const [betRefresh , setBetRefresh] = useState(true)
  const [needRefresh , setNeedRefresh] = useState(true)
  //判斷是否通殺  true = 不通殺開啟   false 不通殺關閉
  const [nkStatus , setNkStatus] = useState(false)
  const [drawData , setDrawDate] = useState({})
  const [drawLoading , setDrawLoading] = useState(true)

  FixBx(fixBxRef,mainRef,loading,loadingMember,fixbarRef,gameModelValue,autoHide)
  // 引入上推function

  useEffect(()=>{
    getData()
  
    // console.log(GameID)
  },[])
  useEffect(()=>{
    if(nkStatus === false){
      getDraw()
    }
  },[nkStatus])


  //getme
  const getMe = async() => {
    try {
      const res = await api.get('/member', {
        headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (res.data.status) {
        setMemberDetail(res.data.data)
      }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    }
  }
  //拿到賠率
  const getOdds = async() => {
    try {
      const res = await api.get(`/games/${GameID}/odds`,{
          headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
          },
      });

      if (res.data.status) {

          setOdds(res.data.data)
       
          if(res.data.data.single_nk.status){
            setNkStatus(true)
            localStorage.setItem('nkStatus' , true)
          } else {
            setNkStatus(false)
            localStorage.setItem('nkStatus' , false)
          }
        }
    } catch (err) {
        const code = err.response.status
        const msg = err.response.data.data.error
        ErrorMessage(code, msg)
    }

  }
  const getRule = async() => {
    try {
      const res = await api.get(`/games/${GameID}/rule?language=${localStorage.getItem("language")}`,{
          headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
          },
      });
      if (res.data.status) {
          setRuleText(res.data.data.rule)
        }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    }

  }
  // 開獎
  const getDraw = async() => {
    try {
      const res = await api.get(`/games/${GameID}/draw`,{
          headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
          },
      });
      if (res.data.status) {
        setDrawDate(res.data.data)
        setDrawLoading(false)
      }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    }

  }
  // TODO : getOdds + getRule  後續帶入遊戲id
  const getData = async() =>{
    setLoading(false)
    try{
      await Promise.all([getMe(), getOdds(),getRule()]);
    } finally{
      setLoading(true)
    }
  }
  //拿到開獎紀錄
  // const getHistory = async() => {

  //   try {
  //     const res = await api.get('/games/1/history',{
  //       headers: {
  //         "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
  //         "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
  //       },
  //     });
  //     if (res.data.status) {
  //       console.log(res.data.data)
  //       setHistoryList(res.data.data)
  //     }
  //   } catch (err) {
  //     console.log(err)
  //     const code = err.response.status
  //     const msg = err.response.data.data.error
  //     ErrorMessage(code, msg)
  //   }
  //   finally {

  //   }
  // }
  //下注後行為
  const chipReduce = async (i) => {
    if(rankID.length !== 0 && (laneID.length !== 0 || sizeID.length !== 0)){
      setNowChip(prevNum => parseInt(prevNum)+parseInt(i));
    }

    if(manualValue.length !== 0){
      setNowChip(prevNum => parseInt(prevNum)+parseInt(i));
    }
  }
  const handleTotalClick = () => {
    setTotalOpen(true)

    //列出列表
    //車號
    const result = laneID && rankID.flatMap((r) => laneID.map((l) => 
      {
        // return chipTotal === 0 ? `${r},${l},${parseInt(nowChip)}` : `${r},${l},${parseInt(chipTotal)+parseInt(nowChip)}`
        return `${r},${l},${parseInt(nowChip)}`
      }
    ));
    //雙面
    const result2 = sizeID && rankID.flatMap((r) => sizeID.map((l) => 
      {
        // return chipTotal === 0 ? `${r},${l},${parseInt(nowChip)}` : `${r},${l},${parseInt(chipTotal)+parseInt(nowChip)}`
        return `${r},${l},${parseInt(nowChip)}`
      }
    ));
    
    const resultTotal = result.concat(result2);
    setTotal(resultTotal);
    
    //計算總金額
    setTotalLen(resultTotal.length)
    setTotalPrice(parseInt(nowChip)*resultTotal.length)
    
    //轉換api格式
    setTotalSendType(resultTotal.join('/'))
    //清空自填金額input
    // clearInputValue(customChipRef)

  }
  const handleManuaTotalClick = () => {
    setTotalOpen(true)    
    //列出列表
    //車號
    // const result = laneID && rankID.flatMap((r) => laneID.map((l) => 
    //   {
    //     // return chipTotal === 0 ? `${r},${l},${parseInt(nowChip)}` : `${r},${l},${parseInt(chipTotal)+parseInt(nowChip)}`
    //     return `${r},${l},${parseInt(nowChip)}`
    //   }
    // ));
    // //雙面
    // const result2 = sizeID && rankID.flatMap((r) => sizeID.map((l) => 
    //   {
    //     // return chipTotal === 0 ? `${r},${l},${parseInt(nowChip)}` : `${r},${l},${parseInt(chipTotal)+parseInt(nowChip)}`
    //     return `${r},${l},${parseInt(nowChip)}`
    //   }
    // ));
    const result = manualValue.map((item) => {
        return `${item},${parseInt(nowChip)}`
    })
    // const resultTotal = result.concat(result2);
    setTotal(result);
    
    //計算總金額
    setTotalLen(result.length)
    setTotalPrice(parseInt(nowChip)*result.length)
    
    //轉換api格式
    setTotalSendType(result.join('/'))
  }
  //儲值(test)
  const payOrder = async(e) => {
    e.preventDefault();

    try {
      const res = await api.post(`/pay-orders`, JSON.stringify({
        type : 1,
        amount : 50
      }), {
        headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (res.data.status) {
        Swal.fire({
          text: intl.formatMessage({
            id: 'alert.success',
          }),
          showConfirmButton: false,
          timer: 1000,
        });
       
      }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg)
    }
    finally {
      setTimeout(() => {
        getMe()
      }, 3000);
    }
  }
  //下注(test)
  const handleBet = async(e) => {
    e.preventDefault();
    console.log(nowChip)
    if(nowChip === 0 || nowChip[0] === 0){
      Swal.fire({
        icon: "warning",
        // text: "請填寫下注金額",
        text : intl.formatMessage({
          id: 'alert.amount',
        }),
        showConfirmButton: true,
      });
    } else{
      try {
        const res = await api.post(`/bets`, {
          bet_str : `${totalSendType}`,
          game_id: Number(GameID),
          language : localStorage.getItem("language")
        }, {
          headers: {
            "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
            "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        if (res.data.status) {
          Swal.fire({
            text: intl.formatMessage({
              id: 'alert.success',
            }),
            showConfirmButton: false,
            timer: 1000,
          });
          getMe()
          empty();
          setBetRefresh(!betRefresh)
        }
      } catch (err) {
        // console.log(err)
        // const code = err.response.status
        // ErrorMessage(code, msg)
        const msg = err.response.data.data.error_msg
        if (msg.includes("api.bad_request")) {
          Swal.fire({
            icon: "warning",
            text: intl.formatMessage({
              id: 'alert.betError',
            }),
            showConfirmButton: true,
          });
        } else if (msg.includes("api.data_invalid.amount")) {
          Swal.fire({
            icon: "warning",
            text: intl.formatMessage({
              id: 'alert.betFailed',
            }),
            showConfirmButton: true,
          });
        } else if (msg.includes("api.data_invalid.bet_time")) {
          Swal.fire({
            icon: "warning",
            text: intl.formatMessage({
              id: 'alert.betTimeout',
            }),
            showConfirmButton: true,
          });
        } else{
          Swal.fire({
            icon: "warning",
            text: intl.formatMessage({
              id: 'alert.betError',
            }),
            showConfirmButton: true,
          });
        }
      }
      finally {
      }
    }
  }
  //盒子開關
  const handleBoxSwitch = (i) =>{
    if(boxSwitch === i){
      setBoxswitch('video');
    }else{
      setBoxswitch(i);
    }
  }
  //打開下方bar
  const handlePlaceShow = () =>{
    if(gamePlaceButtonShow === false){
      setGamePlaceShow(true)
      setGamePlaceButtonShow(true)
    }
  }
  //清空
  const clearInputValue = (ref) => {
    if (ref && ref.current) {
      ref.current.value = ''; // 清空ref的值
    }
  }
  const empty = async() => {
    setTotalOpen(false)
    setTotal(1)
    setChip([])
    setTotalLen(0)
    setTotalPrice(0)
    setNowChip([0])
    setTotalSendType('')
    setLaneID([])
    setSizeID([])
    setRankID([])
    clearInputValue(customChipRef)
    setGamePlaceShow(false)
    setGamePlaceButtonShow(false)
    setNClickSizeID([])
    setManualValue([])
  }
  const emptyC = async() => {
    setTotal(1)
    setChip([])
    setTotalLen(0)
    setTotalPrice(0)
    setNowChip([0])
    setTotalSendType('')
    setLaneID([])
    setSizeID([])
    setRankID([])
    clearInputValue(customChipRef)
    setNClickSizeID([])
    setManualValue([])
  }
  // useEffect(() => {
  //   getMe()
  //   // getHistory()
  // }, []);
  //雙面判斷
  const judgeSize = (value,rank) =>{
    if(autoHide === true){
      switch (value) {
        case "odd":
          setNClickSizeID([...nClickSizeID , `${rank},even`])
          break;
        case "even":
          setNClickSizeID([...nClickSizeID , `${rank},odd`])
          break;
        case "big":
          setNClickSizeID([...nClickSizeID , `${rank},small`])
          break;
        case "small":
          setNClickSizeID([...nClickSizeID , `${rank},big`])
          break;
        default:
          break;
      }
    } else {
      switch (value) {
        case "odd":
          setNClickSizeID([...nClickSizeID , "even"])
          break;
        case "even":
          setNClickSizeID([...nClickSizeID , "odd"])
          break;
        case "big":
          setNClickSizeID([...nClickSizeID , "small"])
          break;
        case "small":
          setNClickSizeID([...nClickSizeID , "big"])
          break;
        default:
          break;
       }
    }
    
  }
  //雙面判斷恢復
  const judgeSizeRecover = (value,rank) =>{
    if(autoHide === true){
      switch (value) {
        case "odd":
        setNClickSizeID(nClickSizeID.filter(item => item !== `${rank},even`))
        break;
        case "even":
        setNClickSizeID(nClickSizeID.filter(item => item !== `${rank},odd`))
        break;
        case "big":
        setNClickSizeID(nClickSizeID.filter(item => item !== `${rank},small`))
        break;
        case "small":
        setNClickSizeID(nClickSizeID.filter(item => item !== `${rank},big`))
        break;
        default:
        break;
      }
    } else {
      switch (value) {
        case "odd":
          setNClickSizeID(nClickSizeID.filter(item => item !== 'even'))
          break;
        case "even":
          setNClickSizeID(nClickSizeID.filter(item => item !== 'odd'))
          break;
        case "big":
          setNClickSizeID(nClickSizeID.filter(item => item !== 'small'))
          break;
        case "small":
          setNClickSizeID(nClickSizeID.filter(item => item !== 'big'))
          break;
        default:
          break;
       }
    }
    
  }
  const handlePlayButtonClick = (value , state , set , rank) => {
    if(gameModelValue === 'model1'){
      if(autoHide === true){
        if (manualValue.indexOf(`${rank},${value}`) == -1) {
          setManualValue(prevNum => [...prevNum ,`${rank},${value}`])
          judgeSize(value,rank);
          setRankID(rank)
        } else{
            const newManuaValue = manualValue.filter((ele) => ele !== `${rank},${value}` )
            setManualValue(newManuaValue)
            judgeSizeRecover(value,rank)
            setRankID(rank)
        }
      } else {
        // 判斷有沒有選過 
        if (state.indexOf(value) !== -1) {
          // 選過就刪掉
          const newArray = state.filter((element) => element !== value);
          judgeSizeRecover(value)
          set(newArray);
        } else {
          set([...state, value]);
          judgeSize(value);
        }
      }
    } else {
      if (state.indexOf(value) !== -1) {
        // 選過就刪掉
        const newArray = state.filter((element) => element !== value);
        judgeSizeRecover(value)
        set(newArray);
      } else {
        set([...state, value]);
        judgeSize(value);
      }
    }
  };

  const judgeNowGameName = (value) =>{
    switch (value) {
      case '161':
        return 'bingo18 1min'
      case '162':
        return 'bingo18 1min'
      case '163':
        return 'bingo18 3min'
      case '164':
        return 'bingo18 3min'
      case '165':
        return 'bingo18 5min'
      case '166':
        return 'bingo18 5min'
      default:
        return 'bingo18'
    }
  }

  

  return (
    <div>
      {gamePlaceButtonShow &&
        <GamePlace
        handlePlayButtonClick={handlePlayButtonClick}
        chip={chip} 
        setChip={setChip}
        handleTotalClick={handleTotalClick}
        total={total}
        totalOpen={totalOpen}
        totalLen={totalLen}
        totalPrice={totalPrice}
        handleBet={handleBet}
        empty={empty}
        customChipRef={customChipRef}
        clearInputValue={clearInputValue}
        chipReduce={chipReduce}
        setGamePlaceShow={setGamePlaceShow}
        gamePlaceShow={gamePlaceShow}
        gameID={'159'}
        ></GamePlace>
      }
      

      <div className="gamePage page mt-0 bingoPage">
        <div ref={fixBxRef} className="fixBx">
          <header className='d-flex'>
            <div className="left">

              <button className='backBtn' onClick={()=>{
                  navigate(`/index`);
              }}
              ><ReplyIcon/></button>
              <p className="title mb-0">
                {judgeNowGameName(GameID)}
                {/* {
                  GameID === '1' && 
                  <FormattedMessage
                      id={`game.name`}
                  />
                }
                {
                  GameID === '113' && 
                  <FormattedMessage
                      id={`game.name2`}
                  />
                }
                {
                  GameID === '114' && 
                  <FormattedMessage
                      id={`game.name5`}
                  />
                } */}
               
              </p>
              {/* <span>1231231期</span> */}
            </div>
            <div className="center">
              {/* <p className='mb-0'>離封盤</p>
              <span>00:00</span> */}
            </div>
            <div className="right">
              <button 
              onClick={() => {
                handleBoxSwitch('');
              }}
              >
                {
                    boxSwitch === '' ?
                    <FormattedMessage
                      id={`game.open`}
                    />
                    :
                    <FormattedMessage
                      id={`game.close`}
                    />
                }
               
              </button>
              <button
              onClick={() => {
                handleBoxSwitch('trend');
              }}
              >
                {
                  boxSwitch === 'trend' ? 
                  <FormattedMessage
                      id={`game.game`}
                  /> :
                  <FormattedMessage
                      id={`game.trend`}
                  />
                }
                
              </button>
              <button
              onClick={() => {
                // handleBoxSwitch('rule');
                setRulePop(true)
              }}
              >
                <FormattedMessage
                    id={`game.rule`}
                />
              </button>
            </div>
          </header>
        
              {
                boxSwitch === 'video' && 
                <>
                  {
                    nkStatus ? 
                    <div className="vedioWrap">
                        <iframe id="carVideo" src='../../../bingo/index.html'></iframe>
                    </div>  
                    :
                    <div className='nkDrawBx'>
                      {
                        drawLoading ||
                        <>
                          <div className='topBx'>
                            <div className='Issue'>
                            <span>
                              <FormattedMessage
                                id={`game.issue`}
                              />  : </span>{drawData.latest_issue_no}
                            </div>
                            <div className='date'>
                              {TimeMoment(drawData.latest_date)}
                              {/* {moment(drawData.latest_date).format('YYYY-MM-DD HH:mm:ss')} */}
                            </div>
                            <button className='refreshBtn' 
                            onClick={()=>{
                              getDraw()
                            }}
                            >
                              <RefreshIcon/>
                            </button>
                          </div>
                          <div className='drawNumber'>
                            {
                              drawData.result.result.map((v)=>{
                                return(
                                  <div>{v}</div>
                                )
                              })
                            }
                            {/* <div>1</div>
                            <div>1</div>
                            <div>1</div> */}
                          </div>
                          <div className='drawSum'>
                            <div>{drawData.result.sum}</div>
                            <div>
                              {
                                drawData.result.sum <= 10 ? 
                                <FormattedMessage
                                id={`game.small`}
                                /> : <FormattedMessage
                                id={`game.big`}
                                />
                              }
                            </div>
                            <div>
                              { 
                                drawData.result.sum %2 === 0 ? 
                                <FormattedMessage
                                id={`game.even`}
                                />  : <FormattedMessage
                                id={`game.odd`}
                                /> 
                              }
                            </div>
                          </div>
                        </>
                      }
                    </div>
                  }
                </>
               
                // <CarVideo GameID={GameID}></CarVideo>
              }
              {
                boxSwitch === 'trend' && <GameTrend gameID={localStorage.getItem("bingoID")} nkStatus={nkStatus}></GameTrend>
              }
              {
                rulePop && <GameRule setRulePop={setRulePop} ruleText={ruleText}></GameRule>
              }

            <MemberWrap loadingMember={loadingMember} setLoadingMember={setLoadingMember} betRefresh={betRefresh} setBetRefresh={setBetRefresh} needRefresh={needRefresh}/>
        </div>
        <div className="home-bg2">
          <div ref={mainRef} className="gameBotBx">
            <ul className='gameModel'>
              {/* {
                GameModel.map((v)=>{
                  return (
                    v.status &&
                    <li key={v.value}
                    data-active={gameModelValue.includes(`${v.value}`)}
                    onClick={() => {
                      setGameModelValue(v.value)
                      empty()
                    }}
                    >
                      <div>
                        <FormattedMessage
                            id={`game.${v.value}`}
                        />
                      </div>
                    </li>
                  )
                })
              } */}
            </ul>
            {
                loading && 
                <>
                  {
                    gameModelValue === 'model1' &&
                    <>
                        {
                            <ManualBet
                            rankID={rankID}
                            handleTotalClick={handleTotalClick}
                            payOrder={payOrder}
                            Lane={Lane}
                            laneID={laneID}
                            setLaneID={setLaneID}
                            Lane2={Lane2}
                            sizeID={sizeID}
                            setSizeID={setSizeID}
                            nClickSizeID={nClickSizeID}
                            setAutoHide={setAutoHide}
                            manualValue={manualValue}
                            handleManuaTotalClick={handleManuaTotalClick}
                            nowChip={nowChip}
                            handlePlaceShow={handlePlaceShow}
                            empty={empty}
                            emptyC={emptyC}
                            handlePlayButtonClick={handlePlayButtonClick}
                            fixbarRef={fixbarRef}
                            odds={odds}
                            setGamePlaceShow={setGamePlaceShow}
                            setGamePlaceButtonShow={setGamePlaceButtonShow}
                            />   
                        }
                    </>
                  }
                </>
            
            }
          </div>
        </div>
        
        

      </div>

      
    </div>
  )
}

export default BingoPage